import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import { IconButton } from '../IconButton/IconButton';
import { MouseEvent } from 'react';
import { useStore } from '../../../state/AppState';

interface CopyButtonProps {
  contentForCopy?: string;
  sizeInPx?: number;
  notificationText?: string;
  label?: string;
}

export const CopyButton = observer((props: CopyButtonProps) => {
  const state = useStore();
  const { notifications } = state;
  const onCopyClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    console.debug('onCopyClick');
    if (props.contentForCopy) {
      copy(props.contentForCopy);
      notifications.addNotification(props.notificationText || 'Copied');
    }
  };
  return (
    <IconButton
      onClick={e => onCopyClick(e)}
      label={props.label}
      iconName="copy"
      heightInPx={props.sizeInPx ?? 16}
      widthInPx={props.sizeInPx ?? 16}
    />
  );
});
