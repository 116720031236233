import { observer } from 'mobx-react';
import { Input } from '../Input/Input';
import './MainSearchInput.scss';
import { useStore } from '../../../state/AppState';
import { SearchBlock } from '../../SearchBlock/SearchBlock';
import cn from 'classnames';

export const MainSearchInput = observer(() => {
  const store = useStore();
  const { searchStore } = store;
  return (
    <div className={cn('MainSearchInputWrapper', { isActive: searchStore.searchIsActive })}>
      <Input
        iconName="search"
        placeholder="Search Transactions, Addresses and Blocks"
        onChange={searchStore.onChangeSearchText}
        onEnter={searchStore.search}
        classNames="MainSearchInput"
        resetTextBtn
        value={searchStore.searchText}
      />
      {searchStore.searchIsActive ? <SearchBlock /> : null}
    </div>
  );
});
