import { observer } from 'mobx-react';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { Input } from '../../components/controls/Input/Input';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useEffect } from 'react';
import { useStore } from '../../state/AppState';
import { ContentBlockTitle } from '../../components/ContentBlock/ContentBlockTitle';
import { CurrencySelect } from '../../components/controls/CurrencySelect/CurrencySelect';
import { protocol } from '../../api/proto';
import { TableLoader } from '../../components/TableLoader/TableLoader';
import { Link } from 'react-router-dom';
import { CurrencyIcon } from '../../components/CurrencyIcon/CurrencyIcon';
import { chainToCurrency } from '../../state/utils/Money';
import { TxAddressesList } from '../../components/TxAddressesList/TxAddressesList';

export const TransactionsPage = observer(() => {
  const store = useStore();
  const { transactionsStore } = store;
  useEffect(() => {
    transactionsStore.initPage();
    return () => {
      transactionsStore.reset();
    };
  }, []);
  return (
    <PageContainer>
      <MainSearchInput />
      <ContentBlock>
        <ContentBlockTitle className="margin-b-16">Transactions</ContentBlockTitle>
        <Input
          iconName="search"
          placeholder="Search Transactions"
          onChange={transactionsStore.onChangeSearchText}
          onEnter={transactionsStore.search}
          classNames="BlocksPageSearchInput margin-b-16"
          resetTextBtn
          value={transactionsStore.searchText}
        />
        <CurrencySelect
          className="margin-b-16"
          onChange={transactionsStore.onChangeSelectedChain}
          chain={transactionsStore.selectedChain}
        />

        <>
          <div className="TableContainer">
            <table className="Table">
              <thead>
                <tr>
                  <th>Hash</th>
                  <th>Amount</th>
                  <th>Amount, $</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Date and Time</th>
                </tr>
              </thead>
              {transactionsStore.isLoading ? (
                <TableLoader itemsInRow={5} />
              ) : transactionsStore.txs?.length ? (
                <tbody>
                  {transactionsStore.txs.map(tx => (
                    <tr>
                      <td>
                        <Link to={`/transaction/${tx.hash}`}>{tx.shortHash}</Link>
                      </td>
                      <td>
                        <div className="d-flex flex-align-items-center column-gap-4">
                          {tx.amountFormatted}
                          <span>
                            {tx.amountCurrency ? <CurrencyIcon size="sm" currency={tx.amountCurrency} /> : null}
                          </span>
                          <span>{tx.amountCurrencyFormatted}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-align-items-center column-gap-4">{tx.amountUsdFormatted}</div>
                      </td>
                      <td>
                        {tx?.ins?.length ? (
                          <TxAddressesList
                            addresses={tx?.ins?.map(_ => ({ address: _.address, isValidAddress: _.isValidAddress }))}
                          />
                        ) : null}
                      </td>
                      <td>
                        {tx?.outs?.length ? (
                          <TxAddressesList
                            addresses={tx?.outs?.map(_ => ({ address: _.address, isValidAddress: _.isValidAddress }))}
                          />
                        ) : null}
                      </td>
                      <td className="dateTime">{tx.timeFormatted}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div>Transactions for address not found</div>
              )}
            </table>
          </div>
        </>
      </ContentBlock>
    </PageContainer>
  );
});
