import { observer } from 'mobx-react';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { useStore } from '../../state/AppState';
import Skeleton from 'react-loading-skeleton';
import { BackButton } from '../../components/controls/BackButton/BackButton';
import './BlockPage.scss';

export const BlockContent = observer(() => {
  const store = useStore();
  const { blockPageStore } = store;
  const { block } = blockPageStore;

  if (blockPageStore.isLoading) {
    return (
      <ContentBlock>
        <>
          {Array.from(Array(4)).map(item => (
            <div className="margin-b-8">
              <Skeleton width={120} height={36} />
            </div>
          ))}
        </>
      </ContentBlock>
    );
  }
  return (
    <ContentBlock>
      <BackButton className="margin-b-16" />
      <div className="BlockPageTitle margin-b-4">
        {block?.chainFullName} block {block?.blockHeight?.toNumber()}
      </div>
      <div className="BlockPageTime margin-b-16">Mined on {block?.timeFormatted}</div>
      <div className="BlockContent__details">
        <div className="InfoItem margin-b-8">
          <div className="InfoItem__title">Block height</div>
          <div className="InfoItem__value">{block?.blockHeight?.toNumber()}</div>
        </div>
        <div className="InfoItem margin-b-8">
          <div className="InfoItem__title">Chain</div>
          <div className="InfoItem__value">{block?.chainShortName}</div>
        </div>
        <div className="InfoItem margin-b-8">
          <div className="InfoItem__title">Time</div>
          <div className="InfoItem__value">{block?.timeFormatted}</div>
        </div>
        <div className="InfoItem margin-b-8">
          <div className="InfoItem__title">Tx count</div>
          <div className="InfoItem__value">{block?.txCount}</div>
        </div>
        <div className="InfoItem margin-b-8">
          <div className="InfoItem__title">Size</div>
          <div className="InfoItem__value">{block?.blockSize ?? 0} bytes</div>
        </div>
        {block?.info?.map(_ => {
          return (
            <div key={_.label} className="InfoItem margin-b-8">
              <div className="InfoItem__title">{_.label}</div>
              <div className="InfoItem__value">{_.value}</div>
            </div>
          );
        })}
      </div>
    </ContentBlock>
  );
});
