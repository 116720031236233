import { makeObservable, observable, action, makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { v4 as uuid } from 'uuid';
import { protocol } from '../api/proto';
import Long from 'long';
import { Block } from './Block';

export class BlocksStore {
  private appState: AppState;

  searchText: string = '';
  selectedChain: protocol.Chain = protocol.Chain.BTC;
  isLoading: boolean = false;
  isSearchActive: boolean = false;

  blocks?: Block[] = [];

  constructor(appState: AppState) {
    makeAutoObservable(this);
    this.appState = appState;
  }

  onChangeSearchText = (val: string) => {
    this.searchText = val;
    if (val === '') {
      this.isSearchActive = false;
      this.loadBlocks();
    }
  };

  onChangeSelectedChain = (chain: protocol.Chain) => {
    this.selectedChain = chain;
    if (this.isSearchActive) {
      this.search();
    } else {
      this.loadBlocks();
    }
  };

  resetSearchText = () => {
    this.searchText = '';
  };

  search = async () => {
    if (this.searchText?.length) {
      this.isSearchActive = true;
      this.blocks = [];
      this.isLoading = true;
      const res = await this.appState.api.getBlockByHeight({
        chain: this.selectedChain,
        heights: [Long.fromString(this.searchText)],
      });
      if (res?.error) {
      } else if (res.getBlockByHeight?.blocks?.length) {
        this.blocks = [new Block(res.getBlockByHeight?.blocks[0])];
      }
      this.isLoading = false;
    }
  };

  initPage = () => {
    if (!this.isSearchActive) {
      this.loadBlocks();
    }
  };

  loadBlocks = async () => {
    this.isLoading = true;
    this.blocks = [];
    const res = await this.appState.api.getBlockByHeight({
      chain: this.selectedChain,
      heights: [Long.fromNumber(1)],
    });
    if (res?.error) {
    } else if (res.getBlockByHeight?.blocks?.length) {
      const firstBlock = res.getBlockByHeight?.blocks[0];
      const lastBlock = firstBlock.blockConfirmations?.notEquals(0) ? firstBlock.blockConfirmations : null;
      if (lastBlock) {
        let blocks = [];
        if (lastBlock.greaterThan(15)) {
          for (let i = 0; i < 15; i++) {
            blocks.push(lastBlock.subtract(i));
          }
        }
        const res = await this.appState.api.getBlockByHeight({
          chain: this.selectedChain,
          heights: blocks,
        });
        if (res.getBlockByHeight?.blocks?.length) {
          this.blocks = res.getBlockByHeight?.blocks?.map(_ => new Block(_));
        }
      }
    }
    this.isLoading = false;
  };

  reset = () => {
    this.isLoading = false;
    this.selectedChain = protocol.Chain.BTC;
    this.resetSearchText();
    this.blocks = [];
    this.isSearchActive = false;
  };
}
