import { Icon } from '../../Icon/Icon';
import './Button.scss';
import cn from 'classnames';

type ButtonProps = {
  label: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  iconName?: string;
  iconPosition?: 'left' | 'right';
  className?: string;
};

export const Button = ({ label, onClick, iconName, iconPosition, className }: ButtonProps) => {
  return (
    <div onClick={onClick} className={cn('Button', className)}>
      {iconName && iconPosition === 'left' ? <Icon iconName={iconName} widthInPx={16} heightInPx={16} /> : null}
      {label}
      {iconName && iconPosition !== 'left' ? <Icon iconName={iconName} widthInPx={16} heightInPx={16} /> : null}
    </div>
  );
};
