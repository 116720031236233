import { observer } from 'mobx-react';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useStore } from '../../state/AppState';
import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { Icon } from '../../components/Icon/Icon';
import './TransactionPage.scss';
import { CopyButton } from '../../components/controls/CopyButton/CopyButton';
import { getShortAddress } from '../../state/utils/getShortAddress';
import { BackButton } from '../../components/controls/BackButton/BackButton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Button } from '../../components/controls/Button/Button';
import { CurrencyIcon } from '../../components/CurrencyIcon/CurrencyIcon';
import { Link } from 'react-router-dom';
import { TransactionInsOuts } from '../../components/TransactionInsOuts/TransactionInsOuts';
import { getChainShortName } from '../../state/utils/Money';

export const TransactionPage = observer(() => {
  const store = useStore();
  const { transactionStore } = store;
  const navigate = useNavigate();
  const { txInfo } = transactionStore;
  const params = useParams();
  useEffect(() => {
    transactionStore.init(params.txHash);
  }, [params.txHash]);
  return (
    <PageContainer>
      <MainSearchInput />
      {transactionStore.txLoading || transactionStore.txInfo ? (
        <TransactionContent />
      ) : (
        <ContentBlock>
          <div className="d-flex flex-justify-center">
            <div className="d-flex flex-column flex-align-items-center">
              <div className="margin-b-16">Transaction not found</div>
              <Button onClick={() => navigate('/')} label="Main page" />
            </div>
          </div>
        </ContentBlock>
      )}
    </PageContainer>
  );
});

const TransactionContent = observer(() => {
  const store = useStore();
  const { transactionStore } = store;
  const { txInfo } = transactionStore;

  return (
    <div className="TransactionPageContainer">
      <ContentBlock>
        <>
          <BackButton />

          <div className="TransactionPage__currencyTitle margin-b-16">
            {transactionStore.txLoading ? (
              <Skeleton height={24} width={120} />
            ) : (
              <>
                <Icon iconName={`${txInfo?.chainShortName?.toLowerCase()}-icon`} widthInPx={24} heightInPx={24} />
                <div className="TransactionPage__currencyTitle__full">{txInfo?.chainFullName}</div>{' '}
                <div className="TransactionPage__currencyTitle__short">{txInfo?.chainShortName}</div>
              </>
            )}
          </div>

          <div className="TransactionPage__txTime margin-b-8">
            {transactionStore.txLoading ? <Skeleton height={16} width={120} /> : txInfo?.timeFormatted}
          </div>
          {txInfo?.hash || transactionStore.txLoading ? (
            <div className="InfoItem margin-b-8">
              <div className="InfoItem__title">Hash ID</div>
              {transactionStore.txLoading ? (
                <Skeleton height={16} width={300} />
              ) : (
                <div className="InfoItem__value">
                  {txInfo?.hash} <CopyButton contentForCopy={txInfo?.hash!} />
                </div>
              )}
            </div>
          ) : null}
          {!txInfo?.isDifferentCurrenciesInOuts || transactionStore.txLoading ? (
            <div className="InfoItem margin-b-8">
              <div className="InfoItem__title">Amount</div>{' '}
              {transactionStore.txLoading ? (
                <Skeleton height={24} width={200} />
              ) : (
                <div className="InfoItem__value big nowrap">
                  {txInfo?.amountFormatted}{' '}
                  <span>
                    {txInfo?.amountCurrency ? <CurrencyIcon size="lg" currency={txInfo?.amountCurrency} /> : null}
                  </span>
                  <span>{txInfo?.amountCurrencyFormatted}</span>
                  {txInfo?.amountUsdFormatted ? (
                    <span className="InfoItem__value__gray"> · {txInfo?.amountUsdFormatted}</span>
                  ) : null}
                </div>
              )}
            </div>
          ) : null}
          <div className="InfoItem margin-b-8">
            <div className="InfoItem__title">Fee</div>
            <div className="InfoItem__value big nowrap">
              {transactionStore.txLoading ? (
                <Skeleton height={24} width={200} />
              ) : (
                <>
                  {txInfo?.feeFormatted}
                  <span>{txInfo?.feeCurrencyFormatted}</span>
                  {txInfo?.feeUsdFormatted ? (
                    <span className="InfoItem__value__gray"> · {txInfo?.feeUsdFormatted}</span>
                  ) : null}
                </>
              )}
            </div>
          </div>
          {transactionStore.txLoading ? (
            <Skeleton height={90} width={'100%'} />
          ) : (
            <div className="TransactionPage__txInsOutsContainer">
              {txInfo?.txOperations?.map(op => {
                return <TransactionInsOuts ins={op.ins ?? []} outs={op.outs ?? []} />;
              })}
            </div>
          )}
        </>
      </ContentBlock>
      <ContentBlock>
        <div className="TransactionPage__details">
          {transactionStore.txLoading ? (
            <DetailsLoader />
          ) : (
            <>
              {txInfo?.hash ? (
                <div className="InfoItem margin-b-8">
                  <div className="InfoItem__title">Hash ID</div>
                  <div className="InfoItem__value">
                    {getShortAddress(txInfo?.hash)} <CopyButton contentForCopy={txInfo?.hash} />
                  </div>
                </div>
              ) : null}
              <div className="InfoItem margin-b-8">
                <div className="InfoItem__title">Age</div>
                <div className="InfoItem__value">{txInfo?.ageFormatted}</div>
              </div>
              <div className="InfoItem margin-b-8">
                <div className="InfoItem__title">Block height</div>
                <div className="InfoItem__value">
                  {txInfo?.blockHeight ? (
                    <Link to={`/blocks/${getChainShortName(txInfo?.chain!)}/${txInfo?.blockHeight?.toNumber()}`}>
                      {txInfo?.blockHeight?.toNumber()}
                    </Link>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              {txInfo?.details?.map(_ => (
                <div key={_.label} className="InfoItem margin-b-8">
                  <div className="InfoItem__title">{_.label}</div>
                  <div className="InfoItem__value">{_.value}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </ContentBlock>
    </div>
  );
});

const DetailsLoader = () => {
  return (
    <>
      {Array.from(Array(4)).map(item => (
        <div className="margin-b-8">
          <Skeleton width={120} height={36} />
        </div>
      ))}
    </>
  );
};
