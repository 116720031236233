import { computed, makeAutoObservable } from 'mobx';
import { protocol } from '../api/proto';
import Long from 'long';
import { formatCurrency, getChainFullName, getChainShortName } from './utils/Money';
import { formatDateTime } from './utils/datatime';
import { formatSize } from './utils/formatSize';

type BlockInfoItem = {
  label: string;
  value: string;
};

const ethKeys: { key: string; label: string }[] = [
  { key: 'totalBlockRewardStr', label: 'Total block reward' },
  { key: 'staticBlockRewardStr', label: 'Static block reward' },
  { key: 'transactionFeesStr', label: 'Transactions fees' },
  { key: 'burntFeesStr', label: 'Burnt fees' },
  { key: 'gasUsed', label: 'Gas used' },
  { key: 'gasLimitStr', label: 'Gas limit' },
  { key: 'baseFeePerGas', label: 'Fee per gas' },
  { key: 'extraDataStr', label: 'Extra data' },
  { key: 'feeRecipient', label: 'Fee recipient' },
  { key: 'totalDifficultyStr', label: 'Total difficulty' },
];

const trxKeys: {
  key: keyof protocol.ITronSpecificBlockInfo;
  key2?: keyof protocol.ITronBlockTotalConsumption | keyof protocol.ITronBlockTransactionOverview;
  label: string;
}[] = [
  { key: 'blockHash', label: 'Block hash' },
  { key: 'parentBlockHash', label: 'Parent block hash' },
  { key: 'versionNumber', label: 'Version number' },
  { key: 'blockRewards', label: 'Block rewards' },
  { key: 'transactionOverview', key2: 'transactionCount', label: 'Transactions count' },
  { key: 'transactionOverview', key2: 'transfersCount', label: 'Transfers count' },
  { key: 'transactionOverview', key2: 'internalTransactionsCount', label: 'Internal transactions count' },
  { key: 'totalConsumption', key2: 'trxBurned', label: 'TRX burned' },
  { key: 'totalConsumption', key2: 'totalBandwidth', label: 'Total bandwidth' },
  { key: 'totalConsumption', key2: 'totalEnergy', label: 'Total energy' },
];

const btcKeys: {
  key: keyof protocol.IBtcSpecificBlockInfo;
  label: string;
}[] = [
  { key: 'blockHash', label: 'Block hash' },
  { key: 'previousBlockHash', label: ' Previous block hash' },
  { key: 'miner', label: 'Miner' },
  { key: 'blockReward', label: 'Block reward' },
  { key: 'staticBlockReward', label: 'Static block reward' },
  { key: 'transactionFees', label: 'Transaction fees' },
  { key: 'difficulty', label: 'Difficulty' },
  { key: 'nonce', label: 'Nonce' },
  { key: 'version', label: 'Version' },
  { key: 'fees', label: 'Fees' },
  { key: 'inputsCount', label: 'Inputs' },
  { key: 'outputsCount', label: 'Outputs' },
  { key: 'merkleRoot', label: 'Merkle root' },
  { key: 'blockValue', label: 'Block value' },
];

export class Block implements protocol.IUnifiedBlock {
  chain?: protocol.Chain | null | undefined;
  network?: protocol.CryptoNetwork | null | undefined;
  blockHeight?: Long | null | undefined;
  blockTime?: Long | null | undefined;
  transactions?: protocol.IUnifiedTransaction[] | null | undefined;
  blockSize?: number | null | undefined;
  blockConfirmations?: Long | null | undefined;

  chainFullName?: string = undefined;
  chainShortName?: string = undefined;
  timeFormatted?: string = undefined;
  sizeFormatted?: string = undefined;

  txCount?: number = undefined;
  blockSpecific?: protocol.IChainSpecificInfoForBlock | null | undefined;

  ethSpecificInfo: BlockInfoItem[] = [];
  trxSpecificInfo: BlockInfoItem[] = [];
  btcSpecificInfo: BlockInfoItem[] = [];

  initialLoad?: boolean = false;

  constructor(block: protocol.IUnifiedBlock, initialLoad?: boolean) {
    Object.assign(this, block);
    makeAutoObservable(this);
    this.initialLoad = initialLoad;
    this.processingBlock(block);
  }

  get info() {
    if (this.chain === protocol.Chain.ETH) {
      return this.ethSpecificInfo;
    }
    if (this.chain === protocol.Chain.TRX) {
      return this.trxSpecificInfo;
    }
    if (this.chain === protocol.Chain.BTC) {
      return this.btcSpecificInfo;
    }
    return [];
  }

  processingBlock = (block: protocol.IUnifiedBlock) => {
    this.chain = block.chain ?? protocol.Chain.BTC;
    this.chainFullName = getChainFullName(block.chain!);
    this.chainShortName = getChainShortName(block.chain!);
    if (block.blockTime) {
      this.timeFormatted = formatDateTime(block.blockTime?.toNumber());
    }
    this.sizeFormatted = formatSize(block.blockSize ?? 0);

    this.txCount = block.txCount ?? 0;

    if (block.chain === protocol.Chain.ETH && block.blockSpecific?.ethSpecific) {
      this.processingEthInfo(block.blockSpecific?.ethSpecific);
    }
    if (block.chain === protocol.Chain.TRX && block.blockSpecific?.tronSpecific) {
      this.processingTrxInfo(block.blockSpecific?.tronSpecific);
    }
    if (block.chain === protocol.Chain.BTC && block.blockSpecific?.btcSpecific) {
      this.processingBtcInfo(block.blockSpecific?.btcSpecific);
    }
  };

  processingBtcInfo = (info: protocol.IBtcSpecificBlockInfo) => {
    btcKeys.forEach(_ => {
      let value: any = '';
      switch (_.key) {
        case 'blockReward':
          value = formatCurrency(
            info.blockReward?.value?.numberInStr ?? '0',
            info.blockReward?.valueCurrency ?? protocol.WLCurrency.WLC_BTC,
          );
          break;

        default:
          value = info[_.key as keyof protocol.IBtcSpecificBlockInfo]
            ? info[_.key as keyof protocol.IBtcSpecificBlockInfo]
            : null;

          value = this.processValue(value);
      }
      this.btcSpecificInfo.push({
        label: _.label,
        value: value,
      });
    });
  };

  processingEthInfo = (info: protocol.IEthSpecificBlockInfo) => {
    ethKeys.forEach(_ => {
      let value: any = '';
      switch (_.key) {
        /*case 'size':
          value = `${info[_.key]} bytes`;
          break;*/
        case 'gasUsed':
          value = `${info[_.key]?.numberInStr} (${info.percentageOfGasUsed}%)`;
          break;
        case 'baseFeePerGas':
          value = formatCurrency(info[_.key]?.numberInStr ?? '0', protocol.WLCurrency.WLC_ETH);
          break;
        case 'feeRecipient':
          value = info.feeRecipient?.address ?? '-';
          break;
        case 'totalBlockRewardStr':
          value = formatCurrency(
            info.blockReward?.totalBlockReward?.value?.numberInStr ?? '0',
            protocol.WLCurrency.WLC_ETH,
          );
          break;
        case 'staticBlockRewardStr':
          value = formatCurrency(
            info.blockReward?.staticBlockReward?.value?.numberInStr ?? '0',
            protocol.WLCurrency.WLC_ETH,
          );
          break;
        case 'transactionFeesStr':
          value = formatCurrency(
            info.blockReward?.transactionFees?.value?.numberInStr ?? '0',
            protocol.WLCurrency.WLC_ETH,
          );
          break;
        case 'burntFeesStr':
          value = formatCurrency(info.blockReward?.burntFees?.value?.numberInStr ?? '0', protocol.WLCurrency.WLC_ETH);
          break;
        default:
          value = info[_.key as keyof protocol.IEthSpecificBlockInfo];
          value = this.processValue(value);
      }
      this.ethSpecificInfo.push({
        label: _.label,
        value: value,
      });
    });
  };

  processingTrxInfo = (info: protocol.ITronSpecificBlockInfo) => {
    trxKeys.forEach(_ => {
      let value: any = '';
      switch (_.key) {
        case 'blockRewards':
          value = formatCurrency(info.blockRewards?.numberInStr ?? '0', protocol.WLCurrency.WLC_TRX);
          break;
        default:
          if (_.key2) {
            value = info[_.key] ? (info[_.key] as unknown as any)[_.key2] : null;
            value = this.processValue(value);
          } else {
            value = info[_.key as keyof protocol.ITronSpecificBlockInfo];
            console.log(value);

            value = this.processValue(value);
          }
      }
      this.trxSpecificInfo.push({
        label: _.label,
        value: value,
      });
    });
  };

  processValue = (value: protocol.IUniValue | protocol.IUnBigInt | Long | string | number | null | undefined) => {
    console.log(value);
    if (!value) {
      return '-';
    }
    if (value instanceof protocol.UniValue) {
      return value?.valueCurrency ? formatCurrency(value?.value?.numberInStr ?? '0', value?.valueCurrency) : '-';
    }
    if (value instanceof protocol.UnBigInt) {
      return value?.numberInStr ?? '-';
    }
    if (Long.isLong(value)) {
      return value.toString();
    }
    return String(value);
  };
}
