import { Link } from 'react-router-dom';
import useComponentVisible from '../../hooks/useComponentVisible';
import { getShortAddress } from '../../state/utils/getShortAddress';
import { Button } from '../controls/Button/Button';
import { Icon } from '../Icon/Icon';
import { CopyButton } from '../controls/CopyButton/CopyButton';
import './TxAddressesList.scss';

export const TxAddressesList = ({
  addresses,
  onToggleShowMoreClick,
}: {
  addresses: { address: string; isValidAddress?: boolean }[];
  onToggleShowMoreClick?: () => void;
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleShowMore = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onToggleShowMoreClick) {
      onToggleShowMoreClick();
    } else {
      setIsComponentVisible(!isComponentVisible);
    }
  };
  return (
    <div className="TxAddressesList d-flex column-gap-4" ref={ref}>
      {addresses[0].isValidAddress ? (
        <Link to={`/address/${addresses[0].address}`}>{getShortAddress(addresses[0].address)}</Link>
      ) : (
        <span>{addresses[0].address}</span>
      )}
      <CopyButton contentForCopy={addresses[0].address} />
      {addresses?.length > 1 ? <Button label={`+${addresses?.length - 1}`} onClick={toggleShowMore} /> : null}
      {isComponentVisible ? (
        <div className="TxAddressesList__moreItems ">
          <div className="TxAddressesList__moreItems__title d-flex flex-justify-content-space-between">
            <div>
              {addresses.length - 1} {addresses.length - 1 > 1 ? 'addresses' : 'address'}
            </div>
            <div className="" onClick={toggleShowMore}>
              <Icon iconName="close" widthInPx={12} heightInPx={12} />
            </div>
          </div>

          <div className="TxAddressesList__moreItems__list CustomScrollBar">
            {addresses.slice(1, addresses.length).map(address => (
              <div className="TxAddressesList__moreItems__item d-flex column-gap-4">
                <Link to={`/address/${address.address}`}>{address.address}</Link>
                <CopyButton contentForCopy={address.address} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
