import { makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import Long from 'long';
import { chainOptions } from './utils/Money';
import { Block } from './Block';

export class SearchStore {
  private appState: AppState;

  searchText: string = '';

  searchIsActive: boolean = false;
  isLoading: boolean = false;
  error?: string;
  routeBeforeSearch?: string = undefined;

  constructor(appState: AppState) {
    this.appState = appState;
    makeAutoObservable(this);
  }

  onChangeSearchText = (val: string) => {
    this.searchText = val;
  };

  resetSearchText = () => {
    this.searchText = '';
  };

  search = async () => {
    if (this.searchText?.length) {
      this.isLoading = true;
      this.searchIsActive = true;
      this.routeBeforeSearch = this.appState.currentRoute;
      if (this.appState.navigate) {
        if (new RegExp(/^\d+$/).test(this.searchText)) {
          let blocks: Block[] = [];
          for (let i = 0; i < chainOptions?.length; i++) {
            const res = await this.appState.api.getBlockByHeight({
              chain: chainOptions[i].value,
              heights: [Long.fromString(this.searchText)],
            });
            if (res.getBlockByHeight?.blocks?.length) {
              blocks.push(new Block(res.getBlockByHeight?.blocks[0]));
            }
          }
          if (blocks?.length) {
            this.appState.blocksStore.isSearchActive = true;
            this.appState.blocksStore.blocks = blocks;
            this.appState.navigate(`/blocks`);
            this.reset();

            return;
          }
        } else {
          const res = await this.appState.api.getTxByHashRequest({ txHashes: [this.searchText] });
          if (res.error) {
          } else if (res.getTxByHash?.txs?.length && res.getTxByHash.txs[0].hash) {
            this.appState.navigate(`/transaction/${this.searchText}`);
            this.reset();
            return;
          }
          const resAddress = await this.appState.api.findTxByAddress({
            address: this.searchText,
            pagination: { limit: Long.fromNumber(10), offset: Long.fromNumber(0) },
          });
          if (resAddress.error) {
            this.error = res.error;
            this.isLoading = false;
            return;
          } else if (resAddress.findTxByAddress?.txs?.length) {
            this.appState.navigate(`/address/${this.searchText}`);
            this.reset();
            return;
          }
        }

        this.isLoading = false;
        this.resetSearchText();
      }
    }
  };

  reset = () => {
    this.isLoading = false;
    this.error = undefined;
    this.resetSearchText();
    this.searchIsActive = false;
  };
}
