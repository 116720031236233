import { useRef } from 'react';
import { Icon } from '../../Icon/Icon';
import './Input.scss';
import cn from 'classnames';

type InputProps = {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  iconName?: string;
  classNames?: string;
  resetTextBtn?: boolean;
  onEnter?: () => void;
};

export const Input = ({ onChange, value, iconName, placeholder, classNames, resetTextBtn, onEnter }: InputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  return (
    <div className={cn('InputContainer', classNames)}>
      {iconName ? <Icon iconName={iconName} /> : null}
      <input
        ref={ref}
        onKeyUp={e => {
          if (onEnter && (e?.key === 'Enter' || e?.keyCode === 13)) {
            onEnter();
            ref.current?.blur();
          }
        }}
        className={'Input'}
        enterKeyHint="search"
        value={value}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
      />
      {resetTextBtn && value?.length ? (
        <Icon className="InputResetBtn" iconName="close" onClick={() => onChange('')} widthInPx={14} heightInPx={14} />
      ) : null}
    </div>
  );
};
