import { makeAutoObservable } from 'mobx';
import { protocol } from '../../api/proto';
import Long from 'long';
import {
  chainToCurrency,
  formatCurrency,
  getChainFullName,
  getChainShortName,
  getWlCurrencyCode,
} from '../utils/Money';
import { formatDateTime, formatSecondsToTime } from '../utils/datatime';
import { getShortAddress } from '../utils/getShortAddress';
import BigInt, { BigInteger } from 'big-integer';
import WAValidator from 'multicoin-address-validator';

type TransactionDetailsItem = {
  label: string;
  value: string;
};

const keysForDetails: { key: keyof protocol.IUnifiedTransaction; label: string }[] = [
  { key: 'network', label: 'Network' },
];

const trxKeys: {
  key: keyof protocol.ITronResourceReceipt;
  label: string;
}[] = [
  { key: 'energyUsage', label: 'Energy usage' },
  { key: 'energyFee', label: 'Energy fee' },
  { key: 'originEnergyUsage', label: 'Origin energy usage' },
  { key: 'energyUsageTotal', label: 'Energy usage total' },
  { key: 'netUsage', label: 'Net usage' },
  { key: 'netFee', label: 'Net fee' },
  { key: 'energyPenaltyTotal', label: 'Energy penalty total' },
];

export type TxOperationFormatted = {
  address: string;
  amount: string;
  currency?: protocol.WLCurrency | null;
  currencyFormatted?: string;
  amountUsdFormatted?: string;
  isValidAddress?: boolean;
};

export class Transaction implements protocol.IUnifiedTransaction {
  hash?: string | null | undefined;
  txTime?: Long | null | undefined;
  chain?: protocol.Chain | null | undefined;
  network?: protocol.CryptoNetwork | null | undefined;
  blockHeight?: Long | null | undefined;
  fee?: protocol.IUniValue | null | undefined;
  feeStr?: string | null | undefined;
  nerveDbId?: Long | null | undefined;
  balanceChanges?: protocol.IRelatedTxBalanceChanges[] | null | undefined;
  time?: Long | null | undefined;
  shortHash?: string = undefined;
  chainFullName?: string = undefined;
  chainShortName?: string = undefined;
  timeFormatted?: string = undefined;

  details: TransactionDetailsItem[] = [];

  amountChainFormatted?: string = undefined;
  amountChainCurrency?: protocol.WLCurrency = undefined;
  amountChainCurrencyFormatted?: string = undefined;

  amountFormatted?: string = undefined;
  amountCurrency?: protocol.WLCurrency = undefined;
  amountCurrencyFormatted?: string = undefined;

  amountUsdFormatted?: string = undefined;
  feeUsdFormatted?: string = undefined;

  isDifferentCurrenciesInOuts: boolean = false;
  feeFormatted?: string = undefined;
  feeCurrency?: protocol.WLCurrency = undefined;
  feeCurrencyFormatted?: string = undefined;
  txOperations: { ins: TxOperationFormatted[]; outs: TxOperationFormatted[] }[] = [];
  ins: TxOperationFormatted[] = [];
  outs: TxOperationFormatted[] = [];

  inOrOutForAddress?: 'in' | 'out' = undefined;

  interval?: number = undefined;
  ageInMs: number = 0;

  infoForTableIsOpen: boolean = false;

  selectedAddressAmount?: string = undefined;
  selectedAddressAmountCurrency?: protocol.WLCurrency = undefined;
  selectedAddressAmountUsd?: string = undefined;

  toogleInfoForTable = () => {
    this.infoForTableIsOpen = !this.infoForTableIsOpen;
  };

  constructor(tx: protocol.IUnifiedTransaction, selectedAddress?: string) {
    Object.assign(this, tx);
    makeAutoObservable(this);
    this.processingTx(tx, selectedAddress);
  }

  get ageFormatted() {
    if (this.ageInMs) {
      return formatSecondsToTime(this.ageInMs / 1000);
    }
    return undefined;
  }

  get amountForAddressPage() {
    return this.chain !== protocol.Chain.BTC ? this.selectedAddressAmount : this.amountFormatted;
  }

  get amountCurrencyForAddressPage() {
    return this.chain !== protocol.Chain.BTC ? this.selectedAddressAmountCurrency : this.amountCurrency;
  }

  get amountUsdForAddressPage() {
    return this.chain !== protocol.Chain.BTC ? this.selectedAddressAmountUsd : this.amountUsdFormatted;
  }

  get amountCurrencyFormattedForAddressPage() {
    return this.chain !== protocol.Chain.BTC
      ? this.amountCurrencyForAddressPage
        ? getWlCurrencyCode(this.amountCurrencyForAddressPage)
        : ''
      : this.amountCurrencyFormatted;
  }

  processingTx = (tx: protocol.IUnifiedTransaction, selectedAddress?: string) => {
    this.chain = tx.chain ?? protocol.Chain.BTC;
    this.shortHash = tx.hash ? getShortAddress(tx.hash) : '—';
    this.chainFullName = getChainFullName(tx.chain!);
    this.chainShortName = getChainShortName(tx.chain!);
    if (tx.time) {
      this.timeFormatted = formatDateTime(tx.time?.toNumber());
      const timeFromTx = new Date().getTime() - tx.time?.toNumber() * 1000;
      this.ageInMs = timeFromTx;
      this.interval = window.setInterval(() => {
        this.ageInMs += 1000;
      }, 1000);
    }
    this.feeFormatted = formatCurrency(tx.fee?.value?.numberInStr ?? '0', chainToCurrency(tx.chain!), true);
    this.feeUsdFormatted = tx.fee?.valueInUSDNow?.numberInStr
      ? formatCurrency(tx.fee?.valueInUSDNow.numberInStr, protocol.WLCurrency.WLC_USD)
      : undefined;
    this.feeCurrency = chainToCurrency(tx.chain!);
    this.feeCurrencyFormatted = getWlCurrencyCode(this.feeCurrency);
    if (tx.balanceChanges?.length) {
      this.processBalanceChanges(tx.chain!, tx.balanceChanges, selectedAddress);
    }
    keysForDetails.forEach(_ => {
      this.details.push({
        label: _.label,
        value: this.processingKey(_.key),
      });
    });
    if (this.chain === protocol.Chain.TRX && tx.chainSpecific?.tronSpecific?.resourceUsage) {
      this.processingTrxInfo(tx.chainSpecific?.tronSpecific?.resourceUsage);
    }
  };

  processBalanceChanges = (
    chain: protocol.Chain,
    balanceChanges: protocol.IRelatedTxBalanceChanges[],
    selectedAddress?: string,
  ) => {
    const chainCurrency = chainToCurrency(chain);
    let amountByCurrency: { [currency: number]: BigInteger } = {};
    amountByCurrency[chainCurrency] = BigInt('0');
    let amountUsd = BigInt('0');
    let selectedAddressIns: protocol.ITxBalanceChange[] = [];
    let selectedAddressOuts: protocol.ITxBalanceChange[] = [];
    let notNullCurrency: protocol.WLCurrency | null = null;
    balanceChanges.forEach(balanceChange => {
      let ins: TxOperationFormatted[] = [];
      let outs: TxOperationFormatted[] = [];
      balanceChange.changes?.forEach(_ => {
        if (_.address) {
          let operation: TxOperationFormatted = {
            address: _.address,
            amount:
              _.change?.valueCurrency && _.change?.value?.numberInStr
                ? formatCurrency(_.change?.value?.numberInStr, _.change?.valueCurrency, true)
                : '',
            currency: _.change?.valueCurrency,
            currencyFormatted: _.change?.valueCurrency ? getWlCurrencyCode(_.change?.valueCurrency) : undefined,
            amountUsdFormatted: _.change?.valueInUSDNow?.numberInStr
              ? formatCurrency(_.change?.valueInUSDNow?.numberInStr, protocol.WLCurrency.WLC_USD)
              : undefined,
            isValidAddress: WAValidator.validate(_.address, getChainShortName(chain)),
          };
          if (selectedAddress && _.address?.toLowerCase() === selectedAddress?.toLowerCase()) {
            this.inOrOutForAddress = _.in ? 'out' : 'in';
            if (_.in) {
              selectedAddressIns.push(_);
            } else {
              selectedAddressOuts.push(_);
            }
          }
          if (_.in) {
            this.ins.push(operation);
            ins.push(operation);
          } else {
            this.outs.push(operation);
            outs.push(operation);
            if (_.change?.valueCurrency) {
              if (!amountByCurrency[_.change?.valueCurrency]) {
                amountByCurrency[_.change?.valueCurrency] = BigInt('0');
              }
              amountByCurrency[_.change?.valueCurrency] = amountByCurrency[_.change?.valueCurrency].add(
                BigInt(_.change?.value?.numberInStr ?? '0'),
              );
              if (_.change?.value?.numberInStr && _.change?.valueInUSDNow?.numberInStr !== '0') {
                notNullCurrency = _.change?.valueCurrency;
              }
            }
            if (_.change?.valueInUSDNow?.numberInStr) {
              amountUsd = amountUsd.add(BigInt(_.change?.valueInUSDNow?.numberInStr));
            }
          }
        }
      });

      if (selectedAddress && (selectedAddressIns?.length || selectedAddressOuts?.length)) {
        let currency = null;
        let insAmount = BigInt('0');
        let outsAmount = BigInt('0');
        let insUsdAmount = BigInt('0');
        let outsUsdAmount = BigInt('0');

        selectedAddressIns.forEach(_ => {
          if (_.change?.value?.numberInStr && _.change?.valueCurrency && _.change?.value?.numberInStr !== '0') {
            currency = _.change?.valueCurrency;
            insAmount = insAmount.add(BigInt(_.change?.value?.numberInStr));
            insUsdAmount = insUsdAmount.add(BigInt(_.change?.valueInUSDNow?.numberInStr ?? '0'));
          }
        });
        selectedAddressOuts.forEach(_ => {
          if (_.change?.value?.numberInStr && _.change?.valueCurrency && _.change?.value?.numberInStr !== '0') {
            currency = _.change?.valueCurrency;
            outsAmount = outsAmount.add(BigInt(_.change?.value?.numberInStr));
            outsUsdAmount = outsUsdAmount.add(BigInt(_.change?.valueInUSDNow?.numberInStr ?? '0'));
          }
        });
        if (currency) {
          this.selectedAddressAmountCurrency = currency;
          const selectedAddressAmount = outsAmount.add(insAmount.negate());
          this.selectedAddressAmount = `${selectedAddressAmount.greater(0) ? '+' : ''}${formatCurrency(
            selectedAddressAmount.toString(),
            currency,
            true,
          )}`;
          const selectedAddressAmountUsd = outsUsdAmount.add(insUsdAmount.negate());
          this.selectedAddressAmountUsd = `${selectedAddressAmountUsd.greater(0) ? '+' : ''}${formatCurrency(
            selectedAddressAmountUsd.toString(),
            protocol.WLCurrency.WLC_USD,
          )}`;
        }
      }
      if (ins?.length || outs?.length) {
        this.txOperations.push({ ins, outs });
      }
    });
    this.amountUsdFormatted = amountUsd?.notEquals(0)
      ? formatCurrency(amountUsd.toString(), protocol.WLCurrency.WLC_USD)
      : undefined;
    const currencyForAmount = notNullCurrency ? notNullCurrency : chainCurrency;
    this.amountFormatted = formatCurrency(amountByCurrency[currencyForAmount].toString(), currencyForAmount, true);
    this.amountCurrency = currencyForAmount;
    this.amountCurrencyFormatted = currencyForAmount ? getWlCurrencyCode(currencyForAmount) : undefined;

    this.amountChainFormatted = formatCurrency(amountByCurrency[chainCurrency].toString(), chainCurrency, true);
    this.amountChainCurrency = chainCurrency;
    this.amountChainCurrencyFormatted = chainCurrency ? getWlCurrencyCode(chainCurrency) : undefined;
  };

  processingKey = (key: keyof protocol.IUnifiedTransaction) => {
    switch (key) {
      case 'network':
        return (this[key] as protocol.CryptoNetwork) === protocol.CryptoNetwork.TESTNET ? 'Testnet' : 'Mainnet';
      default:
        return this.processingDefaultKey(key as keyof Transaction);
    }
  };

  processingTrxInfo = (info: protocol.ITronResourceReceipt) => {
    trxKeys.forEach(_ => {
      if (info[_.key] && Long.isLong(info[_.key]) && (info[_.key] as Long)!.notEquals(0)) {
        this.details.push({
          label: _.label,
          value: info[_.key]?.toString() ?? '—',
        });
      }
    });
  };

  processingDefaultKey = (key: keyof Transaction) => {
    if (this[key]) {
      if (Long.isLong(this[key])) {
        return this[key]?.toString() ?? '—';
      }
    }
    // return this[key];
    return '—';
  };
}
