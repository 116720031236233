import { ReactElement, ReactNode } from 'react';
import './ContentBlock.scss';
import cn from 'classnames';

export const ContentBlockTitle = ({
  children,
  className,
}: {
  children: ReactElement | ReactElement[] | ReactNode;
  className?: string;
}) => {
  return <div className={cn('ContentBlockTitle', className)}>{children}</div>;
};
