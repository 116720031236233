import { RoutesList } from '../../state/Routes';
import './Sidebar.scss';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as TokensIcon } from '../../assets/icons/tokens.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/icons/transactions.svg';
import { ReactComponent as BlocksIcon } from '../../assets/icons/blocks.svg';
import { ReactComponent as GraphsIcon } from '../../assets/icons/graphs.svg';
import { ReactComponent as NftsIcon } from '../../assets/icons/nfts.svg';
import { useStore } from '../../state/AppState';
import useComponentVisible from '../../hooks/useComponentVisible';
import { Icon } from '../Icon/Icon';

const menuItems = [
  {
    title: 'Home',
    route: RoutesList.MAIN_PAGE,
    icon: (color: string) => <HomeIcon className={'SidebarMenuItemIcon'} color={color} />,
  },
  /* {
    title: 'Tokens',
    route: RoutesList.TOKENS,
    icon: (color: string) => <TokensIcon color={color} />,
  },*/
  {
    title: 'Transactions',
    route: RoutesList.TRANSACTIONS,
    icon: (color: string) => <TransactionsIcon color={color} />,
  },
  {
    title: 'Blocks',
    route: RoutesList.BLOCKS,
    icon: (color: string) => <BlocksIcon color={color} />,
  },
  /* {
    title: 'Graphs',
    route: RoutesList.GRAPHS,
    icon: (color: string) => <GraphsIcon color={color} />,
  },
  {
    title: 'NFTs',
    route: RoutesList.NFTS,
    icon: (color: string) => <NftsIcon color={color} />,
  },*/
];

export const Sidebar = observer(() => {
  const store = useStore();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const { searchStore } = store;
  const location = useLocation();
  return (
    <div className={'Sidebar'}>
      <div className="SidebarContainerTop">
        <div className="SidebarLogoContainer">
          <div className={'SidebarLogo'} />
          Blockscope
        </div>
        <div onClick={() => setIsComponentVisible(true)} className="SidebarMobileMenuBtn">
          <Icon iconName="menu" />
        </div>
      </div>
      <div className={cn('SidebarMenuContainer', { isOpened: isComponentVisible })}>
        <div className="SidebarMenu" ref={ref}>
          <div className="SidebarMenuCloseBtn" onClick={() => setIsComponentVisible(false)}>
            <Icon iconName="close" widthInPx={20} heightInPx={20} />
          </div>
          {menuItems.map(item => (
            <Link
              key={item.title}
              onClick={searchStore.reset}
              className={cn('SidebarMenuItem', location.pathname === item.route ? 'SidebarMenuItemActive' : '')}
              to={item.route}
            >
              {item.icon(location.pathname === item.route ? '#FFF' : '#3C3B45')}
              <div className={'SidebarMenuItemTitle'}>{item.title}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});
