import { observer } from 'mobx-react';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { Input } from '../../components/controls/Input/Input';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useEffect } from 'react';
import { useStore } from '../../state/AppState';
import { ContentBlockTitle } from '../../components/ContentBlock/ContentBlockTitle';
import { CurrencySelect } from '../../components/controls/CurrencySelect/CurrencySelect';
import { protocol } from '../../api/proto';
import { TableLoader } from '../../components/TableLoader/TableLoader';
import { Link } from 'react-router-dom';
import { CurrencyIcon } from '../../components/CurrencyIcon/CurrencyIcon';
import { chainToCurrency } from '../../state/utils/Money';

export const BlocksPage = observer(() => {
  const store = useStore();
  const { blocksStore } = store;
  useEffect(() => {
    blocksStore.initPage();
    return () => {
      blocksStore.reset();
    };
  }, []);
  return (
    <PageContainer>
      <MainSearchInput />
      <ContentBlock>
        <ContentBlockTitle className="margin-b-16">Blocks</ContentBlockTitle>
        <Input
          iconName="search"
          placeholder="Search Blocks"
          onChange={blocksStore.onChangeSearchText}
          onEnter={blocksStore.search}
          classNames="BlocksPageSearchInput margin-b-16"
          resetTextBtn
          value={blocksStore.searchText}
        />
        <CurrencySelect
          className="margin-b-16"
          onChange={blocksStore.onChangeSelectedChain}
          chain={blocksStore.selectedChain}
        />

        <>
          <div className="TableContainer">
            <table className="Table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Chain</th>
                  <th>Time</th>
                  <th>Tx count</th>
                </tr>
              </thead>
              {blocksStore.isLoading ? (
                <TableLoader itemsInRow={5} />
              ) : blocksStore.blocks?.length ? (
                <tbody>
                  {blocksStore.blocks.map(block => (
                    <tr>
                      <td>
                        <Link to={`/blocks/${block.chainShortName}/${block.blockHeight?.toString()}`}>
                          {block.blockHeight?.toString()}
                        </Link>
                      </td>
                      <td>
                        <div className="d-flex flex-align-items-center column-gap-4">
                          <CurrencyIcon size="md" currency={chainToCurrency(block.chain ?? protocol.Chain.BTC)} />
                          {block.chainFullName}
                        </div>
                      </td>
                      <td className="dateTime">{block.timeFormatted}</td>
                      <td>{block.txCount}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div>Blocks not found</div>
              )}
            </table>
          </div>
        </>
      </ContentBlock>
    </PageContainer>
  );
});
