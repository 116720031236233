import { makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { Transaction } from './transactions/Transaction';
import Long from 'long';
import { protocol } from '../api/proto';
import { formatCurrency, getWlCurrencyCode } from './utils/Money';
import moment from 'moment';

const LIMIT_PAGINATION = 10;

interface AddressBalance extends protocol.IBalance {
  amountFormatted?: string;
  currencyFormatted?: string;
  amountUsdFormatted?: string;
}

type TxsTab = 'all' | 'tokens';
export const PERIODS = ['All', 'Today', 'Yesterday', 'Custom period'] as const;
export type Period = (typeof PERIODS)[number];

export class AddressStore {
  private appState: AppState;

  address?: string = undefined;
  addressLoading: boolean = false;
  chainCurrency?: AddressBalance;
  balances: AddressBalance[] = [];
  transactions: Transaction[] = [];
  error?: string;

  page: number = 0;
  totalPages: number = 0;
  totalCountTxs: number = 0;

  txsTab: TxsTab = 'all';

  period?: Period = 'All';
  customDateRange?: [Date | null, Date | null];

  constructor(appState: AppState) {
    this.appState = appState;
    makeAutoObservable(this);
  }

  init = (address?: string) => {
    this.reset();
    if (address) {
      this.address = address;
      this.fetchAddressTransactions();
    }
  };

  onChangeTxsTab = (tab: TxsTab) => {
    this.txsTab = tab;
    this.resetPagination();
    this.fetchAddressTransactions();
  };

  changePeriod = (period: string) => {
    this.period = period as Period;
    this.customDateRange = undefined;
    this.resetPagination();
    this.fetchAddressTransactions();
  };

  setCustomDateRange = (range: Date | [Date | null, Date | null] | null) => {
    this.customDateRange = (range as [Date | null, Date | null]) || undefined;
    this.period = 'Custom period';
    this.resetPagination();
    this.fetchAddressTransactions();
  };

  get fromToNumTotalFilter() {
    let from = 0;
    let to = 0;
    if (this.period === 'Today') {
      from = moment().startOf('day').unix();
    } else if (this.period === 'Yesterday') {
      from = moment().subtract('1', 'days').startOf('day').unix();
      to = moment().startOf('day').unix();
    }
    return { from, to };
  }

  get periodTotal() {
    if (this.period === 'Today' || this.period === 'Yesterday') {
      return this.fromToNumTotalFilter;
    } else if (this.period === 'Custom period' && this.customDateRange?.length) {
      const [start, end] = this.customDateRange;
      if (start && end) {
        return {
          from: start.getTime() / 1000,
          to: (end.getTime() + 86400000) / 1000,
        };
      }
    }
    return undefined;
  }

  fetchAddressTransactions = async () => {
    this.addressLoading = true;
    const res = await this.appState.api.findTxByAddress({
      address: this.address,
      onlyTokenTransfers: this.txsTab === 'tokens',
      pagination: { limit: Long.fromNumber(LIMIT_PAGINATION), offset: Long.fromNumber(this.page * LIMIT_PAGINATION) },
      timeRange: {
        from: this.periodTotal?.from ? Long.fromNumber(this.periodTotal.from) : null,
        to: this.periodTotal?.to ? Long.fromNumber(this.periodTotal.to) : null,
      },
    });
    if (res.error) {
      this.error = res.error;
    } else if (res.findTxByAddress?.txs) {
      this.transactions = res.findTxByAddress?.txs?.map(tx => new Transaction(tx, this.address));
      this.totalPages = Math.ceil((res.findTxByAddress?.total?.toNumber() ?? 0) / LIMIT_PAGINATION);
      console.debug((res.findTxByAddress?.total?.toNumber() ?? 0) / LIMIT_PAGINATION);
      this.totalCountTxs = res.findTxByAddress?.total?.toNumber() ?? 0;
    }
    if (res.findTxByAddress?.balances?.length) {
      const balances = res.findTxByAddress?.balances?.filter(_ => !!_.balance?.valueCurrency);
      let chainCurrencyBalance = undefined;
      let otherBalances: AddressBalance[] = [];
      balances.forEach(balance => {
        console.debug(balance);
        const balanceFormatted = {
          ...balance,
          amountFormatted:
            balance.balance?.valueCurrency && balance.balance?.value?.numberInStr
              ? formatCurrency(balance.balance?.value?.numberInStr ?? '0', balance.balance?.valueCurrency, true)
              : undefined,
          currencyFormatted: balance.balance?.valueCurrency
            ? getWlCurrencyCode(balance.balance?.valueCurrency)
            : undefined,
          amountUsdFormatted: balance.balance?.valueInUSDNow?.numberInStr
            ? formatCurrency(balance.balance?.valueInUSDNow?.numberInStr, protocol.WLCurrency.WLC_USD)
            : undefined,
        };
        if (
          balance.balance?.valueCurrency === protocol.WLCurrency.WLC_ETH ||
          balance.balance?.valueCurrency === protocol.WLCurrency.WLC_TRX ||
          balance.balance?.valueCurrency === protocol.WLCurrency.WLC_BTC
        ) {
          chainCurrencyBalance = balanceFormatted;
        } else {
          otherBalances.push(balanceFormatted);
        }
      });
      this.balances = otherBalances;
      this.chainCurrency = chainCurrencyBalance;
    }
    this.addressLoading = false;
  };

  changePage = (page: number) => {
    this.page = page;
    this.fetchAddressTransactions();
  };

  resetPagination = () => {
    this.page = 0;
    this.totalPages = 0;
    this.totalCountTxs = 0;
  };

  reset = () => {
    this.balances = [];
    this.address = undefined;
    this.addressLoading = false;
    this.transactions = [];
    this.error = undefined;
    this.page = 0;
    this.totalPages = 0;
    this.totalCountTxs = 0;
    this.chainCurrency = undefined;
    this.txsTab = 'all';
    this.customDateRange = undefined;
    this.period = 'All';
  };
}
