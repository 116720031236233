import { makeObservable, observable, action, makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { v4 as uuid } from 'uuid';

export enum NotificationType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
  NETWORK_ERROR = 'network-error',
}

export interface INotification {
  message?: string;
  type?: NotificationType;
  id: string;
  withoutAutoClose?: boolean;
}
export class NotificationsState {
  private appState: AppState;

  notificationsList: INotification[] = [];

  constructor(appState: AppState) {
    makeAutoObservable(this);
    this.appState = appState;
  }

  addNotification = (message: string, type?: NotificationType, withoutAutoClose?: boolean) => {
    if (type === NotificationType.NETWORK_ERROR && !!this.notificationsList.find(_ => _.type === type)) return;

    const notificationItem = { message, id: uuid(), type, withoutAutoClose };
    this.notificationsList.unshift(notificationItem);
    if (!withoutAutoClose) {
      setTimeout(() => {
        this.removeNotification(notificationItem);
      }, 3000);
    }
  };

  removeNotification = (notification: INotification) => {
    this.notificationsList = this.notificationsList.filter(_ => _.id !== notification.id);
  };

  removeNetworkErrorNotification = () => {
    this.notificationsList = this.notificationsList.filter(_ => _.type !== NotificationType.NETWORK_ERROR);
  };
}
