export function formatDateTime(date: number) {
  let d = new Date(date * 1000),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hh = String(d.getHours()),
    mm = String(d.getMinutes()),
    ss = String(d.getSeconds());

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hh.length < 2) hh = '0' + hh;
  if (mm.length < 2) mm = '0' + mm;
  if (ss.length < 2) ss = '0' + ss;

  return [year, month, day].join('-') + ' ' + [hh, mm, ss].join(':');
}

export const formatSecondsToTime = function (secs: number | undefined | null) {
  if (secs) {
    let sec_num = secs;
    let days: number | string = Math.floor(sec_num / 86400);
    let hours: number | string = Math.floor((sec_num - days * 86400) / 3600);
    let minutes: number | string = Math.floor((sec_num - days * 86400 - hours * 3600) / 60);
    let seconds: number | string = Math.floor(sec_num - days * 86400 - hours * 3600 - minutes * 60);

    if (days) {
      return `${days.toString()}d`;
    }
    if (hours) {
      return `${hours.toString()}h ${minutes.toString()}m ${seconds.toString()}s`;
    }
    if (minutes) {
      return `${minutes.toString()}m ${seconds.toString()}s`;
    }
    return `${seconds.toString()} s`;
  }
  return '';
};

export function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
