import { observer } from 'mobx-react';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { useStore } from '../../state/AppState';
import Skeleton from 'react-loading-skeleton';
import { ContentBlockTitle } from '../../components/ContentBlock/ContentBlockTitle';
import { Transaction } from '../../state/transactions/Transaction';
import { CopyButton } from '../../components/controls/CopyButton/CopyButton';
import { Link } from 'react-router-dom';
import { TransactionInsOuts } from '../../components/TransactionInsOuts/TransactionInsOuts';
import { Pagination } from '../../components/Pagination/Pagination';

export const BlockTransactions = observer(() => {
  const store = useStore();
  const { blockPageStore } = store;
  const { transactions } = blockPageStore;

  if (blockPageStore.isLoading) {
    return (
      <ContentBlock>
        <>
          {Array.from(Array(4)).map(item => (
            <div className="margin-b-8">
              <Skeleton width={120} height={36} />
            </div>
          ))}
        </>
      </ContentBlock>
    );
  }
  return (
    <ContentBlock>
      <ContentBlockTitle>Transactions</ContentBlockTitle>
      <div className="BlockTransactionsList">
        {transactions?.length ? transactions.map(tx => <BlockTx tx={tx} />) : <div>Transactions not found</div>}
      </div>
      <>
        {blockPageStore.txsTotalPages > 1 ? (
          <Pagination
            pageCount={blockPageStore.txsTotalPages}
            page={blockPageStore.txsPage}
            onPageChange={blockPageStore.changePage}
          />
        ) : null}
      </>
    </ContentBlock>
  );
});

const BlockTx = ({ tx }: { tx: Transaction }) => {
  return (
    <div className="BlockTx">
      <div className="BlockTx__info">
        <div className="BlockTx__info__col">
          <div className="BlockTx__info__item time">{tx.timeFormatted}</div>
          <div className="BlockTx__info__item column-gap-4">
            ID{' '}
            <div className="d-flex column-gap-4 flex-align-items-center">
              <Link to={`/transaction/${tx.hash}`}>{tx.shortHash}</Link>
              <CopyButton contentForCopy={tx.hash ?? ''} />
            </div>
          </div>
        </div>
        <div className="BlockTx__info__col">
          <div className="BlockTx__info__item">
            {tx.amountFormatted} {tx.amountCurrencyFormatted}
          </div>
          <div className="BlockTx__info__item">
            Fee {tx.feeFormatted} {tx.feeCurrencyFormatted}
          </div>
        </div>
      </div>
      <TransactionInsOuts withoutAmounts small ins={tx?.ins ?? []} outs={tx?.outs ?? []} />
    </div>
  );
};
