/*eslint-disable*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";
$protobuf.util.Long = require('long');
$protobuf.configure();
    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.protocol = (function() {
    
        /**
         * Namespace protocol.
         * @exports protocol
         * @namespace
         */
        var protocol = {};
    
        protocol.UniapiRequest = (function() {
    
            /**
             * Properties of an UniapiRequest.
             * @memberof protocol
             * @interface IUniapiRequest
             * @property {protocol.IGetTxByHashRequest|null} [getTxByHash] UniapiRequest getTxByHash
             * @property {protocol.IFindAddressAndTxs|null} [findTxByAddress] UniapiRequest findTxByAddress
             * @property {protocol.IGetBlockByHeightRequest|null} [getBlockByHeight] UniapiRequest getBlockByHeight
             */
    
            /**
             * Constructs a new UniapiRequest.
             * @memberof protocol
             * @classdesc Represents an UniapiRequest.
             * @implements IUniapiRequest
             * @constructor
             * @param {protocol.IUniapiRequest=} [properties] Properties to set
             */
            function UniapiRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UniapiRequest getTxByHash.
             * @member {protocol.IGetTxByHashRequest|null|undefined} getTxByHash
             * @memberof protocol.UniapiRequest
             * @instance
             */
            UniapiRequest.prototype.getTxByHash = null;
    
            /**
             * UniapiRequest findTxByAddress.
             * @member {protocol.IFindAddressAndTxs|null|undefined} findTxByAddress
             * @memberof protocol.UniapiRequest
             * @instance
             */
            UniapiRequest.prototype.findTxByAddress = null;
    
            /**
             * UniapiRequest getBlockByHeight.
             * @member {protocol.IGetBlockByHeightRequest|null|undefined} getBlockByHeight
             * @memberof protocol.UniapiRequest
             * @instance
             */
            UniapiRequest.prototype.getBlockByHeight = null;
    
            /**
             * Creates a new UniapiRequest instance using the specified properties.
             * @function create
             * @memberof protocol.UniapiRequest
             * @static
             * @param {protocol.IUniapiRequest=} [properties] Properties to set
             * @returns {protocol.UniapiRequest} UniapiRequest instance
             */
            UniapiRequest.create = function create(properties) {
                return new UniapiRequest(properties);
            };
    
            /**
             * Encodes the specified UniapiRequest message. Does not implicitly {@link protocol.UniapiRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.UniapiRequest
             * @static
             * @param {protocol.IUniapiRequest} message UniapiRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UniapiRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.getTxByHash != null && Object.hasOwnProperty.call(message, "getTxByHash"))
                    $root.protocol.GetTxByHashRequest.encode(message.getTxByHash, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.findTxByAddress != null && Object.hasOwnProperty.call(message, "findTxByAddress"))
                    $root.protocol.FindAddressAndTxs.encode(message.findTxByAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.getBlockByHeight != null && Object.hasOwnProperty.call(message, "getBlockByHeight"))
                    $root.protocol.GetBlockByHeightRequest.encode(message.getBlockByHeight, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UniapiRequest message, length delimited. Does not implicitly {@link protocol.UniapiRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.UniapiRequest
             * @static
             * @param {protocol.IUniapiRequest} message UniapiRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UniapiRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UniapiRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.UniapiRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.UniapiRequest} UniapiRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UniapiRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.UniapiRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.getTxByHash = $root.protocol.GetTxByHashRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.findTxByAddress = $root.protocol.FindAddressAndTxs.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.getBlockByHeight = $root.protocol.GetBlockByHeightRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UniapiRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.UniapiRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.UniapiRequest} UniapiRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UniapiRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UniapiRequest message.
             * @function verify
             * @memberof protocol.UniapiRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UniapiRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.getTxByHash != null && message.hasOwnProperty("getTxByHash")) {
                    var error = $root.protocol.GetTxByHashRequest.verify(message.getTxByHash);
                    if (error)
                        return "getTxByHash." + error;
                }
                if (message.findTxByAddress != null && message.hasOwnProperty("findTxByAddress")) {
                    var error = $root.protocol.FindAddressAndTxs.verify(message.findTxByAddress);
                    if (error)
                        return "findTxByAddress." + error;
                }
                if (message.getBlockByHeight != null && message.hasOwnProperty("getBlockByHeight")) {
                    var error = $root.protocol.GetBlockByHeightRequest.verify(message.getBlockByHeight);
                    if (error)
                        return "getBlockByHeight." + error;
                }
                return null;
            };
    
            /**
             * Creates an UniapiRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.UniapiRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.UniapiRequest} UniapiRequest
             */
            UniapiRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.UniapiRequest)
                    return object;
                var message = new $root.protocol.UniapiRequest();
                if (object.getTxByHash != null) {
                    if (typeof object.getTxByHash !== "object")
                        throw TypeError(".protocol.UniapiRequest.getTxByHash: object expected");
                    message.getTxByHash = $root.protocol.GetTxByHashRequest.fromObject(object.getTxByHash);
                }
                if (object.findTxByAddress != null) {
                    if (typeof object.findTxByAddress !== "object")
                        throw TypeError(".protocol.UniapiRequest.findTxByAddress: object expected");
                    message.findTxByAddress = $root.protocol.FindAddressAndTxs.fromObject(object.findTxByAddress);
                }
                if (object.getBlockByHeight != null) {
                    if (typeof object.getBlockByHeight !== "object")
                        throw TypeError(".protocol.UniapiRequest.getBlockByHeight: object expected");
                    message.getBlockByHeight = $root.protocol.GetBlockByHeightRequest.fromObject(object.getBlockByHeight);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UniapiRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.UniapiRequest
             * @static
             * @param {protocol.UniapiRequest} message UniapiRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UniapiRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.getTxByHash = null;
                    object.findTxByAddress = null;
                    object.getBlockByHeight = null;
                }
                if (message.getTxByHash != null && message.hasOwnProperty("getTxByHash"))
                    object.getTxByHash = $root.protocol.GetTxByHashRequest.toObject(message.getTxByHash, options);
                if (message.findTxByAddress != null && message.hasOwnProperty("findTxByAddress"))
                    object.findTxByAddress = $root.protocol.FindAddressAndTxs.toObject(message.findTxByAddress, options);
                if (message.getBlockByHeight != null && message.hasOwnProperty("getBlockByHeight"))
                    object.getBlockByHeight = $root.protocol.GetBlockByHeightRequest.toObject(message.getBlockByHeight, options);
                return object;
            };
    
            /**
             * Converts this UniapiRequest to JSON.
             * @function toJSON
             * @memberof protocol.UniapiRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UniapiRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UniapiRequest
             * @function getTypeUrl
             * @memberof protocol.UniapiRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UniapiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.UniapiRequest";
            };
    
            return UniapiRequest;
        })();
    
        protocol.UniapiResponse = (function() {
    
            /**
             * Properties of an UniapiResponse.
             * @memberof protocol
             * @interface IUniapiResponse
             * @property {protocol.IGetTxByHashResponse|null} [getTxByHash] UniapiResponse getTxByHash
             * @property {protocol.IFindTxByAddressResponse|null} [findTxByAddress] UniapiResponse findTxByAddress
             * @property {protocol.IGetBlockByHeightResponse|null} [getBlockByHeight] UniapiResponse getBlockByHeight
             * @property {string|null} [error] UniapiResponse error
             */
    
            /**
             * Constructs a new UniapiResponse.
             * @memberof protocol
             * @classdesc Represents an UniapiResponse.
             * @implements IUniapiResponse
             * @constructor
             * @param {protocol.IUniapiResponse=} [properties] Properties to set
             */
            function UniapiResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UniapiResponse getTxByHash.
             * @member {protocol.IGetTxByHashResponse|null|undefined} getTxByHash
             * @memberof protocol.UniapiResponse
             * @instance
             */
            UniapiResponse.prototype.getTxByHash = null;
    
            /**
             * UniapiResponse findTxByAddress.
             * @member {protocol.IFindTxByAddressResponse|null|undefined} findTxByAddress
             * @memberof protocol.UniapiResponse
             * @instance
             */
            UniapiResponse.prototype.findTxByAddress = null;
    
            /**
             * UniapiResponse getBlockByHeight.
             * @member {protocol.IGetBlockByHeightResponse|null|undefined} getBlockByHeight
             * @memberof protocol.UniapiResponse
             * @instance
             */
            UniapiResponse.prototype.getBlockByHeight = null;
    
            /**
             * UniapiResponse error.
             * @member {string} error
             * @memberof protocol.UniapiResponse
             * @instance
             */
            UniapiResponse.prototype.error = "";
    
            /**
             * Creates a new UniapiResponse instance using the specified properties.
             * @function create
             * @memberof protocol.UniapiResponse
             * @static
             * @param {protocol.IUniapiResponse=} [properties] Properties to set
             * @returns {protocol.UniapiResponse} UniapiResponse instance
             */
            UniapiResponse.create = function create(properties) {
                return new UniapiResponse(properties);
            };
    
            /**
             * Encodes the specified UniapiResponse message. Does not implicitly {@link protocol.UniapiResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.UniapiResponse
             * @static
             * @param {protocol.IUniapiResponse} message UniapiResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UniapiResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.getTxByHash != null && Object.hasOwnProperty.call(message, "getTxByHash"))
                    $root.protocol.GetTxByHashResponse.encode(message.getTxByHash, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.findTxByAddress != null && Object.hasOwnProperty.call(message, "findTxByAddress"))
                    $root.protocol.FindTxByAddressResponse.encode(message.findTxByAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.getBlockByHeight != null && Object.hasOwnProperty.call(message, "getBlockByHeight"))
                    $root.protocol.GetBlockByHeightResponse.encode(message.getBlockByHeight, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.error);
                return writer;
            };
    
            /**
             * Encodes the specified UniapiResponse message, length delimited. Does not implicitly {@link protocol.UniapiResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.UniapiResponse
             * @static
             * @param {protocol.IUniapiResponse} message UniapiResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UniapiResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UniapiResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.UniapiResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.UniapiResponse} UniapiResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UniapiResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.UniapiResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.getTxByHash = $root.protocol.GetTxByHashResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.findTxByAddress = $root.protocol.FindTxByAddressResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.getBlockByHeight = $root.protocol.GetBlockByHeightResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.error = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UniapiResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.UniapiResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.UniapiResponse} UniapiResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UniapiResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UniapiResponse message.
             * @function verify
             * @memberof protocol.UniapiResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UniapiResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.getTxByHash != null && message.hasOwnProperty("getTxByHash")) {
                    var error = $root.protocol.GetTxByHashResponse.verify(message.getTxByHash);
                    if (error)
                        return "getTxByHash." + error;
                }
                if (message.findTxByAddress != null && message.hasOwnProperty("findTxByAddress")) {
                    var error = $root.protocol.FindTxByAddressResponse.verify(message.findTxByAddress);
                    if (error)
                        return "findTxByAddress." + error;
                }
                if (message.getBlockByHeight != null && message.hasOwnProperty("getBlockByHeight")) {
                    var error = $root.protocol.GetBlockByHeightResponse.verify(message.getBlockByHeight);
                    if (error)
                        return "getBlockByHeight." + error;
                }
                if (message.error != null && message.hasOwnProperty("error"))
                    if (!$util.isString(message.error))
                        return "error: string expected";
                return null;
            };
    
            /**
             * Creates an UniapiResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.UniapiResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.UniapiResponse} UniapiResponse
             */
            UniapiResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.UniapiResponse)
                    return object;
                var message = new $root.protocol.UniapiResponse();
                if (object.getTxByHash != null) {
                    if (typeof object.getTxByHash !== "object")
                        throw TypeError(".protocol.UniapiResponse.getTxByHash: object expected");
                    message.getTxByHash = $root.protocol.GetTxByHashResponse.fromObject(object.getTxByHash);
                }
                if (object.findTxByAddress != null) {
                    if (typeof object.findTxByAddress !== "object")
                        throw TypeError(".protocol.UniapiResponse.findTxByAddress: object expected");
                    message.findTxByAddress = $root.protocol.FindTxByAddressResponse.fromObject(object.findTxByAddress);
                }
                if (object.getBlockByHeight != null) {
                    if (typeof object.getBlockByHeight !== "object")
                        throw TypeError(".protocol.UniapiResponse.getBlockByHeight: object expected");
                    message.getBlockByHeight = $root.protocol.GetBlockByHeightResponse.fromObject(object.getBlockByHeight);
                }
                if (object.error != null)
                    message.error = String(object.error);
                return message;
            };
    
            /**
             * Creates a plain object from an UniapiResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.UniapiResponse
             * @static
             * @param {protocol.UniapiResponse} message UniapiResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UniapiResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.getTxByHash = null;
                    object.findTxByAddress = null;
                    object.getBlockByHeight = null;
                    object.error = "";
                }
                if (message.getTxByHash != null && message.hasOwnProperty("getTxByHash"))
                    object.getTxByHash = $root.protocol.GetTxByHashResponse.toObject(message.getTxByHash, options);
                if (message.findTxByAddress != null && message.hasOwnProperty("findTxByAddress"))
                    object.findTxByAddress = $root.protocol.FindTxByAddressResponse.toObject(message.findTxByAddress, options);
                if (message.getBlockByHeight != null && message.hasOwnProperty("getBlockByHeight"))
                    object.getBlockByHeight = $root.protocol.GetBlockByHeightResponse.toObject(message.getBlockByHeight, options);
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = message.error;
                return object;
            };
    
            /**
             * Converts this UniapiResponse to JSON.
             * @function toJSON
             * @memberof protocol.UniapiResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UniapiResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UniapiResponse
             * @function getTypeUrl
             * @memberof protocol.UniapiResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UniapiResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.UniapiResponse";
            };
    
            return UniapiResponse;
        })();
    
        protocol.Pagination = (function() {
    
            /**
             * Properties of a Pagination.
             * @memberof protocol
             * @interface IPagination
             * @property {Long|null} [offset] Pagination offset
             * @property {Long|null} [limit] Pagination limit
             */
    
            /**
             * Constructs a new Pagination.
             * @memberof protocol
             * @classdesc Represents a Pagination.
             * @implements IPagination
             * @constructor
             * @param {protocol.IPagination=} [properties] Properties to set
             */
            function Pagination(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Pagination offset.
             * @member {Long} offset
             * @memberof protocol.Pagination
             * @instance
             */
            Pagination.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Pagination limit.
             * @member {Long} limit
             * @memberof protocol.Pagination
             * @instance
             */
            Pagination.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new Pagination instance using the specified properties.
             * @function create
             * @memberof protocol.Pagination
             * @static
             * @param {protocol.IPagination=} [properties] Properties to set
             * @returns {protocol.Pagination} Pagination instance
             */
            Pagination.create = function create(properties) {
                return new Pagination(properties);
            };
    
            /**
             * Encodes the specified Pagination message. Does not implicitly {@link protocol.Pagination.verify|verify} messages.
             * @function encode
             * @memberof protocol.Pagination
             * @static
             * @param {protocol.IPagination} message Pagination message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pagination.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.limit);
                return writer;
            };
    
            /**
             * Encodes the specified Pagination message, length delimited. Does not implicitly {@link protocol.Pagination.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.Pagination
             * @static
             * @param {protocol.IPagination} message Pagination message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pagination.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Pagination message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.Pagination
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.Pagination} Pagination
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pagination.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.Pagination();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.offset = reader.int64();
                            break;
                        }
                    case 2: {
                            message.limit = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Pagination message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.Pagination
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.Pagination} Pagination
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pagination.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Pagination message.
             * @function verify
             * @memberof protocol.Pagination
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Pagination.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                        return "offset: integer|Long expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                        return "limit: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a Pagination message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.Pagination
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.Pagination} Pagination
             */
            Pagination.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.Pagination)
                    return object;
                var message = new $root.protocol.Pagination();
                if (object.offset != null)
                    if ($util.Long)
                        (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                    else if (typeof object.offset === "string")
                        message.offset = parseInt(object.offset, 10);
                    else if (typeof object.offset === "number")
                        message.offset = object.offset;
                    else if (typeof object.offset === "object")
                        message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                if (object.limit != null)
                    if ($util.Long)
                        (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                    else if (typeof object.limit === "string")
                        message.limit = parseInt(object.limit, 10);
                    else if (typeof object.limit === "number")
                        message.limit = object.limit;
                    else if (typeof object.limit === "object")
                        message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a Pagination message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.Pagination
             * @static
             * @param {protocol.Pagination} message Pagination
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Pagination.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.offset = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limit = options.longs === String ? "0" : 0;
                }
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (typeof message.offset === "number")
                        object.offset = options.longs === String ? String(message.offset) : message.offset;
                    else
                        object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (typeof message.limit === "number")
                        object.limit = options.longs === String ? String(message.limit) : message.limit;
                    else
                        object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                return object;
            };
    
            /**
             * Converts this Pagination to JSON.
             * @function toJSON
             * @memberof protocol.Pagination
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Pagination.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Pagination
             * @function getTypeUrl
             * @memberof protocol.Pagination
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Pagination.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.Pagination";
            };
    
            return Pagination;
        })();
    
        protocol.TimeRange = (function() {
    
            /**
             * Properties of a TimeRange.
             * @memberof protocol
             * @interface ITimeRange
             * @property {Long|null} [from] TimeRange from
             * @property {Long|null} [to] TimeRange to
             */
    
            /**
             * Constructs a new TimeRange.
             * @memberof protocol
             * @classdesc Represents a TimeRange.
             * @implements ITimeRange
             * @constructor
             * @param {protocol.ITimeRange=} [properties] Properties to set
             */
            function TimeRange(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TimeRange from.
             * @member {Long} from
             * @memberof protocol.TimeRange
             * @instance
             */
            TimeRange.prototype.from = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TimeRange to.
             * @member {Long} to
             * @memberof protocol.TimeRange
             * @instance
             */
            TimeRange.prototype.to = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TimeRange instance using the specified properties.
             * @function create
             * @memberof protocol.TimeRange
             * @static
             * @param {protocol.ITimeRange=} [properties] Properties to set
             * @returns {protocol.TimeRange} TimeRange instance
             */
            TimeRange.create = function create(properties) {
                return new TimeRange(properties);
            };
    
            /**
             * Encodes the specified TimeRange message. Does not implicitly {@link protocol.TimeRange.verify|verify} messages.
             * @function encode
             * @memberof protocol.TimeRange
             * @static
             * @param {protocol.ITimeRange} message TimeRange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeRange.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.to);
                return writer;
            };
    
            /**
             * Encodes the specified TimeRange message, length delimited. Does not implicitly {@link protocol.TimeRange.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TimeRange
             * @static
             * @param {protocol.ITimeRange} message TimeRange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeRange.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TimeRange message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TimeRange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TimeRange} TimeRange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeRange.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TimeRange();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = reader.int64();
                            break;
                        }
                    case 2: {
                            message.to = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TimeRange message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TimeRange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TimeRange} TimeRange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeRange.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TimeRange message.
             * @function verify
             * @memberof protocol.TimeRange
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimeRange.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isInteger(message.from) && !(message.from && $util.isInteger(message.from.low) && $util.isInteger(message.from.high)))
                        return "from: integer|Long expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isInteger(message.to) && !(message.to && $util.isInteger(message.to.low) && $util.isInteger(message.to.high)))
                        return "to: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TimeRange message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TimeRange
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TimeRange} TimeRange
             */
            TimeRange.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TimeRange)
                    return object;
                var message = new $root.protocol.TimeRange();
                if (object.from != null)
                    if ($util.Long)
                        (message.from = $util.Long.fromValue(object.from)).unsigned = false;
                    else if (typeof object.from === "string")
                        message.from = parseInt(object.from, 10);
                    else if (typeof object.from === "number")
                        message.from = object.from;
                    else if (typeof object.from === "object")
                        message.from = new $util.LongBits(object.from.low >>> 0, object.from.high >>> 0).toNumber();
                if (object.to != null)
                    if ($util.Long)
                        (message.to = $util.Long.fromValue(object.to)).unsigned = false;
                    else if (typeof object.to === "string")
                        message.to = parseInt(object.to, 10);
                    else if (typeof object.to === "number")
                        message.to = object.to;
                    else if (typeof object.to === "object")
                        message.to = new $util.LongBits(object.to.low >>> 0, object.to.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TimeRange message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TimeRange
             * @static
             * @param {protocol.TimeRange} message TimeRange
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimeRange.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.from = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.from = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.to = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.to = options.longs === String ? "0" : 0;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    if (typeof message.from === "number")
                        object.from = options.longs === String ? String(message.from) : message.from;
                    else
                        object.from = options.longs === String ? $util.Long.prototype.toString.call(message.from) : options.longs === Number ? new $util.LongBits(message.from.low >>> 0, message.from.high >>> 0).toNumber() : message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    if (typeof message.to === "number")
                        object.to = options.longs === String ? String(message.to) : message.to;
                    else
                        object.to = options.longs === String ? $util.Long.prototype.toString.call(message.to) : options.longs === Number ? new $util.LongBits(message.to.low >>> 0, message.to.high >>> 0).toNumber() : message.to;
                return object;
            };
    
            /**
             * Converts this TimeRange to JSON.
             * @function toJSON
             * @memberof protocol.TimeRange
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimeRange.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TimeRange
             * @function getTypeUrl
             * @memberof protocol.TimeRange
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TimeRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TimeRange";
            };
    
            return TimeRange;
        })();
    
        protocol.GetBlockByHeightRequest = (function() {
    
            /**
             * Properties of a GetBlockByHeightRequest.
             * @memberof protocol
             * @interface IGetBlockByHeightRequest
             * @property {protocol.Chain|null} [chain] GetBlockByHeightRequest chain
             * @property {protocol.CryptoNetwork|null} [network] GetBlockByHeightRequest network
             * @property {Array.<Long>|null} [heights] GetBlockByHeightRequest heights
             * @property {boolean|null} [full] GetBlockByHeightRequest full
             * @property {boolean|null} [fillTxHashes] GetBlockByHeightRequest fillTxHashes
             * @property {protocol.IPagination|null} [pagination] GetBlockByHeightRequest pagination
             */
    
            /**
             * Constructs a new GetBlockByHeightRequest.
             * @memberof protocol
             * @classdesc Represents a GetBlockByHeightRequest.
             * @implements IGetBlockByHeightRequest
             * @constructor
             * @param {protocol.IGetBlockByHeightRequest=} [properties] Properties to set
             */
            function GetBlockByHeightRequest(properties) {
                this.heights = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetBlockByHeightRequest chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             */
            GetBlockByHeightRequest.prototype.chain = 0;
    
            /**
             * GetBlockByHeightRequest network.
             * @member {protocol.CryptoNetwork} network
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             */
            GetBlockByHeightRequest.prototype.network = 0;
    
            /**
             * GetBlockByHeightRequest heights.
             * @member {Array.<Long>} heights
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             */
            GetBlockByHeightRequest.prototype.heights = $util.emptyArray;
    
            /**
             * GetBlockByHeightRequest full.
             * @member {boolean} full
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             */
            GetBlockByHeightRequest.prototype.full = false;
    
            /**
             * GetBlockByHeightRequest fillTxHashes.
             * @member {boolean} fillTxHashes
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             */
            GetBlockByHeightRequest.prototype.fillTxHashes = false;
    
            /**
             * GetBlockByHeightRequest pagination.
             * @member {protocol.IPagination|null|undefined} pagination
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             */
            GetBlockByHeightRequest.prototype.pagination = null;
    
            /**
             * Creates a new GetBlockByHeightRequest instance using the specified properties.
             * @function create
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {protocol.IGetBlockByHeightRequest=} [properties] Properties to set
             * @returns {protocol.GetBlockByHeightRequest} GetBlockByHeightRequest instance
             */
            GetBlockByHeightRequest.create = function create(properties) {
                return new GetBlockByHeightRequest(properties);
            };
    
            /**
             * Encodes the specified GetBlockByHeightRequest message. Does not implicitly {@link protocol.GetBlockByHeightRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {protocol.IGetBlockByHeightRequest} message GetBlockByHeightRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBlockByHeightRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.chain);
                if (message.network != null && Object.hasOwnProperty.call(message, "network"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.network);
                if (message.heights != null && message.heights.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.heights.length; ++i)
                        writer.int64(message.heights[i]);
                    writer.ldelim();
                }
                if (message.full != null && Object.hasOwnProperty.call(message, "full"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.full);
                if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                    $root.protocol.Pagination.encode(message.pagination, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.fillTxHashes != null && Object.hasOwnProperty.call(message, "fillTxHashes"))
                    writer.uint32(/* id 40, wireType 0 =*/320).bool(message.fillTxHashes);
                return writer;
            };
    
            /**
             * Encodes the specified GetBlockByHeightRequest message, length delimited. Does not implicitly {@link protocol.GetBlockByHeightRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {protocol.IGetBlockByHeightRequest} message GetBlockByHeightRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBlockByHeightRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetBlockByHeightRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.GetBlockByHeightRequest} GetBlockByHeightRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBlockByHeightRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.GetBlockByHeightRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chain = reader.int32();
                            break;
                        }
                    case 2: {
                            message.network = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.heights && message.heights.length))
                                message.heights = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.heights.push(reader.int64());
                            } else
                                message.heights.push(reader.int64());
                            break;
                        }
                    case 4: {
                            message.full = reader.bool();
                            break;
                        }
                    case 40: {
                            message.fillTxHashes = reader.bool();
                            break;
                        }
                    case 5: {
                            message.pagination = $root.protocol.Pagination.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetBlockByHeightRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.GetBlockByHeightRequest} GetBlockByHeightRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBlockByHeightRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetBlockByHeightRequest message.
             * @function verify
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBlockByHeightRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.network != null && message.hasOwnProperty("network"))
                    switch (message.network) {
                    default:
                        return "network: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.heights != null && message.hasOwnProperty("heights")) {
                    if (!Array.isArray(message.heights))
                        return "heights: array expected";
                    for (var i = 0; i < message.heights.length; ++i)
                        if (!$util.isInteger(message.heights[i]) && !(message.heights[i] && $util.isInteger(message.heights[i].low) && $util.isInteger(message.heights[i].high)))
                            return "heights: integer|Long[] expected";
                }
                if (message.full != null && message.hasOwnProperty("full"))
                    if (typeof message.full !== "boolean")
                        return "full: boolean expected";
                if (message.fillTxHashes != null && message.hasOwnProperty("fillTxHashes"))
                    if (typeof message.fillTxHashes !== "boolean")
                        return "fillTxHashes: boolean expected";
                if (message.pagination != null && message.hasOwnProperty("pagination")) {
                    var error = $root.protocol.Pagination.verify(message.pagination);
                    if (error)
                        return "pagination." + error;
                }
                return null;
            };
    
            /**
             * Creates a GetBlockByHeightRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.GetBlockByHeightRequest} GetBlockByHeightRequest
             */
            GetBlockByHeightRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.GetBlockByHeightRequest)
                    return object;
                var message = new $root.protocol.GetBlockByHeightRequest();
                switch (object.chain) {
                default:
                    if (typeof object.chain === "number") {
                        message.chain = object.chain;
                        break;
                    }
                    break;
                case "BTC":
                case 0:
                    message.chain = 0;
                    break;
                case "ETH":
                case 1:
                    message.chain = 1;
                    break;
                case "TRX":
                case 2:
                    message.chain = 2;
                    break;
                }
                switch (object.network) {
                default:
                    if (typeof object.network === "number") {
                        message.network = object.network;
                        break;
                    }
                    break;
                case "MAINNET":
                case 0:
                    message.network = 0;
                    break;
                case "TESTNET":
                case 1:
                    message.network = 1;
                    break;
                }
                if (object.heights) {
                    if (!Array.isArray(object.heights))
                        throw TypeError(".protocol.GetBlockByHeightRequest.heights: array expected");
                    message.heights = [];
                    for (var i = 0; i < object.heights.length; ++i)
                        if ($util.Long)
                            (message.heights[i] = $util.Long.fromValue(object.heights[i])).unsigned = false;
                        else if (typeof object.heights[i] === "string")
                            message.heights[i] = parseInt(object.heights[i], 10);
                        else if (typeof object.heights[i] === "number")
                            message.heights[i] = object.heights[i];
                        else if (typeof object.heights[i] === "object")
                            message.heights[i] = new $util.LongBits(object.heights[i].low >>> 0, object.heights[i].high >>> 0).toNumber();
                }
                if (object.full != null)
                    message.full = Boolean(object.full);
                if (object.fillTxHashes != null)
                    message.fillTxHashes = Boolean(object.fillTxHashes);
                if (object.pagination != null) {
                    if (typeof object.pagination !== "object")
                        throw TypeError(".protocol.GetBlockByHeightRequest.pagination: object expected");
                    message.pagination = $root.protocol.Pagination.fromObject(object.pagination);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetBlockByHeightRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {protocol.GetBlockByHeightRequest} message GetBlockByHeightRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBlockByHeightRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.heights = [];
                if (options.defaults) {
                    object.chain = options.enums === String ? "BTC" : 0;
                    object.network = options.enums === String ? "MAINNET" : 0;
                    object.full = false;
                    object.pagination = null;
                    object.fillTxHashes = false;
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] === undefined ? message.chain : $root.protocol.Chain[message.chain] : message.chain;
                if (message.network != null && message.hasOwnProperty("network"))
                    object.network = options.enums === String ? $root.protocol.CryptoNetwork[message.network] === undefined ? message.network : $root.protocol.CryptoNetwork[message.network] : message.network;
                if (message.heights && message.heights.length) {
                    object.heights = [];
                    for (var j = 0; j < message.heights.length; ++j)
                        if (typeof message.heights[j] === "number")
                            object.heights[j] = options.longs === String ? String(message.heights[j]) : message.heights[j];
                        else
                            object.heights[j] = options.longs === String ? $util.Long.prototype.toString.call(message.heights[j]) : options.longs === Number ? new $util.LongBits(message.heights[j].low >>> 0, message.heights[j].high >>> 0).toNumber() : message.heights[j];
                }
                if (message.full != null && message.hasOwnProperty("full"))
                    object.full = message.full;
                if (message.pagination != null && message.hasOwnProperty("pagination"))
                    object.pagination = $root.protocol.Pagination.toObject(message.pagination, options);
                if (message.fillTxHashes != null && message.hasOwnProperty("fillTxHashes"))
                    object.fillTxHashes = message.fillTxHashes;
                return object;
            };
    
            /**
             * Converts this GetBlockByHeightRequest to JSON.
             * @function toJSON
             * @memberof protocol.GetBlockByHeightRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBlockByHeightRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetBlockByHeightRequest
             * @function getTypeUrl
             * @memberof protocol.GetBlockByHeightRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetBlockByHeightRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.GetBlockByHeightRequest";
            };
    
            return GetBlockByHeightRequest;
        })();
    
        protocol.GetBlockByHeightResponse = (function() {
    
            /**
             * Properties of a GetBlockByHeightResponse.
             * @memberof protocol
             * @interface IGetBlockByHeightResponse
             * @property {Array.<protocol.IUnifiedBlock>|null} [blocks] GetBlockByHeightResponse blocks
             */
    
            /**
             * Constructs a new GetBlockByHeightResponse.
             * @memberof protocol
             * @classdesc Represents a GetBlockByHeightResponse.
             * @implements IGetBlockByHeightResponse
             * @constructor
             * @param {protocol.IGetBlockByHeightResponse=} [properties] Properties to set
             */
            function GetBlockByHeightResponse(properties) {
                this.blocks = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetBlockByHeightResponse blocks.
             * @member {Array.<protocol.IUnifiedBlock>} blocks
             * @memberof protocol.GetBlockByHeightResponse
             * @instance
             */
            GetBlockByHeightResponse.prototype.blocks = $util.emptyArray;
    
            /**
             * Creates a new GetBlockByHeightResponse instance using the specified properties.
             * @function create
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {protocol.IGetBlockByHeightResponse=} [properties] Properties to set
             * @returns {protocol.GetBlockByHeightResponse} GetBlockByHeightResponse instance
             */
            GetBlockByHeightResponse.create = function create(properties) {
                return new GetBlockByHeightResponse(properties);
            };
    
            /**
             * Encodes the specified GetBlockByHeightResponse message. Does not implicitly {@link protocol.GetBlockByHeightResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {protocol.IGetBlockByHeightResponse} message GetBlockByHeightResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBlockByHeightResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.blocks != null && message.blocks.length)
                    for (var i = 0; i < message.blocks.length; ++i)
                        $root.protocol.UnifiedBlock.encode(message.blocks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GetBlockByHeightResponse message, length delimited. Does not implicitly {@link protocol.GetBlockByHeightResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {protocol.IGetBlockByHeightResponse} message GetBlockByHeightResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBlockByHeightResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetBlockByHeightResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.GetBlockByHeightResponse} GetBlockByHeightResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBlockByHeightResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.GetBlockByHeightResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.blocks && message.blocks.length))
                                message.blocks = [];
                            message.blocks.push($root.protocol.UnifiedBlock.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetBlockByHeightResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.GetBlockByHeightResponse} GetBlockByHeightResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBlockByHeightResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetBlockByHeightResponse message.
             * @function verify
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBlockByHeightResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.blocks != null && message.hasOwnProperty("blocks")) {
                    if (!Array.isArray(message.blocks))
                        return "blocks: array expected";
                    for (var i = 0; i < message.blocks.length; ++i) {
                        var error = $root.protocol.UnifiedBlock.verify(message.blocks[i]);
                        if (error)
                            return "blocks." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a GetBlockByHeightResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.GetBlockByHeightResponse} GetBlockByHeightResponse
             */
            GetBlockByHeightResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.GetBlockByHeightResponse)
                    return object;
                var message = new $root.protocol.GetBlockByHeightResponse();
                if (object.blocks) {
                    if (!Array.isArray(object.blocks))
                        throw TypeError(".protocol.GetBlockByHeightResponse.blocks: array expected");
                    message.blocks = [];
                    for (var i = 0; i < object.blocks.length; ++i) {
                        if (typeof object.blocks[i] !== "object")
                            throw TypeError(".protocol.GetBlockByHeightResponse.blocks: object expected");
                        message.blocks[i] = $root.protocol.UnifiedBlock.fromObject(object.blocks[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetBlockByHeightResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {protocol.GetBlockByHeightResponse} message GetBlockByHeightResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBlockByHeightResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.blocks = [];
                if (message.blocks && message.blocks.length) {
                    object.blocks = [];
                    for (var j = 0; j < message.blocks.length; ++j)
                        object.blocks[j] = $root.protocol.UnifiedBlock.toObject(message.blocks[j], options);
                }
                return object;
            };
    
            /**
             * Converts this GetBlockByHeightResponse to JSON.
             * @function toJSON
             * @memberof protocol.GetBlockByHeightResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBlockByHeightResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetBlockByHeightResponse
             * @function getTypeUrl
             * @memberof protocol.GetBlockByHeightResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetBlockByHeightResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.GetBlockByHeightResponse";
            };
    
            return GetBlockByHeightResponse;
        })();
    
        protocol.GetTxByHashRequest = (function() {
    
            /**
             * Properties of a GetTxByHashRequest.
             * @memberof protocol
             * @interface IGetTxByHashRequest
             * @property {Array.<string>|null} [txHashes] GetTxByHashRequest txHashes
             */
    
            /**
             * Constructs a new GetTxByHashRequest.
             * @memberof protocol
             * @classdesc Represents a GetTxByHashRequest.
             * @implements IGetTxByHashRequest
             * @constructor
             * @param {protocol.IGetTxByHashRequest=} [properties] Properties to set
             */
            function GetTxByHashRequest(properties) {
                this.txHashes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetTxByHashRequest txHashes.
             * @member {Array.<string>} txHashes
             * @memberof protocol.GetTxByHashRequest
             * @instance
             */
            GetTxByHashRequest.prototype.txHashes = $util.emptyArray;
    
            /**
             * Creates a new GetTxByHashRequest instance using the specified properties.
             * @function create
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {protocol.IGetTxByHashRequest=} [properties] Properties to set
             * @returns {protocol.GetTxByHashRequest} GetTxByHashRequest instance
             */
            GetTxByHashRequest.create = function create(properties) {
                return new GetTxByHashRequest(properties);
            };
    
            /**
             * Encodes the specified GetTxByHashRequest message. Does not implicitly {@link protocol.GetTxByHashRequest.verify|verify} messages.
             * @function encode
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {protocol.IGetTxByHashRequest} message GetTxByHashRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTxByHashRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.txHashes != null && message.txHashes.length)
                    for (var i = 0; i < message.txHashes.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.txHashes[i]);
                return writer;
            };
    
            /**
             * Encodes the specified GetTxByHashRequest message, length delimited. Does not implicitly {@link protocol.GetTxByHashRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {protocol.IGetTxByHashRequest} message GetTxByHashRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTxByHashRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetTxByHashRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.GetTxByHashRequest} GetTxByHashRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTxByHashRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.GetTxByHashRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.txHashes && message.txHashes.length))
                                message.txHashes = [];
                            message.txHashes.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetTxByHashRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.GetTxByHashRequest} GetTxByHashRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTxByHashRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetTxByHashRequest message.
             * @function verify
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTxByHashRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.txHashes != null && message.hasOwnProperty("txHashes")) {
                    if (!Array.isArray(message.txHashes))
                        return "txHashes: array expected";
                    for (var i = 0; i < message.txHashes.length; ++i)
                        if (!$util.isString(message.txHashes[i]))
                            return "txHashes: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a GetTxByHashRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.GetTxByHashRequest} GetTxByHashRequest
             */
            GetTxByHashRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.GetTxByHashRequest)
                    return object;
                var message = new $root.protocol.GetTxByHashRequest();
                if (object.txHashes) {
                    if (!Array.isArray(object.txHashes))
                        throw TypeError(".protocol.GetTxByHashRequest.txHashes: array expected");
                    message.txHashes = [];
                    for (var i = 0; i < object.txHashes.length; ++i)
                        message.txHashes[i] = String(object.txHashes[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetTxByHashRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {protocol.GetTxByHashRequest} message GetTxByHashRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTxByHashRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.txHashes = [];
                if (message.txHashes && message.txHashes.length) {
                    object.txHashes = [];
                    for (var j = 0; j < message.txHashes.length; ++j)
                        object.txHashes[j] = message.txHashes[j];
                }
                return object;
            };
    
            /**
             * Converts this GetTxByHashRequest to JSON.
             * @function toJSON
             * @memberof protocol.GetTxByHashRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTxByHashRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetTxByHashRequest
             * @function getTypeUrl
             * @memberof protocol.GetTxByHashRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTxByHashRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.GetTxByHashRequest";
            };
    
            return GetTxByHashRequest;
        })();
    
        protocol.GetTxByHashResponse = (function() {
    
            /**
             * Properties of a GetTxByHashResponse.
             * @memberof protocol
             * @interface IGetTxByHashResponse
             * @property {Array.<protocol.IUnifiedTransaction>|null} [txs] GetTxByHashResponse txs
             */
    
            /**
             * Constructs a new GetTxByHashResponse.
             * @memberof protocol
             * @classdesc Represents a GetTxByHashResponse.
             * @implements IGetTxByHashResponse
             * @constructor
             * @param {protocol.IGetTxByHashResponse=} [properties] Properties to set
             */
            function GetTxByHashResponse(properties) {
                this.txs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetTxByHashResponse txs.
             * @member {Array.<protocol.IUnifiedTransaction>} txs
             * @memberof protocol.GetTxByHashResponse
             * @instance
             */
            GetTxByHashResponse.prototype.txs = $util.emptyArray;
    
            /**
             * Creates a new GetTxByHashResponse instance using the specified properties.
             * @function create
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {protocol.IGetTxByHashResponse=} [properties] Properties to set
             * @returns {protocol.GetTxByHashResponse} GetTxByHashResponse instance
             */
            GetTxByHashResponse.create = function create(properties) {
                return new GetTxByHashResponse(properties);
            };
    
            /**
             * Encodes the specified GetTxByHashResponse message. Does not implicitly {@link protocol.GetTxByHashResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {protocol.IGetTxByHashResponse} message GetTxByHashResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTxByHashResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.txs != null && message.txs.length)
                    for (var i = 0; i < message.txs.length; ++i)
                        $root.protocol.UnifiedTransaction.encode(message.txs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GetTxByHashResponse message, length delimited. Does not implicitly {@link protocol.GetTxByHashResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {protocol.IGetTxByHashResponse} message GetTxByHashResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTxByHashResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetTxByHashResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.GetTxByHashResponse} GetTxByHashResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTxByHashResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.GetTxByHashResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.txs && message.txs.length))
                                message.txs = [];
                            message.txs.push($root.protocol.UnifiedTransaction.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetTxByHashResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.GetTxByHashResponse} GetTxByHashResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTxByHashResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetTxByHashResponse message.
             * @function verify
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTxByHashResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.txs != null && message.hasOwnProperty("txs")) {
                    if (!Array.isArray(message.txs))
                        return "txs: array expected";
                    for (var i = 0; i < message.txs.length; ++i) {
                        var error = $root.protocol.UnifiedTransaction.verify(message.txs[i]);
                        if (error)
                            return "txs." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a GetTxByHashResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.GetTxByHashResponse} GetTxByHashResponse
             */
            GetTxByHashResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.GetTxByHashResponse)
                    return object;
                var message = new $root.protocol.GetTxByHashResponse();
                if (object.txs) {
                    if (!Array.isArray(object.txs))
                        throw TypeError(".protocol.GetTxByHashResponse.txs: array expected");
                    message.txs = [];
                    for (var i = 0; i < object.txs.length; ++i) {
                        if (typeof object.txs[i] !== "object")
                            throw TypeError(".protocol.GetTxByHashResponse.txs: object expected");
                        message.txs[i] = $root.protocol.UnifiedTransaction.fromObject(object.txs[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetTxByHashResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {protocol.GetTxByHashResponse} message GetTxByHashResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTxByHashResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.txs = [];
                if (message.txs && message.txs.length) {
                    object.txs = [];
                    for (var j = 0; j < message.txs.length; ++j)
                        object.txs[j] = $root.protocol.UnifiedTransaction.toObject(message.txs[j], options);
                }
                return object;
            };
    
            /**
             * Converts this GetTxByHashResponse to JSON.
             * @function toJSON
             * @memberof protocol.GetTxByHashResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTxByHashResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetTxByHashResponse
             * @function getTypeUrl
             * @memberof protocol.GetTxByHashResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTxByHashResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.GetTxByHashResponse";
            };
    
            return GetTxByHashResponse;
        })();
    
        protocol.FindAddressAndTxs = (function() {
    
            /**
             * Properties of a FindAddressAndTxs.
             * @memberof protocol
             * @interface IFindAddressAndTxs
             * @property {string|null} [address] FindAddressAndTxs address
             * @property {protocol.IPagination|null} [pagination] FindAddressAndTxs pagination
             * @property {protocol.ITimeRange|null} [timeRange] FindAddressAndTxs timeRange
             * @property {boolean|null} [onlyTokenTransfers] FindAddressAndTxs onlyTokenTransfers
             */
    
            /**
             * Constructs a new FindAddressAndTxs.
             * @memberof protocol
             * @classdesc Represents a FindAddressAndTxs.
             * @implements IFindAddressAndTxs
             * @constructor
             * @param {protocol.IFindAddressAndTxs=} [properties] Properties to set
             */
            function FindAddressAndTxs(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * FindAddressAndTxs address.
             * @member {string} address
             * @memberof protocol.FindAddressAndTxs
             * @instance
             */
            FindAddressAndTxs.prototype.address = "";
    
            /**
             * FindAddressAndTxs pagination.
             * @member {protocol.IPagination|null|undefined} pagination
             * @memberof protocol.FindAddressAndTxs
             * @instance
             */
            FindAddressAndTxs.prototype.pagination = null;
    
            /**
             * FindAddressAndTxs timeRange.
             * @member {protocol.ITimeRange|null|undefined} timeRange
             * @memberof protocol.FindAddressAndTxs
             * @instance
             */
            FindAddressAndTxs.prototype.timeRange = null;
    
            /**
             * FindAddressAndTxs onlyTokenTransfers.
             * @member {boolean} onlyTokenTransfers
             * @memberof protocol.FindAddressAndTxs
             * @instance
             */
            FindAddressAndTxs.prototype.onlyTokenTransfers = false;
    
            /**
             * Creates a new FindAddressAndTxs instance using the specified properties.
             * @function create
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {protocol.IFindAddressAndTxs=} [properties] Properties to set
             * @returns {protocol.FindAddressAndTxs} FindAddressAndTxs instance
             */
            FindAddressAndTxs.create = function create(properties) {
                return new FindAddressAndTxs(properties);
            };
    
            /**
             * Encodes the specified FindAddressAndTxs message. Does not implicitly {@link protocol.FindAddressAndTxs.verify|verify} messages.
             * @function encode
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {protocol.IFindAddressAndTxs} message FindAddressAndTxs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAddressAndTxs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                    $root.protocol.Pagination.encode(message.pagination, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.onlyTokenTransfers != null && Object.hasOwnProperty.call(message, "onlyTokenTransfers"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.onlyTokenTransfers);
                if (message.timeRange != null && Object.hasOwnProperty.call(message, "timeRange"))
                    $root.protocol.TimeRange.encode(message.timeRange, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified FindAddressAndTxs message, length delimited. Does not implicitly {@link protocol.FindAddressAndTxs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {protocol.IFindAddressAndTxs} message FindAddressAndTxs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAddressAndTxs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FindAddressAndTxs message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.FindAddressAndTxs} FindAddressAndTxs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAddressAndTxs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.FindAddressAndTxs();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.string();
                            break;
                        }
                    case 2: {
                            message.pagination = $root.protocol.Pagination.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.timeRange = $root.protocol.TimeRange.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.onlyTokenTransfers = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FindAddressAndTxs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.FindAddressAndTxs} FindAddressAndTxs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAddressAndTxs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FindAddressAndTxs message.
             * @function verify
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindAddressAndTxs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.pagination != null && message.hasOwnProperty("pagination")) {
                    var error = $root.protocol.Pagination.verify(message.pagination);
                    if (error)
                        return "pagination." + error;
                }
                if (message.timeRange != null && message.hasOwnProperty("timeRange")) {
                    var error = $root.protocol.TimeRange.verify(message.timeRange);
                    if (error)
                        return "timeRange." + error;
                }
                if (message.onlyTokenTransfers != null && message.hasOwnProperty("onlyTokenTransfers"))
                    if (typeof message.onlyTokenTransfers !== "boolean")
                        return "onlyTokenTransfers: boolean expected";
                return null;
            };
    
            /**
             * Creates a FindAddressAndTxs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.FindAddressAndTxs} FindAddressAndTxs
             */
            FindAddressAndTxs.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.FindAddressAndTxs)
                    return object;
                var message = new $root.protocol.FindAddressAndTxs();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.pagination != null) {
                    if (typeof object.pagination !== "object")
                        throw TypeError(".protocol.FindAddressAndTxs.pagination: object expected");
                    message.pagination = $root.protocol.Pagination.fromObject(object.pagination);
                }
                if (object.timeRange != null) {
                    if (typeof object.timeRange !== "object")
                        throw TypeError(".protocol.FindAddressAndTxs.timeRange: object expected");
                    message.timeRange = $root.protocol.TimeRange.fromObject(object.timeRange);
                }
                if (object.onlyTokenTransfers != null)
                    message.onlyTokenTransfers = Boolean(object.onlyTokenTransfers);
                return message;
            };
    
            /**
             * Creates a plain object from a FindAddressAndTxs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {protocol.FindAddressAndTxs} message FindAddressAndTxs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindAddressAndTxs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    object.pagination = null;
                    object.onlyTokenTransfers = false;
                    object.timeRange = null;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.pagination != null && message.hasOwnProperty("pagination"))
                    object.pagination = $root.protocol.Pagination.toObject(message.pagination, options);
                if (message.onlyTokenTransfers != null && message.hasOwnProperty("onlyTokenTransfers"))
                    object.onlyTokenTransfers = message.onlyTokenTransfers;
                if (message.timeRange != null && message.hasOwnProperty("timeRange"))
                    object.timeRange = $root.protocol.TimeRange.toObject(message.timeRange, options);
                return object;
            };
    
            /**
             * Converts this FindAddressAndTxs to JSON.
             * @function toJSON
             * @memberof protocol.FindAddressAndTxs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindAddressAndTxs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for FindAddressAndTxs
             * @function getTypeUrl
             * @memberof protocol.FindAddressAndTxs
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindAddressAndTxs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.FindAddressAndTxs";
            };
    
            return FindAddressAndTxs;
        })();
    
        protocol.Balance = (function() {
    
            /**
             * Properties of a Balance.
             * @memberof protocol
             * @interface IBalance
             * @property {protocol.IUniValue|null} [balance] Balance balance
             */
    
            /**
             * Constructs a new Balance.
             * @memberof protocol
             * @classdesc Represents a Balance.
             * @implements IBalance
             * @constructor
             * @param {protocol.IBalance=} [properties] Properties to set
             */
            function Balance(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Balance balance.
             * @member {protocol.IUniValue|null|undefined} balance
             * @memberof protocol.Balance
             * @instance
             */
            Balance.prototype.balance = null;
    
            /**
             * Creates a new Balance instance using the specified properties.
             * @function create
             * @memberof protocol.Balance
             * @static
             * @param {protocol.IBalance=} [properties] Properties to set
             * @returns {protocol.Balance} Balance instance
             */
            Balance.create = function create(properties) {
                return new Balance(properties);
            };
    
            /**
             * Encodes the specified Balance message. Does not implicitly {@link protocol.Balance.verify|verify} messages.
             * @function encode
             * @memberof protocol.Balance
             * @static
             * @param {protocol.IBalance} message Balance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Balance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    $root.protocol.UniValue.encode(message.balance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Balance message, length delimited. Does not implicitly {@link protocol.Balance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.Balance
             * @static
             * @param {protocol.IBalance} message Balance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Balance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Balance message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.Balance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.Balance} Balance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Balance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.Balance();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.balance = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Balance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.Balance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.Balance} Balance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Balance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Balance message.
             * @function verify
             * @memberof protocol.Balance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Balance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balance != null && message.hasOwnProperty("balance")) {
                    var error = $root.protocol.UniValue.verify(message.balance);
                    if (error)
                        return "balance." + error;
                }
                return null;
            };
    
            /**
             * Creates a Balance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.Balance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.Balance} Balance
             */
            Balance.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.Balance)
                    return object;
                var message = new $root.protocol.Balance();
                if (object.balance != null) {
                    if (typeof object.balance !== "object")
                        throw TypeError(".protocol.Balance.balance: object expected");
                    message.balance = $root.protocol.UniValue.fromObject(object.balance);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Balance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.Balance
             * @static
             * @param {protocol.Balance} message Balance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Balance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.balance = null;
                if (message.balance != null && message.hasOwnProperty("balance"))
                    object.balance = $root.protocol.UniValue.toObject(message.balance, options);
                return object;
            };
    
            /**
             * Converts this Balance to JSON.
             * @function toJSON
             * @memberof protocol.Balance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Balance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Balance
             * @function getTypeUrl
             * @memberof protocol.Balance
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Balance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.Balance";
            };
    
            return Balance;
        })();
    
        protocol.FindTxByAddressResponse = (function() {
    
            /**
             * Properties of a FindTxByAddressResponse.
             * @memberof protocol
             * @interface IFindTxByAddressResponse
             * @property {Array.<protocol.IUnifiedTransaction>|null} [txs] FindTxByAddressResponse txs
             * @property {Long|null} [total] FindTxByAddressResponse total
             * @property {Array.<protocol.IBalance>|null} [balances] FindTxByAddressResponse balances
             */
    
            /**
             * Constructs a new FindTxByAddressResponse.
             * @memberof protocol
             * @classdesc Represents a FindTxByAddressResponse.
             * @implements IFindTxByAddressResponse
             * @constructor
             * @param {protocol.IFindTxByAddressResponse=} [properties] Properties to set
             */
            function FindTxByAddressResponse(properties) {
                this.txs = [];
                this.balances = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * FindTxByAddressResponse txs.
             * @member {Array.<protocol.IUnifiedTransaction>} txs
             * @memberof protocol.FindTxByAddressResponse
             * @instance
             */
            FindTxByAddressResponse.prototype.txs = $util.emptyArray;
    
            /**
             * FindTxByAddressResponse total.
             * @member {Long} total
             * @memberof protocol.FindTxByAddressResponse
             * @instance
             */
            FindTxByAddressResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * FindTxByAddressResponse balances.
             * @member {Array.<protocol.IBalance>} balances
             * @memberof protocol.FindTxByAddressResponse
             * @instance
             */
            FindTxByAddressResponse.prototype.balances = $util.emptyArray;
    
            /**
             * Creates a new FindTxByAddressResponse instance using the specified properties.
             * @function create
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {protocol.IFindTxByAddressResponse=} [properties] Properties to set
             * @returns {protocol.FindTxByAddressResponse} FindTxByAddressResponse instance
             */
            FindTxByAddressResponse.create = function create(properties) {
                return new FindTxByAddressResponse(properties);
            };
    
            /**
             * Encodes the specified FindTxByAddressResponse message. Does not implicitly {@link protocol.FindTxByAddressResponse.verify|verify} messages.
             * @function encode
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {protocol.IFindTxByAddressResponse} message FindTxByAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindTxByAddressResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.txs != null && message.txs.length)
                    for (var i = 0; i < message.txs.length; ++i)
                        $root.protocol.UnifiedTransaction.encode(message.txs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.total);
                if (message.balances != null && message.balances.length)
                    for (var i = 0; i < message.balances.length; ++i)
                        $root.protocol.Balance.encode(message.balances[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified FindTxByAddressResponse message, length delimited. Does not implicitly {@link protocol.FindTxByAddressResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {protocol.IFindTxByAddressResponse} message FindTxByAddressResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindTxByAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a FindTxByAddressResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.FindTxByAddressResponse} FindTxByAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindTxByAddressResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.FindTxByAddressResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.txs && message.txs.length))
                                message.txs = [];
                            message.txs.push($root.protocol.UnifiedTransaction.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.total = reader.int64();
                            break;
                        }
                    case 3: {
                            if (!(message.balances && message.balances.length))
                                message.balances = [];
                            message.balances.push($root.protocol.Balance.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a FindTxByAddressResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.FindTxByAddressResponse} FindTxByAddressResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindTxByAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a FindTxByAddressResponse message.
             * @function verify
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindTxByAddressResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.txs != null && message.hasOwnProperty("txs")) {
                    if (!Array.isArray(message.txs))
                        return "txs: array expected";
                    for (var i = 0; i < message.txs.length; ++i) {
                        var error = $root.protocol.UnifiedTransaction.verify(message.txs[i]);
                        if (error)
                            return "txs." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                        return "total: integer|Long expected";
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!Array.isArray(message.balances))
                        return "balances: array expected";
                    for (var i = 0; i < message.balances.length; ++i) {
                        var error = $root.protocol.Balance.verify(message.balances[i]);
                        if (error)
                            return "balances." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a FindTxByAddressResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.FindTxByAddressResponse} FindTxByAddressResponse
             */
            FindTxByAddressResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.FindTxByAddressResponse)
                    return object;
                var message = new $root.protocol.FindTxByAddressResponse();
                if (object.txs) {
                    if (!Array.isArray(object.txs))
                        throw TypeError(".protocol.FindTxByAddressResponse.txs: array expected");
                    message.txs = [];
                    for (var i = 0; i < object.txs.length; ++i) {
                        if (typeof object.txs[i] !== "object")
                            throw TypeError(".protocol.FindTxByAddressResponse.txs: object expected");
                        message.txs[i] = $root.protocol.UnifiedTransaction.fromObject(object.txs[i]);
                    }
                }
                if (object.total != null)
                    if ($util.Long)
                        (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                    else if (typeof object.total === "string")
                        message.total = parseInt(object.total, 10);
                    else if (typeof object.total === "number")
                        message.total = object.total;
                    else if (typeof object.total === "object")
                        message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                if (object.balances) {
                    if (!Array.isArray(object.balances))
                        throw TypeError(".protocol.FindTxByAddressResponse.balances: array expected");
                    message.balances = [];
                    for (var i = 0; i < object.balances.length; ++i) {
                        if (typeof object.balances[i] !== "object")
                            throw TypeError(".protocol.FindTxByAddressResponse.balances: object expected");
                        message.balances[i] = $root.protocol.Balance.fromObject(object.balances[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a FindTxByAddressResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {protocol.FindTxByAddressResponse} message FindTxByAddressResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindTxByAddressResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.txs = [];
                    object.balances = [];
                }
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.total = options.longs === String ? "0" : 0;
                if (message.txs && message.txs.length) {
                    object.txs = [];
                    for (var j = 0; j < message.txs.length; ++j)
                        object.txs[j] = $root.protocol.UnifiedTransaction.toObject(message.txs[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (typeof message.total === "number")
                        object.total = options.longs === String ? String(message.total) : message.total;
                    else
                        object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                if (message.balances && message.balances.length) {
                    object.balances = [];
                    for (var j = 0; j < message.balances.length; ++j)
                        object.balances[j] = $root.protocol.Balance.toObject(message.balances[j], options);
                }
                return object;
            };
    
            /**
             * Converts this FindTxByAddressResponse to JSON.
             * @function toJSON
             * @memberof protocol.FindTxByAddressResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindTxByAddressResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for FindTxByAddressResponse
             * @function getTypeUrl
             * @memberof protocol.FindTxByAddressResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindTxByAddressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.FindTxByAddressResponse";
            };
    
            return FindTxByAddressResponse;
        })();
    
        /**
         * Chain enum.
         * @name protocol.Chain
         * @enum {number}
         * @property {number} BTC=0 BTC value
         * @property {number} ETH=1 ETH value
         * @property {number} TRX=2 TRX value
         */
        protocol.Chain = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BTC"] = 0;
            values[valuesById[1] = "ETH"] = 1;
            values[valuesById[2] = "TRX"] = 2;
            return values;
        })();
    
        /**
         * CryptoNetwork enum.
         * @name protocol.CryptoNetwork
         * @enum {number}
         * @property {number} MAINNET=0 MAINNET value
         * @property {number} TESTNET=1 TESTNET value
         */
        protocol.CryptoNetwork = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MAINNET"] = 0;
            values[valuesById[1] = "TESTNET"] = 1;
            return values;
        })();
    
        protocol.UnBigInt = (function() {
    
            /**
             * Properties of an UnBigInt.
             * @memberof protocol
             * @interface IUnBigInt
             * @property {Uint8Array|null} [numberInBytes] UnBigInt numberInBytes
             * @property {string|null} [numberInStr] UnBigInt numberInStr
             */
    
            /**
             * Constructs a new UnBigInt.
             * @memberof protocol
             * @classdesc Represents an UnBigInt.
             * @implements IUnBigInt
             * @constructor
             * @param {protocol.IUnBigInt=} [properties] Properties to set
             */
            function UnBigInt(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UnBigInt numberInBytes.
             * @member {Uint8Array} numberInBytes
             * @memberof protocol.UnBigInt
             * @instance
             */
            UnBigInt.prototype.numberInBytes = $util.newBuffer([]);
    
            /**
             * UnBigInt numberInStr.
             * @member {string} numberInStr
             * @memberof protocol.UnBigInt
             * @instance
             */
            UnBigInt.prototype.numberInStr = "";
    
            /**
             * Creates a new UnBigInt instance using the specified properties.
             * @function create
             * @memberof protocol.UnBigInt
             * @static
             * @param {protocol.IUnBigInt=} [properties] Properties to set
             * @returns {protocol.UnBigInt} UnBigInt instance
             */
            UnBigInt.create = function create(properties) {
                return new UnBigInt(properties);
            };
    
            /**
             * Encodes the specified UnBigInt message. Does not implicitly {@link protocol.UnBigInt.verify|verify} messages.
             * @function encode
             * @memberof protocol.UnBigInt
             * @static
             * @param {protocol.IUnBigInt} message UnBigInt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnBigInt.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.numberInBytes != null && Object.hasOwnProperty.call(message, "numberInBytes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.numberInBytes);
                if (message.numberInStr != null && Object.hasOwnProperty.call(message, "numberInStr"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.numberInStr);
                return writer;
            };
    
            /**
             * Encodes the specified UnBigInt message, length delimited. Does not implicitly {@link protocol.UnBigInt.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.UnBigInt
             * @static
             * @param {protocol.IUnBigInt} message UnBigInt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnBigInt.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UnBigInt message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.UnBigInt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.UnBigInt} UnBigInt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnBigInt.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.UnBigInt();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.numberInBytes = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.numberInStr = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UnBigInt message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.UnBigInt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.UnBigInt} UnBigInt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnBigInt.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UnBigInt message.
             * @function verify
             * @memberof protocol.UnBigInt
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnBigInt.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.numberInBytes != null && message.hasOwnProperty("numberInBytes"))
                    if (!(message.numberInBytes && typeof message.numberInBytes.length === "number" || $util.isString(message.numberInBytes)))
                        return "numberInBytes: buffer expected";
                if (message.numberInStr != null && message.hasOwnProperty("numberInStr"))
                    if (!$util.isString(message.numberInStr))
                        return "numberInStr: string expected";
                return null;
            };
    
            /**
             * Creates an UnBigInt message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.UnBigInt
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.UnBigInt} UnBigInt
             */
            UnBigInt.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.UnBigInt)
                    return object;
                var message = new $root.protocol.UnBigInt();
                if (object.numberInBytes != null)
                    if (typeof object.numberInBytes === "string")
                        $util.base64.decode(object.numberInBytes, message.numberInBytes = $util.newBuffer($util.base64.length(object.numberInBytes)), 0);
                    else if (object.numberInBytes.length >= 0)
                        message.numberInBytes = object.numberInBytes;
                if (object.numberInStr != null)
                    message.numberInStr = String(object.numberInStr);
                return message;
            };
    
            /**
             * Creates a plain object from an UnBigInt message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.UnBigInt
             * @static
             * @param {protocol.UnBigInt} message UnBigInt
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnBigInt.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.numberInBytes = "";
                    else {
                        object.numberInBytes = [];
                        if (options.bytes !== Array)
                            object.numberInBytes = $util.newBuffer(object.numberInBytes);
                    }
                    object.numberInStr = "";
                }
                if (message.numberInBytes != null && message.hasOwnProperty("numberInBytes"))
                    object.numberInBytes = options.bytes === String ? $util.base64.encode(message.numberInBytes, 0, message.numberInBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.numberInBytes) : message.numberInBytes;
                if (message.numberInStr != null && message.hasOwnProperty("numberInStr"))
                    object.numberInStr = message.numberInStr;
                return object;
            };
    
            /**
             * Converts this UnBigInt to JSON.
             * @function toJSON
             * @memberof protocol.UnBigInt
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnBigInt.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UnBigInt
             * @function getTypeUrl
             * @memberof protocol.UnBigInt
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UnBigInt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.UnBigInt";
            };
    
            return UnBigInt;
        })();
    
        protocol.UniValue = (function() {
    
            /**
             * Properties of an UniValue.
             * @memberof protocol
             * @interface IUniValue
             * @property {protocol.IUnBigInt|null} [value] UniValue value
             * @property {protocol.WLCurrency|null} [valueCurrency] UniValue valueCurrency
             * @property {protocol.IUnBigInt|null} [valueInUSDNow] UniValue valueInUSDNow
             * @property {protocol.IUnBigInt|null} [valueInUSDBackThen] UniValue valueInUSDBackThen
             */
    
            /**
             * Constructs a new UniValue.
             * @memberof protocol
             * @classdesc Represents an UniValue.
             * @implements IUniValue
             * @constructor
             * @param {protocol.IUniValue=} [properties] Properties to set
             */
            function UniValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UniValue value.
             * @member {protocol.IUnBigInt|null|undefined} value
             * @memberof protocol.UniValue
             * @instance
             */
            UniValue.prototype.value = null;
    
            /**
             * UniValue valueCurrency.
             * @member {protocol.WLCurrency} valueCurrency
             * @memberof protocol.UniValue
             * @instance
             */
            UniValue.prototype.valueCurrency = 0;
    
            /**
             * UniValue valueInUSDNow.
             * @member {protocol.IUnBigInt|null|undefined} valueInUSDNow
             * @memberof protocol.UniValue
             * @instance
             */
            UniValue.prototype.valueInUSDNow = null;
    
            /**
             * UniValue valueInUSDBackThen.
             * @member {protocol.IUnBigInt|null|undefined} valueInUSDBackThen
             * @memberof protocol.UniValue
             * @instance
             */
            UniValue.prototype.valueInUSDBackThen = null;
    
            /**
             * Creates a new UniValue instance using the specified properties.
             * @function create
             * @memberof protocol.UniValue
             * @static
             * @param {protocol.IUniValue=} [properties] Properties to set
             * @returns {protocol.UniValue} UniValue instance
             */
            UniValue.create = function create(properties) {
                return new UniValue(properties);
            };
    
            /**
             * Encodes the specified UniValue message. Does not implicitly {@link protocol.UniValue.verify|verify} messages.
             * @function encode
             * @memberof protocol.UniValue
             * @static
             * @param {protocol.IUniValue} message UniValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UniValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    $root.protocol.UnBigInt.encode(message.value, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.valueInUSDNow != null && Object.hasOwnProperty.call(message, "valueInUSDNow"))
                    $root.protocol.UnBigInt.encode(message.valueInUSDNow, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.valueInUSDBackThen != null && Object.hasOwnProperty.call(message, "valueInUSDBackThen"))
                    $root.protocol.UnBigInt.encode(message.valueInUSDBackThen, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.valueCurrency != null && Object.hasOwnProperty.call(message, "valueCurrency"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.valueCurrency);
                return writer;
            };
    
            /**
             * Encodes the specified UniValue message, length delimited. Does not implicitly {@link protocol.UniValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.UniValue
             * @static
             * @param {protocol.IUniValue} message UniValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UniValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UniValue message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.UniValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.UniValue} UniValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UniValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.UniValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.valueCurrency = reader.int32();
                            break;
                        }
                    case 2: {
                            message.valueInUSDNow = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.valueInUSDBackThen = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UniValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.UniValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.UniValue} UniValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UniValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UniValue message.
             * @function verify
             * @memberof protocol.UniValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UniValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    var error = $root.protocol.UnBigInt.verify(message.value);
                    if (error)
                        return "value." + error;
                }
                if (message.valueCurrency != null && message.hasOwnProperty("valueCurrency"))
                    switch (message.valueCurrency) {
                    default:
                        return "valueCurrency: enum value expected";
                    case 0:
                    case 8:
                    case 12:
                    case 32:
                    case 36:
                    case 44:
                    case 48:
                    case 50:
                    case 51:
                    case 52:
                    case 60:
                    case 64:
                    case 68:
                    case 72:
                    case 84:
                    case 90:
                    case 96:
                    case 104:
                    case 108:
                    case 116:
                    case 124:
                    case 132:
                    case 136:
                    case 144:
                    case 152:
                    case 156:
                    case 170:
                    case 174:
                    case 188:
                    case 191:
                    case 192:
                    case 196:
                    case 203:
                    case 208:
                    case 214:
                    case 222:
                    case 230:
                    case 232:
                    case 233:
                    case 238:
                    case 242:
                    case 262:
                    case 270:
                    case 292:
                    case 320:
                    case 324:
                    case 328:
                    case 332:
                    case 340:
                    case 344:
                    case 348:
                    case 352:
                    case 356:
                    case 360:
                    case 364:
                    case 368:
                    case 376:
                    case 388:
                    case 392:
                    case 398:
                    case 400:
                    case 404:
                    case 408:
                    case 410:
                    case 414:
                    case 417:
                    case 418:
                    case 422:
                    case 426:
                    case 428:
                    case 430:
                    case 434:
                    case 440:
                    case 446:
                    case 454:
                    case 458:
                    case 462:
                    case 470:
                    case 478:
                    case 480:
                    case 484:
                    case 496:
                    case 498:
                    case 504:
                    case 512:
                    case 516:
                    case 524:
                    case 532:
                    case 533:
                    case 548:
                    case 554:
                    case 558:
                    case 566:
                    case 578:
                    case 586:
                    case 590:
                    case 598:
                    case 600:
                    case 604:
                    case 608:
                    case 634:
                    case 643:
                    case 646:
                    case 654:
                    case 678:
                    case 682:
                    case 690:
                    case 694:
                    case 702:
                    case 703:
                    case 704:
                    case 706:
                    case 710:
                    case 716:
                    case 728:
                    case 748:
                    case 752:
                    case 756:
                    case 760:
                    case 764:
                    case 776:
                    case 780:
                    case 784:
                    case 788:
                    case 795:
                    case 800:
                    case 807:
                    case 810:
                    case 818:
                    case 826:
                    case 834:
                    case 840:
                    case 858:
                    case 860:
                    case 882:
                    case 886:
                    case 894:
                    case 901:
                    case 931:
                    case 932:
                    case 933:
                    case 934:
                    case 936:
                    case 937:
                    case 938:
                    case 941:
                    case 943:
                    case 944:
                    case 946:
                    case 949:
                    case 950:
                    case 951:
                    case 952:
                    case 953:
                    case 959:
                    case 960:
                    case 961:
                    case 967:
                    case 968:
                    case 969:
                    case 971:
                    case 972:
                    case 973:
                    case 975:
                    case 976:
                    case 977:
                    case 978:
                    case 980:
                    case 981:
                    case 985:
                    case 986:
                    case 990:
                    case 6004:
                    case 6005:
                    case 6006:
                    case 80001:
                    case 80002:
                    case 80003:
                    case 90001:
                    case 90002:
                    case 90003:
                    case 90004:
                    case 90005:
                    case 90006:
                    case 90007:
                    case 90008:
                    case 90009:
                    case 90010:
                    case 90011:
                    case 90012:
                    case 90013:
                    case 90014:
                    case 90015:
                    case 90016:
                    case 90017:
                    case 90018:
                    case 90019:
                    case 90020:
                    case 90021:
                    case 90022:
                    case 90024:
                    case 90025:
                    case 90026:
                    case 90027:
                    case 90028:
                    case 90029:
                    case 90030:
                    case 90032:
                        break;
                    }
                if (message.valueInUSDNow != null && message.hasOwnProperty("valueInUSDNow")) {
                    var error = $root.protocol.UnBigInt.verify(message.valueInUSDNow);
                    if (error)
                        return "valueInUSDNow." + error;
                }
                if (message.valueInUSDBackThen != null && message.hasOwnProperty("valueInUSDBackThen")) {
                    var error = $root.protocol.UnBigInt.verify(message.valueInUSDBackThen);
                    if (error)
                        return "valueInUSDBackThen." + error;
                }
                return null;
            };
    
            /**
             * Creates an UniValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.UniValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.UniValue} UniValue
             */
            UniValue.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.UniValue)
                    return object;
                var message = new $root.protocol.UniValue();
                if (object.value != null) {
                    if (typeof object.value !== "object")
                        throw TypeError(".protocol.UniValue.value: object expected");
                    message.value = $root.protocol.UnBigInt.fromObject(object.value);
                }
                switch (object.valueCurrency) {
                default:
                    if (typeof object.valueCurrency === "number") {
                        message.valueCurrency = object.valueCurrency;
                        break;
                    }
                    break;
                case "WLC_FUN":
                case 0:
                    message.valueCurrency = 0;
                    break;
                case "WLC_ALL":
                case 8:
                    message.valueCurrency = 8;
                    break;
                case "WLC_DZD":
                case 12:
                    message.valueCurrency = 12;
                    break;
                case "WLC_ARS":
                case 32:
                    message.valueCurrency = 32;
                    break;
                case "WLC_AUD":
                case 36:
                    message.valueCurrency = 36;
                    break;
                case "WLC_BSD":
                case 44:
                    message.valueCurrency = 44;
                    break;
                case "WLC_BHD":
                case 48:
                    message.valueCurrency = 48;
                    break;
                case "WLC_BDT":
                case 50:
                    message.valueCurrency = 50;
                    break;
                case "WLC_AMD":
                case 51:
                    message.valueCurrency = 51;
                    break;
                case "WLC_BBD":
                case 52:
                    message.valueCurrency = 52;
                    break;
                case "WLC_BMD":
                case 60:
                    message.valueCurrency = 60;
                    break;
                case "WLC_BTN":
                case 64:
                    message.valueCurrency = 64;
                    break;
                case "WLC_BOB":
                case 68:
                    message.valueCurrency = 68;
                    break;
                case "WLC_BWP":
                case 72:
                    message.valueCurrency = 72;
                    break;
                case "WLC_BZD":
                case 84:
                    message.valueCurrency = 84;
                    break;
                case "WLC_SBD":
                case 90:
                    message.valueCurrency = 90;
                    break;
                case "WLC_BND":
                case 96:
                    message.valueCurrency = 96;
                    break;
                case "WLC_MMK":
                case 104:
                    message.valueCurrency = 104;
                    break;
                case "WLC_BIF":
                case 108:
                    message.valueCurrency = 108;
                    break;
                case "WLC_KHR":
                case 116:
                    message.valueCurrency = 116;
                    break;
                case "WLC_CAD":
                case 124:
                    message.valueCurrency = 124;
                    break;
                case "WLC_CVE":
                case 132:
                    message.valueCurrency = 132;
                    break;
                case "WLC_KYD":
                case 136:
                    message.valueCurrency = 136;
                    break;
                case "WLC_LKR":
                case 144:
                    message.valueCurrency = 144;
                    break;
                case "WLC_CLP":
                case 152:
                    message.valueCurrency = 152;
                    break;
                case "WLC_CNY":
                case 156:
                    message.valueCurrency = 156;
                    break;
                case "WLC_COP":
                case 170:
                    message.valueCurrency = 170;
                    break;
                case "WLC_KMF":
                case 174:
                    message.valueCurrency = 174;
                    break;
                case "WLC_CRC":
                case 188:
                    message.valueCurrency = 188;
                    break;
                case "WLC_HRK":
                case 191:
                    message.valueCurrency = 191;
                    break;
                case "WLC_CUP":
                case 192:
                    message.valueCurrency = 192;
                    break;
                case "WLC_CYP":
                case 196:
                    message.valueCurrency = 196;
                    break;
                case "WLC_CZK":
                case 203:
                    message.valueCurrency = 203;
                    break;
                case "WLC_DKK":
                case 208:
                    message.valueCurrency = 208;
                    break;
                case "WLC_DOP":
                case 214:
                    message.valueCurrency = 214;
                    break;
                case "WLC_SVC":
                case 222:
                    message.valueCurrency = 222;
                    break;
                case "WLC_ETB":
                case 230:
                    message.valueCurrency = 230;
                    break;
                case "WLC_ERN":
                case 232:
                    message.valueCurrency = 232;
                    break;
                case "WLC_EEK":
                case 233:
                    message.valueCurrency = 233;
                    break;
                case "WLC_FKP":
                case 238:
                    message.valueCurrency = 238;
                    break;
                case "WLC_FJD":
                case 242:
                    message.valueCurrency = 242;
                    break;
                case "WLC_DJF":
                case 262:
                    message.valueCurrency = 262;
                    break;
                case "WLC_GMD":
                case 270:
                    message.valueCurrency = 270;
                    break;
                case "WLC_GIP":
                case 292:
                    message.valueCurrency = 292;
                    break;
                case "WLC_GTQ":
                case 320:
                    message.valueCurrency = 320;
                    break;
                case "WLC_GNF":
                case 324:
                    message.valueCurrency = 324;
                    break;
                case "WLC_GYD":
                case 328:
                    message.valueCurrency = 328;
                    break;
                case "WLC_HTG":
                case 332:
                    message.valueCurrency = 332;
                    break;
                case "WLC_HNL":
                case 340:
                    message.valueCurrency = 340;
                    break;
                case "WLC_HKD":
                case 344:
                    message.valueCurrency = 344;
                    break;
                case "WLC_HUF":
                case 348:
                    message.valueCurrency = 348;
                    break;
                case "WLC_ISK":
                case 352:
                    message.valueCurrency = 352;
                    break;
                case "WLC_INR":
                case 356:
                    message.valueCurrency = 356;
                    break;
                case "WLC_IDR":
                case 360:
                    message.valueCurrency = 360;
                    break;
                case "WLC_IRR":
                case 364:
                    message.valueCurrency = 364;
                    break;
                case "WLC_IQD":
                case 368:
                    message.valueCurrency = 368;
                    break;
                case "WLC_ILS":
                case 376:
                    message.valueCurrency = 376;
                    break;
                case "WLC_JMD":
                case 388:
                    message.valueCurrency = 388;
                    break;
                case "WLC_JPY":
                case 392:
                    message.valueCurrency = 392;
                    break;
                case "WLC_KZT":
                case 398:
                    message.valueCurrency = 398;
                    break;
                case "WLC_JOD":
                case 400:
                    message.valueCurrency = 400;
                    break;
                case "WLC_KES":
                case 404:
                    message.valueCurrency = 404;
                    break;
                case "WLC_KPW":
                case 408:
                    message.valueCurrency = 408;
                    break;
                case "WLC_KRW":
                case 410:
                    message.valueCurrency = 410;
                    break;
                case "WLC_KWD":
                case 414:
                    message.valueCurrency = 414;
                    break;
                case "WLC_KGS":
                case 417:
                    message.valueCurrency = 417;
                    break;
                case "WLC_LAK":
                case 418:
                    message.valueCurrency = 418;
                    break;
                case "WLC_LBP":
                case 422:
                    message.valueCurrency = 422;
                    break;
                case "WLC_LSL":
                case 426:
                    message.valueCurrency = 426;
                    break;
                case "WLC_LVL":
                case 428:
                    message.valueCurrency = 428;
                    break;
                case "WLC_LRD":
                case 430:
                    message.valueCurrency = 430;
                    break;
                case "WLC_LYD":
                case 434:
                    message.valueCurrency = 434;
                    break;
                case "WLC_LTL":
                case 440:
                    message.valueCurrency = 440;
                    break;
                case "WLC_MOP":
                case 446:
                    message.valueCurrency = 446;
                    break;
                case "WLC_MWK":
                case 454:
                    message.valueCurrency = 454;
                    break;
                case "WLC_MYR":
                case 458:
                    message.valueCurrency = 458;
                    break;
                case "WLC_MVR":
                case 462:
                    message.valueCurrency = 462;
                    break;
                case "WLC_MTL":
                case 470:
                    message.valueCurrency = 470;
                    break;
                case "WLC_MRO":
                case 478:
                    message.valueCurrency = 478;
                    break;
                case "WLC_MUR":
                case 480:
                    message.valueCurrency = 480;
                    break;
                case "WLC_MXN":
                case 484:
                    message.valueCurrency = 484;
                    break;
                case "WLC_MNT":
                case 496:
                    message.valueCurrency = 496;
                    break;
                case "WLC_MDL":
                case 498:
                    message.valueCurrency = 498;
                    break;
                case "WLC_MAD":
                case 504:
                    message.valueCurrency = 504;
                    break;
                case "WLC_OMR":
                case 512:
                    message.valueCurrency = 512;
                    break;
                case "WLC_NAD":
                case 516:
                    message.valueCurrency = 516;
                    break;
                case "WLC_NPR":
                case 524:
                    message.valueCurrency = 524;
                    break;
                case "WLC_ANG":
                case 532:
                    message.valueCurrency = 532;
                    break;
                case "WLC_AWG":
                case 533:
                    message.valueCurrency = 533;
                    break;
                case "WLC_VUV":
                case 548:
                    message.valueCurrency = 548;
                    break;
                case "WLC_NZD":
                case 554:
                    message.valueCurrency = 554;
                    break;
                case "WLC_NIO":
                case 558:
                    message.valueCurrency = 558;
                    break;
                case "WLC_NGN":
                case 566:
                    message.valueCurrency = 566;
                    break;
                case "WLC_NOK":
                case 578:
                    message.valueCurrency = 578;
                    break;
                case "WLC_PKR":
                case 586:
                    message.valueCurrency = 586;
                    break;
                case "WLC_PAB":
                case 590:
                    message.valueCurrency = 590;
                    break;
                case "WLC_PGK":
                case 598:
                    message.valueCurrency = 598;
                    break;
                case "WLC_PYG":
                case 600:
                    message.valueCurrency = 600;
                    break;
                case "WLC_PEN":
                case 604:
                    message.valueCurrency = 604;
                    break;
                case "WLC_PHP":
                case 608:
                    message.valueCurrency = 608;
                    break;
                case "WLC_QAR":
                case 634:
                    message.valueCurrency = 634;
                    break;
                case "WLC_RUB":
                case 643:
                    message.valueCurrency = 643;
                    break;
                case "WLC_RWF":
                case 646:
                    message.valueCurrency = 646;
                    break;
                case "WLC_SHP":
                case 654:
                    message.valueCurrency = 654;
                    break;
                case "WLC_STD":
                case 678:
                    message.valueCurrency = 678;
                    break;
                case "WLC_SAR":
                case 682:
                    message.valueCurrency = 682;
                    break;
                case "WLC_SCR":
                case 690:
                    message.valueCurrency = 690;
                    break;
                case "WLC_SLL":
                case 694:
                    message.valueCurrency = 694;
                    break;
                case "WLC_SGD":
                case 702:
                    message.valueCurrency = 702;
                    break;
                case "WLC_SKK":
                case 703:
                    message.valueCurrency = 703;
                    break;
                case "WLC_VND":
                case 704:
                    message.valueCurrency = 704;
                    break;
                case "WLC_SOS":
                case 706:
                    message.valueCurrency = 706;
                    break;
                case "WLC_ZAR":
                case 710:
                    message.valueCurrency = 710;
                    break;
                case "WLC_ZWD":
                case 716:
                    message.valueCurrency = 716;
                    break;
                case "WLC_SSP":
                case 728:
                    message.valueCurrency = 728;
                    break;
                case "WLC_SZL":
                case 748:
                    message.valueCurrency = 748;
                    break;
                case "WLC_SEK":
                case 752:
                    message.valueCurrency = 752;
                    break;
                case "WLC_CHF":
                case 756:
                    message.valueCurrency = 756;
                    break;
                case "WLC_SYP":
                case 760:
                    message.valueCurrency = 760;
                    break;
                case "WLC_THB":
                case 764:
                    message.valueCurrency = 764;
                    break;
                case "WLC_TOP":
                case 776:
                    message.valueCurrency = 776;
                    break;
                case "WLC_TTD":
                case 780:
                    message.valueCurrency = 780;
                    break;
                case "WLC_AED":
                case 784:
                    message.valueCurrency = 784;
                    break;
                case "WLC_TND":
                case 788:
                    message.valueCurrency = 788;
                    break;
                case "WLC_TMM":
                case 795:
                    message.valueCurrency = 795;
                    break;
                case "WLC_UGX":
                case 800:
                    message.valueCurrency = 800;
                    break;
                case "WLC_MKD":
                case 807:
                    message.valueCurrency = 807;
                    break;
                case "WLC_RUR":
                case 810:
                    message.valueCurrency = 810;
                    break;
                case "WLC_EGP":
                case 818:
                    message.valueCurrency = 818;
                    break;
                case "WLC_GBP":
                case 826:
                    message.valueCurrency = 826;
                    break;
                case "WLC_TZS":
                case 834:
                    message.valueCurrency = 834;
                    break;
                case "WLC_USD":
                case 840:
                    message.valueCurrency = 840;
                    break;
                case "WLC_UYU":
                case 858:
                    message.valueCurrency = 858;
                    break;
                case "WLC_UZS":
                case 860:
                    message.valueCurrency = 860;
                    break;
                case "WLC_WST":
                case 882:
                    message.valueCurrency = 882;
                    break;
                case "WLC_YER":
                case 886:
                    message.valueCurrency = 886;
                    break;
                case "WLC_ZMK":
                case 894:
                    message.valueCurrency = 894;
                    break;
                case "WLC_TWD":
                case 901:
                    message.valueCurrency = 901;
                    break;
                case "WLC_CUC":
                case 931:
                    message.valueCurrency = 931;
                    break;
                case "WLC_ZWL":
                case 932:
                    message.valueCurrency = 932;
                    break;
                case "WLC_BYN":
                case 933:
                    message.valueCurrency = 933;
                    break;
                case "WLC_TMT":
                case 934:
                    message.valueCurrency = 934;
                    break;
                case "WLC_GHC":
                case 936:
                    message.valueCurrency = 936;
                    break;
                case "WLC_VEF":
                case 937:
                    message.valueCurrency = 937;
                    break;
                case "WLC_SDG":
                case 938:
                    message.valueCurrency = 938;
                    break;
                case "WLC_RSD":
                case 941:
                    message.valueCurrency = 941;
                    break;
                case "WLC_MZN":
                case 943:
                    message.valueCurrency = 943;
                    break;
                case "WLC_AZN":
                case 944:
                    message.valueCurrency = 944;
                    break;
                case "WLC_RON":
                case 946:
                    message.valueCurrency = 946;
                    break;
                case "WLC_TRY":
                case 949:
                    message.valueCurrency = 949;
                    break;
                case "WLC_XAF":
                case 950:
                    message.valueCurrency = 950;
                    break;
                case "WLC_XCD":
                case 951:
                    message.valueCurrency = 951;
                    break;
                case "WLC_XOF":
                case 952:
                    message.valueCurrency = 952;
                    break;
                case "WLC_XPF":
                case 953:
                    message.valueCurrency = 953;
                    break;
                case "WLC_XAU":
                case 959:
                    message.valueCurrency = 959;
                    break;
                case "WLC_XDR":
                case 960:
                    message.valueCurrency = 960;
                    break;
                case "WLC_XAG":
                case 961:
                    message.valueCurrency = 961;
                    break;
                case "WLC_ZMW":
                case 967:
                    message.valueCurrency = 967;
                    break;
                case "WLC_SRD":
                case 968:
                    message.valueCurrency = 968;
                    break;
                case "WLC_MGA":
                case 969:
                    message.valueCurrency = 969;
                    break;
                case "WLC_AFN":
                case 971:
                    message.valueCurrency = 971;
                    break;
                case "WLC_TJS":
                case 972:
                    message.valueCurrency = 972;
                    break;
                case "WLC_AOA":
                case 973:
                    message.valueCurrency = 973;
                    break;
                case "WLC_BGN":
                case 975:
                    message.valueCurrency = 975;
                    break;
                case "WLC_CDF":
                case 976:
                    message.valueCurrency = 976;
                    break;
                case "WLC_BAM":
                case 977:
                    message.valueCurrency = 977;
                    break;
                case "WLC_EUR":
                case 978:
                    message.valueCurrency = 978;
                    break;
                case "WLC_UAH":
                case 980:
                    message.valueCurrency = 980;
                    break;
                case "WLC_GEL":
                case 981:
                    message.valueCurrency = 981;
                    break;
                case "WLC_PLN":
                case 985:
                    message.valueCurrency = 985;
                    break;
                case "WLC_BRL":
                case 986:
                    message.valueCurrency = 986;
                    break;
                case "WLC_CLF":
                case 990:
                    message.valueCurrency = 990;
                    break;
                case "WLC_ENS":
                case 6004:
                    message.valueCurrency = 6004;
                    break;
                case "WLC_SSC":
                case 6005:
                    message.valueCurrency = 6005;
                    break;
                case "WLC_GOC":
                case 6006:
                    message.valueCurrency = 6006;
                    break;
                case "WLC_GGP":
                case 80001:
                    message.valueCurrency = 80001;
                    break;
                case "WLC_IMP":
                case 80002:
                    message.valueCurrency = 80002;
                    break;
                case "WLC_JEP":
                case 80003:
                    message.valueCurrency = 80003;
                    break;
                case "WLC_LTCT":
                case 90001:
                    message.valueCurrency = 90001;
                    break;
                case "WLC_BTC":
                case 90002:
                    message.valueCurrency = 90002;
                    break;
                case "WLC_ETH":
                case 90003:
                    message.valueCurrency = 90003;
                    break;
                case "WLC_DASH":
                case 90004:
                    message.valueCurrency = 90004;
                    break;
                case "WLC_ZEC":
                case 90005:
                    message.valueCurrency = 90005;
                    break;
                case "WLC_BCH":
                case 90006:
                    message.valueCurrency = 90006;
                    break;
                case "WLC_LTC":
                case 90007:
                    message.valueCurrency = 90007;
                    break;
                case "WLC_XRP":
                case 90008:
                    message.valueCurrency = 90008;
                    break;
                case "WLC_ETN":
                case 90009:
                    message.valueCurrency = 90009;
                    break;
                case "WLC_USDT":
                case 90010:
                    message.valueCurrency = 90010;
                    break;
                case "WLC_USDTERC20":
                case 90011:
                    message.valueCurrency = 90011;
                    break;
                case "WLC_USDTTRC20":
                case 90012:
                    message.valueCurrency = 90012;
                    break;
                case "WLC_TRX":
                case 90013:
                    message.valueCurrency = 90013;
                    break;
                case "WLC_USDCERC20":
                case 90014:
                    message.valueCurrency = 90014;
                    break;
                case "WLC_WBTCERC20":
                case 90015:
                    message.valueCurrency = 90015;
                    break;
                case "WLC_DAIERC20":
                case 90016:
                    message.valueCurrency = 90016;
                    break;
                case "WLC_PEPEERC20":
                case 90017:
                    message.valueCurrency = 90017;
                    break;
                case "WLC_FRAXERC20":
                case 90018:
                    message.valueCurrency = 90018;
                    break;
                case "WLC_WEETHERC20":
                case 90019:
                    message.valueCurrency = 90019;
                    break;
                case "WLC_MKRERC20":
                case 90020:
                    message.valueCurrency = 90020;
                    break;
                case "WLC_USDMERC20":
                case 90021:
                    message.valueCurrency = 90021;
                    break;
                case "WLC_LINKERC20":
                case 90022:
                    message.valueCurrency = 90022;
                    break;
                case "WLC_UNIERC20":
                case 90024:
                    message.valueCurrency = 90024;
                    break;
                case "WLC_DOGERC20":
                case 90025:
                    message.valueCurrency = 90025;
                    break;
                case "WLC_FLCERC20":
                case 90026:
                    message.valueCurrency = 90026;
                    break;
                case "WLC_METHERC20":
                case 90027:
                    message.valueCurrency = 90027;
                    break;
                case "WLC_SHIBERC20":
                case 90028:
                    message.valueCurrency = 90028;
                    break;
                case "WLC_ELONERC20":
                case 90029:
                    message.valueCurrency = 90029;
                    break;
                case "WLC_RBNERC20":
                case 90030:
                    message.valueCurrency = 90030;
                    break;
                case "WLC_WETHERC20":
                case 90032:
                    message.valueCurrency = 90032;
                    break;
                }
                if (object.valueInUSDNow != null) {
                    if (typeof object.valueInUSDNow !== "object")
                        throw TypeError(".protocol.UniValue.valueInUSDNow: object expected");
                    message.valueInUSDNow = $root.protocol.UnBigInt.fromObject(object.valueInUSDNow);
                }
                if (object.valueInUSDBackThen != null) {
                    if (typeof object.valueInUSDBackThen !== "object")
                        throw TypeError(".protocol.UniValue.valueInUSDBackThen: object expected");
                    message.valueInUSDBackThen = $root.protocol.UnBigInt.fromObject(object.valueInUSDBackThen);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UniValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.UniValue
             * @static
             * @param {protocol.UniValue} message UniValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UniValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.value = null;
                    object.valueInUSDNow = null;
                    object.valueInUSDBackThen = null;
                    object.valueCurrency = options.enums === String ? "WLC_FUN" : 0;
                }
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = $root.protocol.UnBigInt.toObject(message.value, options);
                if (message.valueInUSDNow != null && message.hasOwnProperty("valueInUSDNow"))
                    object.valueInUSDNow = $root.protocol.UnBigInt.toObject(message.valueInUSDNow, options);
                if (message.valueInUSDBackThen != null && message.hasOwnProperty("valueInUSDBackThen"))
                    object.valueInUSDBackThen = $root.protocol.UnBigInt.toObject(message.valueInUSDBackThen, options);
                if (message.valueCurrency != null && message.hasOwnProperty("valueCurrency"))
                    object.valueCurrency = options.enums === String ? $root.protocol.WLCurrency[message.valueCurrency] === undefined ? message.valueCurrency : $root.protocol.WLCurrency[message.valueCurrency] : message.valueCurrency;
                return object;
            };
    
            /**
             * Converts this UniValue to JSON.
             * @function toJSON
             * @memberof protocol.UniValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UniValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UniValue
             * @function getTypeUrl
             * @memberof protocol.UniValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UniValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.UniValue";
            };
    
            return UniValue;
        })();
    
        protocol.UnifiedBlock = (function() {
    
            /**
             * Properties of an UnifiedBlock.
             * @memberof protocol
             * @interface IUnifiedBlock
             * @property {protocol.Chain|null} [chain] UnifiedBlock chain
             * @property {protocol.CryptoNetwork|null} [network] UnifiedBlock network
             * @property {Long|null} [blockConfirmations] UnifiedBlock blockConfirmations
             * @property {Long|null} [blockHeight] UnifiedBlock blockHeight
             * @property {Long|null} [blockTime] UnifiedBlock blockTime
             * @property {number|null} [blockSize] UnifiedBlock blockSize
             * @property {number|null} [txCount] UnifiedBlock txCount
             * @property {Array.<protocol.IUnifiedTransaction>|null} [transactions] UnifiedBlock transactions
             * @property {Array.<string>|null} [transactionHashes] UnifiedBlock transactionHashes
             * @property {protocol.IChainSpecificInfoForBlock|null} [blockSpecific] UnifiedBlock blockSpecific
             */
    
            /**
             * Constructs a new UnifiedBlock.
             * @memberof protocol
             * @classdesc Represents an UnifiedBlock.
             * @implements IUnifiedBlock
             * @constructor
             * @param {protocol.IUnifiedBlock=} [properties] Properties to set
             */
            function UnifiedBlock(properties) {
                this.transactions = [];
                this.transactionHashes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UnifiedBlock chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.chain = 0;
    
            /**
             * UnifiedBlock network.
             * @member {protocol.CryptoNetwork} network
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.network = 0;
    
            /**
             * UnifiedBlock blockConfirmations.
             * @member {Long} blockConfirmations
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.blockConfirmations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * UnifiedBlock blockHeight.
             * @member {Long} blockHeight
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.blockHeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * UnifiedBlock blockTime.
             * @member {Long} blockTime
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.blockTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * UnifiedBlock blockSize.
             * @member {number} blockSize
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.blockSize = 0;
    
            /**
             * UnifiedBlock txCount.
             * @member {number} txCount
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.txCount = 0;
    
            /**
             * UnifiedBlock transactions.
             * @member {Array.<protocol.IUnifiedTransaction>} transactions
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.transactions = $util.emptyArray;
    
            /**
             * UnifiedBlock transactionHashes.
             * @member {Array.<string>} transactionHashes
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.transactionHashes = $util.emptyArray;
    
            /**
             * UnifiedBlock blockSpecific.
             * @member {protocol.IChainSpecificInfoForBlock|null|undefined} blockSpecific
             * @memberof protocol.UnifiedBlock
             * @instance
             */
            UnifiedBlock.prototype.blockSpecific = null;
    
            /**
             * Creates a new UnifiedBlock instance using the specified properties.
             * @function create
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {protocol.IUnifiedBlock=} [properties] Properties to set
             * @returns {protocol.UnifiedBlock} UnifiedBlock instance
             */
            UnifiedBlock.create = function create(properties) {
                return new UnifiedBlock(properties);
            };
    
            /**
             * Encodes the specified UnifiedBlock message. Does not implicitly {@link protocol.UnifiedBlock.verify|verify} messages.
             * @function encode
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {protocol.IUnifiedBlock} message UnifiedBlock message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnifiedBlock.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.chain);
                if (message.network != null && Object.hasOwnProperty.call(message, "network"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.network);
                if (message.blockConfirmations != null && Object.hasOwnProperty.call(message, "blockConfirmations"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.blockConfirmations);
                if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.blockHeight);
                if (message.blockTime != null && Object.hasOwnProperty.call(message, "blockTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.blockTime);
                if (message.blockSize != null && Object.hasOwnProperty.call(message, "blockSize"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.blockSize);
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.UnifiedTransaction.encode(message.transactions[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.transactionHashes != null && message.transactionHashes.length)
                    for (var i = 0; i < message.transactionHashes.length; ++i)
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.transactionHashes[i]);
                if (message.blockSpecific != null && Object.hasOwnProperty.call(message, "blockSpecific"))
                    $root.protocol.ChainSpecificInfoForBlock.encode(message.blockSpecific, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.txCount != null && Object.hasOwnProperty.call(message, "txCount"))
                    writer.uint32(/* id 60, wireType 0 =*/480).int32(message.txCount);
                return writer;
            };
    
            /**
             * Encodes the specified UnifiedBlock message, length delimited. Does not implicitly {@link protocol.UnifiedBlock.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {protocol.IUnifiedBlock} message UnifiedBlock message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnifiedBlock.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UnifiedBlock message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.UnifiedBlock} UnifiedBlock
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnifiedBlock.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.UnifiedBlock();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chain = reader.int32();
                            break;
                        }
                    case 2: {
                            message.network = reader.int32();
                            break;
                        }
                    case 3: {
                            message.blockConfirmations = reader.int64();
                            break;
                        }
                    case 4: {
                            message.blockHeight = reader.int64();
                            break;
                        }
                    case 5: {
                            message.blockTime = reader.int64();
                            break;
                        }
                    case 6: {
                            message.blockSize = reader.int32();
                            break;
                        }
                    case 60: {
                            message.txCount = reader.int32();
                            break;
                        }
                    case 8: {
                            if (!(message.transactions && message.transactions.length))
                                message.transactions = [];
                            message.transactions.push($root.protocol.UnifiedTransaction.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            if (!(message.transactionHashes && message.transactionHashes.length))
                                message.transactionHashes = [];
                            message.transactionHashes.push(reader.string());
                            break;
                        }
                    case 12: {
                            message.blockSpecific = $root.protocol.ChainSpecificInfoForBlock.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UnifiedBlock message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.UnifiedBlock} UnifiedBlock
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnifiedBlock.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UnifiedBlock message.
             * @function verify
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnifiedBlock.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.network != null && message.hasOwnProperty("network"))
                    switch (message.network) {
                    default:
                        return "network: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.blockConfirmations != null && message.hasOwnProperty("blockConfirmations"))
                    if (!$util.isInteger(message.blockConfirmations) && !(message.blockConfirmations && $util.isInteger(message.blockConfirmations.low) && $util.isInteger(message.blockConfirmations.high)))
                        return "blockConfirmations: integer|Long expected";
                if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                    if (!$util.isInteger(message.blockHeight) && !(message.blockHeight && $util.isInteger(message.blockHeight.low) && $util.isInteger(message.blockHeight.high)))
                        return "blockHeight: integer|Long expected";
                if (message.blockTime != null && message.hasOwnProperty("blockTime"))
                    if (!$util.isInteger(message.blockTime) && !(message.blockTime && $util.isInteger(message.blockTime.low) && $util.isInteger(message.blockTime.high)))
                        return "blockTime: integer|Long expected";
                if (message.blockSize != null && message.hasOwnProperty("blockSize"))
                    if (!$util.isInteger(message.blockSize))
                        return "blockSize: integer expected";
                if (message.txCount != null && message.hasOwnProperty("txCount"))
                    if (!$util.isInteger(message.txCount))
                        return "txCount: integer expected";
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.UnifiedTransaction.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                if (message.transactionHashes != null && message.hasOwnProperty("transactionHashes")) {
                    if (!Array.isArray(message.transactionHashes))
                        return "transactionHashes: array expected";
                    for (var i = 0; i < message.transactionHashes.length; ++i)
                        if (!$util.isString(message.transactionHashes[i]))
                            return "transactionHashes: string[] expected";
                }
                if (message.blockSpecific != null && message.hasOwnProperty("blockSpecific")) {
                    var error = $root.protocol.ChainSpecificInfoForBlock.verify(message.blockSpecific);
                    if (error)
                        return "blockSpecific." + error;
                }
                return null;
            };
    
            /**
             * Creates an UnifiedBlock message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.UnifiedBlock} UnifiedBlock
             */
            UnifiedBlock.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.UnifiedBlock)
                    return object;
                var message = new $root.protocol.UnifiedBlock();
                switch (object.chain) {
                default:
                    if (typeof object.chain === "number") {
                        message.chain = object.chain;
                        break;
                    }
                    break;
                case "BTC":
                case 0:
                    message.chain = 0;
                    break;
                case "ETH":
                case 1:
                    message.chain = 1;
                    break;
                case "TRX":
                case 2:
                    message.chain = 2;
                    break;
                }
                switch (object.network) {
                default:
                    if (typeof object.network === "number") {
                        message.network = object.network;
                        break;
                    }
                    break;
                case "MAINNET":
                case 0:
                    message.network = 0;
                    break;
                case "TESTNET":
                case 1:
                    message.network = 1;
                    break;
                }
                if (object.blockConfirmations != null)
                    if ($util.Long)
                        (message.blockConfirmations = $util.Long.fromValue(object.blockConfirmations)).unsigned = false;
                    else if (typeof object.blockConfirmations === "string")
                        message.blockConfirmations = parseInt(object.blockConfirmations, 10);
                    else if (typeof object.blockConfirmations === "number")
                        message.blockConfirmations = object.blockConfirmations;
                    else if (typeof object.blockConfirmations === "object")
                        message.blockConfirmations = new $util.LongBits(object.blockConfirmations.low >>> 0, object.blockConfirmations.high >>> 0).toNumber();
                if (object.blockHeight != null)
                    if ($util.Long)
                        (message.blockHeight = $util.Long.fromValue(object.blockHeight)).unsigned = false;
                    else if (typeof object.blockHeight === "string")
                        message.blockHeight = parseInt(object.blockHeight, 10);
                    else if (typeof object.blockHeight === "number")
                        message.blockHeight = object.blockHeight;
                    else if (typeof object.blockHeight === "object")
                        message.blockHeight = new $util.LongBits(object.blockHeight.low >>> 0, object.blockHeight.high >>> 0).toNumber();
                if (object.blockTime != null)
                    if ($util.Long)
                        (message.blockTime = $util.Long.fromValue(object.blockTime)).unsigned = false;
                    else if (typeof object.blockTime === "string")
                        message.blockTime = parseInt(object.blockTime, 10);
                    else if (typeof object.blockTime === "number")
                        message.blockTime = object.blockTime;
                    else if (typeof object.blockTime === "object")
                        message.blockTime = new $util.LongBits(object.blockTime.low >>> 0, object.blockTime.high >>> 0).toNumber();
                if (object.blockSize != null)
                    message.blockSize = object.blockSize | 0;
                if (object.txCount != null)
                    message.txCount = object.txCount | 0;
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.UnifiedBlock.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.UnifiedBlock.transactions: object expected");
                        message.transactions[i] = $root.protocol.UnifiedTransaction.fromObject(object.transactions[i]);
                    }
                }
                if (object.transactionHashes) {
                    if (!Array.isArray(object.transactionHashes))
                        throw TypeError(".protocol.UnifiedBlock.transactionHashes: array expected");
                    message.transactionHashes = [];
                    for (var i = 0; i < object.transactionHashes.length; ++i)
                        message.transactionHashes[i] = String(object.transactionHashes[i]);
                }
                if (object.blockSpecific != null) {
                    if (typeof object.blockSpecific !== "object")
                        throw TypeError(".protocol.UnifiedBlock.blockSpecific: object expected");
                    message.blockSpecific = $root.protocol.ChainSpecificInfoForBlock.fromObject(object.blockSpecific);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UnifiedBlock message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {protocol.UnifiedBlock} message UnifiedBlock
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnifiedBlock.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.transactions = [];
                    object.transactionHashes = [];
                }
                if (options.defaults) {
                    object.chain = options.enums === String ? "BTC" : 0;
                    object.network = options.enums === String ? "MAINNET" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.blockConfirmations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockConfirmations = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.blockHeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockHeight = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.blockTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockTime = options.longs === String ? "0" : 0;
                    object.blockSize = 0;
                    object.blockSpecific = null;
                    object.txCount = 0;
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] === undefined ? message.chain : $root.protocol.Chain[message.chain] : message.chain;
                if (message.network != null && message.hasOwnProperty("network"))
                    object.network = options.enums === String ? $root.protocol.CryptoNetwork[message.network] === undefined ? message.network : $root.protocol.CryptoNetwork[message.network] : message.network;
                if (message.blockConfirmations != null && message.hasOwnProperty("blockConfirmations"))
                    if (typeof message.blockConfirmations === "number")
                        object.blockConfirmations = options.longs === String ? String(message.blockConfirmations) : message.blockConfirmations;
                    else
                        object.blockConfirmations = options.longs === String ? $util.Long.prototype.toString.call(message.blockConfirmations) : options.longs === Number ? new $util.LongBits(message.blockConfirmations.low >>> 0, message.blockConfirmations.high >>> 0).toNumber() : message.blockConfirmations;
                if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                    if (typeof message.blockHeight === "number")
                        object.blockHeight = options.longs === String ? String(message.blockHeight) : message.blockHeight;
                    else
                        object.blockHeight = options.longs === String ? $util.Long.prototype.toString.call(message.blockHeight) : options.longs === Number ? new $util.LongBits(message.blockHeight.low >>> 0, message.blockHeight.high >>> 0).toNumber() : message.blockHeight;
                if (message.blockTime != null && message.hasOwnProperty("blockTime"))
                    if (typeof message.blockTime === "number")
                        object.blockTime = options.longs === String ? String(message.blockTime) : message.blockTime;
                    else
                        object.blockTime = options.longs === String ? $util.Long.prototype.toString.call(message.blockTime) : options.longs === Number ? new $util.LongBits(message.blockTime.low >>> 0, message.blockTime.high >>> 0).toNumber() : message.blockTime;
                if (message.blockSize != null && message.hasOwnProperty("blockSize"))
                    object.blockSize = message.blockSize;
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.UnifiedTransaction.toObject(message.transactions[j], options);
                }
                if (message.transactionHashes && message.transactionHashes.length) {
                    object.transactionHashes = [];
                    for (var j = 0; j < message.transactionHashes.length; ++j)
                        object.transactionHashes[j] = message.transactionHashes[j];
                }
                if (message.blockSpecific != null && message.hasOwnProperty("blockSpecific"))
                    object.blockSpecific = $root.protocol.ChainSpecificInfoForBlock.toObject(message.blockSpecific, options);
                if (message.txCount != null && message.hasOwnProperty("txCount"))
                    object.txCount = message.txCount;
                return object;
            };
    
            /**
             * Converts this UnifiedBlock to JSON.
             * @function toJSON
             * @memberof protocol.UnifiedBlock
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnifiedBlock.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UnifiedBlock
             * @function getTypeUrl
             * @memberof protocol.UnifiedBlock
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UnifiedBlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.UnifiedBlock";
            };
    
            return UnifiedBlock;
        })();
    
        protocol.ChainSpecificInfoForBlock = (function() {
    
            /**
             * Properties of a ChainSpecificInfoForBlock.
             * @memberof protocol
             * @interface IChainSpecificInfoForBlock
             * @property {protocol.IBtcSpecificBlockInfo|null} [btcSpecific] ChainSpecificInfoForBlock btcSpecific
             * @property {protocol.IEthSpecificBlockInfo|null} [ethSpecific] ChainSpecificInfoForBlock ethSpecific
             * @property {protocol.ITronSpecificBlockInfo|null} [tronSpecific] ChainSpecificInfoForBlock tronSpecific
             */
    
            /**
             * Constructs a new ChainSpecificInfoForBlock.
             * @memberof protocol
             * @classdesc Represents a ChainSpecificInfoForBlock.
             * @implements IChainSpecificInfoForBlock
             * @constructor
             * @param {protocol.IChainSpecificInfoForBlock=} [properties] Properties to set
             */
            function ChainSpecificInfoForBlock(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ChainSpecificInfoForBlock btcSpecific.
             * @member {protocol.IBtcSpecificBlockInfo|null|undefined} btcSpecific
             * @memberof protocol.ChainSpecificInfoForBlock
             * @instance
             */
            ChainSpecificInfoForBlock.prototype.btcSpecific = null;
    
            /**
             * ChainSpecificInfoForBlock ethSpecific.
             * @member {protocol.IEthSpecificBlockInfo|null|undefined} ethSpecific
             * @memberof protocol.ChainSpecificInfoForBlock
             * @instance
             */
            ChainSpecificInfoForBlock.prototype.ethSpecific = null;
    
            /**
             * ChainSpecificInfoForBlock tronSpecific.
             * @member {protocol.ITronSpecificBlockInfo|null|undefined} tronSpecific
             * @memberof protocol.ChainSpecificInfoForBlock
             * @instance
             */
            ChainSpecificInfoForBlock.prototype.tronSpecific = null;
    
            /**
             * Creates a new ChainSpecificInfoForBlock instance using the specified properties.
             * @function create
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {protocol.IChainSpecificInfoForBlock=} [properties] Properties to set
             * @returns {protocol.ChainSpecificInfoForBlock} ChainSpecificInfoForBlock instance
             */
            ChainSpecificInfoForBlock.create = function create(properties) {
                return new ChainSpecificInfoForBlock(properties);
            };
    
            /**
             * Encodes the specified ChainSpecificInfoForBlock message. Does not implicitly {@link protocol.ChainSpecificInfoForBlock.verify|verify} messages.
             * @function encode
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {protocol.IChainSpecificInfoForBlock} message ChainSpecificInfoForBlock message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChainSpecificInfoForBlock.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.btcSpecific != null && Object.hasOwnProperty.call(message, "btcSpecific"))
                    $root.protocol.BtcSpecificBlockInfo.encode(message.btcSpecific, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.ethSpecific != null && Object.hasOwnProperty.call(message, "ethSpecific"))
                    $root.protocol.EthSpecificBlockInfo.encode(message.ethSpecific, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.tronSpecific != null && Object.hasOwnProperty.call(message, "tronSpecific"))
                    $root.protocol.TronSpecificBlockInfo.encode(message.tronSpecific, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ChainSpecificInfoForBlock message, length delimited. Does not implicitly {@link protocol.ChainSpecificInfoForBlock.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {protocol.IChainSpecificInfoForBlock} message ChainSpecificInfoForBlock message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChainSpecificInfoForBlock.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ChainSpecificInfoForBlock message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ChainSpecificInfoForBlock} ChainSpecificInfoForBlock
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChainSpecificInfoForBlock.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ChainSpecificInfoForBlock();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.btcSpecific = $root.protocol.BtcSpecificBlockInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.ethSpecific = $root.protocol.EthSpecificBlockInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.tronSpecific = $root.protocol.TronSpecificBlockInfo.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ChainSpecificInfoForBlock message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ChainSpecificInfoForBlock} ChainSpecificInfoForBlock
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChainSpecificInfoForBlock.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ChainSpecificInfoForBlock message.
             * @function verify
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChainSpecificInfoForBlock.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.btcSpecific != null && message.hasOwnProperty("btcSpecific")) {
                    var error = $root.protocol.BtcSpecificBlockInfo.verify(message.btcSpecific);
                    if (error)
                        return "btcSpecific." + error;
                }
                if (message.ethSpecific != null && message.hasOwnProperty("ethSpecific")) {
                    var error = $root.protocol.EthSpecificBlockInfo.verify(message.ethSpecific);
                    if (error)
                        return "ethSpecific." + error;
                }
                if (message.tronSpecific != null && message.hasOwnProperty("tronSpecific")) {
                    var error = $root.protocol.TronSpecificBlockInfo.verify(message.tronSpecific);
                    if (error)
                        return "tronSpecific." + error;
                }
                return null;
            };
    
            /**
             * Creates a ChainSpecificInfoForBlock message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ChainSpecificInfoForBlock} ChainSpecificInfoForBlock
             */
            ChainSpecificInfoForBlock.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ChainSpecificInfoForBlock)
                    return object;
                var message = new $root.protocol.ChainSpecificInfoForBlock();
                if (object.btcSpecific != null) {
                    if (typeof object.btcSpecific !== "object")
                        throw TypeError(".protocol.ChainSpecificInfoForBlock.btcSpecific: object expected");
                    message.btcSpecific = $root.protocol.BtcSpecificBlockInfo.fromObject(object.btcSpecific);
                }
                if (object.ethSpecific != null) {
                    if (typeof object.ethSpecific !== "object")
                        throw TypeError(".protocol.ChainSpecificInfoForBlock.ethSpecific: object expected");
                    message.ethSpecific = $root.protocol.EthSpecificBlockInfo.fromObject(object.ethSpecific);
                }
                if (object.tronSpecific != null) {
                    if (typeof object.tronSpecific !== "object")
                        throw TypeError(".protocol.ChainSpecificInfoForBlock.tronSpecific: object expected");
                    message.tronSpecific = $root.protocol.TronSpecificBlockInfo.fromObject(object.tronSpecific);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ChainSpecificInfoForBlock message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {protocol.ChainSpecificInfoForBlock} message ChainSpecificInfoForBlock
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChainSpecificInfoForBlock.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.btcSpecific = null;
                    object.ethSpecific = null;
                    object.tronSpecific = null;
                }
                if (message.btcSpecific != null && message.hasOwnProperty("btcSpecific"))
                    object.btcSpecific = $root.protocol.BtcSpecificBlockInfo.toObject(message.btcSpecific, options);
                if (message.ethSpecific != null && message.hasOwnProperty("ethSpecific"))
                    object.ethSpecific = $root.protocol.EthSpecificBlockInfo.toObject(message.ethSpecific, options);
                if (message.tronSpecific != null && message.hasOwnProperty("tronSpecific"))
                    object.tronSpecific = $root.protocol.TronSpecificBlockInfo.toObject(message.tronSpecific, options);
                return object;
            };
    
            /**
             * Converts this ChainSpecificInfoForBlock to JSON.
             * @function toJSON
             * @memberof protocol.ChainSpecificInfoForBlock
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChainSpecificInfoForBlock.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ChainSpecificInfoForBlock
             * @function getTypeUrl
             * @memberof protocol.ChainSpecificInfoForBlock
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChainSpecificInfoForBlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.ChainSpecificInfoForBlock";
            };
    
            return ChainSpecificInfoForBlock;
        })();
    
        protocol.TronSpecificBlockInfo = (function() {
    
            /**
             * Properties of a TronSpecificBlockInfo.
             * @memberof protocol
             * @interface ITronSpecificBlockInfo
             * @property {string|null} [blockHash] TronSpecificBlockInfo blockHash
             * @property {string|null} [parentBlockHash] TronSpecificBlockInfo parentBlockHash
             * @property {number|null} [versionNumber] TronSpecificBlockInfo versionNumber
             * @property {protocol.ITronBlockTransactionOverview|null} [transactionOverview] TronSpecificBlockInfo transactionOverview
             * @property {protocol.ITronBlockTotalConsumption|null} [totalConsumption] TronSpecificBlockInfo totalConsumption
             * @property {Array.<protocol.ITronSuperRepresentative>|null} [confirmedSRs] TronSpecificBlockInfo confirmedSRs
             * @property {protocol.IUnBigInt|null} [blockRewards] TronSpecificBlockInfo blockRewards
             */
    
            /**
             * Constructs a new TronSpecificBlockInfo.
             * @memberof protocol
             * @classdesc Represents a TronSpecificBlockInfo.
             * @implements ITronSpecificBlockInfo
             * @constructor
             * @param {protocol.ITronSpecificBlockInfo=} [properties] Properties to set
             */
            function TronSpecificBlockInfo(properties) {
                this.confirmedSRs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronSpecificBlockInfo blockHash.
             * @member {string} blockHash
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.blockHash = "";
    
            /**
             * TronSpecificBlockInfo parentBlockHash.
             * @member {string} parentBlockHash
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.parentBlockHash = "";
    
            /**
             * TronSpecificBlockInfo versionNumber.
             * @member {number} versionNumber
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.versionNumber = 0;
    
            /**
             * TronSpecificBlockInfo transactionOverview.
             * @member {protocol.ITronBlockTransactionOverview|null|undefined} transactionOverview
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.transactionOverview = null;
    
            /**
             * TronSpecificBlockInfo totalConsumption.
             * @member {protocol.ITronBlockTotalConsumption|null|undefined} totalConsumption
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.totalConsumption = null;
    
            /**
             * TronSpecificBlockInfo confirmedSRs.
             * @member {Array.<protocol.ITronSuperRepresentative>} confirmedSRs
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.confirmedSRs = $util.emptyArray;
    
            /**
             * TronSpecificBlockInfo blockRewards.
             * @member {protocol.IUnBigInt|null|undefined} blockRewards
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             */
            TronSpecificBlockInfo.prototype.blockRewards = null;
    
            /**
             * Creates a new TronSpecificBlockInfo instance using the specified properties.
             * @function create
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {protocol.ITronSpecificBlockInfo=} [properties] Properties to set
             * @returns {protocol.TronSpecificBlockInfo} TronSpecificBlockInfo instance
             */
            TronSpecificBlockInfo.create = function create(properties) {
                return new TronSpecificBlockInfo(properties);
            };
    
            /**
             * Encodes the specified TronSpecificBlockInfo message. Does not implicitly {@link protocol.TronSpecificBlockInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {protocol.ITronSpecificBlockInfo} message TronSpecificBlockInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronSpecificBlockInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.blockHash != null && Object.hasOwnProperty.call(message, "blockHash"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.blockHash);
                if (message.confirmedSRs != null && message.confirmedSRs.length)
                    for (var i = 0; i < message.confirmedSRs.length; ++i)
                        $root.protocol.TronSuperRepresentative.encode(message.confirmedSRs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.blockRewards != null && Object.hasOwnProperty.call(message, "blockRewards"))
                    $root.protocol.UnBigInt.encode(message.blockRewards, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.parentBlockHash != null && Object.hasOwnProperty.call(message, "parentBlockHash"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.parentBlockHash);
                if (message.versionNumber != null && Object.hasOwnProperty.call(message, "versionNumber"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.versionNumber);
                if (message.transactionOverview != null && Object.hasOwnProperty.call(message, "transactionOverview"))
                    $root.protocol.TronBlockTransactionOverview.encode(message.transactionOverview, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.totalConsumption != null && Object.hasOwnProperty.call(message, "totalConsumption"))
                    $root.protocol.TronBlockTotalConsumption.encode(message.totalConsumption, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronSpecificBlockInfo message, length delimited. Does not implicitly {@link protocol.TronSpecificBlockInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {protocol.ITronSpecificBlockInfo} message TronSpecificBlockInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronSpecificBlockInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronSpecificBlockInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronSpecificBlockInfo} TronSpecificBlockInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronSpecificBlockInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronSpecificBlockInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.blockHash = reader.string();
                            break;
                        }
                    case 6: {
                            message.parentBlockHash = reader.string();
                            break;
                        }
                    case 7: {
                            message.versionNumber = reader.int32();
                            break;
                        }
                    case 8: {
                            message.transactionOverview = $root.protocol.TronBlockTransactionOverview.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.totalConsumption = $root.protocol.TronBlockTotalConsumption.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            if (!(message.confirmedSRs && message.confirmedSRs.length))
                                message.confirmedSRs = [];
                            message.confirmedSRs.push($root.protocol.TronSuperRepresentative.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.blockRewards = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronSpecificBlockInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronSpecificBlockInfo} TronSpecificBlockInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronSpecificBlockInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronSpecificBlockInfo message.
             * @function verify
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronSpecificBlockInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                    if (!$util.isString(message.blockHash))
                        return "blockHash: string expected";
                if (message.parentBlockHash != null && message.hasOwnProperty("parentBlockHash"))
                    if (!$util.isString(message.parentBlockHash))
                        return "parentBlockHash: string expected";
                if (message.versionNumber != null && message.hasOwnProperty("versionNumber"))
                    if (!$util.isInteger(message.versionNumber))
                        return "versionNumber: integer expected";
                if (message.transactionOverview != null && message.hasOwnProperty("transactionOverview")) {
                    var error = $root.protocol.TronBlockTransactionOverview.verify(message.transactionOverview);
                    if (error)
                        return "transactionOverview." + error;
                }
                if (message.totalConsumption != null && message.hasOwnProperty("totalConsumption")) {
                    var error = $root.protocol.TronBlockTotalConsumption.verify(message.totalConsumption);
                    if (error)
                        return "totalConsumption." + error;
                }
                if (message.confirmedSRs != null && message.hasOwnProperty("confirmedSRs")) {
                    if (!Array.isArray(message.confirmedSRs))
                        return "confirmedSRs: array expected";
                    for (var i = 0; i < message.confirmedSRs.length; ++i) {
                        var error = $root.protocol.TronSuperRepresentative.verify(message.confirmedSRs[i]);
                        if (error)
                            return "confirmedSRs." + error;
                    }
                }
                if (message.blockRewards != null && message.hasOwnProperty("blockRewards")) {
                    var error = $root.protocol.UnBigInt.verify(message.blockRewards);
                    if (error)
                        return "blockRewards." + error;
                }
                return null;
            };
    
            /**
             * Creates a TronSpecificBlockInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronSpecificBlockInfo} TronSpecificBlockInfo
             */
            TronSpecificBlockInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronSpecificBlockInfo)
                    return object;
                var message = new $root.protocol.TronSpecificBlockInfo();
                if (object.blockHash != null)
                    message.blockHash = String(object.blockHash);
                if (object.parentBlockHash != null)
                    message.parentBlockHash = String(object.parentBlockHash);
                if (object.versionNumber != null)
                    message.versionNumber = object.versionNumber | 0;
                if (object.transactionOverview != null) {
                    if (typeof object.transactionOverview !== "object")
                        throw TypeError(".protocol.TronSpecificBlockInfo.transactionOverview: object expected");
                    message.transactionOverview = $root.protocol.TronBlockTransactionOverview.fromObject(object.transactionOverview);
                }
                if (object.totalConsumption != null) {
                    if (typeof object.totalConsumption !== "object")
                        throw TypeError(".protocol.TronSpecificBlockInfo.totalConsumption: object expected");
                    message.totalConsumption = $root.protocol.TronBlockTotalConsumption.fromObject(object.totalConsumption);
                }
                if (object.confirmedSRs) {
                    if (!Array.isArray(object.confirmedSRs))
                        throw TypeError(".protocol.TronSpecificBlockInfo.confirmedSRs: array expected");
                    message.confirmedSRs = [];
                    for (var i = 0; i < object.confirmedSRs.length; ++i) {
                        if (typeof object.confirmedSRs[i] !== "object")
                            throw TypeError(".protocol.TronSpecificBlockInfo.confirmedSRs: object expected");
                        message.confirmedSRs[i] = $root.protocol.TronSuperRepresentative.fromObject(object.confirmedSRs[i]);
                    }
                }
                if (object.blockRewards != null) {
                    if (typeof object.blockRewards !== "object")
                        throw TypeError(".protocol.TronSpecificBlockInfo.blockRewards: object expected");
                    message.blockRewards = $root.protocol.UnBigInt.fromObject(object.blockRewards);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronSpecificBlockInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {protocol.TronSpecificBlockInfo} message TronSpecificBlockInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronSpecificBlockInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.confirmedSRs = [];
                if (options.defaults) {
                    object.blockHash = "";
                    object.blockRewards = null;
                    object.parentBlockHash = "";
                    object.versionNumber = 0;
                    object.transactionOverview = null;
                    object.totalConsumption = null;
                }
                if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                    object.blockHash = message.blockHash;
                if (message.confirmedSRs && message.confirmedSRs.length) {
                    object.confirmedSRs = [];
                    for (var j = 0; j < message.confirmedSRs.length; ++j)
                        object.confirmedSRs[j] = $root.protocol.TronSuperRepresentative.toObject(message.confirmedSRs[j], options);
                }
                if (message.blockRewards != null && message.hasOwnProperty("blockRewards"))
                    object.blockRewards = $root.protocol.UnBigInt.toObject(message.blockRewards, options);
                if (message.parentBlockHash != null && message.hasOwnProperty("parentBlockHash"))
                    object.parentBlockHash = message.parentBlockHash;
                if (message.versionNumber != null && message.hasOwnProperty("versionNumber"))
                    object.versionNumber = message.versionNumber;
                if (message.transactionOverview != null && message.hasOwnProperty("transactionOverview"))
                    object.transactionOverview = $root.protocol.TronBlockTransactionOverview.toObject(message.transactionOverview, options);
                if (message.totalConsumption != null && message.hasOwnProperty("totalConsumption"))
                    object.totalConsumption = $root.protocol.TronBlockTotalConsumption.toObject(message.totalConsumption, options);
                return object;
            };
    
            /**
             * Converts this TronSpecificBlockInfo to JSON.
             * @function toJSON
             * @memberof protocol.TronSpecificBlockInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronSpecificBlockInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronSpecificBlockInfo
             * @function getTypeUrl
             * @memberof protocol.TronSpecificBlockInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronSpecificBlockInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronSpecificBlockInfo";
            };
    
            return TronSpecificBlockInfo;
        })();
    
        protocol.TronBlockTransactionOverview = (function() {
    
            /**
             * Properties of a TronBlockTransactionOverview.
             * @memberof protocol
             * @interface ITronBlockTransactionOverview
             * @property {number|null} [transactionCount] TronBlockTransactionOverview transactionCount
             * @property {number|null} [transfersCount] TronBlockTransactionOverview transfersCount
             * @property {number|null} [internalTransactionsCount] TronBlockTransactionOverview internalTransactionsCount
             */
    
            /**
             * Constructs a new TronBlockTransactionOverview.
             * @memberof protocol
             * @classdesc Represents a TronBlockTransactionOverview.
             * @implements ITronBlockTransactionOverview
             * @constructor
             * @param {protocol.ITronBlockTransactionOverview=} [properties] Properties to set
             */
            function TronBlockTransactionOverview(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronBlockTransactionOverview transactionCount.
             * @member {number} transactionCount
             * @memberof protocol.TronBlockTransactionOverview
             * @instance
             */
            TronBlockTransactionOverview.prototype.transactionCount = 0;
    
            /**
             * TronBlockTransactionOverview transfersCount.
             * @member {number} transfersCount
             * @memberof protocol.TronBlockTransactionOverview
             * @instance
             */
            TronBlockTransactionOverview.prototype.transfersCount = 0;
    
            /**
             * TronBlockTransactionOverview internalTransactionsCount.
             * @member {number} internalTransactionsCount
             * @memberof protocol.TronBlockTransactionOverview
             * @instance
             */
            TronBlockTransactionOverview.prototype.internalTransactionsCount = 0;
    
            /**
             * Creates a new TronBlockTransactionOverview instance using the specified properties.
             * @function create
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {protocol.ITronBlockTransactionOverview=} [properties] Properties to set
             * @returns {protocol.TronBlockTransactionOverview} TronBlockTransactionOverview instance
             */
            TronBlockTransactionOverview.create = function create(properties) {
                return new TronBlockTransactionOverview(properties);
            };
    
            /**
             * Encodes the specified TronBlockTransactionOverview message. Does not implicitly {@link protocol.TronBlockTransactionOverview.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {protocol.ITronBlockTransactionOverview} message TronBlockTransactionOverview message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlockTransactionOverview.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transactionCount != null && Object.hasOwnProperty.call(message, "transactionCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.transactionCount);
                if (message.transfersCount != null && Object.hasOwnProperty.call(message, "transfersCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.transfersCount);
                if (message.internalTransactionsCount != null && Object.hasOwnProperty.call(message, "internalTransactionsCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.internalTransactionsCount);
                return writer;
            };
    
            /**
             * Encodes the specified TronBlockTransactionOverview message, length delimited. Does not implicitly {@link protocol.TronBlockTransactionOverview.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {protocol.ITronBlockTransactionOverview} message TronBlockTransactionOverview message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlockTransactionOverview.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronBlockTransactionOverview message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronBlockTransactionOverview} TronBlockTransactionOverview
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlockTransactionOverview.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronBlockTransactionOverview();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.transactionCount = reader.int32();
                            break;
                        }
                    case 2: {
                            message.transfersCount = reader.int32();
                            break;
                        }
                    case 3: {
                            message.internalTransactionsCount = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronBlockTransactionOverview message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronBlockTransactionOverview} TronBlockTransactionOverview
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlockTransactionOverview.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronBlockTransactionOverview message.
             * @function verify
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronBlockTransactionOverview.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transactionCount != null && message.hasOwnProperty("transactionCount"))
                    if (!$util.isInteger(message.transactionCount))
                        return "transactionCount: integer expected";
                if (message.transfersCount != null && message.hasOwnProperty("transfersCount"))
                    if (!$util.isInteger(message.transfersCount))
                        return "transfersCount: integer expected";
                if (message.internalTransactionsCount != null && message.hasOwnProperty("internalTransactionsCount"))
                    if (!$util.isInteger(message.internalTransactionsCount))
                        return "internalTransactionsCount: integer expected";
                return null;
            };
    
            /**
             * Creates a TronBlockTransactionOverview message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronBlockTransactionOverview} TronBlockTransactionOverview
             */
            TronBlockTransactionOverview.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronBlockTransactionOverview)
                    return object;
                var message = new $root.protocol.TronBlockTransactionOverview();
                if (object.transactionCount != null)
                    message.transactionCount = object.transactionCount | 0;
                if (object.transfersCount != null)
                    message.transfersCount = object.transfersCount | 0;
                if (object.internalTransactionsCount != null)
                    message.internalTransactionsCount = object.internalTransactionsCount | 0;
                return message;
            };
    
            /**
             * Creates a plain object from a TronBlockTransactionOverview message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {protocol.TronBlockTransactionOverview} message TronBlockTransactionOverview
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronBlockTransactionOverview.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.transactionCount = 0;
                    object.transfersCount = 0;
                    object.internalTransactionsCount = 0;
                }
                if (message.transactionCount != null && message.hasOwnProperty("transactionCount"))
                    object.transactionCount = message.transactionCount;
                if (message.transfersCount != null && message.hasOwnProperty("transfersCount"))
                    object.transfersCount = message.transfersCount;
                if (message.internalTransactionsCount != null && message.hasOwnProperty("internalTransactionsCount"))
                    object.internalTransactionsCount = message.internalTransactionsCount;
                return object;
            };
    
            /**
             * Converts this TronBlockTransactionOverview to JSON.
             * @function toJSON
             * @memberof protocol.TronBlockTransactionOverview
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronBlockTransactionOverview.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronBlockTransactionOverview
             * @function getTypeUrl
             * @memberof protocol.TronBlockTransactionOverview
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronBlockTransactionOverview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronBlockTransactionOverview";
            };
    
            return TronBlockTransactionOverview;
        })();
    
        protocol.TronBlockTotalConsumption = (function() {
    
            /**
             * Properties of a TronBlockTotalConsumption.
             * @memberof protocol
             * @interface ITronBlockTotalConsumption
             * @property {protocol.IUniValue|null} [trxBurned] TronBlockTotalConsumption trxBurned
             * @property {Long|null} [totalBandwidth] TronBlockTotalConsumption totalBandwidth
             * @property {Long|null} [totalEnergy] TronBlockTotalConsumption totalEnergy
             */
    
            /**
             * Constructs a new TronBlockTotalConsumption.
             * @memberof protocol
             * @classdesc Represents a TronBlockTotalConsumption.
             * @implements ITronBlockTotalConsumption
             * @constructor
             * @param {protocol.ITronBlockTotalConsumption=} [properties] Properties to set
             */
            function TronBlockTotalConsumption(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronBlockTotalConsumption trxBurned.
             * @member {protocol.IUniValue|null|undefined} trxBurned
             * @memberof protocol.TronBlockTotalConsumption
             * @instance
             */
            TronBlockTotalConsumption.prototype.trxBurned = null;
    
            /**
             * TronBlockTotalConsumption totalBandwidth.
             * @member {Long} totalBandwidth
             * @memberof protocol.TronBlockTotalConsumption
             * @instance
             */
            TronBlockTotalConsumption.prototype.totalBandwidth = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronBlockTotalConsumption totalEnergy.
             * @member {Long} totalEnergy
             * @memberof protocol.TronBlockTotalConsumption
             * @instance
             */
            TronBlockTotalConsumption.prototype.totalEnergy = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TronBlockTotalConsumption instance using the specified properties.
             * @function create
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {protocol.ITronBlockTotalConsumption=} [properties] Properties to set
             * @returns {protocol.TronBlockTotalConsumption} TronBlockTotalConsumption instance
             */
            TronBlockTotalConsumption.create = function create(properties) {
                return new TronBlockTotalConsumption(properties);
            };
    
            /**
             * Encodes the specified TronBlockTotalConsumption message. Does not implicitly {@link protocol.TronBlockTotalConsumption.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {protocol.ITronBlockTotalConsumption} message TronBlockTotalConsumption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlockTotalConsumption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.trxBurned != null && Object.hasOwnProperty.call(message, "trxBurned"))
                    $root.protocol.UniValue.encode(message.trxBurned, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalBandwidth != null && Object.hasOwnProperty.call(message, "totalBandwidth"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalBandwidth);
                if (message.totalEnergy != null && Object.hasOwnProperty.call(message, "totalEnergy"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalEnergy);
                return writer;
            };
    
            /**
             * Encodes the specified TronBlockTotalConsumption message, length delimited. Does not implicitly {@link protocol.TronBlockTotalConsumption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {protocol.ITronBlockTotalConsumption} message TronBlockTotalConsumption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlockTotalConsumption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronBlockTotalConsumption message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronBlockTotalConsumption} TronBlockTotalConsumption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlockTotalConsumption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronBlockTotalConsumption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.trxBurned = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.totalBandwidth = reader.int64();
                            break;
                        }
                    case 4: {
                            message.totalEnergy = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronBlockTotalConsumption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronBlockTotalConsumption} TronBlockTotalConsumption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlockTotalConsumption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronBlockTotalConsumption message.
             * @function verify
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronBlockTotalConsumption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.trxBurned != null && message.hasOwnProperty("trxBurned")) {
                    var error = $root.protocol.UniValue.verify(message.trxBurned);
                    if (error)
                        return "trxBurned." + error;
                }
                if (message.totalBandwidth != null && message.hasOwnProperty("totalBandwidth"))
                    if (!$util.isInteger(message.totalBandwidth) && !(message.totalBandwidth && $util.isInteger(message.totalBandwidth.low) && $util.isInteger(message.totalBandwidth.high)))
                        return "totalBandwidth: integer|Long expected";
                if (message.totalEnergy != null && message.hasOwnProperty("totalEnergy"))
                    if (!$util.isInteger(message.totalEnergy) && !(message.totalEnergy && $util.isInteger(message.totalEnergy.low) && $util.isInteger(message.totalEnergy.high)))
                        return "totalEnergy: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TronBlockTotalConsumption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronBlockTotalConsumption} TronBlockTotalConsumption
             */
            TronBlockTotalConsumption.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronBlockTotalConsumption)
                    return object;
                var message = new $root.protocol.TronBlockTotalConsumption();
                if (object.trxBurned != null) {
                    if (typeof object.trxBurned !== "object")
                        throw TypeError(".protocol.TronBlockTotalConsumption.trxBurned: object expected");
                    message.trxBurned = $root.protocol.UniValue.fromObject(object.trxBurned);
                }
                if (object.totalBandwidth != null)
                    if ($util.Long)
                        (message.totalBandwidth = $util.Long.fromValue(object.totalBandwidth)).unsigned = false;
                    else if (typeof object.totalBandwidth === "string")
                        message.totalBandwidth = parseInt(object.totalBandwidth, 10);
                    else if (typeof object.totalBandwidth === "number")
                        message.totalBandwidth = object.totalBandwidth;
                    else if (typeof object.totalBandwidth === "object")
                        message.totalBandwidth = new $util.LongBits(object.totalBandwidth.low >>> 0, object.totalBandwidth.high >>> 0).toNumber();
                if (object.totalEnergy != null)
                    if ($util.Long)
                        (message.totalEnergy = $util.Long.fromValue(object.totalEnergy)).unsigned = false;
                    else if (typeof object.totalEnergy === "string")
                        message.totalEnergy = parseInt(object.totalEnergy, 10);
                    else if (typeof object.totalEnergy === "number")
                        message.totalEnergy = object.totalEnergy;
                    else if (typeof object.totalEnergy === "object")
                        message.totalEnergy = new $util.LongBits(object.totalEnergy.low >>> 0, object.totalEnergy.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TronBlockTotalConsumption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {protocol.TronBlockTotalConsumption} message TronBlockTotalConsumption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronBlockTotalConsumption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.trxBurned = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalBandwidth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalBandwidth = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.totalEnergy = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.totalEnergy = options.longs === String ? "0" : 0;
                }
                if (message.trxBurned != null && message.hasOwnProperty("trxBurned"))
                    object.trxBurned = $root.protocol.UniValue.toObject(message.trxBurned, options);
                if (message.totalBandwidth != null && message.hasOwnProperty("totalBandwidth"))
                    if (typeof message.totalBandwidth === "number")
                        object.totalBandwidth = options.longs === String ? String(message.totalBandwidth) : message.totalBandwidth;
                    else
                        object.totalBandwidth = options.longs === String ? $util.Long.prototype.toString.call(message.totalBandwidth) : options.longs === Number ? new $util.LongBits(message.totalBandwidth.low >>> 0, message.totalBandwidth.high >>> 0).toNumber() : message.totalBandwidth;
                if (message.totalEnergy != null && message.hasOwnProperty("totalEnergy"))
                    if (typeof message.totalEnergy === "number")
                        object.totalEnergy = options.longs === String ? String(message.totalEnergy) : message.totalEnergy;
                    else
                        object.totalEnergy = options.longs === String ? $util.Long.prototype.toString.call(message.totalEnergy) : options.longs === Number ? new $util.LongBits(message.totalEnergy.low >>> 0, message.totalEnergy.high >>> 0).toNumber() : message.totalEnergy;
                return object;
            };
    
            /**
             * Converts this TronBlockTotalConsumption to JSON.
             * @function toJSON
             * @memberof protocol.TronBlockTotalConsumption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronBlockTotalConsumption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronBlockTotalConsumption
             * @function getTypeUrl
             * @memberof protocol.TronBlockTotalConsumption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronBlockTotalConsumption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronBlockTotalConsumption";
            };
    
            return TronBlockTotalConsumption;
        })();
    
        protocol.TronSuperRepresentative = (function() {
    
            /**
             * Properties of a TronSuperRepresentative.
             * @memberof protocol
             * @interface ITronSuperRepresentative
             * @property {string|null} [name] TronSuperRepresentative name
             * @property {string|null} [address] TronSuperRepresentative address
             */
    
            /**
             * Constructs a new TronSuperRepresentative.
             * @memberof protocol
             * @classdesc Represents a TronSuperRepresentative.
             * @implements ITronSuperRepresentative
             * @constructor
             * @param {protocol.ITronSuperRepresentative=} [properties] Properties to set
             */
            function TronSuperRepresentative(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronSuperRepresentative name.
             * @member {string} name
             * @memberof protocol.TronSuperRepresentative
             * @instance
             */
            TronSuperRepresentative.prototype.name = "";
    
            /**
             * TronSuperRepresentative address.
             * @member {string} address
             * @memberof protocol.TronSuperRepresentative
             * @instance
             */
            TronSuperRepresentative.prototype.address = "";
    
            /**
             * Creates a new TronSuperRepresentative instance using the specified properties.
             * @function create
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {protocol.ITronSuperRepresentative=} [properties] Properties to set
             * @returns {protocol.TronSuperRepresentative} TronSuperRepresentative instance
             */
            TronSuperRepresentative.create = function create(properties) {
                return new TronSuperRepresentative(properties);
            };
    
            /**
             * Encodes the specified TronSuperRepresentative message. Does not implicitly {@link protocol.TronSuperRepresentative.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {protocol.ITronSuperRepresentative} message TronSuperRepresentative message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronSuperRepresentative.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                return writer;
            };
    
            /**
             * Encodes the specified TronSuperRepresentative message, length delimited. Does not implicitly {@link protocol.TronSuperRepresentative.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {protocol.ITronSuperRepresentative} message TronSuperRepresentative message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronSuperRepresentative.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronSuperRepresentative message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronSuperRepresentative} TronSuperRepresentative
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronSuperRepresentative.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronSuperRepresentative();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronSuperRepresentative message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronSuperRepresentative} TronSuperRepresentative
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronSuperRepresentative.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronSuperRepresentative message.
             * @function verify
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronSuperRepresentative.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };
    
            /**
             * Creates a TronSuperRepresentative message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronSuperRepresentative} TronSuperRepresentative
             */
            TronSuperRepresentative.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronSuperRepresentative)
                    return object;
                var message = new $root.protocol.TronSuperRepresentative();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };
    
            /**
             * Creates a plain object from a TronSuperRepresentative message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {protocol.TronSuperRepresentative} message TronSuperRepresentative
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronSuperRepresentative.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.address = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };
    
            /**
             * Converts this TronSuperRepresentative to JSON.
             * @function toJSON
             * @memberof protocol.TronSuperRepresentative
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronSuperRepresentative.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronSuperRepresentative
             * @function getTypeUrl
             * @memberof protocol.TronSuperRepresentative
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronSuperRepresentative.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronSuperRepresentative";
            };
    
            return TronSuperRepresentative;
        })();
    
        protocol.BtcSpecificBlockInfo = (function() {
    
            /**
             * Properties of a BtcSpecificBlockInfo.
             * @memberof protocol
             * @interface IBtcSpecificBlockInfo
             * @property {string|null} [blockHash] BtcSpecificBlockInfo blockHash
             * @property {string|null} [previousBlockHash] BtcSpecificBlockInfo previousBlockHash
             * @property {number|null} [version] BtcSpecificBlockInfo version
             * @property {number|null} [inputsCount] BtcSpecificBlockInfo inputsCount
             * @property {number|null} [outputsCount] BtcSpecificBlockInfo outputsCount
             * @property {number|null} [nonce] BtcSpecificBlockInfo nonce
             * @property {string|null} [merkleRoot] BtcSpecificBlockInfo merkleRoot
             * @property {number|null} [bits] BtcSpecificBlockInfo bits
             * @property {protocol.IUniValue|null} [blockValue] BtcSpecificBlockInfo blockValue
             * @property {string|null} [miner] BtcSpecificBlockInfo miner
             * @property {protocol.IUniValue|null} [blockReward] BtcSpecificBlockInfo blockReward
             * @property {protocol.IUniValue|null} [staticBlockReward] BtcSpecificBlockInfo staticBlockReward
             * @property {protocol.IUniValue|null} [transactionFees] BtcSpecificBlockInfo transactionFees
             * @property {protocol.IUnBigInt|null} [difficulty] BtcSpecificBlockInfo difficulty
             * @property {protocol.IUniValue|null} [fees] BtcSpecificBlockInfo fees
             */
    
            /**
             * Constructs a new BtcSpecificBlockInfo.
             * @memberof protocol
             * @classdesc Represents a BtcSpecificBlockInfo.
             * @implements IBtcSpecificBlockInfo
             * @constructor
             * @param {protocol.IBtcSpecificBlockInfo=} [properties] Properties to set
             */
            function BtcSpecificBlockInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BtcSpecificBlockInfo blockHash.
             * @member {string} blockHash
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.blockHash = "";
    
            /**
             * BtcSpecificBlockInfo previousBlockHash.
             * @member {string} previousBlockHash
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.previousBlockHash = "";
    
            /**
             * BtcSpecificBlockInfo version.
             * @member {number} version
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.version = 0;
    
            /**
             * BtcSpecificBlockInfo inputsCount.
             * @member {number} inputsCount
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.inputsCount = 0;
    
            /**
             * BtcSpecificBlockInfo outputsCount.
             * @member {number} outputsCount
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.outputsCount = 0;
    
            /**
             * BtcSpecificBlockInfo nonce.
             * @member {number} nonce
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.nonce = 0;
    
            /**
             * BtcSpecificBlockInfo merkleRoot.
             * @member {string} merkleRoot
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.merkleRoot = "";
    
            /**
             * BtcSpecificBlockInfo bits.
             * @member {number} bits
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.bits = 0;
    
            /**
             * BtcSpecificBlockInfo blockValue.
             * @member {protocol.IUniValue|null|undefined} blockValue
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.blockValue = null;
    
            /**
             * BtcSpecificBlockInfo miner.
             * @member {string} miner
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.miner = "";
    
            /**
             * BtcSpecificBlockInfo blockReward.
             * @member {protocol.IUniValue|null|undefined} blockReward
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.blockReward = null;
    
            /**
             * BtcSpecificBlockInfo staticBlockReward.
             * @member {protocol.IUniValue|null|undefined} staticBlockReward
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.staticBlockReward = null;
    
            /**
             * BtcSpecificBlockInfo transactionFees.
             * @member {protocol.IUniValue|null|undefined} transactionFees
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.transactionFees = null;
    
            /**
             * BtcSpecificBlockInfo difficulty.
             * @member {protocol.IUnBigInt|null|undefined} difficulty
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.difficulty = null;
    
            /**
             * BtcSpecificBlockInfo fees.
             * @member {protocol.IUniValue|null|undefined} fees
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             */
            BtcSpecificBlockInfo.prototype.fees = null;
    
            /**
             * Creates a new BtcSpecificBlockInfo instance using the specified properties.
             * @function create
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {protocol.IBtcSpecificBlockInfo=} [properties] Properties to set
             * @returns {protocol.BtcSpecificBlockInfo} BtcSpecificBlockInfo instance
             */
            BtcSpecificBlockInfo.create = function create(properties) {
                return new BtcSpecificBlockInfo(properties);
            };
    
            /**
             * Encodes the specified BtcSpecificBlockInfo message. Does not implicitly {@link protocol.BtcSpecificBlockInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {protocol.IBtcSpecificBlockInfo} message BtcSpecificBlockInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BtcSpecificBlockInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.blockHash != null && Object.hasOwnProperty.call(message, "blockHash"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.blockHash);
                if (message.miner != null && Object.hasOwnProperty.call(message, "miner"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.miner);
                if (message.blockReward != null && Object.hasOwnProperty.call(message, "blockReward"))
                    $root.protocol.UniValue.encode(message.blockReward, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.staticBlockReward != null && Object.hasOwnProperty.call(message, "staticBlockReward"))
                    $root.protocol.UniValue.encode(message.staticBlockReward, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.transactionFees != null && Object.hasOwnProperty.call(message, "transactionFees"))
                    $root.protocol.UniValue.encode(message.transactionFees, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.difficulty != null && Object.hasOwnProperty.call(message, "difficulty"))
                    $root.protocol.UnBigInt.encode(message.difficulty, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.nonce);
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.version);
                if (message.fees != null && Object.hasOwnProperty.call(message, "fees"))
                    $root.protocol.UniValue.encode(message.fees, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.inputsCount != null && Object.hasOwnProperty.call(message, "inputsCount"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.inputsCount);
                if (message.outputsCount != null && Object.hasOwnProperty.call(message, "outputsCount"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.outputsCount);
                if (message.blockValue != null && Object.hasOwnProperty.call(message, "blockValue"))
                    $root.protocol.UniValue.encode(message.blockValue, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.merkleRoot != null && Object.hasOwnProperty.call(message, "merkleRoot"))
                    writer.uint32(/* id 90, wireType 2 =*/722).string(message.merkleRoot);
                if (message.bits != null && Object.hasOwnProperty.call(message, "bits"))
                    writer.uint32(/* id 91, wireType 0 =*/728).uint32(message.bits);
                if (message.previousBlockHash != null && Object.hasOwnProperty.call(message, "previousBlockHash"))
                    writer.uint32(/* id 101, wireType 2 =*/810).string(message.previousBlockHash);
                return writer;
            };
    
            /**
             * Encodes the specified BtcSpecificBlockInfo message, length delimited. Does not implicitly {@link protocol.BtcSpecificBlockInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {protocol.IBtcSpecificBlockInfo} message BtcSpecificBlockInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BtcSpecificBlockInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BtcSpecificBlockInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.BtcSpecificBlockInfo} BtcSpecificBlockInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BtcSpecificBlockInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BtcSpecificBlockInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.blockHash = reader.string();
                            break;
                        }
                    case 101: {
                            message.previousBlockHash = reader.string();
                            break;
                        }
                    case 10: {
                            message.version = reader.int32();
                            break;
                        }
                    case 12: {
                            message.inputsCount = reader.int32();
                            break;
                        }
                    case 13: {
                            message.outputsCount = reader.int32();
                            break;
                        }
                    case 9: {
                            message.nonce = reader.uint32();
                            break;
                        }
                    case 90: {
                            message.merkleRoot = reader.string();
                            break;
                        }
                    case 91: {
                            message.bits = reader.uint32();
                            break;
                        }
                    case 14: {
                            message.blockValue = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.miner = reader.string();
                            break;
                        }
                    case 4: {
                            message.blockReward = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.staticBlockReward = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.transactionFees = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.difficulty = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.fees = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BtcSpecificBlockInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.BtcSpecificBlockInfo} BtcSpecificBlockInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BtcSpecificBlockInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BtcSpecificBlockInfo message.
             * @function verify
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BtcSpecificBlockInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                    if (!$util.isString(message.blockHash))
                        return "blockHash: string expected";
                if (message.previousBlockHash != null && message.hasOwnProperty("previousBlockHash"))
                    if (!$util.isString(message.previousBlockHash))
                        return "previousBlockHash: string expected";
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isInteger(message.version))
                        return "version: integer expected";
                if (message.inputsCount != null && message.hasOwnProperty("inputsCount"))
                    if (!$util.isInteger(message.inputsCount))
                        return "inputsCount: integer expected";
                if (message.outputsCount != null && message.hasOwnProperty("outputsCount"))
                    if (!$util.isInteger(message.outputsCount))
                        return "outputsCount: integer expected";
                if (message.nonce != null && message.hasOwnProperty("nonce"))
                    if (!$util.isInteger(message.nonce))
                        return "nonce: integer expected";
                if (message.merkleRoot != null && message.hasOwnProperty("merkleRoot"))
                    if (!$util.isString(message.merkleRoot))
                        return "merkleRoot: string expected";
                if (message.bits != null && message.hasOwnProperty("bits"))
                    if (!$util.isInteger(message.bits))
                        return "bits: integer expected";
                if (message.blockValue != null && message.hasOwnProperty("blockValue")) {
                    var error = $root.protocol.UniValue.verify(message.blockValue);
                    if (error)
                        return "blockValue." + error;
                }
                if (message.miner != null && message.hasOwnProperty("miner"))
                    if (!$util.isString(message.miner))
                        return "miner: string expected";
                if (message.blockReward != null && message.hasOwnProperty("blockReward")) {
                    var error = $root.protocol.UniValue.verify(message.blockReward);
                    if (error)
                        return "blockReward." + error;
                }
                if (message.staticBlockReward != null && message.hasOwnProperty("staticBlockReward")) {
                    var error = $root.protocol.UniValue.verify(message.staticBlockReward);
                    if (error)
                        return "staticBlockReward." + error;
                }
                if (message.transactionFees != null && message.hasOwnProperty("transactionFees")) {
                    var error = $root.protocol.UniValue.verify(message.transactionFees);
                    if (error)
                        return "transactionFees." + error;
                }
                if (message.difficulty != null && message.hasOwnProperty("difficulty")) {
                    var error = $root.protocol.UnBigInt.verify(message.difficulty);
                    if (error)
                        return "difficulty." + error;
                }
                if (message.fees != null && message.hasOwnProperty("fees")) {
                    var error = $root.protocol.UniValue.verify(message.fees);
                    if (error)
                        return "fees." + error;
                }
                return null;
            };
    
            /**
             * Creates a BtcSpecificBlockInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.BtcSpecificBlockInfo} BtcSpecificBlockInfo
             */
            BtcSpecificBlockInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.BtcSpecificBlockInfo)
                    return object;
                var message = new $root.protocol.BtcSpecificBlockInfo();
                if (object.blockHash != null)
                    message.blockHash = String(object.blockHash);
                if (object.previousBlockHash != null)
                    message.previousBlockHash = String(object.previousBlockHash);
                if (object.version != null)
                    message.version = object.version | 0;
                if (object.inputsCount != null)
                    message.inputsCount = object.inputsCount | 0;
                if (object.outputsCount != null)
                    message.outputsCount = object.outputsCount | 0;
                if (object.nonce != null)
                    message.nonce = object.nonce >>> 0;
                if (object.merkleRoot != null)
                    message.merkleRoot = String(object.merkleRoot);
                if (object.bits != null)
                    message.bits = object.bits >>> 0;
                if (object.blockValue != null) {
                    if (typeof object.blockValue !== "object")
                        throw TypeError(".protocol.BtcSpecificBlockInfo.blockValue: object expected");
                    message.blockValue = $root.protocol.UniValue.fromObject(object.blockValue);
                }
                if (object.miner != null)
                    message.miner = String(object.miner);
                if (object.blockReward != null) {
                    if (typeof object.blockReward !== "object")
                        throw TypeError(".protocol.BtcSpecificBlockInfo.blockReward: object expected");
                    message.blockReward = $root.protocol.UniValue.fromObject(object.blockReward);
                }
                if (object.staticBlockReward != null) {
                    if (typeof object.staticBlockReward !== "object")
                        throw TypeError(".protocol.BtcSpecificBlockInfo.staticBlockReward: object expected");
                    message.staticBlockReward = $root.protocol.UniValue.fromObject(object.staticBlockReward);
                }
                if (object.transactionFees != null) {
                    if (typeof object.transactionFees !== "object")
                        throw TypeError(".protocol.BtcSpecificBlockInfo.transactionFees: object expected");
                    message.transactionFees = $root.protocol.UniValue.fromObject(object.transactionFees);
                }
                if (object.difficulty != null) {
                    if (typeof object.difficulty !== "object")
                        throw TypeError(".protocol.BtcSpecificBlockInfo.difficulty: object expected");
                    message.difficulty = $root.protocol.UnBigInt.fromObject(object.difficulty);
                }
                if (object.fees != null) {
                    if (typeof object.fees !== "object")
                        throw TypeError(".protocol.BtcSpecificBlockInfo.fees: object expected");
                    message.fees = $root.protocol.UniValue.fromObject(object.fees);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BtcSpecificBlockInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {protocol.BtcSpecificBlockInfo} message BtcSpecificBlockInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BtcSpecificBlockInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.blockHash = "";
                    object.miner = "";
                    object.blockReward = null;
                    object.staticBlockReward = null;
                    object.transactionFees = null;
                    object.difficulty = null;
                    object.nonce = 0;
                    object.version = 0;
                    object.fees = null;
                    object.inputsCount = 0;
                    object.outputsCount = 0;
                    object.blockValue = null;
                    object.merkleRoot = "";
                    object.bits = 0;
                    object.previousBlockHash = "";
                }
                if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                    object.blockHash = message.blockHash;
                if (message.miner != null && message.hasOwnProperty("miner"))
                    object.miner = message.miner;
                if (message.blockReward != null && message.hasOwnProperty("blockReward"))
                    object.blockReward = $root.protocol.UniValue.toObject(message.blockReward, options);
                if (message.staticBlockReward != null && message.hasOwnProperty("staticBlockReward"))
                    object.staticBlockReward = $root.protocol.UniValue.toObject(message.staticBlockReward, options);
                if (message.transactionFees != null && message.hasOwnProperty("transactionFees"))
                    object.transactionFees = $root.protocol.UniValue.toObject(message.transactionFees, options);
                if (message.difficulty != null && message.hasOwnProperty("difficulty"))
                    object.difficulty = $root.protocol.UnBigInt.toObject(message.difficulty, options);
                if (message.nonce != null && message.hasOwnProperty("nonce"))
                    object.nonce = message.nonce;
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = message.version;
                if (message.fees != null && message.hasOwnProperty("fees"))
                    object.fees = $root.protocol.UniValue.toObject(message.fees, options);
                if (message.inputsCount != null && message.hasOwnProperty("inputsCount"))
                    object.inputsCount = message.inputsCount;
                if (message.outputsCount != null && message.hasOwnProperty("outputsCount"))
                    object.outputsCount = message.outputsCount;
                if (message.blockValue != null && message.hasOwnProperty("blockValue"))
                    object.blockValue = $root.protocol.UniValue.toObject(message.blockValue, options);
                if (message.merkleRoot != null && message.hasOwnProperty("merkleRoot"))
                    object.merkleRoot = message.merkleRoot;
                if (message.bits != null && message.hasOwnProperty("bits"))
                    object.bits = message.bits;
                if (message.previousBlockHash != null && message.hasOwnProperty("previousBlockHash"))
                    object.previousBlockHash = message.previousBlockHash;
                return object;
            };
    
            /**
             * Converts this BtcSpecificBlockInfo to JSON.
             * @function toJSON
             * @memberof protocol.BtcSpecificBlockInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BtcSpecificBlockInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BtcSpecificBlockInfo
             * @function getTypeUrl
             * @memberof protocol.BtcSpecificBlockInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BtcSpecificBlockInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.BtcSpecificBlockInfo";
            };
    
            return BtcSpecificBlockInfo;
        })();
    
        protocol.ProposedOn = (function() {
    
            /**
             * Properties of a ProposedOn.
             * @memberof protocol
             * @interface IProposedOn
             * @property {Long|null} [slot] ProposedOn slot
             * @property {Long|null} [epoch] ProposedOn epoch
             */
    
            /**
             * Constructs a new ProposedOn.
             * @memberof protocol
             * @classdesc Represents a ProposedOn.
             * @implements IProposedOn
             * @constructor
             * @param {protocol.IProposedOn=} [properties] Properties to set
             */
            function ProposedOn(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ProposedOn slot.
             * @member {Long} slot
             * @memberof protocol.ProposedOn
             * @instance
             */
            ProposedOn.prototype.slot = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * ProposedOn epoch.
             * @member {Long} epoch
             * @memberof protocol.ProposedOn
             * @instance
             */
            ProposedOn.prototype.epoch = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new ProposedOn instance using the specified properties.
             * @function create
             * @memberof protocol.ProposedOn
             * @static
             * @param {protocol.IProposedOn=} [properties] Properties to set
             * @returns {protocol.ProposedOn} ProposedOn instance
             */
            ProposedOn.create = function create(properties) {
                return new ProposedOn(properties);
            };
    
            /**
             * Encodes the specified ProposedOn message. Does not implicitly {@link protocol.ProposedOn.verify|verify} messages.
             * @function encode
             * @memberof protocol.ProposedOn
             * @static
             * @param {protocol.IProposedOn} message ProposedOn message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProposedOn.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.slot != null && Object.hasOwnProperty.call(message, "slot"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.slot);
                if (message.epoch != null && Object.hasOwnProperty.call(message, "epoch"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.epoch);
                return writer;
            };
    
            /**
             * Encodes the specified ProposedOn message, length delimited. Does not implicitly {@link protocol.ProposedOn.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ProposedOn
             * @static
             * @param {protocol.IProposedOn} message ProposedOn message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProposedOn.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ProposedOn message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ProposedOn
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ProposedOn} ProposedOn
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProposedOn.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ProposedOn();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.slot = reader.int64();
                            break;
                        }
                    case 2: {
                            message.epoch = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ProposedOn message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ProposedOn
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ProposedOn} ProposedOn
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProposedOn.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ProposedOn message.
             * @function verify
             * @memberof protocol.ProposedOn
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProposedOn.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.slot != null && message.hasOwnProperty("slot"))
                    if (!$util.isInteger(message.slot) && !(message.slot && $util.isInteger(message.slot.low) && $util.isInteger(message.slot.high)))
                        return "slot: integer|Long expected";
                if (message.epoch != null && message.hasOwnProperty("epoch"))
                    if (!$util.isInteger(message.epoch) && !(message.epoch && $util.isInteger(message.epoch.low) && $util.isInteger(message.epoch.high)))
                        return "epoch: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a ProposedOn message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ProposedOn
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ProposedOn} ProposedOn
             */
            ProposedOn.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ProposedOn)
                    return object;
                var message = new $root.protocol.ProposedOn();
                if (object.slot != null)
                    if ($util.Long)
                        (message.slot = $util.Long.fromValue(object.slot)).unsigned = false;
                    else if (typeof object.slot === "string")
                        message.slot = parseInt(object.slot, 10);
                    else if (typeof object.slot === "number")
                        message.slot = object.slot;
                    else if (typeof object.slot === "object")
                        message.slot = new $util.LongBits(object.slot.low >>> 0, object.slot.high >>> 0).toNumber();
                if (object.epoch != null)
                    if ($util.Long)
                        (message.epoch = $util.Long.fromValue(object.epoch)).unsigned = false;
                    else if (typeof object.epoch === "string")
                        message.epoch = parseInt(object.epoch, 10);
                    else if (typeof object.epoch === "number")
                        message.epoch = object.epoch;
                    else if (typeof object.epoch === "object")
                        message.epoch = new $util.LongBits(object.epoch.low >>> 0, object.epoch.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a ProposedOn message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ProposedOn
             * @static
             * @param {protocol.ProposedOn} message ProposedOn
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProposedOn.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.slot = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.slot = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.epoch = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.epoch = options.longs === String ? "0" : 0;
                }
                if (message.slot != null && message.hasOwnProperty("slot"))
                    if (typeof message.slot === "number")
                        object.slot = options.longs === String ? String(message.slot) : message.slot;
                    else
                        object.slot = options.longs === String ? $util.Long.prototype.toString.call(message.slot) : options.longs === Number ? new $util.LongBits(message.slot.low >>> 0, message.slot.high >>> 0).toNumber() : message.slot;
                if (message.epoch != null && message.hasOwnProperty("epoch"))
                    if (typeof message.epoch === "number")
                        object.epoch = options.longs === String ? String(message.epoch) : message.epoch;
                    else
                        object.epoch = options.longs === String ? $util.Long.prototype.toString.call(message.epoch) : options.longs === Number ? new $util.LongBits(message.epoch.low >>> 0, message.epoch.high >>> 0).toNumber() : message.epoch;
                return object;
            };
    
            /**
             * Converts this ProposedOn to JSON.
             * @function toJSON
             * @memberof protocol.ProposedOn
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProposedOn.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ProposedOn
             * @function getTypeUrl
             * @memberof protocol.ProposedOn
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProposedOn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.ProposedOn";
            };
    
            return ProposedOn;
        })();
    
        protocol.EthWithdrawal = (function() {
    
            /**
             * Properties of an EthWithdrawal.
             * @memberof protocol
             * @interface IEthWithdrawal
             * @property {Long|null} [index] EthWithdrawal index
             * @property {Long|null} [validator] EthWithdrawal validator
             * @property {string|null} [address] EthWithdrawal address
             * @property {protocol.IUniValue|null} [value] EthWithdrawal value
             */
    
            /**
             * Constructs a new EthWithdrawal.
             * @memberof protocol
             * @classdesc Represents an EthWithdrawal.
             * @implements IEthWithdrawal
             * @constructor
             * @param {protocol.IEthWithdrawal=} [properties] Properties to set
             */
            function EthWithdrawal(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EthWithdrawal index.
             * @member {Long} index
             * @memberof protocol.EthWithdrawal
             * @instance
             */
            EthWithdrawal.prototype.index = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthWithdrawal validator.
             * @member {Long} validator
             * @memberof protocol.EthWithdrawal
             * @instance
             */
            EthWithdrawal.prototype.validator = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * EthWithdrawal address.
             * @member {string} address
             * @memberof protocol.EthWithdrawal
             * @instance
             */
            EthWithdrawal.prototype.address = "";
    
            /**
             * EthWithdrawal value.
             * @member {protocol.IUniValue|null|undefined} value
             * @memberof protocol.EthWithdrawal
             * @instance
             */
            EthWithdrawal.prototype.value = null;
    
            /**
             * Creates a new EthWithdrawal instance using the specified properties.
             * @function create
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {protocol.IEthWithdrawal=} [properties] Properties to set
             * @returns {protocol.EthWithdrawal} EthWithdrawal instance
             */
            EthWithdrawal.create = function create(properties) {
                return new EthWithdrawal(properties);
            };
    
            /**
             * Encodes the specified EthWithdrawal message. Does not implicitly {@link protocol.EthWithdrawal.verify|verify} messages.
             * @function encode
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {protocol.IEthWithdrawal} message EthWithdrawal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthWithdrawal.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.index);
                if (message.validator != null && Object.hasOwnProperty.call(message, "validator"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.validator);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    $root.protocol.UniValue.encode(message.value, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EthWithdrawal message, length delimited. Does not implicitly {@link protocol.EthWithdrawal.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {protocol.IEthWithdrawal} message EthWithdrawal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthWithdrawal.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EthWithdrawal message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EthWithdrawal} EthWithdrawal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthWithdrawal.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EthWithdrawal();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.index = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.validator = reader.uint64();
                            break;
                        }
                    case 3: {
                            message.address = reader.string();
                            break;
                        }
                    case 4: {
                            message.value = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EthWithdrawal message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EthWithdrawal} EthWithdrawal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthWithdrawal.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EthWithdrawal message.
             * @function verify
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EthWithdrawal.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.index != null && message.hasOwnProperty("index"))
                    if (!$util.isInteger(message.index) && !(message.index && $util.isInteger(message.index.low) && $util.isInteger(message.index.high)))
                        return "index: integer|Long expected";
                if (message.validator != null && message.hasOwnProperty("validator"))
                    if (!$util.isInteger(message.validator) && !(message.validator && $util.isInteger(message.validator.low) && $util.isInteger(message.validator.high)))
                        return "validator: integer|Long expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    var error = $root.protocol.UniValue.verify(message.value);
                    if (error)
                        return "value." + error;
                }
                return null;
            };
    
            /**
             * Creates an EthWithdrawal message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EthWithdrawal} EthWithdrawal
             */
            EthWithdrawal.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EthWithdrawal)
                    return object;
                var message = new $root.protocol.EthWithdrawal();
                if (object.index != null)
                    if ($util.Long)
                        (message.index = $util.Long.fromValue(object.index)).unsigned = true;
                    else if (typeof object.index === "string")
                        message.index = parseInt(object.index, 10);
                    else if (typeof object.index === "number")
                        message.index = object.index;
                    else if (typeof object.index === "object")
                        message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber(true);
                if (object.validator != null)
                    if ($util.Long)
                        (message.validator = $util.Long.fromValue(object.validator)).unsigned = true;
                    else if (typeof object.validator === "string")
                        message.validator = parseInt(object.validator, 10);
                    else if (typeof object.validator === "number")
                        message.validator = object.validator;
                    else if (typeof object.validator === "object")
                        message.validator = new $util.LongBits(object.validator.low >>> 0, object.validator.high >>> 0).toNumber(true);
                if (object.address != null)
                    message.address = String(object.address);
                if (object.value != null) {
                    if (typeof object.value !== "object")
                        throw TypeError(".protocol.EthWithdrawal.value: object expected");
                    message.value = $root.protocol.UniValue.fromObject(object.value);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EthWithdrawal message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {protocol.EthWithdrawal} message EthWithdrawal
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EthWithdrawal.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.index = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.validator = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.validator = options.longs === String ? "0" : 0;
                    object.address = "";
                    object.value = null;
                }
                if (message.index != null && message.hasOwnProperty("index"))
                    if (typeof message.index === "number")
                        object.index = options.longs === String ? String(message.index) : message.index;
                    else
                        object.index = options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber(true) : message.index;
                if (message.validator != null && message.hasOwnProperty("validator"))
                    if (typeof message.validator === "number")
                        object.validator = options.longs === String ? String(message.validator) : message.validator;
                    else
                        object.validator = options.longs === String ? $util.Long.prototype.toString.call(message.validator) : options.longs === Number ? new $util.LongBits(message.validator.low >>> 0, message.validator.high >>> 0).toNumber(true) : message.validator;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = $root.protocol.UniValue.toObject(message.value, options);
                return object;
            };
    
            /**
             * Converts this EthWithdrawal to JSON.
             * @function toJSON
             * @memberof protocol.EthWithdrawal
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EthWithdrawal.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EthWithdrawal
             * @function getTypeUrl
             * @memberof protocol.EthWithdrawal
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EthWithdrawal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.EthWithdrawal";
            };
    
            return EthWithdrawal;
        })();
    
        protocol.EthBlockReward = (function() {
    
            /**
             * Properties of an EthBlockReward.
             * @memberof protocol
             * @interface IEthBlockReward
             * @property {protocol.IUniValue|null} [staticBlockReward] EthBlockReward staticBlockReward
             * @property {protocol.IUniValue|null} [burntFees] EthBlockReward burntFees
             * @property {protocol.IUniValue|null} [transactionFees] EthBlockReward transactionFees
             * @property {protocol.IUniValue|null} [totalBlockReward] EthBlockReward totalBlockReward
             */
    
            /**
             * Constructs a new EthBlockReward.
             * @memberof protocol
             * @classdesc Represents an EthBlockReward.
             * @implements IEthBlockReward
             * @constructor
             * @param {protocol.IEthBlockReward=} [properties] Properties to set
             */
            function EthBlockReward(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EthBlockReward staticBlockReward.
             * @member {protocol.IUniValue|null|undefined} staticBlockReward
             * @memberof protocol.EthBlockReward
             * @instance
             */
            EthBlockReward.prototype.staticBlockReward = null;
    
            /**
             * EthBlockReward burntFees.
             * @member {protocol.IUniValue|null|undefined} burntFees
             * @memberof protocol.EthBlockReward
             * @instance
             */
            EthBlockReward.prototype.burntFees = null;
    
            /**
             * EthBlockReward transactionFees.
             * @member {protocol.IUniValue|null|undefined} transactionFees
             * @memberof protocol.EthBlockReward
             * @instance
             */
            EthBlockReward.prototype.transactionFees = null;
    
            /**
             * EthBlockReward totalBlockReward.
             * @member {protocol.IUniValue|null|undefined} totalBlockReward
             * @memberof protocol.EthBlockReward
             * @instance
             */
            EthBlockReward.prototype.totalBlockReward = null;
    
            /**
             * Creates a new EthBlockReward instance using the specified properties.
             * @function create
             * @memberof protocol.EthBlockReward
             * @static
             * @param {protocol.IEthBlockReward=} [properties] Properties to set
             * @returns {protocol.EthBlockReward} EthBlockReward instance
             */
            EthBlockReward.create = function create(properties) {
                return new EthBlockReward(properties);
            };
    
            /**
             * Encodes the specified EthBlockReward message. Does not implicitly {@link protocol.EthBlockReward.verify|verify} messages.
             * @function encode
             * @memberof protocol.EthBlockReward
             * @static
             * @param {protocol.IEthBlockReward} message EthBlockReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthBlockReward.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.staticBlockReward != null && Object.hasOwnProperty.call(message, "staticBlockReward"))
                    $root.protocol.UniValue.encode(message.staticBlockReward, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.transactionFees != null && Object.hasOwnProperty.call(message, "transactionFees"))
                    $root.protocol.UniValue.encode(message.transactionFees, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.burntFees != null && Object.hasOwnProperty.call(message, "burntFees"))
                    $root.protocol.UniValue.encode(message.burntFees, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.totalBlockReward != null && Object.hasOwnProperty.call(message, "totalBlockReward"))
                    $root.protocol.UniValue.encode(message.totalBlockReward, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified EthBlockReward message, length delimited. Does not implicitly {@link protocol.EthBlockReward.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EthBlockReward
             * @static
             * @param {protocol.IEthBlockReward} message EthBlockReward message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthBlockReward.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EthBlockReward message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EthBlockReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EthBlockReward} EthBlockReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthBlockReward.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EthBlockReward();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.staticBlockReward = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.burntFees = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.transactionFees = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.totalBlockReward = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EthBlockReward message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EthBlockReward
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EthBlockReward} EthBlockReward
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthBlockReward.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EthBlockReward message.
             * @function verify
             * @memberof protocol.EthBlockReward
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EthBlockReward.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.staticBlockReward != null && message.hasOwnProperty("staticBlockReward")) {
                    var error = $root.protocol.UniValue.verify(message.staticBlockReward);
                    if (error)
                        return "staticBlockReward." + error;
                }
                if (message.burntFees != null && message.hasOwnProperty("burntFees")) {
                    var error = $root.protocol.UniValue.verify(message.burntFees);
                    if (error)
                        return "burntFees." + error;
                }
                if (message.transactionFees != null && message.hasOwnProperty("transactionFees")) {
                    var error = $root.protocol.UniValue.verify(message.transactionFees);
                    if (error)
                        return "transactionFees." + error;
                }
                if (message.totalBlockReward != null && message.hasOwnProperty("totalBlockReward")) {
                    var error = $root.protocol.UniValue.verify(message.totalBlockReward);
                    if (error)
                        return "totalBlockReward." + error;
                }
                return null;
            };
    
            /**
             * Creates an EthBlockReward message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EthBlockReward
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EthBlockReward} EthBlockReward
             */
            EthBlockReward.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EthBlockReward)
                    return object;
                var message = new $root.protocol.EthBlockReward();
                if (object.staticBlockReward != null) {
                    if (typeof object.staticBlockReward !== "object")
                        throw TypeError(".protocol.EthBlockReward.staticBlockReward: object expected");
                    message.staticBlockReward = $root.protocol.UniValue.fromObject(object.staticBlockReward);
                }
                if (object.burntFees != null) {
                    if (typeof object.burntFees !== "object")
                        throw TypeError(".protocol.EthBlockReward.burntFees: object expected");
                    message.burntFees = $root.protocol.UniValue.fromObject(object.burntFees);
                }
                if (object.transactionFees != null) {
                    if (typeof object.transactionFees !== "object")
                        throw TypeError(".protocol.EthBlockReward.transactionFees: object expected");
                    message.transactionFees = $root.protocol.UniValue.fromObject(object.transactionFees);
                }
                if (object.totalBlockReward != null) {
                    if (typeof object.totalBlockReward !== "object")
                        throw TypeError(".protocol.EthBlockReward.totalBlockReward: object expected");
                    message.totalBlockReward = $root.protocol.UniValue.fromObject(object.totalBlockReward);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EthBlockReward message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EthBlockReward
             * @static
             * @param {protocol.EthBlockReward} message EthBlockReward
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EthBlockReward.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.staticBlockReward = null;
                    object.transactionFees = null;
                    object.burntFees = null;
                    object.totalBlockReward = null;
                }
                if (message.staticBlockReward != null && message.hasOwnProperty("staticBlockReward"))
                    object.staticBlockReward = $root.protocol.UniValue.toObject(message.staticBlockReward, options);
                if (message.transactionFees != null && message.hasOwnProperty("transactionFees"))
                    object.transactionFees = $root.protocol.UniValue.toObject(message.transactionFees, options);
                if (message.burntFees != null && message.hasOwnProperty("burntFees"))
                    object.burntFees = $root.protocol.UniValue.toObject(message.burntFees, options);
                if (message.totalBlockReward != null && message.hasOwnProperty("totalBlockReward"))
                    object.totalBlockReward = $root.protocol.UniValue.toObject(message.totalBlockReward, options);
                return object;
            };
    
            /**
             * Converts this EthBlockReward to JSON.
             * @function toJSON
             * @memberof protocol.EthBlockReward
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EthBlockReward.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EthBlockReward
             * @function getTypeUrl
             * @memberof protocol.EthBlockReward
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EthBlockReward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.EthBlockReward";
            };
    
            return EthBlockReward;
        })();
    
        protocol.EthFeeRecipient = (function() {
    
            /**
             * Properties of an EthFeeRecipient.
             * @memberof protocol
             * @interface IEthFeeRecipient
             * @property {string|null} [address] EthFeeRecipient address
             * @property {Long|null} [inMilliseconds] EthFeeRecipient inMilliseconds
             */
    
            /**
             * Constructs a new EthFeeRecipient.
             * @memberof protocol
             * @classdesc Represents an EthFeeRecipient.
             * @implements IEthFeeRecipient
             * @constructor
             * @param {protocol.IEthFeeRecipient=} [properties] Properties to set
             */
            function EthFeeRecipient(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EthFeeRecipient address.
             * @member {string} address
             * @memberof protocol.EthFeeRecipient
             * @instance
             */
            EthFeeRecipient.prototype.address = "";
    
            /**
             * EthFeeRecipient inMilliseconds.
             * @member {Long} inMilliseconds
             * @memberof protocol.EthFeeRecipient
             * @instance
             */
            EthFeeRecipient.prototype.inMilliseconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new EthFeeRecipient instance using the specified properties.
             * @function create
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {protocol.IEthFeeRecipient=} [properties] Properties to set
             * @returns {protocol.EthFeeRecipient} EthFeeRecipient instance
             */
            EthFeeRecipient.create = function create(properties) {
                return new EthFeeRecipient(properties);
            };
    
            /**
             * Encodes the specified EthFeeRecipient message. Does not implicitly {@link protocol.EthFeeRecipient.verify|verify} messages.
             * @function encode
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {protocol.IEthFeeRecipient} message EthFeeRecipient message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthFeeRecipient.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.inMilliseconds != null && Object.hasOwnProperty.call(message, "inMilliseconds"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.inMilliseconds);
                return writer;
            };
    
            /**
             * Encodes the specified EthFeeRecipient message, length delimited. Does not implicitly {@link protocol.EthFeeRecipient.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {protocol.IEthFeeRecipient} message EthFeeRecipient message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthFeeRecipient.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EthFeeRecipient message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EthFeeRecipient} EthFeeRecipient
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthFeeRecipient.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EthFeeRecipient();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.string();
                            break;
                        }
                    case 2: {
                            message.inMilliseconds = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EthFeeRecipient message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EthFeeRecipient} EthFeeRecipient
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthFeeRecipient.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EthFeeRecipient message.
             * @function verify
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EthFeeRecipient.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.inMilliseconds != null && message.hasOwnProperty("inMilliseconds"))
                    if (!$util.isInteger(message.inMilliseconds) && !(message.inMilliseconds && $util.isInteger(message.inMilliseconds.low) && $util.isInteger(message.inMilliseconds.high)))
                        return "inMilliseconds: integer|Long expected";
                return null;
            };
    
            /**
             * Creates an EthFeeRecipient message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EthFeeRecipient} EthFeeRecipient
             */
            EthFeeRecipient.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EthFeeRecipient)
                    return object;
                var message = new $root.protocol.EthFeeRecipient();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.inMilliseconds != null)
                    if ($util.Long)
                        (message.inMilliseconds = $util.Long.fromValue(object.inMilliseconds)).unsigned = false;
                    else if (typeof object.inMilliseconds === "string")
                        message.inMilliseconds = parseInt(object.inMilliseconds, 10);
                    else if (typeof object.inMilliseconds === "number")
                        message.inMilliseconds = object.inMilliseconds;
                    else if (typeof object.inMilliseconds === "object")
                        message.inMilliseconds = new $util.LongBits(object.inMilliseconds.low >>> 0, object.inMilliseconds.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from an EthFeeRecipient message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {protocol.EthFeeRecipient} message EthFeeRecipient
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EthFeeRecipient.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.inMilliseconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.inMilliseconds = options.longs === String ? "0" : 0;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.inMilliseconds != null && message.hasOwnProperty("inMilliseconds"))
                    if (typeof message.inMilliseconds === "number")
                        object.inMilliseconds = options.longs === String ? String(message.inMilliseconds) : message.inMilliseconds;
                    else
                        object.inMilliseconds = options.longs === String ? $util.Long.prototype.toString.call(message.inMilliseconds) : options.longs === Number ? new $util.LongBits(message.inMilliseconds.low >>> 0, message.inMilliseconds.high >>> 0).toNumber() : message.inMilliseconds;
                return object;
            };
    
            /**
             * Converts this EthFeeRecipient to JSON.
             * @function toJSON
             * @memberof protocol.EthFeeRecipient
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EthFeeRecipient.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EthFeeRecipient
             * @function getTypeUrl
             * @memberof protocol.EthFeeRecipient
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EthFeeRecipient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.EthFeeRecipient";
            };
    
            return EthFeeRecipient;
        })();
    
        protocol.EthSpecificBlockInfo = (function() {
    
            /**
             * Properties of an EthSpecificBlockInfo.
             * @memberof protocol
             * @interface IEthSpecificBlockInfo
             * @property {Array.<protocol.IEthWithdrawal>|null} [withdrawals] EthSpecificBlockInfo withdrawals
             * @property {protocol.IUnBigInt|null} [gasUsed] EthSpecificBlockInfo gasUsed
             * @property {protocol.IUnBigInt|null} [gasLimit] EthSpecificBlockInfo gasLimit
             * @property {number|null} [percentageOfGasUsed] EthSpecificBlockInfo percentageOfGasUsed
             * @property {protocol.IUnBigInt|null} [baseFeePerGas] EthSpecificBlockInfo baseFeePerGas
             * @property {Uint8Array|null} [extraData] EthSpecificBlockInfo extraData
             * @property {string|null} [extraDataStr] EthSpecificBlockInfo extraDataStr
             * @property {protocol.IProposedOn|null} [proposedOn] EthSpecificBlockInfo proposedOn
             * @property {Long|null} [contractInternalTransactionsCount] EthSpecificBlockInfo contractInternalTransactionsCount
             * @property {protocol.IEthFeeRecipient|null} [feeRecipient] EthSpecificBlockInfo feeRecipient
             * @property {protocol.IEthBlockReward|null} [blockReward] EthSpecificBlockInfo blockReward
             * @property {protocol.IUnBigInt|null} [totalDifficulty] EthSpecificBlockInfo totalDifficulty
             */
    
            /**
             * Constructs a new EthSpecificBlockInfo.
             * @memberof protocol
             * @classdesc Represents an EthSpecificBlockInfo.
             * @implements IEthSpecificBlockInfo
             * @constructor
             * @param {protocol.IEthSpecificBlockInfo=} [properties] Properties to set
             */
            function EthSpecificBlockInfo(properties) {
                this.withdrawals = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EthSpecificBlockInfo withdrawals.
             * @member {Array.<protocol.IEthWithdrawal>} withdrawals
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.withdrawals = $util.emptyArray;
    
            /**
             * EthSpecificBlockInfo gasUsed.
             * @member {protocol.IUnBigInt|null|undefined} gasUsed
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.gasUsed = null;
    
            /**
             * EthSpecificBlockInfo gasLimit.
             * @member {protocol.IUnBigInt|null|undefined} gasLimit
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.gasLimit = null;
    
            /**
             * EthSpecificBlockInfo percentageOfGasUsed.
             * @member {number} percentageOfGasUsed
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.percentageOfGasUsed = 0;
    
            /**
             * EthSpecificBlockInfo baseFeePerGas.
             * @member {protocol.IUnBigInt|null|undefined} baseFeePerGas
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.baseFeePerGas = null;
    
            /**
             * EthSpecificBlockInfo extraData.
             * @member {Uint8Array} extraData
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.extraData = $util.newBuffer([]);
    
            /**
             * EthSpecificBlockInfo extraDataStr.
             * @member {string} extraDataStr
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.extraDataStr = "";
    
            /**
             * EthSpecificBlockInfo proposedOn.
             * @member {protocol.IProposedOn|null|undefined} proposedOn
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.proposedOn = null;
    
            /**
             * EthSpecificBlockInfo contractInternalTransactionsCount.
             * @member {Long} contractInternalTransactionsCount
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.contractInternalTransactionsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * EthSpecificBlockInfo feeRecipient.
             * @member {protocol.IEthFeeRecipient|null|undefined} feeRecipient
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.feeRecipient = null;
    
            /**
             * EthSpecificBlockInfo blockReward.
             * @member {protocol.IEthBlockReward|null|undefined} blockReward
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.blockReward = null;
    
            /**
             * EthSpecificBlockInfo totalDifficulty.
             * @member {protocol.IUnBigInt|null|undefined} totalDifficulty
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             */
            EthSpecificBlockInfo.prototype.totalDifficulty = null;
    
            /**
             * Creates a new EthSpecificBlockInfo instance using the specified properties.
             * @function create
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {protocol.IEthSpecificBlockInfo=} [properties] Properties to set
             * @returns {protocol.EthSpecificBlockInfo} EthSpecificBlockInfo instance
             */
            EthSpecificBlockInfo.create = function create(properties) {
                return new EthSpecificBlockInfo(properties);
            };
    
            /**
             * Encodes the specified EthSpecificBlockInfo message. Does not implicitly {@link protocol.EthSpecificBlockInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {protocol.IEthSpecificBlockInfo} message EthSpecificBlockInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthSpecificBlockInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.proposedOn != null && Object.hasOwnProperty.call(message, "proposedOn"))
                    $root.protocol.ProposedOn.encode(message.proposedOn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.contractInternalTransactionsCount != null && Object.hasOwnProperty.call(message, "contractInternalTransactionsCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.contractInternalTransactionsCount);
                if (message.withdrawals != null && message.withdrawals.length)
                    for (var i = 0; i < message.withdrawals.length; ++i)
                        $root.protocol.EthWithdrawal.encode(message.withdrawals[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.feeRecipient != null && Object.hasOwnProperty.call(message, "feeRecipient"))
                    $root.protocol.EthFeeRecipient.encode(message.feeRecipient, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.blockReward != null && Object.hasOwnProperty.call(message, "blockReward"))
                    $root.protocol.EthBlockReward.encode(message.blockReward, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.totalDifficulty != null && Object.hasOwnProperty.call(message, "totalDifficulty"))
                    $root.protocol.UnBigInt.encode(message.totalDifficulty, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.gasUsed != null && Object.hasOwnProperty.call(message, "gasUsed"))
                    $root.protocol.UnBigInt.encode(message.gasUsed, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.gasLimit != null && Object.hasOwnProperty.call(message, "gasLimit"))
                    $root.protocol.UnBigInt.encode(message.gasLimit, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.percentageOfGasUsed != null && Object.hasOwnProperty.call(message, "percentageOfGasUsed"))
                    writer.uint32(/* id 13, wireType 1 =*/105).double(message.percentageOfGasUsed);
                if (message.baseFeePerGas != null && Object.hasOwnProperty.call(message, "baseFeePerGas"))
                    $root.protocol.UnBigInt.encode(message.baseFeePerGas, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.extraData != null && Object.hasOwnProperty.call(message, "extraData"))
                    writer.uint32(/* id 16, wireType 2 =*/130).bytes(message.extraData);
                if (message.extraDataStr != null && Object.hasOwnProperty.call(message, "extraDataStr"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.extraDataStr);
                return writer;
            };
    
            /**
             * Encodes the specified EthSpecificBlockInfo message, length delimited. Does not implicitly {@link protocol.EthSpecificBlockInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {protocol.IEthSpecificBlockInfo} message EthSpecificBlockInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthSpecificBlockInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EthSpecificBlockInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EthSpecificBlockInfo} EthSpecificBlockInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthSpecificBlockInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EthSpecificBlockInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            if (!(message.withdrawals && message.withdrawals.length))
                                message.withdrawals = [];
                            message.withdrawals.push($root.protocol.EthWithdrawal.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            message.gasUsed = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.gasLimit = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.percentageOfGasUsed = reader.double();
                            break;
                        }
                    case 14: {
                            message.baseFeePerGas = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.extraData = reader.bytes();
                            break;
                        }
                    case 17: {
                            message.extraDataStr = reader.string();
                            break;
                        }
                    case 1: {
                            message.proposedOn = $root.protocol.ProposedOn.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.contractInternalTransactionsCount = reader.int64();
                            break;
                        }
                    case 4: {
                            message.feeRecipient = $root.protocol.EthFeeRecipient.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.blockReward = $root.protocol.EthBlockReward.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.totalDifficulty = $root.protocol.UnBigInt.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EthSpecificBlockInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EthSpecificBlockInfo} EthSpecificBlockInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthSpecificBlockInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EthSpecificBlockInfo message.
             * @function verify
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EthSpecificBlockInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.withdrawals != null && message.hasOwnProperty("withdrawals")) {
                    if (!Array.isArray(message.withdrawals))
                        return "withdrawals: array expected";
                    for (var i = 0; i < message.withdrawals.length; ++i) {
                        var error = $root.protocol.EthWithdrawal.verify(message.withdrawals[i]);
                        if (error)
                            return "withdrawals." + error;
                    }
                }
                if (message.gasUsed != null && message.hasOwnProperty("gasUsed")) {
                    var error = $root.protocol.UnBigInt.verify(message.gasUsed);
                    if (error)
                        return "gasUsed." + error;
                }
                if (message.gasLimit != null && message.hasOwnProperty("gasLimit")) {
                    var error = $root.protocol.UnBigInt.verify(message.gasLimit);
                    if (error)
                        return "gasLimit." + error;
                }
                if (message.percentageOfGasUsed != null && message.hasOwnProperty("percentageOfGasUsed"))
                    if (typeof message.percentageOfGasUsed !== "number")
                        return "percentageOfGasUsed: number expected";
                if (message.baseFeePerGas != null && message.hasOwnProperty("baseFeePerGas")) {
                    var error = $root.protocol.UnBigInt.verify(message.baseFeePerGas);
                    if (error)
                        return "baseFeePerGas." + error;
                }
                if (message.extraData != null && message.hasOwnProperty("extraData"))
                    if (!(message.extraData && typeof message.extraData.length === "number" || $util.isString(message.extraData)))
                        return "extraData: buffer expected";
                if (message.extraDataStr != null && message.hasOwnProperty("extraDataStr"))
                    if (!$util.isString(message.extraDataStr))
                        return "extraDataStr: string expected";
                if (message.proposedOn != null && message.hasOwnProperty("proposedOn")) {
                    var error = $root.protocol.ProposedOn.verify(message.proposedOn);
                    if (error)
                        return "proposedOn." + error;
                }
                if (message.contractInternalTransactionsCount != null && message.hasOwnProperty("contractInternalTransactionsCount"))
                    if (!$util.isInteger(message.contractInternalTransactionsCount) && !(message.contractInternalTransactionsCount && $util.isInteger(message.contractInternalTransactionsCount.low) && $util.isInteger(message.contractInternalTransactionsCount.high)))
                        return "contractInternalTransactionsCount: integer|Long expected";
                if (message.feeRecipient != null && message.hasOwnProperty("feeRecipient")) {
                    var error = $root.protocol.EthFeeRecipient.verify(message.feeRecipient);
                    if (error)
                        return "feeRecipient." + error;
                }
                if (message.blockReward != null && message.hasOwnProperty("blockReward")) {
                    var error = $root.protocol.EthBlockReward.verify(message.blockReward);
                    if (error)
                        return "blockReward." + error;
                }
                if (message.totalDifficulty != null && message.hasOwnProperty("totalDifficulty")) {
                    var error = $root.protocol.UnBigInt.verify(message.totalDifficulty);
                    if (error)
                        return "totalDifficulty." + error;
                }
                return null;
            };
    
            /**
             * Creates an EthSpecificBlockInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EthSpecificBlockInfo} EthSpecificBlockInfo
             */
            EthSpecificBlockInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EthSpecificBlockInfo)
                    return object;
                var message = new $root.protocol.EthSpecificBlockInfo();
                if (object.withdrawals) {
                    if (!Array.isArray(object.withdrawals))
                        throw TypeError(".protocol.EthSpecificBlockInfo.withdrawals: array expected");
                    message.withdrawals = [];
                    for (var i = 0; i < object.withdrawals.length; ++i) {
                        if (typeof object.withdrawals[i] !== "object")
                            throw TypeError(".protocol.EthSpecificBlockInfo.withdrawals: object expected");
                        message.withdrawals[i] = $root.protocol.EthWithdrawal.fromObject(object.withdrawals[i]);
                    }
                }
                if (object.gasUsed != null) {
                    if (typeof object.gasUsed !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.gasUsed: object expected");
                    message.gasUsed = $root.protocol.UnBigInt.fromObject(object.gasUsed);
                }
                if (object.gasLimit != null) {
                    if (typeof object.gasLimit !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.gasLimit: object expected");
                    message.gasLimit = $root.protocol.UnBigInt.fromObject(object.gasLimit);
                }
                if (object.percentageOfGasUsed != null)
                    message.percentageOfGasUsed = Number(object.percentageOfGasUsed);
                if (object.baseFeePerGas != null) {
                    if (typeof object.baseFeePerGas !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.baseFeePerGas: object expected");
                    message.baseFeePerGas = $root.protocol.UnBigInt.fromObject(object.baseFeePerGas);
                }
                if (object.extraData != null)
                    if (typeof object.extraData === "string")
                        $util.base64.decode(object.extraData, message.extraData = $util.newBuffer($util.base64.length(object.extraData)), 0);
                    else if (object.extraData.length >= 0)
                        message.extraData = object.extraData;
                if (object.extraDataStr != null)
                    message.extraDataStr = String(object.extraDataStr);
                if (object.proposedOn != null) {
                    if (typeof object.proposedOn !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.proposedOn: object expected");
                    message.proposedOn = $root.protocol.ProposedOn.fromObject(object.proposedOn);
                }
                if (object.contractInternalTransactionsCount != null)
                    if ($util.Long)
                        (message.contractInternalTransactionsCount = $util.Long.fromValue(object.contractInternalTransactionsCount)).unsigned = false;
                    else if (typeof object.contractInternalTransactionsCount === "string")
                        message.contractInternalTransactionsCount = parseInt(object.contractInternalTransactionsCount, 10);
                    else if (typeof object.contractInternalTransactionsCount === "number")
                        message.contractInternalTransactionsCount = object.contractInternalTransactionsCount;
                    else if (typeof object.contractInternalTransactionsCount === "object")
                        message.contractInternalTransactionsCount = new $util.LongBits(object.contractInternalTransactionsCount.low >>> 0, object.contractInternalTransactionsCount.high >>> 0).toNumber();
                if (object.feeRecipient != null) {
                    if (typeof object.feeRecipient !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.feeRecipient: object expected");
                    message.feeRecipient = $root.protocol.EthFeeRecipient.fromObject(object.feeRecipient);
                }
                if (object.blockReward != null) {
                    if (typeof object.blockReward !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.blockReward: object expected");
                    message.blockReward = $root.protocol.EthBlockReward.fromObject(object.blockReward);
                }
                if (object.totalDifficulty != null) {
                    if (typeof object.totalDifficulty !== "object")
                        throw TypeError(".protocol.EthSpecificBlockInfo.totalDifficulty: object expected");
                    message.totalDifficulty = $root.protocol.UnBigInt.fromObject(object.totalDifficulty);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an EthSpecificBlockInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {protocol.EthSpecificBlockInfo} message EthSpecificBlockInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EthSpecificBlockInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.withdrawals = [];
                if (options.defaults) {
                    object.proposedOn = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.contractInternalTransactionsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.contractInternalTransactionsCount = options.longs === String ? "0" : 0;
                    object.feeRecipient = null;
                    object.blockReward = null;
                    object.totalDifficulty = null;
                    object.gasUsed = null;
                    object.gasLimit = null;
                    object.percentageOfGasUsed = 0;
                    object.baseFeePerGas = null;
                    if (options.bytes === String)
                        object.extraData = "";
                    else {
                        object.extraData = [];
                        if (options.bytes !== Array)
                            object.extraData = $util.newBuffer(object.extraData);
                    }
                    object.extraDataStr = "";
                }
                if (message.proposedOn != null && message.hasOwnProperty("proposedOn"))
                    object.proposedOn = $root.protocol.ProposedOn.toObject(message.proposedOn, options);
                if (message.contractInternalTransactionsCount != null && message.hasOwnProperty("contractInternalTransactionsCount"))
                    if (typeof message.contractInternalTransactionsCount === "number")
                        object.contractInternalTransactionsCount = options.longs === String ? String(message.contractInternalTransactionsCount) : message.contractInternalTransactionsCount;
                    else
                        object.contractInternalTransactionsCount = options.longs === String ? $util.Long.prototype.toString.call(message.contractInternalTransactionsCount) : options.longs === Number ? new $util.LongBits(message.contractInternalTransactionsCount.low >>> 0, message.contractInternalTransactionsCount.high >>> 0).toNumber() : message.contractInternalTransactionsCount;
                if (message.withdrawals && message.withdrawals.length) {
                    object.withdrawals = [];
                    for (var j = 0; j < message.withdrawals.length; ++j)
                        object.withdrawals[j] = $root.protocol.EthWithdrawal.toObject(message.withdrawals[j], options);
                }
                if (message.feeRecipient != null && message.hasOwnProperty("feeRecipient"))
                    object.feeRecipient = $root.protocol.EthFeeRecipient.toObject(message.feeRecipient, options);
                if (message.blockReward != null && message.hasOwnProperty("blockReward"))
                    object.blockReward = $root.protocol.EthBlockReward.toObject(message.blockReward, options);
                if (message.totalDifficulty != null && message.hasOwnProperty("totalDifficulty"))
                    object.totalDifficulty = $root.protocol.UnBigInt.toObject(message.totalDifficulty, options);
                if (message.gasUsed != null && message.hasOwnProperty("gasUsed"))
                    object.gasUsed = $root.protocol.UnBigInt.toObject(message.gasUsed, options);
                if (message.gasLimit != null && message.hasOwnProperty("gasLimit"))
                    object.gasLimit = $root.protocol.UnBigInt.toObject(message.gasLimit, options);
                if (message.percentageOfGasUsed != null && message.hasOwnProperty("percentageOfGasUsed"))
                    object.percentageOfGasUsed = options.json && !isFinite(message.percentageOfGasUsed) ? String(message.percentageOfGasUsed) : message.percentageOfGasUsed;
                if (message.baseFeePerGas != null && message.hasOwnProperty("baseFeePerGas"))
                    object.baseFeePerGas = $root.protocol.UnBigInt.toObject(message.baseFeePerGas, options);
                if (message.extraData != null && message.hasOwnProperty("extraData"))
                    object.extraData = options.bytes === String ? $util.base64.encode(message.extraData, 0, message.extraData.length) : options.bytes === Array ? Array.prototype.slice.call(message.extraData) : message.extraData;
                if (message.extraDataStr != null && message.hasOwnProperty("extraDataStr"))
                    object.extraDataStr = message.extraDataStr;
                return object;
            };
    
            /**
             * Converts this EthSpecificBlockInfo to JSON.
             * @function toJSON
             * @memberof protocol.EthSpecificBlockInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EthSpecificBlockInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EthSpecificBlockInfo
             * @function getTypeUrl
             * @memberof protocol.EthSpecificBlockInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EthSpecificBlockInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.EthSpecificBlockInfo";
            };
    
            return EthSpecificBlockInfo;
        })();
    
        protocol.UnifiedTransaction = (function() {
    
            /**
             * Properties of an UnifiedTransaction.
             * @memberof protocol
             * @interface IUnifiedTransaction
             * @property {protocol.Chain|null} [chain] UnifiedTransaction chain
             * @property {protocol.CryptoNetwork|null} [network] UnifiedTransaction network
             * @property {protocol.IUniValue|null} [fee] UnifiedTransaction fee
             * @property {Array.<protocol.IRelatedTxBalanceChanges>|null} [balanceChanges] UnifiedTransaction balanceChanges
             * @property {string|null} [hash] UnifiedTransaction hash
             * @property {Long|null} [time] UnifiedTransaction time
             * @property {Long|null} [blockHeight] UnifiedTransaction blockHeight
             * @property {protocol.IChainSpecificInfoForTransaction|null} [chainSpecific] UnifiedTransaction chainSpecific
             */
    
            /**
             * Constructs a new UnifiedTransaction.
             * @memberof protocol
             * @classdesc Represents an UnifiedTransaction.
             * @implements IUnifiedTransaction
             * @constructor
             * @param {protocol.IUnifiedTransaction=} [properties] Properties to set
             */
            function UnifiedTransaction(properties) {
                this.balanceChanges = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * UnifiedTransaction chain.
             * @member {protocol.Chain} chain
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.chain = 0;
    
            /**
             * UnifiedTransaction network.
             * @member {protocol.CryptoNetwork} network
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.network = 0;
    
            /**
             * UnifiedTransaction fee.
             * @member {protocol.IUniValue|null|undefined} fee
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.fee = null;
    
            /**
             * UnifiedTransaction balanceChanges.
             * @member {Array.<protocol.IRelatedTxBalanceChanges>} balanceChanges
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.balanceChanges = $util.emptyArray;
    
            /**
             * UnifiedTransaction hash.
             * @member {string} hash
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.hash = "";
    
            /**
             * UnifiedTransaction time.
             * @member {Long} time
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * UnifiedTransaction blockHeight.
             * @member {Long} blockHeight
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.blockHeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * UnifiedTransaction chainSpecific.
             * @member {protocol.IChainSpecificInfoForTransaction|null|undefined} chainSpecific
             * @memberof protocol.UnifiedTransaction
             * @instance
             */
            UnifiedTransaction.prototype.chainSpecific = null;
    
            /**
             * Creates a new UnifiedTransaction instance using the specified properties.
             * @function create
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {protocol.IUnifiedTransaction=} [properties] Properties to set
             * @returns {protocol.UnifiedTransaction} UnifiedTransaction instance
             */
            UnifiedTransaction.create = function create(properties) {
                return new UnifiedTransaction(properties);
            };
    
            /**
             * Encodes the specified UnifiedTransaction message. Does not implicitly {@link protocol.UnifiedTransaction.verify|verify} messages.
             * @function encode
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {protocol.IUnifiedTransaction} message UnifiedTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnifiedTransaction.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.chain);
                if (message.network != null && Object.hasOwnProperty.call(message, "network"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.network);
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    $root.protocol.UniValue.encode(message.fee, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.balanceChanges != null && message.balanceChanges.length)
                    for (var i = 0; i < message.balanceChanges.length; ++i)
                        $root.protocol.RelatedTxBalanceChanges.encode(message.balanceChanges[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.time);
                if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.hash);
                if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.blockHeight);
                if (message.chainSpecific != null && Object.hasOwnProperty.call(message, "chainSpecific"))
                    $root.protocol.ChainSpecificInfoForTransaction.encode(message.chainSpecific, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified UnifiedTransaction message, length delimited. Does not implicitly {@link protocol.UnifiedTransaction.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {protocol.IUnifiedTransaction} message UnifiedTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnifiedTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UnifiedTransaction message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.UnifiedTransaction} UnifiedTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnifiedTransaction.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.UnifiedTransaction();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chain = reader.int32();
                            break;
                        }
                    case 2: {
                            message.network = reader.int32();
                            break;
                        }
                    case 4: {
                            message.fee = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            if (!(message.balanceChanges && message.balanceChanges.length))
                                message.balanceChanges = [];
                            message.balanceChanges.push($root.protocol.RelatedTxBalanceChanges.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            message.hash = reader.string();
                            break;
                        }
                    case 8: {
                            message.time = reader.int64();
                            break;
                        }
                    case 10: {
                            message.blockHeight = reader.int64();
                            break;
                        }
                    case 13: {
                            message.chainSpecific = $root.protocol.ChainSpecificInfoForTransaction.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UnifiedTransaction message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.UnifiedTransaction} UnifiedTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnifiedTransaction.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UnifiedTransaction message.
             * @function verify
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnifiedTransaction.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chain != null && message.hasOwnProperty("chain"))
                    switch (message.chain) {
                    default:
                        return "chain: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.network != null && message.hasOwnProperty("network"))
                    switch (message.network) {
                    default:
                        return "network: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.fee != null && message.hasOwnProperty("fee")) {
                    var error = $root.protocol.UniValue.verify(message.fee);
                    if (error)
                        return "fee." + error;
                }
                if (message.balanceChanges != null && message.hasOwnProperty("balanceChanges")) {
                    if (!Array.isArray(message.balanceChanges))
                        return "balanceChanges: array expected";
                    for (var i = 0; i < message.balanceChanges.length; ++i) {
                        var error = $root.protocol.RelatedTxBalanceChanges.verify(message.balanceChanges[i]);
                        if (error)
                            return "balanceChanges." + error;
                    }
                }
                if (message.hash != null && message.hasOwnProperty("hash"))
                    if (!$util.isString(message.hash))
                        return "hash: string expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                    if (!$util.isInteger(message.blockHeight) && !(message.blockHeight && $util.isInteger(message.blockHeight.low) && $util.isInteger(message.blockHeight.high)))
                        return "blockHeight: integer|Long expected";
                if (message.chainSpecific != null && message.hasOwnProperty("chainSpecific")) {
                    var error = $root.protocol.ChainSpecificInfoForTransaction.verify(message.chainSpecific);
                    if (error)
                        return "chainSpecific." + error;
                }
                return null;
            };
    
            /**
             * Creates an UnifiedTransaction message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.UnifiedTransaction} UnifiedTransaction
             */
            UnifiedTransaction.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.UnifiedTransaction)
                    return object;
                var message = new $root.protocol.UnifiedTransaction();
                switch (object.chain) {
                default:
                    if (typeof object.chain === "number") {
                        message.chain = object.chain;
                        break;
                    }
                    break;
                case "BTC":
                case 0:
                    message.chain = 0;
                    break;
                case "ETH":
                case 1:
                    message.chain = 1;
                    break;
                case "TRX":
                case 2:
                    message.chain = 2;
                    break;
                }
                switch (object.network) {
                default:
                    if (typeof object.network === "number") {
                        message.network = object.network;
                        break;
                    }
                    break;
                case "MAINNET":
                case 0:
                    message.network = 0;
                    break;
                case "TESTNET":
                case 1:
                    message.network = 1;
                    break;
                }
                if (object.fee != null) {
                    if (typeof object.fee !== "object")
                        throw TypeError(".protocol.UnifiedTransaction.fee: object expected");
                    message.fee = $root.protocol.UniValue.fromObject(object.fee);
                }
                if (object.balanceChanges) {
                    if (!Array.isArray(object.balanceChanges))
                        throw TypeError(".protocol.UnifiedTransaction.balanceChanges: array expected");
                    message.balanceChanges = [];
                    for (var i = 0; i < object.balanceChanges.length; ++i) {
                        if (typeof object.balanceChanges[i] !== "object")
                            throw TypeError(".protocol.UnifiedTransaction.balanceChanges: object expected");
                        message.balanceChanges[i] = $root.protocol.RelatedTxBalanceChanges.fromObject(object.balanceChanges[i]);
                    }
                }
                if (object.hash != null)
                    message.hash = String(object.hash);
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                if (object.blockHeight != null)
                    if ($util.Long)
                        (message.blockHeight = $util.Long.fromValue(object.blockHeight)).unsigned = false;
                    else if (typeof object.blockHeight === "string")
                        message.blockHeight = parseInt(object.blockHeight, 10);
                    else if (typeof object.blockHeight === "number")
                        message.blockHeight = object.blockHeight;
                    else if (typeof object.blockHeight === "object")
                        message.blockHeight = new $util.LongBits(object.blockHeight.low >>> 0, object.blockHeight.high >>> 0).toNumber();
                if (object.chainSpecific != null) {
                    if (typeof object.chainSpecific !== "object")
                        throw TypeError(".protocol.UnifiedTransaction.chainSpecific: object expected");
                    message.chainSpecific = $root.protocol.ChainSpecificInfoForTransaction.fromObject(object.chainSpecific);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an UnifiedTransaction message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {protocol.UnifiedTransaction} message UnifiedTransaction
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnifiedTransaction.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.balanceChanges = [];
                if (options.defaults) {
                    object.chain = options.enums === String ? "BTC" : 0;
                    object.network = options.enums === String ? "MAINNET" : 0;
                    object.fee = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                    object.hash = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.blockHeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockHeight = options.longs === String ? "0" : 0;
                    object.chainSpecific = null;
                }
                if (message.chain != null && message.hasOwnProperty("chain"))
                    object.chain = options.enums === String ? $root.protocol.Chain[message.chain] === undefined ? message.chain : $root.protocol.Chain[message.chain] : message.chain;
                if (message.network != null && message.hasOwnProperty("network"))
                    object.network = options.enums === String ? $root.protocol.CryptoNetwork[message.network] === undefined ? message.network : $root.protocol.CryptoNetwork[message.network] : message.network;
                if (message.fee != null && message.hasOwnProperty("fee"))
                    object.fee = $root.protocol.UniValue.toObject(message.fee, options);
                if (message.balanceChanges && message.balanceChanges.length) {
                    object.balanceChanges = [];
                    for (var j = 0; j < message.balanceChanges.length; ++j)
                        object.balanceChanges[j] = $root.protocol.RelatedTxBalanceChanges.toObject(message.balanceChanges[j], options);
                }
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                if (message.hash != null && message.hasOwnProperty("hash"))
                    object.hash = message.hash;
                if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                    if (typeof message.blockHeight === "number")
                        object.blockHeight = options.longs === String ? String(message.blockHeight) : message.blockHeight;
                    else
                        object.blockHeight = options.longs === String ? $util.Long.prototype.toString.call(message.blockHeight) : options.longs === Number ? new $util.LongBits(message.blockHeight.low >>> 0, message.blockHeight.high >>> 0).toNumber() : message.blockHeight;
                if (message.chainSpecific != null && message.hasOwnProperty("chainSpecific"))
                    object.chainSpecific = $root.protocol.ChainSpecificInfoForTransaction.toObject(message.chainSpecific, options);
                return object;
            };
    
            /**
             * Converts this UnifiedTransaction to JSON.
             * @function toJSON
             * @memberof protocol.UnifiedTransaction
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnifiedTransaction.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for UnifiedTransaction
             * @function getTypeUrl
             * @memberof protocol.UnifiedTransaction
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UnifiedTransaction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.UnifiedTransaction";
            };
    
            return UnifiedTransaction;
        })();
    
        protocol.RelatedTxBalanceChanges = (function() {
    
            /**
             * Properties of a RelatedTxBalanceChanges.
             * @memberof protocol
             * @interface IRelatedTxBalanceChanges
             * @property {Array.<protocol.ITxBalanceChange>|null} [changes] RelatedTxBalanceChanges changes
             */
    
            /**
             * Constructs a new RelatedTxBalanceChanges.
             * @memberof protocol
             * @classdesc Represents a RelatedTxBalanceChanges.
             * @implements IRelatedTxBalanceChanges
             * @constructor
             * @param {protocol.IRelatedTxBalanceChanges=} [properties] Properties to set
             */
            function RelatedTxBalanceChanges(properties) {
                this.changes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * RelatedTxBalanceChanges changes.
             * @member {Array.<protocol.ITxBalanceChange>} changes
             * @memberof protocol.RelatedTxBalanceChanges
             * @instance
             */
            RelatedTxBalanceChanges.prototype.changes = $util.emptyArray;
    
            /**
             * Creates a new RelatedTxBalanceChanges instance using the specified properties.
             * @function create
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {protocol.IRelatedTxBalanceChanges=} [properties] Properties to set
             * @returns {protocol.RelatedTxBalanceChanges} RelatedTxBalanceChanges instance
             */
            RelatedTxBalanceChanges.create = function create(properties) {
                return new RelatedTxBalanceChanges(properties);
            };
    
            /**
             * Encodes the specified RelatedTxBalanceChanges message. Does not implicitly {@link protocol.RelatedTxBalanceChanges.verify|verify} messages.
             * @function encode
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {protocol.IRelatedTxBalanceChanges} message RelatedTxBalanceChanges message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RelatedTxBalanceChanges.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.changes != null && message.changes.length)
                    for (var i = 0; i < message.changes.length; ++i)
                        $root.protocol.TxBalanceChange.encode(message.changes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified RelatedTxBalanceChanges message, length delimited. Does not implicitly {@link protocol.RelatedTxBalanceChanges.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {protocol.IRelatedTxBalanceChanges} message RelatedTxBalanceChanges message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RelatedTxBalanceChanges.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a RelatedTxBalanceChanges message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.RelatedTxBalanceChanges} RelatedTxBalanceChanges
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RelatedTxBalanceChanges.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.RelatedTxBalanceChanges();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.changes && message.changes.length))
                                message.changes = [];
                            message.changes.push($root.protocol.TxBalanceChange.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a RelatedTxBalanceChanges message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.RelatedTxBalanceChanges} RelatedTxBalanceChanges
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RelatedTxBalanceChanges.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a RelatedTxBalanceChanges message.
             * @function verify
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RelatedTxBalanceChanges.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.changes != null && message.hasOwnProperty("changes")) {
                    if (!Array.isArray(message.changes))
                        return "changes: array expected";
                    for (var i = 0; i < message.changes.length; ++i) {
                        var error = $root.protocol.TxBalanceChange.verify(message.changes[i]);
                        if (error)
                            return "changes." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a RelatedTxBalanceChanges message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.RelatedTxBalanceChanges} RelatedTxBalanceChanges
             */
            RelatedTxBalanceChanges.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.RelatedTxBalanceChanges)
                    return object;
                var message = new $root.protocol.RelatedTxBalanceChanges();
                if (object.changes) {
                    if (!Array.isArray(object.changes))
                        throw TypeError(".protocol.RelatedTxBalanceChanges.changes: array expected");
                    message.changes = [];
                    for (var i = 0; i < object.changes.length; ++i) {
                        if (typeof object.changes[i] !== "object")
                            throw TypeError(".protocol.RelatedTxBalanceChanges.changes: object expected");
                        message.changes[i] = $root.protocol.TxBalanceChange.fromObject(object.changes[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a RelatedTxBalanceChanges message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {protocol.RelatedTxBalanceChanges} message RelatedTxBalanceChanges
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RelatedTxBalanceChanges.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.changes = [];
                if (message.changes && message.changes.length) {
                    object.changes = [];
                    for (var j = 0; j < message.changes.length; ++j)
                        object.changes[j] = $root.protocol.TxBalanceChange.toObject(message.changes[j], options);
                }
                return object;
            };
    
            /**
             * Converts this RelatedTxBalanceChanges to JSON.
             * @function toJSON
             * @memberof protocol.RelatedTxBalanceChanges
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RelatedTxBalanceChanges.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for RelatedTxBalanceChanges
             * @function getTypeUrl
             * @memberof protocol.RelatedTxBalanceChanges
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RelatedTxBalanceChanges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.RelatedTxBalanceChanges";
            };
    
            return RelatedTxBalanceChanges;
        })();
    
        protocol.TxBalanceChange = (function() {
    
            /**
             * Properties of a TxBalanceChange.
             * @memberof protocol
             * @interface ITxBalanceChange
             * @property {string|null} [address] TxBalanceChange address
             * @property {protocol.IUniValue|null} [change] TxBalanceChange change
             * @property {boolean|null} ["in"] TxBalanceChange in
             */
    
            /**
             * Constructs a new TxBalanceChange.
             * @memberof protocol
             * @classdesc Represents a TxBalanceChange.
             * @implements ITxBalanceChange
             * @constructor
             * @param {protocol.ITxBalanceChange=} [properties] Properties to set
             */
            function TxBalanceChange(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TxBalanceChange address.
             * @member {string} address
             * @memberof protocol.TxBalanceChange
             * @instance
             */
            TxBalanceChange.prototype.address = "";
    
            /**
             * TxBalanceChange change.
             * @member {protocol.IUniValue|null|undefined} change
             * @memberof protocol.TxBalanceChange
             * @instance
             */
            TxBalanceChange.prototype.change = null;
    
            /**
             * TxBalanceChange in.
             * @member {boolean} in
             * @memberof protocol.TxBalanceChange
             * @instance
             */
            TxBalanceChange.prototype["in"] = false;
    
            /**
             * Creates a new TxBalanceChange instance using the specified properties.
             * @function create
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {protocol.ITxBalanceChange=} [properties] Properties to set
             * @returns {protocol.TxBalanceChange} TxBalanceChange instance
             */
            TxBalanceChange.create = function create(properties) {
                return new TxBalanceChange(properties);
            };
    
            /**
             * Encodes the specified TxBalanceChange message. Does not implicitly {@link protocol.TxBalanceChange.verify|verify} messages.
             * @function encode
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {protocol.ITxBalanceChange} message TxBalanceChange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxBalanceChange.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                if (message.change != null && Object.hasOwnProperty.call(message, "change"))
                    $root.protocol.UniValue.encode(message.change, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message["in"] != null && Object.hasOwnProperty.call(message, "in"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message["in"]);
                return writer;
            };
    
            /**
             * Encodes the specified TxBalanceChange message, length delimited. Does not implicitly {@link protocol.TxBalanceChange.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {protocol.ITxBalanceChange} message TxBalanceChange message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TxBalanceChange.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TxBalanceChange message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TxBalanceChange} TxBalanceChange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxBalanceChange.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TxBalanceChange();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.string();
                            break;
                        }
                    case 2: {
                            message.change = $root.protocol.UniValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message["in"] = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TxBalanceChange message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TxBalanceChange} TxBalanceChange
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TxBalanceChange.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TxBalanceChange message.
             * @function verify
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TxBalanceChange.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.change != null && message.hasOwnProperty("change")) {
                    var error = $root.protocol.UniValue.verify(message.change);
                    if (error)
                        return "change." + error;
                }
                if (message["in"] != null && message.hasOwnProperty("in"))
                    if (typeof message["in"] !== "boolean")
                        return "in: boolean expected";
                return null;
            };
    
            /**
             * Creates a TxBalanceChange message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TxBalanceChange} TxBalanceChange
             */
            TxBalanceChange.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TxBalanceChange)
                    return object;
                var message = new $root.protocol.TxBalanceChange();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.change != null) {
                    if (typeof object.change !== "object")
                        throw TypeError(".protocol.TxBalanceChange.change: object expected");
                    message.change = $root.protocol.UniValue.fromObject(object.change);
                }
                if (object["in"] != null)
                    message["in"] = Boolean(object["in"]);
                return message;
            };
    
            /**
             * Creates a plain object from a TxBalanceChange message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {protocol.TxBalanceChange} message TxBalanceChange
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TxBalanceChange.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = "";
                    object.change = null;
                    object["in"] = false;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.change != null && message.hasOwnProperty("change"))
                    object.change = $root.protocol.UniValue.toObject(message.change, options);
                if (message["in"] != null && message.hasOwnProperty("in"))
                    object["in"] = message["in"];
                return object;
            };
    
            /**
             * Converts this TxBalanceChange to JSON.
             * @function toJSON
             * @memberof protocol.TxBalanceChange
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TxBalanceChange.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TxBalanceChange
             * @function getTypeUrl
             * @memberof protocol.TxBalanceChange
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TxBalanceChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TxBalanceChange";
            };
    
            return TxBalanceChange;
        })();
    
        protocol.ChainSpecificInfoForTransaction = (function() {
    
            /**
             * Properties of a ChainSpecificInfoForTransaction.
             * @memberof protocol
             * @interface IChainSpecificInfoForTransaction
             * @property {protocol.IBtcSpecificTransactionInfo|null} [btcSpecific] ChainSpecificInfoForTransaction btcSpecific
             * @property {protocol.IEthSpecificTransactionInfo|null} [ethSpecific] ChainSpecificInfoForTransaction ethSpecific
             * @property {protocol.ITronSpecificTransactionInfo|null} [tronSpecific] ChainSpecificInfoForTransaction tronSpecific
             */
    
            /**
             * Constructs a new ChainSpecificInfoForTransaction.
             * @memberof protocol
             * @classdesc Represents a ChainSpecificInfoForTransaction.
             * @implements IChainSpecificInfoForTransaction
             * @constructor
             * @param {protocol.IChainSpecificInfoForTransaction=} [properties] Properties to set
             */
            function ChainSpecificInfoForTransaction(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ChainSpecificInfoForTransaction btcSpecific.
             * @member {protocol.IBtcSpecificTransactionInfo|null|undefined} btcSpecific
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @instance
             */
            ChainSpecificInfoForTransaction.prototype.btcSpecific = null;
    
            /**
             * ChainSpecificInfoForTransaction ethSpecific.
             * @member {protocol.IEthSpecificTransactionInfo|null|undefined} ethSpecific
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @instance
             */
            ChainSpecificInfoForTransaction.prototype.ethSpecific = null;
    
            /**
             * ChainSpecificInfoForTransaction tronSpecific.
             * @member {protocol.ITronSpecificTransactionInfo|null|undefined} tronSpecific
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @instance
             */
            ChainSpecificInfoForTransaction.prototype.tronSpecific = null;
    
            /**
             * Creates a new ChainSpecificInfoForTransaction instance using the specified properties.
             * @function create
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {protocol.IChainSpecificInfoForTransaction=} [properties] Properties to set
             * @returns {protocol.ChainSpecificInfoForTransaction} ChainSpecificInfoForTransaction instance
             */
            ChainSpecificInfoForTransaction.create = function create(properties) {
                return new ChainSpecificInfoForTransaction(properties);
            };
    
            /**
             * Encodes the specified ChainSpecificInfoForTransaction message. Does not implicitly {@link protocol.ChainSpecificInfoForTransaction.verify|verify} messages.
             * @function encode
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {protocol.IChainSpecificInfoForTransaction} message ChainSpecificInfoForTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChainSpecificInfoForTransaction.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.btcSpecific != null && Object.hasOwnProperty.call(message, "btcSpecific"))
                    $root.protocol.BtcSpecificTransactionInfo.encode(message.btcSpecific, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.ethSpecific != null && Object.hasOwnProperty.call(message, "ethSpecific"))
                    $root.protocol.EthSpecificTransactionInfo.encode(message.ethSpecific, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.tronSpecific != null && Object.hasOwnProperty.call(message, "tronSpecific"))
                    $root.protocol.TronSpecificTransactionInfo.encode(message.tronSpecific, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ChainSpecificInfoForTransaction message, length delimited. Does not implicitly {@link protocol.ChainSpecificInfoForTransaction.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {protocol.IChainSpecificInfoForTransaction} message ChainSpecificInfoForTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChainSpecificInfoForTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ChainSpecificInfoForTransaction message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ChainSpecificInfoForTransaction} ChainSpecificInfoForTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChainSpecificInfoForTransaction.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ChainSpecificInfoForTransaction();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.btcSpecific = $root.protocol.BtcSpecificTransactionInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.ethSpecific = $root.protocol.EthSpecificTransactionInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.tronSpecific = $root.protocol.TronSpecificTransactionInfo.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ChainSpecificInfoForTransaction message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ChainSpecificInfoForTransaction} ChainSpecificInfoForTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChainSpecificInfoForTransaction.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ChainSpecificInfoForTransaction message.
             * @function verify
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChainSpecificInfoForTransaction.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.btcSpecific != null && message.hasOwnProperty("btcSpecific")) {
                    var error = $root.protocol.BtcSpecificTransactionInfo.verify(message.btcSpecific);
                    if (error)
                        return "btcSpecific." + error;
                }
                if (message.ethSpecific != null && message.hasOwnProperty("ethSpecific")) {
                    var error = $root.protocol.EthSpecificTransactionInfo.verify(message.ethSpecific);
                    if (error)
                        return "ethSpecific." + error;
                }
                if (message.tronSpecific != null && message.hasOwnProperty("tronSpecific")) {
                    var error = $root.protocol.TronSpecificTransactionInfo.verify(message.tronSpecific);
                    if (error)
                        return "tronSpecific." + error;
                }
                return null;
            };
    
            /**
             * Creates a ChainSpecificInfoForTransaction message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ChainSpecificInfoForTransaction} ChainSpecificInfoForTransaction
             */
            ChainSpecificInfoForTransaction.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ChainSpecificInfoForTransaction)
                    return object;
                var message = new $root.protocol.ChainSpecificInfoForTransaction();
                if (object.btcSpecific != null) {
                    if (typeof object.btcSpecific !== "object")
                        throw TypeError(".protocol.ChainSpecificInfoForTransaction.btcSpecific: object expected");
                    message.btcSpecific = $root.protocol.BtcSpecificTransactionInfo.fromObject(object.btcSpecific);
                }
                if (object.ethSpecific != null) {
                    if (typeof object.ethSpecific !== "object")
                        throw TypeError(".protocol.ChainSpecificInfoForTransaction.ethSpecific: object expected");
                    message.ethSpecific = $root.protocol.EthSpecificTransactionInfo.fromObject(object.ethSpecific);
                }
                if (object.tronSpecific != null) {
                    if (typeof object.tronSpecific !== "object")
                        throw TypeError(".protocol.ChainSpecificInfoForTransaction.tronSpecific: object expected");
                    message.tronSpecific = $root.protocol.TronSpecificTransactionInfo.fromObject(object.tronSpecific);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ChainSpecificInfoForTransaction message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {protocol.ChainSpecificInfoForTransaction} message ChainSpecificInfoForTransaction
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChainSpecificInfoForTransaction.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.btcSpecific = null;
                    object.ethSpecific = null;
                    object.tronSpecific = null;
                }
                if (message.btcSpecific != null && message.hasOwnProperty("btcSpecific"))
                    object.btcSpecific = $root.protocol.BtcSpecificTransactionInfo.toObject(message.btcSpecific, options);
                if (message.ethSpecific != null && message.hasOwnProperty("ethSpecific"))
                    object.ethSpecific = $root.protocol.EthSpecificTransactionInfo.toObject(message.ethSpecific, options);
                if (message.tronSpecific != null && message.hasOwnProperty("tronSpecific"))
                    object.tronSpecific = $root.protocol.TronSpecificTransactionInfo.toObject(message.tronSpecific, options);
                return object;
            };
    
            /**
             * Converts this ChainSpecificInfoForTransaction to JSON.
             * @function toJSON
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChainSpecificInfoForTransaction.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ChainSpecificInfoForTransaction
             * @function getTypeUrl
             * @memberof protocol.ChainSpecificInfoForTransaction
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChainSpecificInfoForTransaction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.ChainSpecificInfoForTransaction";
            };
    
            return ChainSpecificInfoForTransaction;
        })();
    
        protocol.TronSpecificTransactionInfo = (function() {
    
            /**
             * Properties of a TronSpecificTransactionInfo.
             * @memberof protocol
             * @interface ITronSpecificTransactionInfo
             * @property {protocol.ITronResourceReceipt|null} [resourceUsage] TronSpecificTransactionInfo resourceUsage
             */
    
            /**
             * Constructs a new TronSpecificTransactionInfo.
             * @memberof protocol
             * @classdesc Represents a TronSpecificTransactionInfo.
             * @implements ITronSpecificTransactionInfo
             * @constructor
             * @param {protocol.ITronSpecificTransactionInfo=} [properties] Properties to set
             */
            function TronSpecificTransactionInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronSpecificTransactionInfo resourceUsage.
             * @member {protocol.ITronResourceReceipt|null|undefined} resourceUsage
             * @memberof protocol.TronSpecificTransactionInfo
             * @instance
             */
            TronSpecificTransactionInfo.prototype.resourceUsage = null;
    
            /**
             * Creates a new TronSpecificTransactionInfo instance using the specified properties.
             * @function create
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {protocol.ITronSpecificTransactionInfo=} [properties] Properties to set
             * @returns {protocol.TronSpecificTransactionInfo} TronSpecificTransactionInfo instance
             */
            TronSpecificTransactionInfo.create = function create(properties) {
                return new TronSpecificTransactionInfo(properties);
            };
    
            /**
             * Encodes the specified TronSpecificTransactionInfo message. Does not implicitly {@link protocol.TronSpecificTransactionInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {protocol.ITronSpecificTransactionInfo} message TronSpecificTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronSpecificTransactionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.resourceUsage != null && Object.hasOwnProperty.call(message, "resourceUsage"))
                    $root.protocol.TronResourceReceipt.encode(message.resourceUsage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronSpecificTransactionInfo message, length delimited. Does not implicitly {@link protocol.TronSpecificTransactionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {protocol.ITronSpecificTransactionInfo} message TronSpecificTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronSpecificTransactionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronSpecificTransactionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronSpecificTransactionInfo} TronSpecificTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronSpecificTransactionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronSpecificTransactionInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.resourceUsage = $root.protocol.TronResourceReceipt.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronSpecificTransactionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronSpecificTransactionInfo} TronSpecificTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronSpecificTransactionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronSpecificTransactionInfo message.
             * @function verify
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronSpecificTransactionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.resourceUsage != null && message.hasOwnProperty("resourceUsage")) {
                    var error = $root.protocol.TronResourceReceipt.verify(message.resourceUsage);
                    if (error)
                        return "resourceUsage." + error;
                }
                return null;
            };
    
            /**
             * Creates a TronSpecificTransactionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronSpecificTransactionInfo} TronSpecificTransactionInfo
             */
            TronSpecificTransactionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronSpecificTransactionInfo)
                    return object;
                var message = new $root.protocol.TronSpecificTransactionInfo();
                if (object.resourceUsage != null) {
                    if (typeof object.resourceUsage !== "object")
                        throw TypeError(".protocol.TronSpecificTransactionInfo.resourceUsage: object expected");
                    message.resourceUsage = $root.protocol.TronResourceReceipt.fromObject(object.resourceUsage);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronSpecificTransactionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {protocol.TronSpecificTransactionInfo} message TronSpecificTransactionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronSpecificTransactionInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.resourceUsage = null;
                if (message.resourceUsage != null && message.hasOwnProperty("resourceUsage"))
                    object.resourceUsage = $root.protocol.TronResourceReceipt.toObject(message.resourceUsage, options);
                return object;
            };
    
            /**
             * Converts this TronSpecificTransactionInfo to JSON.
             * @function toJSON
             * @memberof protocol.TronSpecificTransactionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronSpecificTransactionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronSpecificTransactionInfo
             * @function getTypeUrl
             * @memberof protocol.TronSpecificTransactionInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronSpecificTransactionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronSpecificTransactionInfo";
            };
    
            return TronSpecificTransactionInfo;
        })();
    
        protocol.BtcSpecificTransactionInfo = (function() {
    
            /**
             * Properties of a BtcSpecificTransactionInfo.
             * @memberof protocol
             * @interface IBtcSpecificTransactionInfo
             */
    
            /**
             * Constructs a new BtcSpecificTransactionInfo.
             * @memberof protocol
             * @classdesc Represents a BtcSpecificTransactionInfo.
             * @implements IBtcSpecificTransactionInfo
             * @constructor
             * @param {protocol.IBtcSpecificTransactionInfo=} [properties] Properties to set
             */
            function BtcSpecificTransactionInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new BtcSpecificTransactionInfo instance using the specified properties.
             * @function create
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {protocol.IBtcSpecificTransactionInfo=} [properties] Properties to set
             * @returns {protocol.BtcSpecificTransactionInfo} BtcSpecificTransactionInfo instance
             */
            BtcSpecificTransactionInfo.create = function create(properties) {
                return new BtcSpecificTransactionInfo(properties);
            };
    
            /**
             * Encodes the specified BtcSpecificTransactionInfo message. Does not implicitly {@link protocol.BtcSpecificTransactionInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {protocol.IBtcSpecificTransactionInfo} message BtcSpecificTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BtcSpecificTransactionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified BtcSpecificTransactionInfo message, length delimited. Does not implicitly {@link protocol.BtcSpecificTransactionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {protocol.IBtcSpecificTransactionInfo} message BtcSpecificTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BtcSpecificTransactionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BtcSpecificTransactionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.BtcSpecificTransactionInfo} BtcSpecificTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BtcSpecificTransactionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.BtcSpecificTransactionInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BtcSpecificTransactionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.BtcSpecificTransactionInfo} BtcSpecificTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BtcSpecificTransactionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BtcSpecificTransactionInfo message.
             * @function verify
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BtcSpecificTransactionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a BtcSpecificTransactionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.BtcSpecificTransactionInfo} BtcSpecificTransactionInfo
             */
            BtcSpecificTransactionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.BtcSpecificTransactionInfo)
                    return object;
                return new $root.protocol.BtcSpecificTransactionInfo();
            };
    
            /**
             * Creates a plain object from a BtcSpecificTransactionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {protocol.BtcSpecificTransactionInfo} message BtcSpecificTransactionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BtcSpecificTransactionInfo.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this BtcSpecificTransactionInfo to JSON.
             * @function toJSON
             * @memberof protocol.BtcSpecificTransactionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BtcSpecificTransactionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BtcSpecificTransactionInfo
             * @function getTypeUrl
             * @memberof protocol.BtcSpecificTransactionInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BtcSpecificTransactionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.BtcSpecificTransactionInfo";
            };
    
            return BtcSpecificTransactionInfo;
        })();
    
        protocol.EthSpecificTransactionInfo = (function() {
    
            /**
             * Properties of an EthSpecificTransactionInfo.
             * @memberof protocol
             * @interface IEthSpecificTransactionInfo
             */
    
            /**
             * Constructs a new EthSpecificTransactionInfo.
             * @memberof protocol
             * @classdesc Represents an EthSpecificTransactionInfo.
             * @implements IEthSpecificTransactionInfo
             * @constructor
             * @param {protocol.IEthSpecificTransactionInfo=} [properties] Properties to set
             */
            function EthSpecificTransactionInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new EthSpecificTransactionInfo instance using the specified properties.
             * @function create
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {protocol.IEthSpecificTransactionInfo=} [properties] Properties to set
             * @returns {protocol.EthSpecificTransactionInfo} EthSpecificTransactionInfo instance
             */
            EthSpecificTransactionInfo.create = function create(properties) {
                return new EthSpecificTransactionInfo(properties);
            };
    
            /**
             * Encodes the specified EthSpecificTransactionInfo message. Does not implicitly {@link protocol.EthSpecificTransactionInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {protocol.IEthSpecificTransactionInfo} message EthSpecificTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthSpecificTransactionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified EthSpecificTransactionInfo message, length delimited. Does not implicitly {@link protocol.EthSpecificTransactionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {protocol.IEthSpecificTransactionInfo} message EthSpecificTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EthSpecificTransactionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EthSpecificTransactionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.EthSpecificTransactionInfo} EthSpecificTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthSpecificTransactionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.EthSpecificTransactionInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EthSpecificTransactionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.EthSpecificTransactionInfo} EthSpecificTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EthSpecificTransactionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EthSpecificTransactionInfo message.
             * @function verify
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EthSpecificTransactionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an EthSpecificTransactionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.EthSpecificTransactionInfo} EthSpecificTransactionInfo
             */
            EthSpecificTransactionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.EthSpecificTransactionInfo)
                    return object;
                return new $root.protocol.EthSpecificTransactionInfo();
            };
    
            /**
             * Creates a plain object from an EthSpecificTransactionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {protocol.EthSpecificTransactionInfo} message EthSpecificTransactionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EthSpecificTransactionInfo.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this EthSpecificTransactionInfo to JSON.
             * @function toJSON
             * @memberof protocol.EthSpecificTransactionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EthSpecificTransactionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EthSpecificTransactionInfo
             * @function getTypeUrl
             * @memberof protocol.EthSpecificTransactionInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EthSpecificTransactionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.EthSpecificTransactionInfo";
            };
    
            return EthSpecificTransactionInfo;
        })();
    
        /**
         * WLCurrency enum.
         * @name protocol.WLCurrency
         * @enum {number}
         * @property {number} WLC_FUN=0 WLC_FUN value
         * @property {number} WLC_ALL=8 WLC_ALL value
         * @property {number} WLC_DZD=12 WLC_DZD value
         * @property {number} WLC_ARS=32 WLC_ARS value
         * @property {number} WLC_AUD=36 WLC_AUD value
         * @property {number} WLC_BSD=44 WLC_BSD value
         * @property {number} WLC_BHD=48 WLC_BHD value
         * @property {number} WLC_BDT=50 WLC_BDT value
         * @property {number} WLC_AMD=51 WLC_AMD value
         * @property {number} WLC_BBD=52 WLC_BBD value
         * @property {number} WLC_BMD=60 WLC_BMD value
         * @property {number} WLC_BTN=64 WLC_BTN value
         * @property {number} WLC_BOB=68 WLC_BOB value
         * @property {number} WLC_BWP=72 WLC_BWP value
         * @property {number} WLC_BZD=84 WLC_BZD value
         * @property {number} WLC_SBD=90 WLC_SBD value
         * @property {number} WLC_BND=96 WLC_BND value
         * @property {number} WLC_MMK=104 WLC_MMK value
         * @property {number} WLC_BIF=108 WLC_BIF value
         * @property {number} WLC_KHR=116 WLC_KHR value
         * @property {number} WLC_CAD=124 WLC_CAD value
         * @property {number} WLC_CVE=132 WLC_CVE value
         * @property {number} WLC_KYD=136 WLC_KYD value
         * @property {number} WLC_LKR=144 WLC_LKR value
         * @property {number} WLC_CLP=152 WLC_CLP value
         * @property {number} WLC_CNY=156 WLC_CNY value
         * @property {number} WLC_COP=170 WLC_COP value
         * @property {number} WLC_KMF=174 WLC_KMF value
         * @property {number} WLC_CRC=188 WLC_CRC value
         * @property {number} WLC_HRK=191 WLC_HRK value
         * @property {number} WLC_CUP=192 WLC_CUP value
         * @property {number} WLC_CYP=196 WLC_CYP value
         * @property {number} WLC_CZK=203 WLC_CZK value
         * @property {number} WLC_DKK=208 WLC_DKK value
         * @property {number} WLC_DOP=214 WLC_DOP value
         * @property {number} WLC_SVC=222 WLC_SVC value
         * @property {number} WLC_ETB=230 WLC_ETB value
         * @property {number} WLC_ERN=232 WLC_ERN value
         * @property {number} WLC_EEK=233 WLC_EEK value
         * @property {number} WLC_FKP=238 WLC_FKP value
         * @property {number} WLC_FJD=242 WLC_FJD value
         * @property {number} WLC_DJF=262 WLC_DJF value
         * @property {number} WLC_GMD=270 WLC_GMD value
         * @property {number} WLC_GIP=292 WLC_GIP value
         * @property {number} WLC_GTQ=320 WLC_GTQ value
         * @property {number} WLC_GNF=324 WLC_GNF value
         * @property {number} WLC_GYD=328 WLC_GYD value
         * @property {number} WLC_HTG=332 WLC_HTG value
         * @property {number} WLC_HNL=340 WLC_HNL value
         * @property {number} WLC_HKD=344 WLC_HKD value
         * @property {number} WLC_HUF=348 WLC_HUF value
         * @property {number} WLC_ISK=352 WLC_ISK value
         * @property {number} WLC_INR=356 WLC_INR value
         * @property {number} WLC_IDR=360 WLC_IDR value
         * @property {number} WLC_IRR=364 WLC_IRR value
         * @property {number} WLC_IQD=368 WLC_IQD value
         * @property {number} WLC_ILS=376 WLC_ILS value
         * @property {number} WLC_JMD=388 WLC_JMD value
         * @property {number} WLC_JPY=392 WLC_JPY value
         * @property {number} WLC_KZT=398 WLC_KZT value
         * @property {number} WLC_JOD=400 WLC_JOD value
         * @property {number} WLC_KES=404 WLC_KES value
         * @property {number} WLC_KPW=408 WLC_KPW value
         * @property {number} WLC_KRW=410 WLC_KRW value
         * @property {number} WLC_KWD=414 WLC_KWD value
         * @property {number} WLC_KGS=417 WLC_KGS value
         * @property {number} WLC_LAK=418 WLC_LAK value
         * @property {number} WLC_LBP=422 WLC_LBP value
         * @property {number} WLC_LSL=426 WLC_LSL value
         * @property {number} WLC_LVL=428 WLC_LVL value
         * @property {number} WLC_LRD=430 WLC_LRD value
         * @property {number} WLC_LYD=434 WLC_LYD value
         * @property {number} WLC_LTL=440 WLC_LTL value
         * @property {number} WLC_MOP=446 WLC_MOP value
         * @property {number} WLC_MWK=454 WLC_MWK value
         * @property {number} WLC_MYR=458 WLC_MYR value
         * @property {number} WLC_MVR=462 WLC_MVR value
         * @property {number} WLC_MTL=470 WLC_MTL value
         * @property {number} WLC_MRO=478 WLC_MRO value
         * @property {number} WLC_MUR=480 WLC_MUR value
         * @property {number} WLC_MXN=484 WLC_MXN value
         * @property {number} WLC_MNT=496 WLC_MNT value
         * @property {number} WLC_MDL=498 WLC_MDL value
         * @property {number} WLC_MAD=504 WLC_MAD value
         * @property {number} WLC_OMR=512 WLC_OMR value
         * @property {number} WLC_NAD=516 WLC_NAD value
         * @property {number} WLC_NPR=524 WLC_NPR value
         * @property {number} WLC_ANG=532 WLC_ANG value
         * @property {number} WLC_AWG=533 WLC_AWG value
         * @property {number} WLC_VUV=548 WLC_VUV value
         * @property {number} WLC_NZD=554 WLC_NZD value
         * @property {number} WLC_NIO=558 WLC_NIO value
         * @property {number} WLC_NGN=566 WLC_NGN value
         * @property {number} WLC_NOK=578 WLC_NOK value
         * @property {number} WLC_PKR=586 WLC_PKR value
         * @property {number} WLC_PAB=590 WLC_PAB value
         * @property {number} WLC_PGK=598 WLC_PGK value
         * @property {number} WLC_PYG=600 WLC_PYG value
         * @property {number} WLC_PEN=604 WLC_PEN value
         * @property {number} WLC_PHP=608 WLC_PHP value
         * @property {number} WLC_QAR=634 WLC_QAR value
         * @property {number} WLC_RUB=643 WLC_RUB value
         * @property {number} WLC_RWF=646 WLC_RWF value
         * @property {number} WLC_SHP=654 WLC_SHP value
         * @property {number} WLC_STD=678 WLC_STD value
         * @property {number} WLC_SAR=682 WLC_SAR value
         * @property {number} WLC_SCR=690 WLC_SCR value
         * @property {number} WLC_SLL=694 WLC_SLL value
         * @property {number} WLC_SGD=702 WLC_SGD value
         * @property {number} WLC_SKK=703 WLC_SKK value
         * @property {number} WLC_VND=704 WLC_VND value
         * @property {number} WLC_SOS=706 WLC_SOS value
         * @property {number} WLC_ZAR=710 WLC_ZAR value
         * @property {number} WLC_ZWD=716 WLC_ZWD value
         * @property {number} WLC_SSP=728 WLC_SSP value
         * @property {number} WLC_SZL=748 WLC_SZL value
         * @property {number} WLC_SEK=752 WLC_SEK value
         * @property {number} WLC_CHF=756 WLC_CHF value
         * @property {number} WLC_SYP=760 WLC_SYP value
         * @property {number} WLC_THB=764 WLC_THB value
         * @property {number} WLC_TOP=776 WLC_TOP value
         * @property {number} WLC_TTD=780 WLC_TTD value
         * @property {number} WLC_AED=784 WLC_AED value
         * @property {number} WLC_TND=788 WLC_TND value
         * @property {number} WLC_TMM=795 WLC_TMM value
         * @property {number} WLC_UGX=800 WLC_UGX value
         * @property {number} WLC_MKD=807 WLC_MKD value
         * @property {number} WLC_RUR=810 WLC_RUR value
         * @property {number} WLC_EGP=818 WLC_EGP value
         * @property {number} WLC_GBP=826 WLC_GBP value
         * @property {number} WLC_TZS=834 WLC_TZS value
         * @property {number} WLC_USD=840 WLC_USD value
         * @property {number} WLC_UYU=858 WLC_UYU value
         * @property {number} WLC_UZS=860 WLC_UZS value
         * @property {number} WLC_WST=882 WLC_WST value
         * @property {number} WLC_YER=886 WLC_YER value
         * @property {number} WLC_ZMK=894 WLC_ZMK value
         * @property {number} WLC_TWD=901 WLC_TWD value
         * @property {number} WLC_CUC=931 WLC_CUC value
         * @property {number} WLC_ZWL=932 WLC_ZWL value
         * @property {number} WLC_BYN=933 WLC_BYN value
         * @property {number} WLC_TMT=934 WLC_TMT value
         * @property {number} WLC_GHC=936 WLC_GHC value
         * @property {number} WLC_VEF=937 WLC_VEF value
         * @property {number} WLC_SDG=938 WLC_SDG value
         * @property {number} WLC_RSD=941 WLC_RSD value
         * @property {number} WLC_MZN=943 WLC_MZN value
         * @property {number} WLC_AZN=944 WLC_AZN value
         * @property {number} WLC_RON=946 WLC_RON value
         * @property {number} WLC_TRY=949 WLC_TRY value
         * @property {number} WLC_XAF=950 WLC_XAF value
         * @property {number} WLC_XCD=951 WLC_XCD value
         * @property {number} WLC_XOF=952 WLC_XOF value
         * @property {number} WLC_XPF=953 WLC_XPF value
         * @property {number} WLC_XAU=959 WLC_XAU value
         * @property {number} WLC_XDR=960 WLC_XDR value
         * @property {number} WLC_XAG=961 WLC_XAG value
         * @property {number} WLC_ZMW=967 WLC_ZMW value
         * @property {number} WLC_SRD=968 WLC_SRD value
         * @property {number} WLC_MGA=969 WLC_MGA value
         * @property {number} WLC_AFN=971 WLC_AFN value
         * @property {number} WLC_TJS=972 WLC_TJS value
         * @property {number} WLC_AOA=973 WLC_AOA value
         * @property {number} WLC_BGN=975 WLC_BGN value
         * @property {number} WLC_CDF=976 WLC_CDF value
         * @property {number} WLC_BAM=977 WLC_BAM value
         * @property {number} WLC_EUR=978 WLC_EUR value
         * @property {number} WLC_UAH=980 WLC_UAH value
         * @property {number} WLC_GEL=981 WLC_GEL value
         * @property {number} WLC_PLN=985 WLC_PLN value
         * @property {number} WLC_BRL=986 WLC_BRL value
         * @property {number} WLC_CLF=990 WLC_CLF value
         * @property {number} WLC_ENS=6004 WLC_ENS value
         * @property {number} WLC_SSC=6005 WLC_SSC value
         * @property {number} WLC_GOC=6006 WLC_GOC value
         * @property {number} WLC_GGP=80001 WLC_GGP value
         * @property {number} WLC_IMP=80002 WLC_IMP value
         * @property {number} WLC_JEP=80003 WLC_JEP value
         * @property {number} WLC_LTCT=90001 WLC_LTCT value
         * @property {number} WLC_BTC=90002 WLC_BTC value
         * @property {number} WLC_ETH=90003 WLC_ETH value
         * @property {number} WLC_DASH=90004 WLC_DASH value
         * @property {number} WLC_ZEC=90005 WLC_ZEC value
         * @property {number} WLC_BCH=90006 WLC_BCH value
         * @property {number} WLC_LTC=90007 WLC_LTC value
         * @property {number} WLC_XRP=90008 WLC_XRP value
         * @property {number} WLC_ETN=90009 WLC_ETN value
         * @property {number} WLC_USDT=90010 WLC_USDT value
         * @property {number} WLC_USDTERC20=90011 WLC_USDTERC20 value
         * @property {number} WLC_USDTTRC20=90012 WLC_USDTTRC20 value
         * @property {number} WLC_TRX=90013 WLC_TRX value
         * @property {number} WLC_USDCERC20=90014 WLC_USDCERC20 value
         * @property {number} WLC_WBTCERC20=90015 WLC_WBTCERC20 value
         * @property {number} WLC_DAIERC20=90016 WLC_DAIERC20 value
         * @property {number} WLC_PEPEERC20=90017 WLC_PEPEERC20 value
         * @property {number} WLC_FRAXERC20=90018 WLC_FRAXERC20 value
         * @property {number} WLC_WEETHERC20=90019 WLC_WEETHERC20 value
         * @property {number} WLC_MKRERC20=90020 WLC_MKRERC20 value
         * @property {number} WLC_USDMERC20=90021 WLC_USDMERC20 value
         * @property {number} WLC_LINKERC20=90022 WLC_LINKERC20 value
         * @property {number} WLC_UNIERC20=90024 WLC_UNIERC20 value
         * @property {number} WLC_DOGERC20=90025 WLC_DOGERC20 value
         * @property {number} WLC_FLCERC20=90026 WLC_FLCERC20 value
         * @property {number} WLC_METHERC20=90027 WLC_METHERC20 value
         * @property {number} WLC_SHIBERC20=90028 WLC_SHIBERC20 value
         * @property {number} WLC_ELONERC20=90029 WLC_ELONERC20 value
         * @property {number} WLC_RBNERC20=90030 WLC_RBNERC20 value
         * @property {number} WLC_WETHERC20=90032 WLC_WETHERC20 value
         */
        protocol.WLCurrency = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WLC_FUN"] = 0;
            values[valuesById[8] = "WLC_ALL"] = 8;
            values[valuesById[12] = "WLC_DZD"] = 12;
            values[valuesById[32] = "WLC_ARS"] = 32;
            values[valuesById[36] = "WLC_AUD"] = 36;
            values[valuesById[44] = "WLC_BSD"] = 44;
            values[valuesById[48] = "WLC_BHD"] = 48;
            values[valuesById[50] = "WLC_BDT"] = 50;
            values[valuesById[51] = "WLC_AMD"] = 51;
            values[valuesById[52] = "WLC_BBD"] = 52;
            values[valuesById[60] = "WLC_BMD"] = 60;
            values[valuesById[64] = "WLC_BTN"] = 64;
            values[valuesById[68] = "WLC_BOB"] = 68;
            values[valuesById[72] = "WLC_BWP"] = 72;
            values[valuesById[84] = "WLC_BZD"] = 84;
            values[valuesById[90] = "WLC_SBD"] = 90;
            values[valuesById[96] = "WLC_BND"] = 96;
            values[valuesById[104] = "WLC_MMK"] = 104;
            values[valuesById[108] = "WLC_BIF"] = 108;
            values[valuesById[116] = "WLC_KHR"] = 116;
            values[valuesById[124] = "WLC_CAD"] = 124;
            values[valuesById[132] = "WLC_CVE"] = 132;
            values[valuesById[136] = "WLC_KYD"] = 136;
            values[valuesById[144] = "WLC_LKR"] = 144;
            values[valuesById[152] = "WLC_CLP"] = 152;
            values[valuesById[156] = "WLC_CNY"] = 156;
            values[valuesById[170] = "WLC_COP"] = 170;
            values[valuesById[174] = "WLC_KMF"] = 174;
            values[valuesById[188] = "WLC_CRC"] = 188;
            values[valuesById[191] = "WLC_HRK"] = 191;
            values[valuesById[192] = "WLC_CUP"] = 192;
            values[valuesById[196] = "WLC_CYP"] = 196;
            values[valuesById[203] = "WLC_CZK"] = 203;
            values[valuesById[208] = "WLC_DKK"] = 208;
            values[valuesById[214] = "WLC_DOP"] = 214;
            values[valuesById[222] = "WLC_SVC"] = 222;
            values[valuesById[230] = "WLC_ETB"] = 230;
            values[valuesById[232] = "WLC_ERN"] = 232;
            values[valuesById[233] = "WLC_EEK"] = 233;
            values[valuesById[238] = "WLC_FKP"] = 238;
            values[valuesById[242] = "WLC_FJD"] = 242;
            values[valuesById[262] = "WLC_DJF"] = 262;
            values[valuesById[270] = "WLC_GMD"] = 270;
            values[valuesById[292] = "WLC_GIP"] = 292;
            values[valuesById[320] = "WLC_GTQ"] = 320;
            values[valuesById[324] = "WLC_GNF"] = 324;
            values[valuesById[328] = "WLC_GYD"] = 328;
            values[valuesById[332] = "WLC_HTG"] = 332;
            values[valuesById[340] = "WLC_HNL"] = 340;
            values[valuesById[344] = "WLC_HKD"] = 344;
            values[valuesById[348] = "WLC_HUF"] = 348;
            values[valuesById[352] = "WLC_ISK"] = 352;
            values[valuesById[356] = "WLC_INR"] = 356;
            values[valuesById[360] = "WLC_IDR"] = 360;
            values[valuesById[364] = "WLC_IRR"] = 364;
            values[valuesById[368] = "WLC_IQD"] = 368;
            values[valuesById[376] = "WLC_ILS"] = 376;
            values[valuesById[388] = "WLC_JMD"] = 388;
            values[valuesById[392] = "WLC_JPY"] = 392;
            values[valuesById[398] = "WLC_KZT"] = 398;
            values[valuesById[400] = "WLC_JOD"] = 400;
            values[valuesById[404] = "WLC_KES"] = 404;
            values[valuesById[408] = "WLC_KPW"] = 408;
            values[valuesById[410] = "WLC_KRW"] = 410;
            values[valuesById[414] = "WLC_KWD"] = 414;
            values[valuesById[417] = "WLC_KGS"] = 417;
            values[valuesById[418] = "WLC_LAK"] = 418;
            values[valuesById[422] = "WLC_LBP"] = 422;
            values[valuesById[426] = "WLC_LSL"] = 426;
            values[valuesById[428] = "WLC_LVL"] = 428;
            values[valuesById[430] = "WLC_LRD"] = 430;
            values[valuesById[434] = "WLC_LYD"] = 434;
            values[valuesById[440] = "WLC_LTL"] = 440;
            values[valuesById[446] = "WLC_MOP"] = 446;
            values[valuesById[454] = "WLC_MWK"] = 454;
            values[valuesById[458] = "WLC_MYR"] = 458;
            values[valuesById[462] = "WLC_MVR"] = 462;
            values[valuesById[470] = "WLC_MTL"] = 470;
            values[valuesById[478] = "WLC_MRO"] = 478;
            values[valuesById[480] = "WLC_MUR"] = 480;
            values[valuesById[484] = "WLC_MXN"] = 484;
            values[valuesById[496] = "WLC_MNT"] = 496;
            values[valuesById[498] = "WLC_MDL"] = 498;
            values[valuesById[504] = "WLC_MAD"] = 504;
            values[valuesById[512] = "WLC_OMR"] = 512;
            values[valuesById[516] = "WLC_NAD"] = 516;
            values[valuesById[524] = "WLC_NPR"] = 524;
            values[valuesById[532] = "WLC_ANG"] = 532;
            values[valuesById[533] = "WLC_AWG"] = 533;
            values[valuesById[548] = "WLC_VUV"] = 548;
            values[valuesById[554] = "WLC_NZD"] = 554;
            values[valuesById[558] = "WLC_NIO"] = 558;
            values[valuesById[566] = "WLC_NGN"] = 566;
            values[valuesById[578] = "WLC_NOK"] = 578;
            values[valuesById[586] = "WLC_PKR"] = 586;
            values[valuesById[590] = "WLC_PAB"] = 590;
            values[valuesById[598] = "WLC_PGK"] = 598;
            values[valuesById[600] = "WLC_PYG"] = 600;
            values[valuesById[604] = "WLC_PEN"] = 604;
            values[valuesById[608] = "WLC_PHP"] = 608;
            values[valuesById[634] = "WLC_QAR"] = 634;
            values[valuesById[643] = "WLC_RUB"] = 643;
            values[valuesById[646] = "WLC_RWF"] = 646;
            values[valuesById[654] = "WLC_SHP"] = 654;
            values[valuesById[678] = "WLC_STD"] = 678;
            values[valuesById[682] = "WLC_SAR"] = 682;
            values[valuesById[690] = "WLC_SCR"] = 690;
            values[valuesById[694] = "WLC_SLL"] = 694;
            values[valuesById[702] = "WLC_SGD"] = 702;
            values[valuesById[703] = "WLC_SKK"] = 703;
            values[valuesById[704] = "WLC_VND"] = 704;
            values[valuesById[706] = "WLC_SOS"] = 706;
            values[valuesById[710] = "WLC_ZAR"] = 710;
            values[valuesById[716] = "WLC_ZWD"] = 716;
            values[valuesById[728] = "WLC_SSP"] = 728;
            values[valuesById[748] = "WLC_SZL"] = 748;
            values[valuesById[752] = "WLC_SEK"] = 752;
            values[valuesById[756] = "WLC_CHF"] = 756;
            values[valuesById[760] = "WLC_SYP"] = 760;
            values[valuesById[764] = "WLC_THB"] = 764;
            values[valuesById[776] = "WLC_TOP"] = 776;
            values[valuesById[780] = "WLC_TTD"] = 780;
            values[valuesById[784] = "WLC_AED"] = 784;
            values[valuesById[788] = "WLC_TND"] = 788;
            values[valuesById[795] = "WLC_TMM"] = 795;
            values[valuesById[800] = "WLC_UGX"] = 800;
            values[valuesById[807] = "WLC_MKD"] = 807;
            values[valuesById[810] = "WLC_RUR"] = 810;
            values[valuesById[818] = "WLC_EGP"] = 818;
            values[valuesById[826] = "WLC_GBP"] = 826;
            values[valuesById[834] = "WLC_TZS"] = 834;
            values[valuesById[840] = "WLC_USD"] = 840;
            values[valuesById[858] = "WLC_UYU"] = 858;
            values[valuesById[860] = "WLC_UZS"] = 860;
            values[valuesById[882] = "WLC_WST"] = 882;
            values[valuesById[886] = "WLC_YER"] = 886;
            values[valuesById[894] = "WLC_ZMK"] = 894;
            values[valuesById[901] = "WLC_TWD"] = 901;
            values[valuesById[931] = "WLC_CUC"] = 931;
            values[valuesById[932] = "WLC_ZWL"] = 932;
            values[valuesById[933] = "WLC_BYN"] = 933;
            values[valuesById[934] = "WLC_TMT"] = 934;
            values[valuesById[936] = "WLC_GHC"] = 936;
            values[valuesById[937] = "WLC_VEF"] = 937;
            values[valuesById[938] = "WLC_SDG"] = 938;
            values[valuesById[941] = "WLC_RSD"] = 941;
            values[valuesById[943] = "WLC_MZN"] = 943;
            values[valuesById[944] = "WLC_AZN"] = 944;
            values[valuesById[946] = "WLC_RON"] = 946;
            values[valuesById[949] = "WLC_TRY"] = 949;
            values[valuesById[950] = "WLC_XAF"] = 950;
            values[valuesById[951] = "WLC_XCD"] = 951;
            values[valuesById[952] = "WLC_XOF"] = 952;
            values[valuesById[953] = "WLC_XPF"] = 953;
            values[valuesById[959] = "WLC_XAU"] = 959;
            values[valuesById[960] = "WLC_XDR"] = 960;
            values[valuesById[961] = "WLC_XAG"] = 961;
            values[valuesById[967] = "WLC_ZMW"] = 967;
            values[valuesById[968] = "WLC_SRD"] = 968;
            values[valuesById[969] = "WLC_MGA"] = 969;
            values[valuesById[971] = "WLC_AFN"] = 971;
            values[valuesById[972] = "WLC_TJS"] = 972;
            values[valuesById[973] = "WLC_AOA"] = 973;
            values[valuesById[975] = "WLC_BGN"] = 975;
            values[valuesById[976] = "WLC_CDF"] = 976;
            values[valuesById[977] = "WLC_BAM"] = 977;
            values[valuesById[978] = "WLC_EUR"] = 978;
            values[valuesById[980] = "WLC_UAH"] = 980;
            values[valuesById[981] = "WLC_GEL"] = 981;
            values[valuesById[985] = "WLC_PLN"] = 985;
            values[valuesById[986] = "WLC_BRL"] = 986;
            values[valuesById[990] = "WLC_CLF"] = 990;
            values[valuesById[6004] = "WLC_ENS"] = 6004;
            values[valuesById[6005] = "WLC_SSC"] = 6005;
            values[valuesById[6006] = "WLC_GOC"] = 6006;
            values[valuesById[80001] = "WLC_GGP"] = 80001;
            values[valuesById[80002] = "WLC_IMP"] = 80002;
            values[valuesById[80003] = "WLC_JEP"] = 80003;
            values[valuesById[90001] = "WLC_LTCT"] = 90001;
            values[valuesById[90002] = "WLC_BTC"] = 90002;
            values[valuesById[90003] = "WLC_ETH"] = 90003;
            values[valuesById[90004] = "WLC_DASH"] = 90004;
            values[valuesById[90005] = "WLC_ZEC"] = 90005;
            values[valuesById[90006] = "WLC_BCH"] = 90006;
            values[valuesById[90007] = "WLC_LTC"] = 90007;
            values[valuesById[90008] = "WLC_XRP"] = 90008;
            values[valuesById[90009] = "WLC_ETN"] = 90009;
            values[valuesById[90010] = "WLC_USDT"] = 90010;
            values[valuesById[90011] = "WLC_USDTERC20"] = 90011;
            values[valuesById[90012] = "WLC_USDTTRC20"] = 90012;
            values[valuesById[90013] = "WLC_TRX"] = 90013;
            values[valuesById[90014] = "WLC_USDCERC20"] = 90014;
            values[valuesById[90015] = "WLC_WBTCERC20"] = 90015;
            values[valuesById[90016] = "WLC_DAIERC20"] = 90016;
            values[valuesById[90017] = "WLC_PEPEERC20"] = 90017;
            values[valuesById[90018] = "WLC_FRAXERC20"] = 90018;
            values[valuesById[90019] = "WLC_WEETHERC20"] = 90019;
            values[valuesById[90020] = "WLC_MKRERC20"] = 90020;
            values[valuesById[90021] = "WLC_USDMERC20"] = 90021;
            values[valuesById[90022] = "WLC_LINKERC20"] = 90022;
            values[valuesById[90024] = "WLC_UNIERC20"] = 90024;
            values[valuesById[90025] = "WLC_DOGERC20"] = 90025;
            values[valuesById[90026] = "WLC_FLCERC20"] = 90026;
            values[valuesById[90027] = "WLC_METHERC20"] = 90027;
            values[valuesById[90028] = "WLC_SHIBERC20"] = 90028;
            values[valuesById[90029] = "WLC_ELONERC20"] = 90029;
            values[valuesById[90030] = "WLC_RBNERC20"] = 90030;
            values[valuesById[90032] = "WLC_WETHERC20"] = 90032;
            return values;
        })();
    
        protocol.ExchangeRate = (function() {
    
            /**
             * Properties of an ExchangeRate.
             * @memberof protocol
             * @interface IExchangeRate
             * @property {protocol.WLCurrency|null} [currencyFrom] ExchangeRate currencyFrom
             * @property {protocol.WLCurrency|null} [currencyTo] ExchangeRate currencyTo
             * @property {string|null} [rate] ExchangeRate rate
             */
    
            /**
             * Constructs a new ExchangeRate.
             * @memberof protocol
             * @classdesc Represents an ExchangeRate.
             * @implements IExchangeRate
             * @constructor
             * @param {protocol.IExchangeRate=} [properties] Properties to set
             */
            function ExchangeRate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ExchangeRate currencyFrom.
             * @member {protocol.WLCurrency} currencyFrom
             * @memberof protocol.ExchangeRate
             * @instance
             */
            ExchangeRate.prototype.currencyFrom = 0;
    
            /**
             * ExchangeRate currencyTo.
             * @member {protocol.WLCurrency} currencyTo
             * @memberof protocol.ExchangeRate
             * @instance
             */
            ExchangeRate.prototype.currencyTo = 0;
    
            /**
             * ExchangeRate rate.
             * @member {string} rate
             * @memberof protocol.ExchangeRate
             * @instance
             */
            ExchangeRate.prototype.rate = "";
    
            /**
             * Creates a new ExchangeRate instance using the specified properties.
             * @function create
             * @memberof protocol.ExchangeRate
             * @static
             * @param {protocol.IExchangeRate=} [properties] Properties to set
             * @returns {protocol.ExchangeRate} ExchangeRate instance
             */
            ExchangeRate.create = function create(properties) {
                return new ExchangeRate(properties);
            };
    
            /**
             * Encodes the specified ExchangeRate message. Does not implicitly {@link protocol.ExchangeRate.verify|verify} messages.
             * @function encode
             * @memberof protocol.ExchangeRate
             * @static
             * @param {protocol.IExchangeRate} message ExchangeRate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currencyFrom != null && Object.hasOwnProperty.call(message, "currencyFrom"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyFrom);
                if (message.currencyTo != null && Object.hasOwnProperty.call(message, "currencyTo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyTo);
                if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.rate);
                return writer;
            };
    
            /**
             * Encodes the specified ExchangeRate message, length delimited. Does not implicitly {@link protocol.ExchangeRate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ExchangeRate
             * @static
             * @param {protocol.IExchangeRate} message ExchangeRate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ExchangeRate message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ExchangeRate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ExchangeRate} ExchangeRate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ExchangeRate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currencyFrom = reader.int32();
                            break;
                        }
                    case 2: {
                            message.currencyTo = reader.int32();
                            break;
                        }
                    case 3: {
                            message.rate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ExchangeRate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ExchangeRate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ExchangeRate} ExchangeRate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ExchangeRate message.
             * @function verify
             * @memberof protocol.ExchangeRate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExchangeRate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currencyFrom != null && message.hasOwnProperty("currencyFrom"))
                    switch (message.currencyFrom) {
                    default:
                        return "currencyFrom: enum value expected";
                    case 0:
                    case 8:
                    case 12:
                    case 32:
                    case 36:
                    case 44:
                    case 48:
                    case 50:
                    case 51:
                    case 52:
                    case 60:
                    case 64:
                    case 68:
                    case 72:
                    case 84:
                    case 90:
                    case 96:
                    case 104:
                    case 108:
                    case 116:
                    case 124:
                    case 132:
                    case 136:
                    case 144:
                    case 152:
                    case 156:
                    case 170:
                    case 174:
                    case 188:
                    case 191:
                    case 192:
                    case 196:
                    case 203:
                    case 208:
                    case 214:
                    case 222:
                    case 230:
                    case 232:
                    case 233:
                    case 238:
                    case 242:
                    case 262:
                    case 270:
                    case 292:
                    case 320:
                    case 324:
                    case 328:
                    case 332:
                    case 340:
                    case 344:
                    case 348:
                    case 352:
                    case 356:
                    case 360:
                    case 364:
                    case 368:
                    case 376:
                    case 388:
                    case 392:
                    case 398:
                    case 400:
                    case 404:
                    case 408:
                    case 410:
                    case 414:
                    case 417:
                    case 418:
                    case 422:
                    case 426:
                    case 428:
                    case 430:
                    case 434:
                    case 440:
                    case 446:
                    case 454:
                    case 458:
                    case 462:
                    case 470:
                    case 478:
                    case 480:
                    case 484:
                    case 496:
                    case 498:
                    case 504:
                    case 512:
                    case 516:
                    case 524:
                    case 532:
                    case 533:
                    case 548:
                    case 554:
                    case 558:
                    case 566:
                    case 578:
                    case 586:
                    case 590:
                    case 598:
                    case 600:
                    case 604:
                    case 608:
                    case 634:
                    case 643:
                    case 646:
                    case 654:
                    case 678:
                    case 682:
                    case 690:
                    case 694:
                    case 702:
                    case 703:
                    case 704:
                    case 706:
                    case 710:
                    case 716:
                    case 728:
                    case 748:
                    case 752:
                    case 756:
                    case 760:
                    case 764:
                    case 776:
                    case 780:
                    case 784:
                    case 788:
                    case 795:
                    case 800:
                    case 807:
                    case 810:
                    case 818:
                    case 826:
                    case 834:
                    case 840:
                    case 858:
                    case 860:
                    case 882:
                    case 886:
                    case 894:
                    case 901:
                    case 931:
                    case 932:
                    case 933:
                    case 934:
                    case 936:
                    case 937:
                    case 938:
                    case 941:
                    case 943:
                    case 944:
                    case 946:
                    case 949:
                    case 950:
                    case 951:
                    case 952:
                    case 953:
                    case 959:
                    case 960:
                    case 961:
                    case 967:
                    case 968:
                    case 969:
                    case 971:
                    case 972:
                    case 973:
                    case 975:
                    case 976:
                    case 977:
                    case 978:
                    case 980:
                    case 981:
                    case 985:
                    case 986:
                    case 990:
                    case 6004:
                    case 6005:
                    case 6006:
                    case 80001:
                    case 80002:
                    case 80003:
                    case 90001:
                    case 90002:
                    case 90003:
                    case 90004:
                    case 90005:
                    case 90006:
                    case 90007:
                    case 90008:
                    case 90009:
                    case 90010:
                    case 90011:
                    case 90012:
                    case 90013:
                    case 90014:
                    case 90015:
                    case 90016:
                    case 90017:
                    case 90018:
                    case 90019:
                    case 90020:
                    case 90021:
                    case 90022:
                    case 90024:
                    case 90025:
                    case 90026:
                    case 90027:
                    case 90028:
                    case 90029:
                    case 90030:
                    case 90032:
                        break;
                    }
                if (message.currencyTo != null && message.hasOwnProperty("currencyTo"))
                    switch (message.currencyTo) {
                    default:
                        return "currencyTo: enum value expected";
                    case 0:
                    case 8:
                    case 12:
                    case 32:
                    case 36:
                    case 44:
                    case 48:
                    case 50:
                    case 51:
                    case 52:
                    case 60:
                    case 64:
                    case 68:
                    case 72:
                    case 84:
                    case 90:
                    case 96:
                    case 104:
                    case 108:
                    case 116:
                    case 124:
                    case 132:
                    case 136:
                    case 144:
                    case 152:
                    case 156:
                    case 170:
                    case 174:
                    case 188:
                    case 191:
                    case 192:
                    case 196:
                    case 203:
                    case 208:
                    case 214:
                    case 222:
                    case 230:
                    case 232:
                    case 233:
                    case 238:
                    case 242:
                    case 262:
                    case 270:
                    case 292:
                    case 320:
                    case 324:
                    case 328:
                    case 332:
                    case 340:
                    case 344:
                    case 348:
                    case 352:
                    case 356:
                    case 360:
                    case 364:
                    case 368:
                    case 376:
                    case 388:
                    case 392:
                    case 398:
                    case 400:
                    case 404:
                    case 408:
                    case 410:
                    case 414:
                    case 417:
                    case 418:
                    case 422:
                    case 426:
                    case 428:
                    case 430:
                    case 434:
                    case 440:
                    case 446:
                    case 454:
                    case 458:
                    case 462:
                    case 470:
                    case 478:
                    case 480:
                    case 484:
                    case 496:
                    case 498:
                    case 504:
                    case 512:
                    case 516:
                    case 524:
                    case 532:
                    case 533:
                    case 548:
                    case 554:
                    case 558:
                    case 566:
                    case 578:
                    case 586:
                    case 590:
                    case 598:
                    case 600:
                    case 604:
                    case 608:
                    case 634:
                    case 643:
                    case 646:
                    case 654:
                    case 678:
                    case 682:
                    case 690:
                    case 694:
                    case 702:
                    case 703:
                    case 704:
                    case 706:
                    case 710:
                    case 716:
                    case 728:
                    case 748:
                    case 752:
                    case 756:
                    case 760:
                    case 764:
                    case 776:
                    case 780:
                    case 784:
                    case 788:
                    case 795:
                    case 800:
                    case 807:
                    case 810:
                    case 818:
                    case 826:
                    case 834:
                    case 840:
                    case 858:
                    case 860:
                    case 882:
                    case 886:
                    case 894:
                    case 901:
                    case 931:
                    case 932:
                    case 933:
                    case 934:
                    case 936:
                    case 937:
                    case 938:
                    case 941:
                    case 943:
                    case 944:
                    case 946:
                    case 949:
                    case 950:
                    case 951:
                    case 952:
                    case 953:
                    case 959:
                    case 960:
                    case 961:
                    case 967:
                    case 968:
                    case 969:
                    case 971:
                    case 972:
                    case 973:
                    case 975:
                    case 976:
                    case 977:
                    case 978:
                    case 980:
                    case 981:
                    case 985:
                    case 986:
                    case 990:
                    case 6004:
                    case 6005:
                    case 6006:
                    case 80001:
                    case 80002:
                    case 80003:
                    case 90001:
                    case 90002:
                    case 90003:
                    case 90004:
                    case 90005:
                    case 90006:
                    case 90007:
                    case 90008:
                    case 90009:
                    case 90010:
                    case 90011:
                    case 90012:
                    case 90013:
                    case 90014:
                    case 90015:
                    case 90016:
                    case 90017:
                    case 90018:
                    case 90019:
                    case 90020:
                    case 90021:
                    case 90022:
                    case 90024:
                    case 90025:
                    case 90026:
                    case 90027:
                    case 90028:
                    case 90029:
                    case 90030:
                    case 90032:
                        break;
                    }
                if (message.rate != null && message.hasOwnProperty("rate"))
                    if (!$util.isString(message.rate))
                        return "rate: string expected";
                return null;
            };
    
            /**
             * Creates an ExchangeRate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ExchangeRate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ExchangeRate} ExchangeRate
             */
            ExchangeRate.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ExchangeRate)
                    return object;
                var message = new $root.protocol.ExchangeRate();
                switch (object.currencyFrom) {
                default:
                    if (typeof object.currencyFrom === "number") {
                        message.currencyFrom = object.currencyFrom;
                        break;
                    }
                    break;
                case "WLC_FUN":
                case 0:
                    message.currencyFrom = 0;
                    break;
                case "WLC_ALL":
                case 8:
                    message.currencyFrom = 8;
                    break;
                case "WLC_DZD":
                case 12:
                    message.currencyFrom = 12;
                    break;
                case "WLC_ARS":
                case 32:
                    message.currencyFrom = 32;
                    break;
                case "WLC_AUD":
                case 36:
                    message.currencyFrom = 36;
                    break;
                case "WLC_BSD":
                case 44:
                    message.currencyFrom = 44;
                    break;
                case "WLC_BHD":
                case 48:
                    message.currencyFrom = 48;
                    break;
                case "WLC_BDT":
                case 50:
                    message.currencyFrom = 50;
                    break;
                case "WLC_AMD":
                case 51:
                    message.currencyFrom = 51;
                    break;
                case "WLC_BBD":
                case 52:
                    message.currencyFrom = 52;
                    break;
                case "WLC_BMD":
                case 60:
                    message.currencyFrom = 60;
                    break;
                case "WLC_BTN":
                case 64:
                    message.currencyFrom = 64;
                    break;
                case "WLC_BOB":
                case 68:
                    message.currencyFrom = 68;
                    break;
                case "WLC_BWP":
                case 72:
                    message.currencyFrom = 72;
                    break;
                case "WLC_BZD":
                case 84:
                    message.currencyFrom = 84;
                    break;
                case "WLC_SBD":
                case 90:
                    message.currencyFrom = 90;
                    break;
                case "WLC_BND":
                case 96:
                    message.currencyFrom = 96;
                    break;
                case "WLC_MMK":
                case 104:
                    message.currencyFrom = 104;
                    break;
                case "WLC_BIF":
                case 108:
                    message.currencyFrom = 108;
                    break;
                case "WLC_KHR":
                case 116:
                    message.currencyFrom = 116;
                    break;
                case "WLC_CAD":
                case 124:
                    message.currencyFrom = 124;
                    break;
                case "WLC_CVE":
                case 132:
                    message.currencyFrom = 132;
                    break;
                case "WLC_KYD":
                case 136:
                    message.currencyFrom = 136;
                    break;
                case "WLC_LKR":
                case 144:
                    message.currencyFrom = 144;
                    break;
                case "WLC_CLP":
                case 152:
                    message.currencyFrom = 152;
                    break;
                case "WLC_CNY":
                case 156:
                    message.currencyFrom = 156;
                    break;
                case "WLC_COP":
                case 170:
                    message.currencyFrom = 170;
                    break;
                case "WLC_KMF":
                case 174:
                    message.currencyFrom = 174;
                    break;
                case "WLC_CRC":
                case 188:
                    message.currencyFrom = 188;
                    break;
                case "WLC_HRK":
                case 191:
                    message.currencyFrom = 191;
                    break;
                case "WLC_CUP":
                case 192:
                    message.currencyFrom = 192;
                    break;
                case "WLC_CYP":
                case 196:
                    message.currencyFrom = 196;
                    break;
                case "WLC_CZK":
                case 203:
                    message.currencyFrom = 203;
                    break;
                case "WLC_DKK":
                case 208:
                    message.currencyFrom = 208;
                    break;
                case "WLC_DOP":
                case 214:
                    message.currencyFrom = 214;
                    break;
                case "WLC_SVC":
                case 222:
                    message.currencyFrom = 222;
                    break;
                case "WLC_ETB":
                case 230:
                    message.currencyFrom = 230;
                    break;
                case "WLC_ERN":
                case 232:
                    message.currencyFrom = 232;
                    break;
                case "WLC_EEK":
                case 233:
                    message.currencyFrom = 233;
                    break;
                case "WLC_FKP":
                case 238:
                    message.currencyFrom = 238;
                    break;
                case "WLC_FJD":
                case 242:
                    message.currencyFrom = 242;
                    break;
                case "WLC_DJF":
                case 262:
                    message.currencyFrom = 262;
                    break;
                case "WLC_GMD":
                case 270:
                    message.currencyFrom = 270;
                    break;
                case "WLC_GIP":
                case 292:
                    message.currencyFrom = 292;
                    break;
                case "WLC_GTQ":
                case 320:
                    message.currencyFrom = 320;
                    break;
                case "WLC_GNF":
                case 324:
                    message.currencyFrom = 324;
                    break;
                case "WLC_GYD":
                case 328:
                    message.currencyFrom = 328;
                    break;
                case "WLC_HTG":
                case 332:
                    message.currencyFrom = 332;
                    break;
                case "WLC_HNL":
                case 340:
                    message.currencyFrom = 340;
                    break;
                case "WLC_HKD":
                case 344:
                    message.currencyFrom = 344;
                    break;
                case "WLC_HUF":
                case 348:
                    message.currencyFrom = 348;
                    break;
                case "WLC_ISK":
                case 352:
                    message.currencyFrom = 352;
                    break;
                case "WLC_INR":
                case 356:
                    message.currencyFrom = 356;
                    break;
                case "WLC_IDR":
                case 360:
                    message.currencyFrom = 360;
                    break;
                case "WLC_IRR":
                case 364:
                    message.currencyFrom = 364;
                    break;
                case "WLC_IQD":
                case 368:
                    message.currencyFrom = 368;
                    break;
                case "WLC_ILS":
                case 376:
                    message.currencyFrom = 376;
                    break;
                case "WLC_JMD":
                case 388:
                    message.currencyFrom = 388;
                    break;
                case "WLC_JPY":
                case 392:
                    message.currencyFrom = 392;
                    break;
                case "WLC_KZT":
                case 398:
                    message.currencyFrom = 398;
                    break;
                case "WLC_JOD":
                case 400:
                    message.currencyFrom = 400;
                    break;
                case "WLC_KES":
                case 404:
                    message.currencyFrom = 404;
                    break;
                case "WLC_KPW":
                case 408:
                    message.currencyFrom = 408;
                    break;
                case "WLC_KRW":
                case 410:
                    message.currencyFrom = 410;
                    break;
                case "WLC_KWD":
                case 414:
                    message.currencyFrom = 414;
                    break;
                case "WLC_KGS":
                case 417:
                    message.currencyFrom = 417;
                    break;
                case "WLC_LAK":
                case 418:
                    message.currencyFrom = 418;
                    break;
                case "WLC_LBP":
                case 422:
                    message.currencyFrom = 422;
                    break;
                case "WLC_LSL":
                case 426:
                    message.currencyFrom = 426;
                    break;
                case "WLC_LVL":
                case 428:
                    message.currencyFrom = 428;
                    break;
                case "WLC_LRD":
                case 430:
                    message.currencyFrom = 430;
                    break;
                case "WLC_LYD":
                case 434:
                    message.currencyFrom = 434;
                    break;
                case "WLC_LTL":
                case 440:
                    message.currencyFrom = 440;
                    break;
                case "WLC_MOP":
                case 446:
                    message.currencyFrom = 446;
                    break;
                case "WLC_MWK":
                case 454:
                    message.currencyFrom = 454;
                    break;
                case "WLC_MYR":
                case 458:
                    message.currencyFrom = 458;
                    break;
                case "WLC_MVR":
                case 462:
                    message.currencyFrom = 462;
                    break;
                case "WLC_MTL":
                case 470:
                    message.currencyFrom = 470;
                    break;
                case "WLC_MRO":
                case 478:
                    message.currencyFrom = 478;
                    break;
                case "WLC_MUR":
                case 480:
                    message.currencyFrom = 480;
                    break;
                case "WLC_MXN":
                case 484:
                    message.currencyFrom = 484;
                    break;
                case "WLC_MNT":
                case 496:
                    message.currencyFrom = 496;
                    break;
                case "WLC_MDL":
                case 498:
                    message.currencyFrom = 498;
                    break;
                case "WLC_MAD":
                case 504:
                    message.currencyFrom = 504;
                    break;
                case "WLC_OMR":
                case 512:
                    message.currencyFrom = 512;
                    break;
                case "WLC_NAD":
                case 516:
                    message.currencyFrom = 516;
                    break;
                case "WLC_NPR":
                case 524:
                    message.currencyFrom = 524;
                    break;
                case "WLC_ANG":
                case 532:
                    message.currencyFrom = 532;
                    break;
                case "WLC_AWG":
                case 533:
                    message.currencyFrom = 533;
                    break;
                case "WLC_VUV":
                case 548:
                    message.currencyFrom = 548;
                    break;
                case "WLC_NZD":
                case 554:
                    message.currencyFrom = 554;
                    break;
                case "WLC_NIO":
                case 558:
                    message.currencyFrom = 558;
                    break;
                case "WLC_NGN":
                case 566:
                    message.currencyFrom = 566;
                    break;
                case "WLC_NOK":
                case 578:
                    message.currencyFrom = 578;
                    break;
                case "WLC_PKR":
                case 586:
                    message.currencyFrom = 586;
                    break;
                case "WLC_PAB":
                case 590:
                    message.currencyFrom = 590;
                    break;
                case "WLC_PGK":
                case 598:
                    message.currencyFrom = 598;
                    break;
                case "WLC_PYG":
                case 600:
                    message.currencyFrom = 600;
                    break;
                case "WLC_PEN":
                case 604:
                    message.currencyFrom = 604;
                    break;
                case "WLC_PHP":
                case 608:
                    message.currencyFrom = 608;
                    break;
                case "WLC_QAR":
                case 634:
                    message.currencyFrom = 634;
                    break;
                case "WLC_RUB":
                case 643:
                    message.currencyFrom = 643;
                    break;
                case "WLC_RWF":
                case 646:
                    message.currencyFrom = 646;
                    break;
                case "WLC_SHP":
                case 654:
                    message.currencyFrom = 654;
                    break;
                case "WLC_STD":
                case 678:
                    message.currencyFrom = 678;
                    break;
                case "WLC_SAR":
                case 682:
                    message.currencyFrom = 682;
                    break;
                case "WLC_SCR":
                case 690:
                    message.currencyFrom = 690;
                    break;
                case "WLC_SLL":
                case 694:
                    message.currencyFrom = 694;
                    break;
                case "WLC_SGD":
                case 702:
                    message.currencyFrom = 702;
                    break;
                case "WLC_SKK":
                case 703:
                    message.currencyFrom = 703;
                    break;
                case "WLC_VND":
                case 704:
                    message.currencyFrom = 704;
                    break;
                case "WLC_SOS":
                case 706:
                    message.currencyFrom = 706;
                    break;
                case "WLC_ZAR":
                case 710:
                    message.currencyFrom = 710;
                    break;
                case "WLC_ZWD":
                case 716:
                    message.currencyFrom = 716;
                    break;
                case "WLC_SSP":
                case 728:
                    message.currencyFrom = 728;
                    break;
                case "WLC_SZL":
                case 748:
                    message.currencyFrom = 748;
                    break;
                case "WLC_SEK":
                case 752:
                    message.currencyFrom = 752;
                    break;
                case "WLC_CHF":
                case 756:
                    message.currencyFrom = 756;
                    break;
                case "WLC_SYP":
                case 760:
                    message.currencyFrom = 760;
                    break;
                case "WLC_THB":
                case 764:
                    message.currencyFrom = 764;
                    break;
                case "WLC_TOP":
                case 776:
                    message.currencyFrom = 776;
                    break;
                case "WLC_TTD":
                case 780:
                    message.currencyFrom = 780;
                    break;
                case "WLC_AED":
                case 784:
                    message.currencyFrom = 784;
                    break;
                case "WLC_TND":
                case 788:
                    message.currencyFrom = 788;
                    break;
                case "WLC_TMM":
                case 795:
                    message.currencyFrom = 795;
                    break;
                case "WLC_UGX":
                case 800:
                    message.currencyFrom = 800;
                    break;
                case "WLC_MKD":
                case 807:
                    message.currencyFrom = 807;
                    break;
                case "WLC_RUR":
                case 810:
                    message.currencyFrom = 810;
                    break;
                case "WLC_EGP":
                case 818:
                    message.currencyFrom = 818;
                    break;
                case "WLC_GBP":
                case 826:
                    message.currencyFrom = 826;
                    break;
                case "WLC_TZS":
                case 834:
                    message.currencyFrom = 834;
                    break;
                case "WLC_USD":
                case 840:
                    message.currencyFrom = 840;
                    break;
                case "WLC_UYU":
                case 858:
                    message.currencyFrom = 858;
                    break;
                case "WLC_UZS":
                case 860:
                    message.currencyFrom = 860;
                    break;
                case "WLC_WST":
                case 882:
                    message.currencyFrom = 882;
                    break;
                case "WLC_YER":
                case 886:
                    message.currencyFrom = 886;
                    break;
                case "WLC_ZMK":
                case 894:
                    message.currencyFrom = 894;
                    break;
                case "WLC_TWD":
                case 901:
                    message.currencyFrom = 901;
                    break;
                case "WLC_CUC":
                case 931:
                    message.currencyFrom = 931;
                    break;
                case "WLC_ZWL":
                case 932:
                    message.currencyFrom = 932;
                    break;
                case "WLC_BYN":
                case 933:
                    message.currencyFrom = 933;
                    break;
                case "WLC_TMT":
                case 934:
                    message.currencyFrom = 934;
                    break;
                case "WLC_GHC":
                case 936:
                    message.currencyFrom = 936;
                    break;
                case "WLC_VEF":
                case 937:
                    message.currencyFrom = 937;
                    break;
                case "WLC_SDG":
                case 938:
                    message.currencyFrom = 938;
                    break;
                case "WLC_RSD":
                case 941:
                    message.currencyFrom = 941;
                    break;
                case "WLC_MZN":
                case 943:
                    message.currencyFrom = 943;
                    break;
                case "WLC_AZN":
                case 944:
                    message.currencyFrom = 944;
                    break;
                case "WLC_RON":
                case 946:
                    message.currencyFrom = 946;
                    break;
                case "WLC_TRY":
                case 949:
                    message.currencyFrom = 949;
                    break;
                case "WLC_XAF":
                case 950:
                    message.currencyFrom = 950;
                    break;
                case "WLC_XCD":
                case 951:
                    message.currencyFrom = 951;
                    break;
                case "WLC_XOF":
                case 952:
                    message.currencyFrom = 952;
                    break;
                case "WLC_XPF":
                case 953:
                    message.currencyFrom = 953;
                    break;
                case "WLC_XAU":
                case 959:
                    message.currencyFrom = 959;
                    break;
                case "WLC_XDR":
                case 960:
                    message.currencyFrom = 960;
                    break;
                case "WLC_XAG":
                case 961:
                    message.currencyFrom = 961;
                    break;
                case "WLC_ZMW":
                case 967:
                    message.currencyFrom = 967;
                    break;
                case "WLC_SRD":
                case 968:
                    message.currencyFrom = 968;
                    break;
                case "WLC_MGA":
                case 969:
                    message.currencyFrom = 969;
                    break;
                case "WLC_AFN":
                case 971:
                    message.currencyFrom = 971;
                    break;
                case "WLC_TJS":
                case 972:
                    message.currencyFrom = 972;
                    break;
                case "WLC_AOA":
                case 973:
                    message.currencyFrom = 973;
                    break;
                case "WLC_BGN":
                case 975:
                    message.currencyFrom = 975;
                    break;
                case "WLC_CDF":
                case 976:
                    message.currencyFrom = 976;
                    break;
                case "WLC_BAM":
                case 977:
                    message.currencyFrom = 977;
                    break;
                case "WLC_EUR":
                case 978:
                    message.currencyFrom = 978;
                    break;
                case "WLC_UAH":
                case 980:
                    message.currencyFrom = 980;
                    break;
                case "WLC_GEL":
                case 981:
                    message.currencyFrom = 981;
                    break;
                case "WLC_PLN":
                case 985:
                    message.currencyFrom = 985;
                    break;
                case "WLC_BRL":
                case 986:
                    message.currencyFrom = 986;
                    break;
                case "WLC_CLF":
                case 990:
                    message.currencyFrom = 990;
                    break;
                case "WLC_ENS":
                case 6004:
                    message.currencyFrom = 6004;
                    break;
                case "WLC_SSC":
                case 6005:
                    message.currencyFrom = 6005;
                    break;
                case "WLC_GOC":
                case 6006:
                    message.currencyFrom = 6006;
                    break;
                case "WLC_GGP":
                case 80001:
                    message.currencyFrom = 80001;
                    break;
                case "WLC_IMP":
                case 80002:
                    message.currencyFrom = 80002;
                    break;
                case "WLC_JEP":
                case 80003:
                    message.currencyFrom = 80003;
                    break;
                case "WLC_LTCT":
                case 90001:
                    message.currencyFrom = 90001;
                    break;
                case "WLC_BTC":
                case 90002:
                    message.currencyFrom = 90002;
                    break;
                case "WLC_ETH":
                case 90003:
                    message.currencyFrom = 90003;
                    break;
                case "WLC_DASH":
                case 90004:
                    message.currencyFrom = 90004;
                    break;
                case "WLC_ZEC":
                case 90005:
                    message.currencyFrom = 90005;
                    break;
                case "WLC_BCH":
                case 90006:
                    message.currencyFrom = 90006;
                    break;
                case "WLC_LTC":
                case 90007:
                    message.currencyFrom = 90007;
                    break;
                case "WLC_XRP":
                case 90008:
                    message.currencyFrom = 90008;
                    break;
                case "WLC_ETN":
                case 90009:
                    message.currencyFrom = 90009;
                    break;
                case "WLC_USDT":
                case 90010:
                    message.currencyFrom = 90010;
                    break;
                case "WLC_USDTERC20":
                case 90011:
                    message.currencyFrom = 90011;
                    break;
                case "WLC_USDTTRC20":
                case 90012:
                    message.currencyFrom = 90012;
                    break;
                case "WLC_TRX":
                case 90013:
                    message.currencyFrom = 90013;
                    break;
                case "WLC_USDCERC20":
                case 90014:
                    message.currencyFrom = 90014;
                    break;
                case "WLC_WBTCERC20":
                case 90015:
                    message.currencyFrom = 90015;
                    break;
                case "WLC_DAIERC20":
                case 90016:
                    message.currencyFrom = 90016;
                    break;
                case "WLC_PEPEERC20":
                case 90017:
                    message.currencyFrom = 90017;
                    break;
                case "WLC_FRAXERC20":
                case 90018:
                    message.currencyFrom = 90018;
                    break;
                case "WLC_WEETHERC20":
                case 90019:
                    message.currencyFrom = 90019;
                    break;
                case "WLC_MKRERC20":
                case 90020:
                    message.currencyFrom = 90020;
                    break;
                case "WLC_USDMERC20":
                case 90021:
                    message.currencyFrom = 90021;
                    break;
                case "WLC_LINKERC20":
                case 90022:
                    message.currencyFrom = 90022;
                    break;
                case "WLC_UNIERC20":
                case 90024:
                    message.currencyFrom = 90024;
                    break;
                case "WLC_DOGERC20":
                case 90025:
                    message.currencyFrom = 90025;
                    break;
                case "WLC_FLCERC20":
                case 90026:
                    message.currencyFrom = 90026;
                    break;
                case "WLC_METHERC20":
                case 90027:
                    message.currencyFrom = 90027;
                    break;
                case "WLC_SHIBERC20":
                case 90028:
                    message.currencyFrom = 90028;
                    break;
                case "WLC_ELONERC20":
                case 90029:
                    message.currencyFrom = 90029;
                    break;
                case "WLC_RBNERC20":
                case 90030:
                    message.currencyFrom = 90030;
                    break;
                case "WLC_WETHERC20":
                case 90032:
                    message.currencyFrom = 90032;
                    break;
                }
                switch (object.currencyTo) {
                default:
                    if (typeof object.currencyTo === "number") {
                        message.currencyTo = object.currencyTo;
                        break;
                    }
                    break;
                case "WLC_FUN":
                case 0:
                    message.currencyTo = 0;
                    break;
                case "WLC_ALL":
                case 8:
                    message.currencyTo = 8;
                    break;
                case "WLC_DZD":
                case 12:
                    message.currencyTo = 12;
                    break;
                case "WLC_ARS":
                case 32:
                    message.currencyTo = 32;
                    break;
                case "WLC_AUD":
                case 36:
                    message.currencyTo = 36;
                    break;
                case "WLC_BSD":
                case 44:
                    message.currencyTo = 44;
                    break;
                case "WLC_BHD":
                case 48:
                    message.currencyTo = 48;
                    break;
                case "WLC_BDT":
                case 50:
                    message.currencyTo = 50;
                    break;
                case "WLC_AMD":
                case 51:
                    message.currencyTo = 51;
                    break;
                case "WLC_BBD":
                case 52:
                    message.currencyTo = 52;
                    break;
                case "WLC_BMD":
                case 60:
                    message.currencyTo = 60;
                    break;
                case "WLC_BTN":
                case 64:
                    message.currencyTo = 64;
                    break;
                case "WLC_BOB":
                case 68:
                    message.currencyTo = 68;
                    break;
                case "WLC_BWP":
                case 72:
                    message.currencyTo = 72;
                    break;
                case "WLC_BZD":
                case 84:
                    message.currencyTo = 84;
                    break;
                case "WLC_SBD":
                case 90:
                    message.currencyTo = 90;
                    break;
                case "WLC_BND":
                case 96:
                    message.currencyTo = 96;
                    break;
                case "WLC_MMK":
                case 104:
                    message.currencyTo = 104;
                    break;
                case "WLC_BIF":
                case 108:
                    message.currencyTo = 108;
                    break;
                case "WLC_KHR":
                case 116:
                    message.currencyTo = 116;
                    break;
                case "WLC_CAD":
                case 124:
                    message.currencyTo = 124;
                    break;
                case "WLC_CVE":
                case 132:
                    message.currencyTo = 132;
                    break;
                case "WLC_KYD":
                case 136:
                    message.currencyTo = 136;
                    break;
                case "WLC_LKR":
                case 144:
                    message.currencyTo = 144;
                    break;
                case "WLC_CLP":
                case 152:
                    message.currencyTo = 152;
                    break;
                case "WLC_CNY":
                case 156:
                    message.currencyTo = 156;
                    break;
                case "WLC_COP":
                case 170:
                    message.currencyTo = 170;
                    break;
                case "WLC_KMF":
                case 174:
                    message.currencyTo = 174;
                    break;
                case "WLC_CRC":
                case 188:
                    message.currencyTo = 188;
                    break;
                case "WLC_HRK":
                case 191:
                    message.currencyTo = 191;
                    break;
                case "WLC_CUP":
                case 192:
                    message.currencyTo = 192;
                    break;
                case "WLC_CYP":
                case 196:
                    message.currencyTo = 196;
                    break;
                case "WLC_CZK":
                case 203:
                    message.currencyTo = 203;
                    break;
                case "WLC_DKK":
                case 208:
                    message.currencyTo = 208;
                    break;
                case "WLC_DOP":
                case 214:
                    message.currencyTo = 214;
                    break;
                case "WLC_SVC":
                case 222:
                    message.currencyTo = 222;
                    break;
                case "WLC_ETB":
                case 230:
                    message.currencyTo = 230;
                    break;
                case "WLC_ERN":
                case 232:
                    message.currencyTo = 232;
                    break;
                case "WLC_EEK":
                case 233:
                    message.currencyTo = 233;
                    break;
                case "WLC_FKP":
                case 238:
                    message.currencyTo = 238;
                    break;
                case "WLC_FJD":
                case 242:
                    message.currencyTo = 242;
                    break;
                case "WLC_DJF":
                case 262:
                    message.currencyTo = 262;
                    break;
                case "WLC_GMD":
                case 270:
                    message.currencyTo = 270;
                    break;
                case "WLC_GIP":
                case 292:
                    message.currencyTo = 292;
                    break;
                case "WLC_GTQ":
                case 320:
                    message.currencyTo = 320;
                    break;
                case "WLC_GNF":
                case 324:
                    message.currencyTo = 324;
                    break;
                case "WLC_GYD":
                case 328:
                    message.currencyTo = 328;
                    break;
                case "WLC_HTG":
                case 332:
                    message.currencyTo = 332;
                    break;
                case "WLC_HNL":
                case 340:
                    message.currencyTo = 340;
                    break;
                case "WLC_HKD":
                case 344:
                    message.currencyTo = 344;
                    break;
                case "WLC_HUF":
                case 348:
                    message.currencyTo = 348;
                    break;
                case "WLC_ISK":
                case 352:
                    message.currencyTo = 352;
                    break;
                case "WLC_INR":
                case 356:
                    message.currencyTo = 356;
                    break;
                case "WLC_IDR":
                case 360:
                    message.currencyTo = 360;
                    break;
                case "WLC_IRR":
                case 364:
                    message.currencyTo = 364;
                    break;
                case "WLC_IQD":
                case 368:
                    message.currencyTo = 368;
                    break;
                case "WLC_ILS":
                case 376:
                    message.currencyTo = 376;
                    break;
                case "WLC_JMD":
                case 388:
                    message.currencyTo = 388;
                    break;
                case "WLC_JPY":
                case 392:
                    message.currencyTo = 392;
                    break;
                case "WLC_KZT":
                case 398:
                    message.currencyTo = 398;
                    break;
                case "WLC_JOD":
                case 400:
                    message.currencyTo = 400;
                    break;
                case "WLC_KES":
                case 404:
                    message.currencyTo = 404;
                    break;
                case "WLC_KPW":
                case 408:
                    message.currencyTo = 408;
                    break;
                case "WLC_KRW":
                case 410:
                    message.currencyTo = 410;
                    break;
                case "WLC_KWD":
                case 414:
                    message.currencyTo = 414;
                    break;
                case "WLC_KGS":
                case 417:
                    message.currencyTo = 417;
                    break;
                case "WLC_LAK":
                case 418:
                    message.currencyTo = 418;
                    break;
                case "WLC_LBP":
                case 422:
                    message.currencyTo = 422;
                    break;
                case "WLC_LSL":
                case 426:
                    message.currencyTo = 426;
                    break;
                case "WLC_LVL":
                case 428:
                    message.currencyTo = 428;
                    break;
                case "WLC_LRD":
                case 430:
                    message.currencyTo = 430;
                    break;
                case "WLC_LYD":
                case 434:
                    message.currencyTo = 434;
                    break;
                case "WLC_LTL":
                case 440:
                    message.currencyTo = 440;
                    break;
                case "WLC_MOP":
                case 446:
                    message.currencyTo = 446;
                    break;
                case "WLC_MWK":
                case 454:
                    message.currencyTo = 454;
                    break;
                case "WLC_MYR":
                case 458:
                    message.currencyTo = 458;
                    break;
                case "WLC_MVR":
                case 462:
                    message.currencyTo = 462;
                    break;
                case "WLC_MTL":
                case 470:
                    message.currencyTo = 470;
                    break;
                case "WLC_MRO":
                case 478:
                    message.currencyTo = 478;
                    break;
                case "WLC_MUR":
                case 480:
                    message.currencyTo = 480;
                    break;
                case "WLC_MXN":
                case 484:
                    message.currencyTo = 484;
                    break;
                case "WLC_MNT":
                case 496:
                    message.currencyTo = 496;
                    break;
                case "WLC_MDL":
                case 498:
                    message.currencyTo = 498;
                    break;
                case "WLC_MAD":
                case 504:
                    message.currencyTo = 504;
                    break;
                case "WLC_OMR":
                case 512:
                    message.currencyTo = 512;
                    break;
                case "WLC_NAD":
                case 516:
                    message.currencyTo = 516;
                    break;
                case "WLC_NPR":
                case 524:
                    message.currencyTo = 524;
                    break;
                case "WLC_ANG":
                case 532:
                    message.currencyTo = 532;
                    break;
                case "WLC_AWG":
                case 533:
                    message.currencyTo = 533;
                    break;
                case "WLC_VUV":
                case 548:
                    message.currencyTo = 548;
                    break;
                case "WLC_NZD":
                case 554:
                    message.currencyTo = 554;
                    break;
                case "WLC_NIO":
                case 558:
                    message.currencyTo = 558;
                    break;
                case "WLC_NGN":
                case 566:
                    message.currencyTo = 566;
                    break;
                case "WLC_NOK":
                case 578:
                    message.currencyTo = 578;
                    break;
                case "WLC_PKR":
                case 586:
                    message.currencyTo = 586;
                    break;
                case "WLC_PAB":
                case 590:
                    message.currencyTo = 590;
                    break;
                case "WLC_PGK":
                case 598:
                    message.currencyTo = 598;
                    break;
                case "WLC_PYG":
                case 600:
                    message.currencyTo = 600;
                    break;
                case "WLC_PEN":
                case 604:
                    message.currencyTo = 604;
                    break;
                case "WLC_PHP":
                case 608:
                    message.currencyTo = 608;
                    break;
                case "WLC_QAR":
                case 634:
                    message.currencyTo = 634;
                    break;
                case "WLC_RUB":
                case 643:
                    message.currencyTo = 643;
                    break;
                case "WLC_RWF":
                case 646:
                    message.currencyTo = 646;
                    break;
                case "WLC_SHP":
                case 654:
                    message.currencyTo = 654;
                    break;
                case "WLC_STD":
                case 678:
                    message.currencyTo = 678;
                    break;
                case "WLC_SAR":
                case 682:
                    message.currencyTo = 682;
                    break;
                case "WLC_SCR":
                case 690:
                    message.currencyTo = 690;
                    break;
                case "WLC_SLL":
                case 694:
                    message.currencyTo = 694;
                    break;
                case "WLC_SGD":
                case 702:
                    message.currencyTo = 702;
                    break;
                case "WLC_SKK":
                case 703:
                    message.currencyTo = 703;
                    break;
                case "WLC_VND":
                case 704:
                    message.currencyTo = 704;
                    break;
                case "WLC_SOS":
                case 706:
                    message.currencyTo = 706;
                    break;
                case "WLC_ZAR":
                case 710:
                    message.currencyTo = 710;
                    break;
                case "WLC_ZWD":
                case 716:
                    message.currencyTo = 716;
                    break;
                case "WLC_SSP":
                case 728:
                    message.currencyTo = 728;
                    break;
                case "WLC_SZL":
                case 748:
                    message.currencyTo = 748;
                    break;
                case "WLC_SEK":
                case 752:
                    message.currencyTo = 752;
                    break;
                case "WLC_CHF":
                case 756:
                    message.currencyTo = 756;
                    break;
                case "WLC_SYP":
                case 760:
                    message.currencyTo = 760;
                    break;
                case "WLC_THB":
                case 764:
                    message.currencyTo = 764;
                    break;
                case "WLC_TOP":
                case 776:
                    message.currencyTo = 776;
                    break;
                case "WLC_TTD":
                case 780:
                    message.currencyTo = 780;
                    break;
                case "WLC_AED":
                case 784:
                    message.currencyTo = 784;
                    break;
                case "WLC_TND":
                case 788:
                    message.currencyTo = 788;
                    break;
                case "WLC_TMM":
                case 795:
                    message.currencyTo = 795;
                    break;
                case "WLC_UGX":
                case 800:
                    message.currencyTo = 800;
                    break;
                case "WLC_MKD":
                case 807:
                    message.currencyTo = 807;
                    break;
                case "WLC_RUR":
                case 810:
                    message.currencyTo = 810;
                    break;
                case "WLC_EGP":
                case 818:
                    message.currencyTo = 818;
                    break;
                case "WLC_GBP":
                case 826:
                    message.currencyTo = 826;
                    break;
                case "WLC_TZS":
                case 834:
                    message.currencyTo = 834;
                    break;
                case "WLC_USD":
                case 840:
                    message.currencyTo = 840;
                    break;
                case "WLC_UYU":
                case 858:
                    message.currencyTo = 858;
                    break;
                case "WLC_UZS":
                case 860:
                    message.currencyTo = 860;
                    break;
                case "WLC_WST":
                case 882:
                    message.currencyTo = 882;
                    break;
                case "WLC_YER":
                case 886:
                    message.currencyTo = 886;
                    break;
                case "WLC_ZMK":
                case 894:
                    message.currencyTo = 894;
                    break;
                case "WLC_TWD":
                case 901:
                    message.currencyTo = 901;
                    break;
                case "WLC_CUC":
                case 931:
                    message.currencyTo = 931;
                    break;
                case "WLC_ZWL":
                case 932:
                    message.currencyTo = 932;
                    break;
                case "WLC_BYN":
                case 933:
                    message.currencyTo = 933;
                    break;
                case "WLC_TMT":
                case 934:
                    message.currencyTo = 934;
                    break;
                case "WLC_GHC":
                case 936:
                    message.currencyTo = 936;
                    break;
                case "WLC_VEF":
                case 937:
                    message.currencyTo = 937;
                    break;
                case "WLC_SDG":
                case 938:
                    message.currencyTo = 938;
                    break;
                case "WLC_RSD":
                case 941:
                    message.currencyTo = 941;
                    break;
                case "WLC_MZN":
                case 943:
                    message.currencyTo = 943;
                    break;
                case "WLC_AZN":
                case 944:
                    message.currencyTo = 944;
                    break;
                case "WLC_RON":
                case 946:
                    message.currencyTo = 946;
                    break;
                case "WLC_TRY":
                case 949:
                    message.currencyTo = 949;
                    break;
                case "WLC_XAF":
                case 950:
                    message.currencyTo = 950;
                    break;
                case "WLC_XCD":
                case 951:
                    message.currencyTo = 951;
                    break;
                case "WLC_XOF":
                case 952:
                    message.currencyTo = 952;
                    break;
                case "WLC_XPF":
                case 953:
                    message.currencyTo = 953;
                    break;
                case "WLC_XAU":
                case 959:
                    message.currencyTo = 959;
                    break;
                case "WLC_XDR":
                case 960:
                    message.currencyTo = 960;
                    break;
                case "WLC_XAG":
                case 961:
                    message.currencyTo = 961;
                    break;
                case "WLC_ZMW":
                case 967:
                    message.currencyTo = 967;
                    break;
                case "WLC_SRD":
                case 968:
                    message.currencyTo = 968;
                    break;
                case "WLC_MGA":
                case 969:
                    message.currencyTo = 969;
                    break;
                case "WLC_AFN":
                case 971:
                    message.currencyTo = 971;
                    break;
                case "WLC_TJS":
                case 972:
                    message.currencyTo = 972;
                    break;
                case "WLC_AOA":
                case 973:
                    message.currencyTo = 973;
                    break;
                case "WLC_BGN":
                case 975:
                    message.currencyTo = 975;
                    break;
                case "WLC_CDF":
                case 976:
                    message.currencyTo = 976;
                    break;
                case "WLC_BAM":
                case 977:
                    message.currencyTo = 977;
                    break;
                case "WLC_EUR":
                case 978:
                    message.currencyTo = 978;
                    break;
                case "WLC_UAH":
                case 980:
                    message.currencyTo = 980;
                    break;
                case "WLC_GEL":
                case 981:
                    message.currencyTo = 981;
                    break;
                case "WLC_PLN":
                case 985:
                    message.currencyTo = 985;
                    break;
                case "WLC_BRL":
                case 986:
                    message.currencyTo = 986;
                    break;
                case "WLC_CLF":
                case 990:
                    message.currencyTo = 990;
                    break;
                case "WLC_ENS":
                case 6004:
                    message.currencyTo = 6004;
                    break;
                case "WLC_SSC":
                case 6005:
                    message.currencyTo = 6005;
                    break;
                case "WLC_GOC":
                case 6006:
                    message.currencyTo = 6006;
                    break;
                case "WLC_GGP":
                case 80001:
                    message.currencyTo = 80001;
                    break;
                case "WLC_IMP":
                case 80002:
                    message.currencyTo = 80002;
                    break;
                case "WLC_JEP":
                case 80003:
                    message.currencyTo = 80003;
                    break;
                case "WLC_LTCT":
                case 90001:
                    message.currencyTo = 90001;
                    break;
                case "WLC_BTC":
                case 90002:
                    message.currencyTo = 90002;
                    break;
                case "WLC_ETH":
                case 90003:
                    message.currencyTo = 90003;
                    break;
                case "WLC_DASH":
                case 90004:
                    message.currencyTo = 90004;
                    break;
                case "WLC_ZEC":
                case 90005:
                    message.currencyTo = 90005;
                    break;
                case "WLC_BCH":
                case 90006:
                    message.currencyTo = 90006;
                    break;
                case "WLC_LTC":
                case 90007:
                    message.currencyTo = 90007;
                    break;
                case "WLC_XRP":
                case 90008:
                    message.currencyTo = 90008;
                    break;
                case "WLC_ETN":
                case 90009:
                    message.currencyTo = 90009;
                    break;
                case "WLC_USDT":
                case 90010:
                    message.currencyTo = 90010;
                    break;
                case "WLC_USDTERC20":
                case 90011:
                    message.currencyTo = 90011;
                    break;
                case "WLC_USDTTRC20":
                case 90012:
                    message.currencyTo = 90012;
                    break;
                case "WLC_TRX":
                case 90013:
                    message.currencyTo = 90013;
                    break;
                case "WLC_USDCERC20":
                case 90014:
                    message.currencyTo = 90014;
                    break;
                case "WLC_WBTCERC20":
                case 90015:
                    message.currencyTo = 90015;
                    break;
                case "WLC_DAIERC20":
                case 90016:
                    message.currencyTo = 90016;
                    break;
                case "WLC_PEPEERC20":
                case 90017:
                    message.currencyTo = 90017;
                    break;
                case "WLC_FRAXERC20":
                case 90018:
                    message.currencyTo = 90018;
                    break;
                case "WLC_WEETHERC20":
                case 90019:
                    message.currencyTo = 90019;
                    break;
                case "WLC_MKRERC20":
                case 90020:
                    message.currencyTo = 90020;
                    break;
                case "WLC_USDMERC20":
                case 90021:
                    message.currencyTo = 90021;
                    break;
                case "WLC_LINKERC20":
                case 90022:
                    message.currencyTo = 90022;
                    break;
                case "WLC_UNIERC20":
                case 90024:
                    message.currencyTo = 90024;
                    break;
                case "WLC_DOGERC20":
                case 90025:
                    message.currencyTo = 90025;
                    break;
                case "WLC_FLCERC20":
                case 90026:
                    message.currencyTo = 90026;
                    break;
                case "WLC_METHERC20":
                case 90027:
                    message.currencyTo = 90027;
                    break;
                case "WLC_SHIBERC20":
                case 90028:
                    message.currencyTo = 90028;
                    break;
                case "WLC_ELONERC20":
                case 90029:
                    message.currencyTo = 90029;
                    break;
                case "WLC_RBNERC20":
                case 90030:
                    message.currencyTo = 90030;
                    break;
                case "WLC_WETHERC20":
                case 90032:
                    message.currencyTo = 90032;
                    break;
                }
                if (object.rate != null)
                    message.rate = String(object.rate);
                return message;
            };
    
            /**
             * Creates a plain object from an ExchangeRate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ExchangeRate
             * @static
             * @param {protocol.ExchangeRate} message ExchangeRate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExchangeRate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currencyFrom = options.enums === String ? "WLC_FUN" : 0;
                    object.currencyTo = options.enums === String ? "WLC_FUN" : 0;
                    object.rate = "";
                }
                if (message.currencyFrom != null && message.hasOwnProperty("currencyFrom"))
                    object.currencyFrom = options.enums === String ? $root.protocol.WLCurrency[message.currencyFrom] === undefined ? message.currencyFrom : $root.protocol.WLCurrency[message.currencyFrom] : message.currencyFrom;
                if (message.currencyTo != null && message.hasOwnProperty("currencyTo"))
                    object.currencyTo = options.enums === String ? $root.protocol.WLCurrency[message.currencyTo] === undefined ? message.currencyTo : $root.protocol.WLCurrency[message.currencyTo] : message.currencyTo;
                if (message.rate != null && message.hasOwnProperty("rate"))
                    object.rate = message.rate;
                return object;
            };
    
            /**
             * Converts this ExchangeRate to JSON.
             * @function toJSON
             * @memberof protocol.ExchangeRate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExchangeRate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ExchangeRate
             * @function getTypeUrl
             * @memberof protocol.ExchangeRate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ExchangeRate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.ExchangeRate";
            };
    
            return ExchangeRate;
        })();
    
        protocol.ExchangeRates = (function() {
    
            /**
             * Properties of an ExchangeRates.
             * @memberof protocol
             * @interface IExchangeRates
             * @property {Array.<protocol.IExchangeRate>|null} [rates] ExchangeRates rates
             */
    
            /**
             * Constructs a new ExchangeRates.
             * @memberof protocol
             * @classdesc Represents an ExchangeRates.
             * @implements IExchangeRates
             * @constructor
             * @param {protocol.IExchangeRates=} [properties] Properties to set
             */
            function ExchangeRates(properties) {
                this.rates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ExchangeRates rates.
             * @member {Array.<protocol.IExchangeRate>} rates
             * @memberof protocol.ExchangeRates
             * @instance
             */
            ExchangeRates.prototype.rates = $util.emptyArray;
    
            /**
             * Creates a new ExchangeRates instance using the specified properties.
             * @function create
             * @memberof protocol.ExchangeRates
             * @static
             * @param {protocol.IExchangeRates=} [properties] Properties to set
             * @returns {protocol.ExchangeRates} ExchangeRates instance
             */
            ExchangeRates.create = function create(properties) {
                return new ExchangeRates(properties);
            };
    
            /**
             * Encodes the specified ExchangeRates message. Does not implicitly {@link protocol.ExchangeRates.verify|verify} messages.
             * @function encode
             * @memberof protocol.ExchangeRates
             * @static
             * @param {protocol.IExchangeRates} message ExchangeRates message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRates.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rates != null && message.rates.length)
                    for (var i = 0; i < message.rates.length; ++i)
                        $root.protocol.ExchangeRate.encode(message.rates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ExchangeRates message, length delimited. Does not implicitly {@link protocol.ExchangeRates.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.ExchangeRates
             * @static
             * @param {protocol.IExchangeRates} message ExchangeRates message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExchangeRates.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an ExchangeRates message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.ExchangeRates
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.ExchangeRates} ExchangeRates
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRates.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.ExchangeRates();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.rates && message.rates.length))
                                message.rates = [];
                            message.rates.push($root.protocol.ExchangeRate.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an ExchangeRates message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.ExchangeRates
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.ExchangeRates} ExchangeRates
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExchangeRates.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an ExchangeRates message.
             * @function verify
             * @memberof protocol.ExchangeRates
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExchangeRates.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.rates != null && message.hasOwnProperty("rates")) {
                    if (!Array.isArray(message.rates))
                        return "rates: array expected";
                    for (var i = 0; i < message.rates.length; ++i) {
                        var error = $root.protocol.ExchangeRate.verify(message.rates[i]);
                        if (error)
                            return "rates." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an ExchangeRates message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.ExchangeRates
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.ExchangeRates} ExchangeRates
             */
            ExchangeRates.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.ExchangeRates)
                    return object;
                var message = new $root.protocol.ExchangeRates();
                if (object.rates) {
                    if (!Array.isArray(object.rates))
                        throw TypeError(".protocol.ExchangeRates.rates: array expected");
                    message.rates = [];
                    for (var i = 0; i < object.rates.length; ++i) {
                        if (typeof object.rates[i] !== "object")
                            throw TypeError(".protocol.ExchangeRates.rates: object expected");
                        message.rates[i] = $root.protocol.ExchangeRate.fromObject(object.rates[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an ExchangeRates message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.ExchangeRates
             * @static
             * @param {protocol.ExchangeRates} message ExchangeRates
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExchangeRates.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.rates = [];
                if (message.rates && message.rates.length) {
                    object.rates = [];
                    for (var j = 0; j < message.rates.length; ++j)
                        object.rates[j] = $root.protocol.ExchangeRate.toObject(message.rates[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ExchangeRates to JSON.
             * @function toJSON
             * @memberof protocol.ExchangeRates
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExchangeRates.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ExchangeRates
             * @function getTypeUrl
             * @memberof protocol.ExchangeRates
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ExchangeRates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.ExchangeRates";
            };
    
            return ExchangeRates;
        })();
    
        protocol.TronData = (function() {
    
            /**
             * Properties of a TronData.
             * @memberof protocol
             * @interface ITronData
             * @property {protocol.ITronBlock|null} [block] TronData block
             * @property {Array.<protocol.ITronTransactionInfo>|null} [txInfo] TronData txInfo
             */
    
            /**
             * Constructs a new TronData.
             * @memberof protocol
             * @classdesc Represents a TronData.
             * @implements ITronData
             * @constructor
             * @param {protocol.ITronData=} [properties] Properties to set
             */
            function TronData(properties) {
                this.txInfo = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronData block.
             * @member {protocol.ITronBlock|null|undefined} block
             * @memberof protocol.TronData
             * @instance
             */
            TronData.prototype.block = null;
    
            /**
             * TronData txInfo.
             * @member {Array.<protocol.ITronTransactionInfo>} txInfo
             * @memberof protocol.TronData
             * @instance
             */
            TronData.prototype.txInfo = $util.emptyArray;
    
            /**
             * Creates a new TronData instance using the specified properties.
             * @function create
             * @memberof protocol.TronData
             * @static
             * @param {protocol.ITronData=} [properties] Properties to set
             * @returns {protocol.TronData} TronData instance
             */
            TronData.create = function create(properties) {
                return new TronData(properties);
            };
    
            /**
             * Encodes the specified TronData message. Does not implicitly {@link protocol.TronData.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronData
             * @static
             * @param {protocol.ITronData} message TronData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.block != null && Object.hasOwnProperty.call(message, "block"))
                    $root.protocol.TronBlock.encode(message.block, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.txInfo != null && message.txInfo.length)
                    for (var i = 0; i < message.txInfo.length; ++i)
                        $root.protocol.TronTransactionInfo.encode(message.txInfo[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronData message, length delimited. Does not implicitly {@link protocol.TronData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronData
             * @static
             * @param {protocol.ITronData} message TronData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronData message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronData} TronData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.block = $root.protocol.TronBlock.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.txInfo && message.txInfo.length))
                                message.txInfo = [];
                            message.txInfo.push($root.protocol.TronTransactionInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronData} TronData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronData message.
             * @function verify
             * @memberof protocol.TronData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.block != null && message.hasOwnProperty("block")) {
                    var error = $root.protocol.TronBlock.verify(message.block);
                    if (error)
                        return "block." + error;
                }
                if (message.txInfo != null && message.hasOwnProperty("txInfo")) {
                    if (!Array.isArray(message.txInfo))
                        return "txInfo: array expected";
                    for (var i = 0; i < message.txInfo.length; ++i) {
                        var error = $root.protocol.TronTransactionInfo.verify(message.txInfo[i]);
                        if (error)
                            return "txInfo." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TronData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronData} TronData
             */
            TronData.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronData)
                    return object;
                var message = new $root.protocol.TronData();
                if (object.block != null) {
                    if (typeof object.block !== "object")
                        throw TypeError(".protocol.TronData.block: object expected");
                    message.block = $root.protocol.TronBlock.fromObject(object.block);
                }
                if (object.txInfo) {
                    if (!Array.isArray(object.txInfo))
                        throw TypeError(".protocol.TronData.txInfo: array expected");
                    message.txInfo = [];
                    for (var i = 0; i < object.txInfo.length; ++i) {
                        if (typeof object.txInfo[i] !== "object")
                            throw TypeError(".protocol.TronData.txInfo: object expected");
                        message.txInfo[i] = $root.protocol.TronTransactionInfo.fromObject(object.txInfo[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronData
             * @static
             * @param {protocol.TronData} message TronData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.txInfo = [];
                if (options.defaults)
                    object.block = null;
                if (message.block != null && message.hasOwnProperty("block"))
                    object.block = $root.protocol.TronBlock.toObject(message.block, options);
                if (message.txInfo && message.txInfo.length) {
                    object.txInfo = [];
                    for (var j = 0; j < message.txInfo.length; ++j)
                        object.txInfo[j] = $root.protocol.TronTransactionInfo.toObject(message.txInfo[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TronData to JSON.
             * @function toJSON
             * @memberof protocol.TronData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronData
             * @function getTypeUrl
             * @memberof protocol.TronData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronData";
            };
    
            return TronData;
        })();
    
        protocol.TronBlock = (function() {
    
            /**
             * Properties of a TronBlock.
             * @memberof protocol
             * @interface ITronBlock
             * @property {Uint8Array|null} [blockId] TronBlock blockId
             * @property {protocol.ITronBlockHeader|null} [header] TronBlock header
             * @property {Array.<protocol.ITronTransaction>|null} [transactions] TronBlock transactions
             */
    
            /**
             * Constructs a new TronBlock.
             * @memberof protocol
             * @classdesc Represents a TronBlock.
             * @implements ITronBlock
             * @constructor
             * @param {protocol.ITronBlock=} [properties] Properties to set
             */
            function TronBlock(properties) {
                this.transactions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronBlock blockId.
             * @member {Uint8Array} blockId
             * @memberof protocol.TronBlock
             * @instance
             */
            TronBlock.prototype.blockId = $util.newBuffer([]);
    
            /**
             * TronBlock header.
             * @member {protocol.ITronBlockHeader|null|undefined} header
             * @memberof protocol.TronBlock
             * @instance
             */
            TronBlock.prototype.header = null;
    
            /**
             * TronBlock transactions.
             * @member {Array.<protocol.ITronTransaction>} transactions
             * @memberof protocol.TronBlock
             * @instance
             */
            TronBlock.prototype.transactions = $util.emptyArray;
    
            /**
             * Creates a new TronBlock instance using the specified properties.
             * @function create
             * @memberof protocol.TronBlock
             * @static
             * @param {protocol.ITronBlock=} [properties] Properties to set
             * @returns {protocol.TronBlock} TronBlock instance
             */
            TronBlock.create = function create(properties) {
                return new TronBlock(properties);
            };
    
            /**
             * Encodes the specified TronBlock message. Does not implicitly {@link protocol.TronBlock.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronBlock
             * @static
             * @param {protocol.ITronBlock} message TronBlock message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlock.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.blockId != null && Object.hasOwnProperty.call(message, "blockId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.blockId);
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.protocol.TronBlockHeader.encode(message.header, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.transactions != null && message.transactions.length)
                    for (var i = 0; i < message.transactions.length; ++i)
                        $root.protocol.TronTransaction.encode(message.transactions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronBlock message, length delimited. Does not implicitly {@link protocol.TronBlock.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronBlock
             * @static
             * @param {protocol.ITronBlock} message TronBlock message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlock.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronBlock message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronBlock
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronBlock} TronBlock
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlock.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronBlock();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.blockId = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.header = $root.protocol.TronBlockHeader.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            if (!(message.transactions && message.transactions.length))
                                message.transactions = [];
                            message.transactions.push($root.protocol.TronTransaction.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronBlock message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronBlock
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronBlock} TronBlock
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlock.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronBlock message.
             * @function verify
             * @memberof protocol.TronBlock
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronBlock.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.blockId != null && message.hasOwnProperty("blockId"))
                    if (!(message.blockId && typeof message.blockId.length === "number" || $util.isString(message.blockId)))
                        return "blockId: buffer expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.protocol.TronBlockHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.transactions != null && message.hasOwnProperty("transactions")) {
                    if (!Array.isArray(message.transactions))
                        return "transactions: array expected";
                    for (var i = 0; i < message.transactions.length; ++i) {
                        var error = $root.protocol.TronTransaction.verify(message.transactions[i]);
                        if (error)
                            return "transactions." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TronBlock message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronBlock
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronBlock} TronBlock
             */
            TronBlock.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronBlock)
                    return object;
                var message = new $root.protocol.TronBlock();
                if (object.blockId != null)
                    if (typeof object.blockId === "string")
                        $util.base64.decode(object.blockId, message.blockId = $util.newBuffer($util.base64.length(object.blockId)), 0);
                    else if (object.blockId.length >= 0)
                        message.blockId = object.blockId;
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".protocol.TronBlock.header: object expected");
                    message.header = $root.protocol.TronBlockHeader.fromObject(object.header);
                }
                if (object.transactions) {
                    if (!Array.isArray(object.transactions))
                        throw TypeError(".protocol.TronBlock.transactions: array expected");
                    message.transactions = [];
                    for (var i = 0; i < object.transactions.length; ++i) {
                        if (typeof object.transactions[i] !== "object")
                            throw TypeError(".protocol.TronBlock.transactions: object expected");
                        message.transactions[i] = $root.protocol.TronTransaction.fromObject(object.transactions[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronBlock message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronBlock
             * @static
             * @param {protocol.TronBlock} message TronBlock
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronBlock.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.transactions = [];
                if (options.defaults) {
                    if (options.bytes === String)
                        object.blockId = "";
                    else {
                        object.blockId = [];
                        if (options.bytes !== Array)
                            object.blockId = $util.newBuffer(object.blockId);
                    }
                    object.header = null;
                }
                if (message.blockId != null && message.hasOwnProperty("blockId"))
                    object.blockId = options.bytes === String ? $util.base64.encode(message.blockId, 0, message.blockId.length) : options.bytes === Array ? Array.prototype.slice.call(message.blockId) : message.blockId;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.protocol.TronBlockHeader.toObject(message.header, options);
                if (message.transactions && message.transactions.length) {
                    object.transactions = [];
                    for (var j = 0; j < message.transactions.length; ++j)
                        object.transactions[j] = $root.protocol.TronTransaction.toObject(message.transactions[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TronBlock to JSON.
             * @function toJSON
             * @memberof protocol.TronBlock
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronBlock.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronBlock
             * @function getTypeUrl
             * @memberof protocol.TronBlock
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronBlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronBlock";
            };
    
            return TronBlock;
        })();
    
        protocol.TronBlockHeader = (function() {
    
            /**
             * Properties of a TronBlockHeader.
             * @memberof protocol
             * @interface ITronBlockHeader
             * @property {Long|null} [timestamp] TronBlockHeader timestamp
             * @property {Uint8Array|null} [txTrieRoot] TronBlockHeader txTrieRoot
             * @property {Uint8Array|null} [parentHash] TronBlockHeader parentHash
             * @property {Long|null} [number] TronBlockHeader number
             * @property {Long|null} [witnessId] TronBlockHeader witnessId
             * @property {Uint8Array|null} [witnessAddress] TronBlockHeader witnessAddress
             * @property {number|null} [version] TronBlockHeader version
             * @property {Uint8Array|null} [accountStateRoot] TronBlockHeader accountStateRoot
             * @property {Uint8Array|null} [witnessSignature] TronBlockHeader witnessSignature
             */
    
            /**
             * Constructs a new TronBlockHeader.
             * @memberof protocol
             * @classdesc Represents a TronBlockHeader.
             * @implements ITronBlockHeader
             * @constructor
             * @param {protocol.ITronBlockHeader=} [properties] Properties to set
             */
            function TronBlockHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronBlockHeader timestamp.
             * @member {Long} timestamp
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronBlockHeader txTrieRoot.
             * @member {Uint8Array} txTrieRoot
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.txTrieRoot = $util.newBuffer([]);
    
            /**
             * TronBlockHeader parentHash.
             * @member {Uint8Array} parentHash
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.parentHash = $util.newBuffer([]);
    
            /**
             * TronBlockHeader number.
             * @member {Long} number
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.number = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronBlockHeader witnessId.
             * @member {Long} witnessId
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.witnessId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronBlockHeader witnessAddress.
             * @member {Uint8Array} witnessAddress
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.witnessAddress = $util.newBuffer([]);
    
            /**
             * TronBlockHeader version.
             * @member {number} version
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.version = 0;
    
            /**
             * TronBlockHeader accountStateRoot.
             * @member {Uint8Array} accountStateRoot
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.accountStateRoot = $util.newBuffer([]);
    
            /**
             * TronBlockHeader witnessSignature.
             * @member {Uint8Array} witnessSignature
             * @memberof protocol.TronBlockHeader
             * @instance
             */
            TronBlockHeader.prototype.witnessSignature = $util.newBuffer([]);
    
            /**
             * Creates a new TronBlockHeader instance using the specified properties.
             * @function create
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {protocol.ITronBlockHeader=} [properties] Properties to set
             * @returns {protocol.TronBlockHeader} TronBlockHeader instance
             */
            TronBlockHeader.create = function create(properties) {
                return new TronBlockHeader(properties);
            };
    
            /**
             * Encodes the specified TronBlockHeader message. Does not implicitly {@link protocol.TronBlockHeader.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {protocol.ITronBlockHeader} message TronBlockHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlockHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestamp);
                if (message.txTrieRoot != null && Object.hasOwnProperty.call(message, "txTrieRoot"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.txTrieRoot);
                if (message.parentHash != null && Object.hasOwnProperty.call(message, "parentHash"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.parentHash);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.number);
                if (message.witnessId != null && Object.hasOwnProperty.call(message, "witnessId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.witnessId);
                if (message.witnessAddress != null && Object.hasOwnProperty.call(message, "witnessAddress"))
                    writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.witnessAddress);
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.version);
                if (message.accountStateRoot != null && Object.hasOwnProperty.call(message, "accountStateRoot"))
                    writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.accountStateRoot);
                if (message.witnessSignature != null && Object.hasOwnProperty.call(message, "witnessSignature"))
                    writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.witnessSignature);
                return writer;
            };
    
            /**
             * Encodes the specified TronBlockHeader message, length delimited. Does not implicitly {@link protocol.TronBlockHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {protocol.ITronBlockHeader} message TronBlockHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronBlockHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronBlockHeader message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronBlockHeader} TronBlockHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlockHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronBlockHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.timestamp = reader.int64();
                            break;
                        }
                    case 2: {
                            message.txTrieRoot = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.parentHash = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.number = reader.int64();
                            break;
                        }
                    case 5: {
                            message.witnessId = reader.int64();
                            break;
                        }
                    case 6: {
                            message.witnessAddress = reader.bytes();
                            break;
                        }
                    case 7: {
                            message.version = reader.int32();
                            break;
                        }
                    case 8: {
                            message.accountStateRoot = reader.bytes();
                            break;
                        }
                    case 9: {
                            message.witnessSignature = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronBlockHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronBlockHeader} TronBlockHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronBlockHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronBlockHeader message.
             * @function verify
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronBlockHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.txTrieRoot != null && message.hasOwnProperty("txTrieRoot"))
                    if (!(message.txTrieRoot && typeof message.txTrieRoot.length === "number" || $util.isString(message.txTrieRoot)))
                        return "txTrieRoot: buffer expected";
                if (message.parentHash != null && message.hasOwnProperty("parentHash"))
                    if (!(message.parentHash && typeof message.parentHash.length === "number" || $util.isString(message.parentHash)))
                        return "parentHash: buffer expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number) && !(message.number && $util.isInteger(message.number.low) && $util.isInteger(message.number.high)))
                        return "number: integer|Long expected";
                if (message.witnessId != null && message.hasOwnProperty("witnessId"))
                    if (!$util.isInteger(message.witnessId) && !(message.witnessId && $util.isInteger(message.witnessId.low) && $util.isInteger(message.witnessId.high)))
                        return "witnessId: integer|Long expected";
                if (message.witnessAddress != null && message.hasOwnProperty("witnessAddress"))
                    if (!(message.witnessAddress && typeof message.witnessAddress.length === "number" || $util.isString(message.witnessAddress)))
                        return "witnessAddress: buffer expected";
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isInteger(message.version))
                        return "version: integer expected";
                if (message.accountStateRoot != null && message.hasOwnProperty("accountStateRoot"))
                    if (!(message.accountStateRoot && typeof message.accountStateRoot.length === "number" || $util.isString(message.accountStateRoot)))
                        return "accountStateRoot: buffer expected";
                if (message.witnessSignature != null && message.hasOwnProperty("witnessSignature"))
                    if (!(message.witnessSignature && typeof message.witnessSignature.length === "number" || $util.isString(message.witnessSignature)))
                        return "witnessSignature: buffer expected";
                return null;
            };
    
            /**
             * Creates a TronBlockHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronBlockHeader} TronBlockHeader
             */
            TronBlockHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronBlockHeader)
                    return object;
                var message = new $root.protocol.TronBlockHeader();
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.txTrieRoot != null)
                    if (typeof object.txTrieRoot === "string")
                        $util.base64.decode(object.txTrieRoot, message.txTrieRoot = $util.newBuffer($util.base64.length(object.txTrieRoot)), 0);
                    else if (object.txTrieRoot.length >= 0)
                        message.txTrieRoot = object.txTrieRoot;
                if (object.parentHash != null)
                    if (typeof object.parentHash === "string")
                        $util.base64.decode(object.parentHash, message.parentHash = $util.newBuffer($util.base64.length(object.parentHash)), 0);
                    else if (object.parentHash.length >= 0)
                        message.parentHash = object.parentHash;
                if (object.number != null)
                    if ($util.Long)
                        (message.number = $util.Long.fromValue(object.number)).unsigned = false;
                    else if (typeof object.number === "string")
                        message.number = parseInt(object.number, 10);
                    else if (typeof object.number === "number")
                        message.number = object.number;
                    else if (typeof object.number === "object")
                        message.number = new $util.LongBits(object.number.low >>> 0, object.number.high >>> 0).toNumber();
                if (object.witnessId != null)
                    if ($util.Long)
                        (message.witnessId = $util.Long.fromValue(object.witnessId)).unsigned = false;
                    else if (typeof object.witnessId === "string")
                        message.witnessId = parseInt(object.witnessId, 10);
                    else if (typeof object.witnessId === "number")
                        message.witnessId = object.witnessId;
                    else if (typeof object.witnessId === "object")
                        message.witnessId = new $util.LongBits(object.witnessId.low >>> 0, object.witnessId.high >>> 0).toNumber();
                if (object.witnessAddress != null)
                    if (typeof object.witnessAddress === "string")
                        $util.base64.decode(object.witnessAddress, message.witnessAddress = $util.newBuffer($util.base64.length(object.witnessAddress)), 0);
                    else if (object.witnessAddress.length >= 0)
                        message.witnessAddress = object.witnessAddress;
                if (object.version != null)
                    message.version = object.version | 0;
                if (object.accountStateRoot != null)
                    if (typeof object.accountStateRoot === "string")
                        $util.base64.decode(object.accountStateRoot, message.accountStateRoot = $util.newBuffer($util.base64.length(object.accountStateRoot)), 0);
                    else if (object.accountStateRoot.length >= 0)
                        message.accountStateRoot = object.accountStateRoot;
                if (object.witnessSignature != null)
                    if (typeof object.witnessSignature === "string")
                        $util.base64.decode(object.witnessSignature, message.witnessSignature = $util.newBuffer($util.base64.length(object.witnessSignature)), 0);
                    else if (object.witnessSignature.length >= 0)
                        message.witnessSignature = object.witnessSignature;
                return message;
            };
    
            /**
             * Creates a plain object from a TronBlockHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {protocol.TronBlockHeader} message TronBlockHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronBlockHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.txTrieRoot = "";
                    else {
                        object.txTrieRoot = [];
                        if (options.bytes !== Array)
                            object.txTrieRoot = $util.newBuffer(object.txTrieRoot);
                    }
                    if (options.bytes === String)
                        object.parentHash = "";
                    else {
                        object.parentHash = [];
                        if (options.bytes !== Array)
                            object.parentHash = $util.newBuffer(object.parentHash);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.number = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.number = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.witnessId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.witnessId = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.witnessAddress = "";
                    else {
                        object.witnessAddress = [];
                        if (options.bytes !== Array)
                            object.witnessAddress = $util.newBuffer(object.witnessAddress);
                    }
                    object.version = 0;
                    if (options.bytes === String)
                        object.accountStateRoot = "";
                    else {
                        object.accountStateRoot = [];
                        if (options.bytes !== Array)
                            object.accountStateRoot = $util.newBuffer(object.accountStateRoot);
                    }
                    if (options.bytes === String)
                        object.witnessSignature = "";
                    else {
                        object.witnessSignature = [];
                        if (options.bytes !== Array)
                            object.witnessSignature = $util.newBuffer(object.witnessSignature);
                    }
                }
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.txTrieRoot != null && message.hasOwnProperty("txTrieRoot"))
                    object.txTrieRoot = options.bytes === String ? $util.base64.encode(message.txTrieRoot, 0, message.txTrieRoot.length) : options.bytes === Array ? Array.prototype.slice.call(message.txTrieRoot) : message.txTrieRoot;
                if (message.parentHash != null && message.hasOwnProperty("parentHash"))
                    object.parentHash = options.bytes === String ? $util.base64.encode(message.parentHash, 0, message.parentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.parentHash) : message.parentHash;
                if (message.number != null && message.hasOwnProperty("number"))
                    if (typeof message.number === "number")
                        object.number = options.longs === String ? String(message.number) : message.number;
                    else
                        object.number = options.longs === String ? $util.Long.prototype.toString.call(message.number) : options.longs === Number ? new $util.LongBits(message.number.low >>> 0, message.number.high >>> 0).toNumber() : message.number;
                if (message.witnessId != null && message.hasOwnProperty("witnessId"))
                    if (typeof message.witnessId === "number")
                        object.witnessId = options.longs === String ? String(message.witnessId) : message.witnessId;
                    else
                        object.witnessId = options.longs === String ? $util.Long.prototype.toString.call(message.witnessId) : options.longs === Number ? new $util.LongBits(message.witnessId.low >>> 0, message.witnessId.high >>> 0).toNumber() : message.witnessId;
                if (message.witnessAddress != null && message.hasOwnProperty("witnessAddress"))
                    object.witnessAddress = options.bytes === String ? $util.base64.encode(message.witnessAddress, 0, message.witnessAddress.length) : options.bytes === Array ? Array.prototype.slice.call(message.witnessAddress) : message.witnessAddress;
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = message.version;
                if (message.accountStateRoot != null && message.hasOwnProperty("accountStateRoot"))
                    object.accountStateRoot = options.bytes === String ? $util.base64.encode(message.accountStateRoot, 0, message.accountStateRoot.length) : options.bytes === Array ? Array.prototype.slice.call(message.accountStateRoot) : message.accountStateRoot;
                if (message.witnessSignature != null && message.hasOwnProperty("witnessSignature"))
                    object.witnessSignature = options.bytes === String ? $util.base64.encode(message.witnessSignature, 0, message.witnessSignature.length) : options.bytes === Array ? Array.prototype.slice.call(message.witnessSignature) : message.witnessSignature;
                return object;
            };
    
            /**
             * Converts this TronBlockHeader to JSON.
             * @function toJSON
             * @memberof protocol.TronBlockHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronBlockHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronBlockHeader
             * @function getTypeUrl
             * @memberof protocol.TronBlockHeader
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronBlockHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronBlockHeader";
            };
    
            return TronBlockHeader;
        })();
    
        protocol.TronTransaction = (function() {
    
            /**
             * Properties of a TronTransaction.
             * @memberof protocol
             * @interface ITronTransaction
             * @property {Uint8Array|null} [txId] TronTransaction txId
             * @property {Array.<Uint8Array>|null} [constantResult] TronTransaction constantResult
             * @property {protocol.ITronReturn|null} [result] TronTransaction result
             * @property {Long|null} [energyUsed] TronTransaction energyUsed
             * @property {Array.<protocol.ITronLog>|null} [logs] TronTransaction logs
             * @property {Array.<protocol.ITronInternalTransaction>|null} [internalTransactions] TronTransaction internalTransactions
             * @property {Long|null} [energyPenalty] TronTransaction energyPenalty
             * @property {Uint8Array|null} [refBlockBytes] TronTransaction refBlockBytes
             * @property {Long|null} [refBlockNum] TronTransaction refBlockNum
             * @property {Uint8Array|null} [refBlockHash] TronTransaction refBlockHash
             * @property {Long|null} [expiration] TronTransaction expiration
             * @property {Array.<protocol.ITronAuthority>|null} [auths] TronTransaction auths
             * @property {Uint8Array|null} [data] TronTransaction data
             * @property {Array.<protocol.ITronContract>|null} [contract] TronTransaction contract
             * @property {Uint8Array|null} [scripts] TronTransaction scripts
             * @property {Long|null} [timestamp] TronTransaction timestamp
             * @property {Long|null} [feeLimit] TronTransaction feeLimit
             * @property {Array.<Uint8Array>|null} [signature] TronTransaction signature
             * @property {Array.<protocol.ITronTransactionResult>|null} [ret] TronTransaction ret
             */
    
            /**
             * Constructs a new TronTransaction.
             * @memberof protocol
             * @classdesc Represents a TronTransaction.
             * @implements ITronTransaction
             * @constructor
             * @param {protocol.ITronTransaction=} [properties] Properties to set
             */
            function TronTransaction(properties) {
                this.constantResult = [];
                this.logs = [];
                this.internalTransactions = [];
                this.auths = [];
                this.contract = [];
                this.signature = [];
                this.ret = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronTransaction txId.
             * @member {Uint8Array} txId
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.txId = $util.newBuffer([]);
    
            /**
             * TronTransaction constantResult.
             * @member {Array.<Uint8Array>} constantResult
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.constantResult = $util.emptyArray;
    
            /**
             * TronTransaction result.
             * @member {protocol.ITronReturn|null|undefined} result
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.result = null;
    
            /**
             * TronTransaction energyUsed.
             * @member {Long} energyUsed
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.energyUsed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransaction logs.
             * @member {Array.<protocol.ITronLog>} logs
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.logs = $util.emptyArray;
    
            /**
             * TronTransaction internalTransactions.
             * @member {Array.<protocol.ITronInternalTransaction>} internalTransactions
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.internalTransactions = $util.emptyArray;
    
            /**
             * TronTransaction energyPenalty.
             * @member {Long} energyPenalty
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.energyPenalty = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransaction refBlockBytes.
             * @member {Uint8Array} refBlockBytes
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.refBlockBytes = $util.newBuffer([]);
    
            /**
             * TronTransaction refBlockNum.
             * @member {Long} refBlockNum
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.refBlockNum = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransaction refBlockHash.
             * @member {Uint8Array} refBlockHash
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.refBlockHash = $util.newBuffer([]);
    
            /**
             * TronTransaction expiration.
             * @member {Long} expiration
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.expiration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransaction auths.
             * @member {Array.<protocol.ITronAuthority>} auths
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.auths = $util.emptyArray;
    
            /**
             * TronTransaction data.
             * @member {Uint8Array} data
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.data = $util.newBuffer([]);
    
            /**
             * TronTransaction contract.
             * @member {Array.<protocol.ITronContract>} contract
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.contract = $util.emptyArray;
    
            /**
             * TronTransaction scripts.
             * @member {Uint8Array} scripts
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.scripts = $util.newBuffer([]);
    
            /**
             * TronTransaction timestamp.
             * @member {Long} timestamp
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransaction feeLimit.
             * @member {Long} feeLimit
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.feeLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransaction signature.
             * @member {Array.<Uint8Array>} signature
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.signature = $util.emptyArray;
    
            /**
             * TronTransaction ret.
             * @member {Array.<protocol.ITronTransactionResult>} ret
             * @memberof protocol.TronTransaction
             * @instance
             */
            TronTransaction.prototype.ret = $util.emptyArray;
    
            /**
             * Creates a new TronTransaction instance using the specified properties.
             * @function create
             * @memberof protocol.TronTransaction
             * @static
             * @param {protocol.ITronTransaction=} [properties] Properties to set
             * @returns {protocol.TronTransaction} TronTransaction instance
             */
            TronTransaction.create = function create(properties) {
                return new TronTransaction(properties);
            };
    
            /**
             * Encodes the specified TronTransaction message. Does not implicitly {@link protocol.TronTransaction.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronTransaction
             * @static
             * @param {protocol.ITronTransaction} message TronTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTransaction.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.txId != null && Object.hasOwnProperty.call(message, "txId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.txId);
                if (message.constantResult != null && message.constantResult.length)
                    for (var i = 0; i < message.constantResult.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.constantResult[i]);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.protocol.TronReturn.encode(message.result, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.energyUsed != null && Object.hasOwnProperty.call(message, "energyUsed"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.energyUsed);
                if (message.logs != null && message.logs.length)
                    for (var i = 0; i < message.logs.length; ++i)
                        $root.protocol.TronLog.encode(message.logs[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.internalTransactions != null && message.internalTransactions.length)
                    for (var i = 0; i < message.internalTransactions.length; ++i)
                        $root.protocol.TronInternalTransaction.encode(message.internalTransactions[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.energyPenalty != null && Object.hasOwnProperty.call(message, "energyPenalty"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.energyPenalty);
                if (message.refBlockBytes != null && Object.hasOwnProperty.call(message, "refBlockBytes"))
                    writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.refBlockBytes);
                if (message.refBlockNum != null && Object.hasOwnProperty.call(message, "refBlockNum"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.refBlockNum);
                if (message.refBlockHash != null && Object.hasOwnProperty.call(message, "refBlockHash"))
                    writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.refBlockHash);
                if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int64(message.expiration);
                if (message.auths != null && message.auths.length)
                    for (var i = 0; i < message.auths.length; ++i)
                        $root.protocol.TronAuthority.encode(message.auths[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 13, wireType 2 =*/106).bytes(message.data);
                if (message.contract != null && message.contract.length)
                    for (var i = 0; i < message.contract.length; ++i)
                        $root.protocol.TronContract.encode(message.contract[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.scripts != null && Object.hasOwnProperty.call(message, "scripts"))
                    writer.uint32(/* id 15, wireType 2 =*/122).bytes(message.scripts);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int64(message.timestamp);
                if (message.feeLimit != null && Object.hasOwnProperty.call(message, "feeLimit"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int64(message.feeLimit);
                if (message.signature != null && message.signature.length)
                    for (var i = 0; i < message.signature.length; ++i)
                        writer.uint32(/* id 18, wireType 2 =*/146).bytes(message.signature[i]);
                if (message.ret != null && message.ret.length)
                    for (var i = 0; i < message.ret.length; ++i)
                        $root.protocol.TronTransactionResult.encode(message.ret[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronTransaction message, length delimited. Does not implicitly {@link protocol.TronTransaction.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronTransaction
             * @static
             * @param {protocol.ITronTransaction} message TronTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronTransaction message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronTransaction} TronTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTransaction.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronTransaction();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.txId = reader.bytes();
                            break;
                        }
                    case 2: {
                            if (!(message.constantResult && message.constantResult.length))
                                message.constantResult = [];
                            message.constantResult.push(reader.bytes());
                            break;
                        }
                    case 3: {
                            message.result = $root.protocol.TronReturn.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.energyUsed = reader.int64();
                            break;
                        }
                    case 5: {
                            if (!(message.logs && message.logs.length))
                                message.logs = [];
                            message.logs.push($root.protocol.TronLog.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.internalTransactions && message.internalTransactions.length))
                                message.internalTransactions = [];
                            message.internalTransactions.push($root.protocol.TronInternalTransaction.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            message.energyPenalty = reader.int64();
                            break;
                        }
                    case 8: {
                            message.refBlockBytes = reader.bytes();
                            break;
                        }
                    case 9: {
                            message.refBlockNum = reader.int64();
                            break;
                        }
                    case 10: {
                            message.refBlockHash = reader.bytes();
                            break;
                        }
                    case 11: {
                            message.expiration = reader.int64();
                            break;
                        }
                    case 12: {
                            if (!(message.auths && message.auths.length))
                                message.auths = [];
                            message.auths.push($root.protocol.TronAuthority.decode(reader, reader.uint32()));
                            break;
                        }
                    case 13: {
                            message.data = reader.bytes();
                            break;
                        }
                    case 14: {
                            if (!(message.contract && message.contract.length))
                                message.contract = [];
                            message.contract.push($root.protocol.TronContract.decode(reader, reader.uint32()));
                            break;
                        }
                    case 15: {
                            message.scripts = reader.bytes();
                            break;
                        }
                    case 16: {
                            message.timestamp = reader.int64();
                            break;
                        }
                    case 17: {
                            message.feeLimit = reader.int64();
                            break;
                        }
                    case 18: {
                            if (!(message.signature && message.signature.length))
                                message.signature = [];
                            message.signature.push(reader.bytes());
                            break;
                        }
                    case 19: {
                            if (!(message.ret && message.ret.length))
                                message.ret = [];
                            message.ret.push($root.protocol.TronTransactionResult.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronTransaction message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronTransaction} TronTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTransaction.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronTransaction message.
             * @function verify
             * @memberof protocol.TronTransaction
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronTransaction.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.txId != null && message.hasOwnProperty("txId"))
                    if (!(message.txId && typeof message.txId.length === "number" || $util.isString(message.txId)))
                        return "txId: buffer expected";
                if (message.constantResult != null && message.hasOwnProperty("constantResult")) {
                    if (!Array.isArray(message.constantResult))
                        return "constantResult: array expected";
                    for (var i = 0; i < message.constantResult.length; ++i)
                        if (!(message.constantResult[i] && typeof message.constantResult[i].length === "number" || $util.isString(message.constantResult[i])))
                            return "constantResult: buffer[] expected";
                }
                if (message.result != null && message.hasOwnProperty("result")) {
                    var error = $root.protocol.TronReturn.verify(message.result);
                    if (error)
                        return "result." + error;
                }
                if (message.energyUsed != null && message.hasOwnProperty("energyUsed"))
                    if (!$util.isInteger(message.energyUsed) && !(message.energyUsed && $util.isInteger(message.energyUsed.low) && $util.isInteger(message.energyUsed.high)))
                        return "energyUsed: integer|Long expected";
                if (message.logs != null && message.hasOwnProperty("logs")) {
                    if (!Array.isArray(message.logs))
                        return "logs: array expected";
                    for (var i = 0; i < message.logs.length; ++i) {
                        var error = $root.protocol.TronLog.verify(message.logs[i]);
                        if (error)
                            return "logs." + error;
                    }
                }
                if (message.internalTransactions != null && message.hasOwnProperty("internalTransactions")) {
                    if (!Array.isArray(message.internalTransactions))
                        return "internalTransactions: array expected";
                    for (var i = 0; i < message.internalTransactions.length; ++i) {
                        var error = $root.protocol.TronInternalTransaction.verify(message.internalTransactions[i]);
                        if (error)
                            return "internalTransactions." + error;
                    }
                }
                if (message.energyPenalty != null && message.hasOwnProperty("energyPenalty"))
                    if (!$util.isInteger(message.energyPenalty) && !(message.energyPenalty && $util.isInteger(message.energyPenalty.low) && $util.isInteger(message.energyPenalty.high)))
                        return "energyPenalty: integer|Long expected";
                if (message.refBlockBytes != null && message.hasOwnProperty("refBlockBytes"))
                    if (!(message.refBlockBytes && typeof message.refBlockBytes.length === "number" || $util.isString(message.refBlockBytes)))
                        return "refBlockBytes: buffer expected";
                if (message.refBlockNum != null && message.hasOwnProperty("refBlockNum"))
                    if (!$util.isInteger(message.refBlockNum) && !(message.refBlockNum && $util.isInteger(message.refBlockNum.low) && $util.isInteger(message.refBlockNum.high)))
                        return "refBlockNum: integer|Long expected";
                if (message.refBlockHash != null && message.hasOwnProperty("refBlockHash"))
                    if (!(message.refBlockHash && typeof message.refBlockHash.length === "number" || $util.isString(message.refBlockHash)))
                        return "refBlockHash: buffer expected";
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    if (!$util.isInteger(message.expiration) && !(message.expiration && $util.isInteger(message.expiration.low) && $util.isInteger(message.expiration.high)))
                        return "expiration: integer|Long expected";
                if (message.auths != null && message.hasOwnProperty("auths")) {
                    if (!Array.isArray(message.auths))
                        return "auths: array expected";
                    for (var i = 0; i < message.auths.length; ++i) {
                        var error = $root.protocol.TronAuthority.verify(message.auths[i]);
                        if (error)
                            return "auths." + error;
                    }
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                if (message.contract != null && message.hasOwnProperty("contract")) {
                    if (!Array.isArray(message.contract))
                        return "contract: array expected";
                    for (var i = 0; i < message.contract.length; ++i) {
                        var error = $root.protocol.TronContract.verify(message.contract[i]);
                        if (error)
                            return "contract." + error;
                    }
                }
                if (message.scripts != null && message.hasOwnProperty("scripts"))
                    if (!(message.scripts && typeof message.scripts.length === "number" || $util.isString(message.scripts)))
                        return "scripts: buffer expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.feeLimit != null && message.hasOwnProperty("feeLimit"))
                    if (!$util.isInteger(message.feeLimit) && !(message.feeLimit && $util.isInteger(message.feeLimit.low) && $util.isInteger(message.feeLimit.high)))
                        return "feeLimit: integer|Long expected";
                if (message.signature != null && message.hasOwnProperty("signature")) {
                    if (!Array.isArray(message.signature))
                        return "signature: array expected";
                    for (var i = 0; i < message.signature.length; ++i)
                        if (!(message.signature[i] && typeof message.signature[i].length === "number" || $util.isString(message.signature[i])))
                            return "signature: buffer[] expected";
                }
                if (message.ret != null && message.hasOwnProperty("ret")) {
                    if (!Array.isArray(message.ret))
                        return "ret: array expected";
                    for (var i = 0; i < message.ret.length; ++i) {
                        var error = $root.protocol.TronTransactionResult.verify(message.ret[i]);
                        if (error)
                            return "ret." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a TronTransaction message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronTransaction
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronTransaction} TronTransaction
             */
            TronTransaction.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronTransaction)
                    return object;
                var message = new $root.protocol.TronTransaction();
                if (object.txId != null)
                    if (typeof object.txId === "string")
                        $util.base64.decode(object.txId, message.txId = $util.newBuffer($util.base64.length(object.txId)), 0);
                    else if (object.txId.length >= 0)
                        message.txId = object.txId;
                if (object.constantResult) {
                    if (!Array.isArray(object.constantResult))
                        throw TypeError(".protocol.TronTransaction.constantResult: array expected");
                    message.constantResult = [];
                    for (var i = 0; i < object.constantResult.length; ++i)
                        if (typeof object.constantResult[i] === "string")
                            $util.base64.decode(object.constantResult[i], message.constantResult[i] = $util.newBuffer($util.base64.length(object.constantResult[i])), 0);
                        else if (object.constantResult[i].length >= 0)
                            message.constantResult[i] = object.constantResult[i];
                }
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".protocol.TronTransaction.result: object expected");
                    message.result = $root.protocol.TronReturn.fromObject(object.result);
                }
                if (object.energyUsed != null)
                    if ($util.Long)
                        (message.energyUsed = $util.Long.fromValue(object.energyUsed)).unsigned = false;
                    else if (typeof object.energyUsed === "string")
                        message.energyUsed = parseInt(object.energyUsed, 10);
                    else if (typeof object.energyUsed === "number")
                        message.energyUsed = object.energyUsed;
                    else if (typeof object.energyUsed === "object")
                        message.energyUsed = new $util.LongBits(object.energyUsed.low >>> 0, object.energyUsed.high >>> 0).toNumber();
                if (object.logs) {
                    if (!Array.isArray(object.logs))
                        throw TypeError(".protocol.TronTransaction.logs: array expected");
                    message.logs = [];
                    for (var i = 0; i < object.logs.length; ++i) {
                        if (typeof object.logs[i] !== "object")
                            throw TypeError(".protocol.TronTransaction.logs: object expected");
                        message.logs[i] = $root.protocol.TronLog.fromObject(object.logs[i]);
                    }
                }
                if (object.internalTransactions) {
                    if (!Array.isArray(object.internalTransactions))
                        throw TypeError(".protocol.TronTransaction.internalTransactions: array expected");
                    message.internalTransactions = [];
                    for (var i = 0; i < object.internalTransactions.length; ++i) {
                        if (typeof object.internalTransactions[i] !== "object")
                            throw TypeError(".protocol.TronTransaction.internalTransactions: object expected");
                        message.internalTransactions[i] = $root.protocol.TronInternalTransaction.fromObject(object.internalTransactions[i]);
                    }
                }
                if (object.energyPenalty != null)
                    if ($util.Long)
                        (message.energyPenalty = $util.Long.fromValue(object.energyPenalty)).unsigned = false;
                    else if (typeof object.energyPenalty === "string")
                        message.energyPenalty = parseInt(object.energyPenalty, 10);
                    else if (typeof object.energyPenalty === "number")
                        message.energyPenalty = object.energyPenalty;
                    else if (typeof object.energyPenalty === "object")
                        message.energyPenalty = new $util.LongBits(object.energyPenalty.low >>> 0, object.energyPenalty.high >>> 0).toNumber();
                if (object.refBlockBytes != null)
                    if (typeof object.refBlockBytes === "string")
                        $util.base64.decode(object.refBlockBytes, message.refBlockBytes = $util.newBuffer($util.base64.length(object.refBlockBytes)), 0);
                    else if (object.refBlockBytes.length >= 0)
                        message.refBlockBytes = object.refBlockBytes;
                if (object.refBlockNum != null)
                    if ($util.Long)
                        (message.refBlockNum = $util.Long.fromValue(object.refBlockNum)).unsigned = false;
                    else if (typeof object.refBlockNum === "string")
                        message.refBlockNum = parseInt(object.refBlockNum, 10);
                    else if (typeof object.refBlockNum === "number")
                        message.refBlockNum = object.refBlockNum;
                    else if (typeof object.refBlockNum === "object")
                        message.refBlockNum = new $util.LongBits(object.refBlockNum.low >>> 0, object.refBlockNum.high >>> 0).toNumber();
                if (object.refBlockHash != null)
                    if (typeof object.refBlockHash === "string")
                        $util.base64.decode(object.refBlockHash, message.refBlockHash = $util.newBuffer($util.base64.length(object.refBlockHash)), 0);
                    else if (object.refBlockHash.length >= 0)
                        message.refBlockHash = object.refBlockHash;
                if (object.expiration != null)
                    if ($util.Long)
                        (message.expiration = $util.Long.fromValue(object.expiration)).unsigned = false;
                    else if (typeof object.expiration === "string")
                        message.expiration = parseInt(object.expiration, 10);
                    else if (typeof object.expiration === "number")
                        message.expiration = object.expiration;
                    else if (typeof object.expiration === "object")
                        message.expiration = new $util.LongBits(object.expiration.low >>> 0, object.expiration.high >>> 0).toNumber();
                if (object.auths) {
                    if (!Array.isArray(object.auths))
                        throw TypeError(".protocol.TronTransaction.auths: array expected");
                    message.auths = [];
                    for (var i = 0; i < object.auths.length; ++i) {
                        if (typeof object.auths[i] !== "object")
                            throw TypeError(".protocol.TronTransaction.auths: object expected");
                        message.auths[i] = $root.protocol.TronAuthority.fromObject(object.auths[i]);
                    }
                }
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length >= 0)
                        message.data = object.data;
                if (object.contract) {
                    if (!Array.isArray(object.contract))
                        throw TypeError(".protocol.TronTransaction.contract: array expected");
                    message.contract = [];
                    for (var i = 0; i < object.contract.length; ++i) {
                        if (typeof object.contract[i] !== "object")
                            throw TypeError(".protocol.TronTransaction.contract: object expected");
                        message.contract[i] = $root.protocol.TronContract.fromObject(object.contract[i]);
                    }
                }
                if (object.scripts != null)
                    if (typeof object.scripts === "string")
                        $util.base64.decode(object.scripts, message.scripts = $util.newBuffer($util.base64.length(object.scripts)), 0);
                    else if (object.scripts.length >= 0)
                        message.scripts = object.scripts;
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.feeLimit != null)
                    if ($util.Long)
                        (message.feeLimit = $util.Long.fromValue(object.feeLimit)).unsigned = false;
                    else if (typeof object.feeLimit === "string")
                        message.feeLimit = parseInt(object.feeLimit, 10);
                    else if (typeof object.feeLimit === "number")
                        message.feeLimit = object.feeLimit;
                    else if (typeof object.feeLimit === "object")
                        message.feeLimit = new $util.LongBits(object.feeLimit.low >>> 0, object.feeLimit.high >>> 0).toNumber();
                if (object.signature) {
                    if (!Array.isArray(object.signature))
                        throw TypeError(".protocol.TronTransaction.signature: array expected");
                    message.signature = [];
                    for (var i = 0; i < object.signature.length; ++i)
                        if (typeof object.signature[i] === "string")
                            $util.base64.decode(object.signature[i], message.signature[i] = $util.newBuffer($util.base64.length(object.signature[i])), 0);
                        else if (object.signature[i].length >= 0)
                            message.signature[i] = object.signature[i];
                }
                if (object.ret) {
                    if (!Array.isArray(object.ret))
                        throw TypeError(".protocol.TronTransaction.ret: array expected");
                    message.ret = [];
                    for (var i = 0; i < object.ret.length; ++i) {
                        if (typeof object.ret[i] !== "object")
                            throw TypeError(".protocol.TronTransaction.ret: object expected");
                        message.ret[i] = $root.protocol.TronTransactionResult.fromObject(object.ret[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronTransaction message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronTransaction
             * @static
             * @param {protocol.TronTransaction} message TronTransaction
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronTransaction.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.constantResult = [];
                    object.logs = [];
                    object.internalTransactions = [];
                    object.auths = [];
                    object.contract = [];
                    object.signature = [];
                    object.ret = [];
                }
                if (options.defaults) {
                    if (options.bytes === String)
                        object.txId = "";
                    else {
                        object.txId = [];
                        if (options.bytes !== Array)
                            object.txId = $util.newBuffer(object.txId);
                    }
                    object.result = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.energyUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.energyUsed = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.energyPenalty = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.energyPenalty = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.refBlockBytes = "";
                    else {
                        object.refBlockBytes = [];
                        if (options.bytes !== Array)
                            object.refBlockBytes = $util.newBuffer(object.refBlockBytes);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.refBlockNum = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.refBlockNum = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.refBlockHash = "";
                    else {
                        object.refBlockHash = [];
                        if (options.bytes !== Array)
                            object.refBlockHash = $util.newBuffer(object.refBlockHash);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.expiration = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    if (options.bytes === String)
                        object.scripts = "";
                    else {
                        object.scripts = [];
                        if (options.bytes !== Array)
                            object.scripts = $util.newBuffer(object.scripts);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.feeLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.feeLimit = options.longs === String ? "0" : 0;
                }
                if (message.txId != null && message.hasOwnProperty("txId"))
                    object.txId = options.bytes === String ? $util.base64.encode(message.txId, 0, message.txId.length) : options.bytes === Array ? Array.prototype.slice.call(message.txId) : message.txId;
                if (message.constantResult && message.constantResult.length) {
                    object.constantResult = [];
                    for (var j = 0; j < message.constantResult.length; ++j)
                        object.constantResult[j] = options.bytes === String ? $util.base64.encode(message.constantResult[j], 0, message.constantResult[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.constantResult[j]) : message.constantResult[j];
                }
                if (message.result != null && message.hasOwnProperty("result"))
                    object.result = $root.protocol.TronReturn.toObject(message.result, options);
                if (message.energyUsed != null && message.hasOwnProperty("energyUsed"))
                    if (typeof message.energyUsed === "number")
                        object.energyUsed = options.longs === String ? String(message.energyUsed) : message.energyUsed;
                    else
                        object.energyUsed = options.longs === String ? $util.Long.prototype.toString.call(message.energyUsed) : options.longs === Number ? new $util.LongBits(message.energyUsed.low >>> 0, message.energyUsed.high >>> 0).toNumber() : message.energyUsed;
                if (message.logs && message.logs.length) {
                    object.logs = [];
                    for (var j = 0; j < message.logs.length; ++j)
                        object.logs[j] = $root.protocol.TronLog.toObject(message.logs[j], options);
                }
                if (message.internalTransactions && message.internalTransactions.length) {
                    object.internalTransactions = [];
                    for (var j = 0; j < message.internalTransactions.length; ++j)
                        object.internalTransactions[j] = $root.protocol.TronInternalTransaction.toObject(message.internalTransactions[j], options);
                }
                if (message.energyPenalty != null && message.hasOwnProperty("energyPenalty"))
                    if (typeof message.energyPenalty === "number")
                        object.energyPenalty = options.longs === String ? String(message.energyPenalty) : message.energyPenalty;
                    else
                        object.energyPenalty = options.longs === String ? $util.Long.prototype.toString.call(message.energyPenalty) : options.longs === Number ? new $util.LongBits(message.energyPenalty.low >>> 0, message.energyPenalty.high >>> 0).toNumber() : message.energyPenalty;
                if (message.refBlockBytes != null && message.hasOwnProperty("refBlockBytes"))
                    object.refBlockBytes = options.bytes === String ? $util.base64.encode(message.refBlockBytes, 0, message.refBlockBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.refBlockBytes) : message.refBlockBytes;
                if (message.refBlockNum != null && message.hasOwnProperty("refBlockNum"))
                    if (typeof message.refBlockNum === "number")
                        object.refBlockNum = options.longs === String ? String(message.refBlockNum) : message.refBlockNum;
                    else
                        object.refBlockNum = options.longs === String ? $util.Long.prototype.toString.call(message.refBlockNum) : options.longs === Number ? new $util.LongBits(message.refBlockNum.low >>> 0, message.refBlockNum.high >>> 0).toNumber() : message.refBlockNum;
                if (message.refBlockHash != null && message.hasOwnProperty("refBlockHash"))
                    object.refBlockHash = options.bytes === String ? $util.base64.encode(message.refBlockHash, 0, message.refBlockHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.refBlockHash) : message.refBlockHash;
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    if (typeof message.expiration === "number")
                        object.expiration = options.longs === String ? String(message.expiration) : message.expiration;
                    else
                        object.expiration = options.longs === String ? $util.Long.prototype.toString.call(message.expiration) : options.longs === Number ? new $util.LongBits(message.expiration.low >>> 0, message.expiration.high >>> 0).toNumber() : message.expiration;
                if (message.auths && message.auths.length) {
                    object.auths = [];
                    for (var j = 0; j < message.auths.length; ++j)
                        object.auths[j] = $root.protocol.TronAuthority.toObject(message.auths[j], options);
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.contract && message.contract.length) {
                    object.contract = [];
                    for (var j = 0; j < message.contract.length; ++j)
                        object.contract[j] = $root.protocol.TronContract.toObject(message.contract[j], options);
                }
                if (message.scripts != null && message.hasOwnProperty("scripts"))
                    object.scripts = options.bytes === String ? $util.base64.encode(message.scripts, 0, message.scripts.length) : options.bytes === Array ? Array.prototype.slice.call(message.scripts) : message.scripts;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.feeLimit != null && message.hasOwnProperty("feeLimit"))
                    if (typeof message.feeLimit === "number")
                        object.feeLimit = options.longs === String ? String(message.feeLimit) : message.feeLimit;
                    else
                        object.feeLimit = options.longs === String ? $util.Long.prototype.toString.call(message.feeLimit) : options.longs === Number ? new $util.LongBits(message.feeLimit.low >>> 0, message.feeLimit.high >>> 0).toNumber() : message.feeLimit;
                if (message.signature && message.signature.length) {
                    object.signature = [];
                    for (var j = 0; j < message.signature.length; ++j)
                        object.signature[j] = options.bytes === String ? $util.base64.encode(message.signature[j], 0, message.signature[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.signature[j]) : message.signature[j];
                }
                if (message.ret && message.ret.length) {
                    object.ret = [];
                    for (var j = 0; j < message.ret.length; ++j)
                        object.ret[j] = $root.protocol.TronTransactionResult.toObject(message.ret[j], options);
                }
                return object;
            };
    
            /**
             * Converts this TronTransaction to JSON.
             * @function toJSON
             * @memberof protocol.TronTransaction
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronTransaction.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronTransaction
             * @function getTypeUrl
             * @memberof protocol.TronTransaction
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronTransaction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronTransaction";
            };
    
            return TronTransaction;
        })();
    
        protocol.TronReturn = (function() {
    
            /**
             * Properties of a TronReturn.
             * @memberof protocol
             * @interface ITronReturn
             * @property {boolean|null} [result] TronReturn result
             * @property {protocol.TronReturn.ResponseCode|null} [code] TronReturn code
             * @property {Uint8Array|null} [message] TronReturn message
             */
    
            /**
             * Constructs a new TronReturn.
             * @memberof protocol
             * @classdesc Represents a TronReturn.
             * @implements ITronReturn
             * @constructor
             * @param {protocol.ITronReturn=} [properties] Properties to set
             */
            function TronReturn(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronReturn result.
             * @member {boolean} result
             * @memberof protocol.TronReturn
             * @instance
             */
            TronReturn.prototype.result = false;
    
            /**
             * TronReturn code.
             * @member {protocol.TronReturn.ResponseCode} code
             * @memberof protocol.TronReturn
             * @instance
             */
            TronReturn.prototype.code = 0;
    
            /**
             * TronReturn message.
             * @member {Uint8Array} message
             * @memberof protocol.TronReturn
             * @instance
             */
            TronReturn.prototype.message = $util.newBuffer([]);
    
            /**
             * Creates a new TronReturn instance using the specified properties.
             * @function create
             * @memberof protocol.TronReturn
             * @static
             * @param {protocol.ITronReturn=} [properties] Properties to set
             * @returns {protocol.TronReturn} TronReturn instance
             */
            TronReturn.create = function create(properties) {
                return new TronReturn(properties);
            };
    
            /**
             * Encodes the specified TronReturn message. Does not implicitly {@link protocol.TronReturn.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronReturn
             * @static
             * @param {protocol.ITronReturn} message TronReturn message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronReturn.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.result);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.code);
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.message);
                return writer;
            };
    
            /**
             * Encodes the specified TronReturn message, length delimited. Does not implicitly {@link protocol.TronReturn.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronReturn
             * @static
             * @param {protocol.ITronReturn} message TronReturn message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronReturn.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronReturn message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronReturn
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronReturn} TronReturn
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronReturn.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronReturn();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.result = reader.bool();
                            break;
                        }
                    case 2: {
                            message.code = reader.int32();
                            break;
                        }
                    case 3: {
                            message.message = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronReturn message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronReturn
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronReturn} TronReturn
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronReturn.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronReturn message.
             * @function verify
             * @memberof protocol.TronReturn
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronReturn.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.result != null && message.hasOwnProperty("result"))
                    if (typeof message.result !== "boolean")
                        return "result: boolean expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 20:
                        break;
                    }
                if (message.message != null && message.hasOwnProperty("message"))
                    if (!(message.message && typeof message.message.length === "number" || $util.isString(message.message)))
                        return "message: buffer expected";
                return null;
            };
    
            /**
             * Creates a TronReturn message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronReturn
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronReturn} TronReturn
             */
            TronReturn.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronReturn)
                    return object;
                var message = new $root.protocol.TronReturn();
                if (object.result != null)
                    message.result = Boolean(object.result);
                switch (object.code) {
                default:
                    if (typeof object.code === "number") {
                        message.code = object.code;
                        break;
                    }
                    break;
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "SIGERROR":
                case 1:
                    message.code = 1;
                    break;
                case "CONTRACT_VALIDATE_ERROR":
                case 2:
                    message.code = 2;
                    break;
                case "CONTRACT_EXE_ERROR":
                case 3:
                    message.code = 3;
                    break;
                case "BANDWITH_ERROR":
                case 4:
                    message.code = 4;
                    break;
                case "DUP_TRANSACTION_ERROR":
                case 5:
                    message.code = 5;
                    break;
                case "TAPOS_ERROR":
                case 6:
                    message.code = 6;
                    break;
                case "TOO_BIG_TRANSACTION_ERROR":
                case 7:
                    message.code = 7;
                    break;
                case "TRANSACTION_EXPIRATION_ERROR":
                case 8:
                    message.code = 8;
                    break;
                case "SERVER_BUSY":
                case 9:
                    message.code = 9;
                    break;
                case "NO_CONNECTION":
                case 10:
                    message.code = 10;
                    break;
                case "NOT_ENOUGH_EFFECTIVE_CONNECTION":
                case 11:
                    message.code = 11;
                    break;
                case "BLOCK_UNSOLIDIFIED":
                case 12:
                    message.code = 12;
                    break;
                case "OTHER_ERROR":
                case 20:
                    message.code = 20;
                    break;
                }
                if (object.message != null)
                    if (typeof object.message === "string")
                        $util.base64.decode(object.message, message.message = $util.newBuffer($util.base64.length(object.message)), 0);
                    else if (object.message.length >= 0)
                        message.message = object.message;
                return message;
            };
    
            /**
             * Creates a plain object from a TronReturn message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronReturn
             * @static
             * @param {protocol.TronReturn} message TronReturn
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronReturn.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.result = false;
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    if (options.bytes === String)
                        object.message = "";
                    else {
                        object.message = [];
                        if (options.bytes !== Array)
                            object.message = $util.newBuffer(object.message);
                    }
                }
                if (message.result != null && message.hasOwnProperty("result"))
                    object.result = message.result;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.protocol.TronReturn.ResponseCode[message.code] === undefined ? message.code : $root.protocol.TronReturn.ResponseCode[message.code] : message.code;
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = options.bytes === String ? $util.base64.encode(message.message, 0, message.message.length) : options.bytes === Array ? Array.prototype.slice.call(message.message) : message.message;
                return object;
            };
    
            /**
             * Converts this TronReturn to JSON.
             * @function toJSON
             * @memberof protocol.TronReturn
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronReturn.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronReturn
             * @function getTypeUrl
             * @memberof protocol.TronReturn
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronReturn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronReturn";
            };
    
            /**
             * ResponseCode enum.
             * @name protocol.TronReturn.ResponseCode
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} SIGERROR=1 SIGERROR value
             * @property {number} CONTRACT_VALIDATE_ERROR=2 CONTRACT_VALIDATE_ERROR value
             * @property {number} CONTRACT_EXE_ERROR=3 CONTRACT_EXE_ERROR value
             * @property {number} BANDWITH_ERROR=4 BANDWITH_ERROR value
             * @property {number} DUP_TRANSACTION_ERROR=5 DUP_TRANSACTION_ERROR value
             * @property {number} TAPOS_ERROR=6 TAPOS_ERROR value
             * @property {number} TOO_BIG_TRANSACTION_ERROR=7 TOO_BIG_TRANSACTION_ERROR value
             * @property {number} TRANSACTION_EXPIRATION_ERROR=8 TRANSACTION_EXPIRATION_ERROR value
             * @property {number} SERVER_BUSY=9 SERVER_BUSY value
             * @property {number} NO_CONNECTION=10 NO_CONNECTION value
             * @property {number} NOT_ENOUGH_EFFECTIVE_CONNECTION=11 NOT_ENOUGH_EFFECTIVE_CONNECTION value
             * @property {number} BLOCK_UNSOLIDIFIED=12 BLOCK_UNSOLIDIFIED value
             * @property {number} OTHER_ERROR=20 OTHER_ERROR value
             */
            TronReturn.ResponseCode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "SIGERROR"] = 1;
                values[valuesById[2] = "CONTRACT_VALIDATE_ERROR"] = 2;
                values[valuesById[3] = "CONTRACT_EXE_ERROR"] = 3;
                values[valuesById[4] = "BANDWITH_ERROR"] = 4;
                values[valuesById[5] = "DUP_TRANSACTION_ERROR"] = 5;
                values[valuesById[6] = "TAPOS_ERROR"] = 6;
                values[valuesById[7] = "TOO_BIG_TRANSACTION_ERROR"] = 7;
                values[valuesById[8] = "TRANSACTION_EXPIRATION_ERROR"] = 8;
                values[valuesById[9] = "SERVER_BUSY"] = 9;
                values[valuesById[10] = "NO_CONNECTION"] = 10;
                values[valuesById[11] = "NOT_ENOUGH_EFFECTIVE_CONNECTION"] = 11;
                values[valuesById[12] = "BLOCK_UNSOLIDIFIED"] = 12;
                values[valuesById[20] = "OTHER_ERROR"] = 20;
                return values;
            })();
    
            return TronReturn;
        })();
    
        protocol.TronLog = (function() {
    
            /**
             * Properties of a TronLog.
             * @memberof protocol
             * @interface ITronLog
             * @property {Uint8Array|null} [address] TronLog address
             * @property {Array.<Uint8Array>|null} [topics] TronLog topics
             * @property {Uint8Array|null} [data] TronLog data
             * @property {protocol.EventType|null} [eventType] TronLog eventType
             * @property {protocol.IAny|null} [parsed] TronLog parsed
             */
    
            /**
             * Constructs a new TronLog.
             * @memberof protocol
             * @classdesc Represents a TronLog.
             * @implements ITronLog
             * @constructor
             * @param {protocol.ITronLog=} [properties] Properties to set
             */
            function TronLog(properties) {
                this.topics = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronLog address.
             * @member {Uint8Array} address
             * @memberof protocol.TronLog
             * @instance
             */
            TronLog.prototype.address = $util.newBuffer([]);
    
            /**
             * TronLog topics.
             * @member {Array.<Uint8Array>} topics
             * @memberof protocol.TronLog
             * @instance
             */
            TronLog.prototype.topics = $util.emptyArray;
    
            /**
             * TronLog data.
             * @member {Uint8Array} data
             * @memberof protocol.TronLog
             * @instance
             */
            TronLog.prototype.data = $util.newBuffer([]);
    
            /**
             * TronLog eventType.
             * @member {protocol.EventType} eventType
             * @memberof protocol.TronLog
             * @instance
             */
            TronLog.prototype.eventType = 0;
    
            /**
             * TronLog parsed.
             * @member {protocol.IAny|null|undefined} parsed
             * @memberof protocol.TronLog
             * @instance
             */
            TronLog.prototype.parsed = null;
    
            /**
             * Creates a new TronLog instance using the specified properties.
             * @function create
             * @memberof protocol.TronLog
             * @static
             * @param {protocol.ITronLog=} [properties] Properties to set
             * @returns {protocol.TronLog} TronLog instance
             */
            TronLog.create = function create(properties) {
                return new TronLog(properties);
            };
    
            /**
             * Encodes the specified TronLog message. Does not implicitly {@link protocol.TronLog.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronLog
             * @static
             * @param {protocol.ITronLog} message TronLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronLog.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.address);
                if (message.topics != null && message.topics.length)
                    for (var i = 0; i < message.topics.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.topics[i]);
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
                if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.eventType);
                if (message.parsed != null && Object.hasOwnProperty.call(message, "parsed"))
                    $root.protocol.Any.encode(message.parsed, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified TronLog message, length delimited. Does not implicitly {@link protocol.TronLog.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronLog
             * @static
             * @param {protocol.ITronLog} message TronLog message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronLog.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronLog message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronLog} TronLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronLog.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronLog();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.bytes();
                            break;
                        }
                    case 2: {
                            if (!(message.topics && message.topics.length))
                                message.topics = [];
                            message.topics.push(reader.bytes());
                            break;
                        }
                    case 3: {
                            message.data = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.eventType = reader.int32();
                            break;
                        }
                    case 5: {
                            message.parsed = $root.protocol.Any.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronLog message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronLog
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronLog} TronLog
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronLog.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronLog message.
             * @function verify
             * @memberof protocol.TronLog
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronLog.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.topics != null && message.hasOwnProperty("topics")) {
                    if (!Array.isArray(message.topics))
                        return "topics: array expected";
                    for (var i = 0; i < message.topics.length; ++i)
                        if (!(message.topics[i] && typeof message.topics[i].length === "number" || $util.isString(message.topics[i])))
                            return "topics: buffer[] expected";
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                if (message.eventType != null && message.hasOwnProperty("eventType"))
                    switch (message.eventType) {
                    default:
                        return "eventType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.parsed != null && message.hasOwnProperty("parsed")) {
                    var error = $root.protocol.Any.verify(message.parsed);
                    if (error)
                        return "parsed." + error;
                }
                return null;
            };
    
            /**
             * Creates a TronLog message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronLog
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronLog} TronLog
             */
            TronLog.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronLog)
                    return object;
                var message = new $root.protocol.TronLog();
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length >= 0)
                        message.address = object.address;
                if (object.topics) {
                    if (!Array.isArray(object.topics))
                        throw TypeError(".protocol.TronLog.topics: array expected");
                    message.topics = [];
                    for (var i = 0; i < object.topics.length; ++i)
                        if (typeof object.topics[i] === "string")
                            $util.base64.decode(object.topics[i], message.topics[i] = $util.newBuffer($util.base64.length(object.topics[i])), 0);
                        else if (object.topics[i].length >= 0)
                            message.topics[i] = object.topics[i];
                }
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length >= 0)
                        message.data = object.data;
                switch (object.eventType) {
                default:
                    if (typeof object.eventType === "number") {
                        message.eventType = object.eventType;
                        break;
                    }
                    break;
                case "UnknownEvent":
                case 0:
                    message.eventType = 0;
                    break;
                case "TransferEvent":
                case 1:
                    message.eventType = 1;
                    break;
                }
                if (object.parsed != null) {
                    if (typeof object.parsed !== "object")
                        throw TypeError(".protocol.TronLog.parsed: object expected");
                    message.parsed = $root.protocol.Any.fromObject(object.parsed);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a TronLog message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronLog
             * @static
             * @param {protocol.TronLog} message TronLog
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronLog.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.topics = [];
                if (options.defaults) {
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                    object.eventType = options.enums === String ? "UnknownEvent" : 0;
                    object.parsed = null;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.topics && message.topics.length) {
                    object.topics = [];
                    for (var j = 0; j < message.topics.length; ++j)
                        object.topics[j] = options.bytes === String ? $util.base64.encode(message.topics[j], 0, message.topics[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.topics[j]) : message.topics[j];
                }
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                if (message.eventType != null && message.hasOwnProperty("eventType"))
                    object.eventType = options.enums === String ? $root.protocol.EventType[message.eventType] === undefined ? message.eventType : $root.protocol.EventType[message.eventType] : message.eventType;
                if (message.parsed != null && message.hasOwnProperty("parsed"))
                    object.parsed = $root.protocol.Any.toObject(message.parsed, options);
                return object;
            };
    
            /**
             * Converts this TronLog to JSON.
             * @function toJSON
             * @memberof protocol.TronLog
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronLog.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronLog
             * @function getTypeUrl
             * @memberof protocol.TronLog
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronLog.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronLog";
            };
    
            return TronLog;
        })();
    
        protocol.TronInternalTransaction = (function() {
    
            /**
             * Properties of a TronInternalTransaction.
             * @memberof protocol
             * @interface ITronInternalTransaction
             * @property {Uint8Array|null} [hash] TronInternalTransaction hash
             * @property {Uint8Array|null} [callerAddress] TronInternalTransaction callerAddress
             * @property {Uint8Array|null} [transferToAddress] TronInternalTransaction transferToAddress
             * @property {Array.<protocol.TronInternalTransaction.ICallValueInfo>|null} [callValueInfo] TronInternalTransaction callValueInfo
             * @property {Uint8Array|null} [note] TronInternalTransaction note
             * @property {boolean|null} [rejected] TronInternalTransaction rejected
             * @property {string|null} [extra] TronInternalTransaction extra
             */
    
            /**
             * Constructs a new TronInternalTransaction.
             * @memberof protocol
             * @classdesc Represents a TronInternalTransaction.
             * @implements ITronInternalTransaction
             * @constructor
             * @param {protocol.ITronInternalTransaction=} [properties] Properties to set
             */
            function TronInternalTransaction(properties) {
                this.callValueInfo = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronInternalTransaction hash.
             * @member {Uint8Array} hash
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.hash = $util.newBuffer([]);
    
            /**
             * TronInternalTransaction callerAddress.
             * @member {Uint8Array} callerAddress
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.callerAddress = $util.newBuffer([]);
    
            /**
             * TronInternalTransaction transferToAddress.
             * @member {Uint8Array} transferToAddress
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.transferToAddress = $util.newBuffer([]);
    
            /**
             * TronInternalTransaction callValueInfo.
             * @member {Array.<protocol.TronInternalTransaction.ICallValueInfo>} callValueInfo
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.callValueInfo = $util.emptyArray;
    
            /**
             * TronInternalTransaction note.
             * @member {Uint8Array} note
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.note = $util.newBuffer([]);
    
            /**
             * TronInternalTransaction rejected.
             * @member {boolean} rejected
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.rejected = false;
    
            /**
             * TronInternalTransaction extra.
             * @member {string} extra
             * @memberof protocol.TronInternalTransaction
             * @instance
             */
            TronInternalTransaction.prototype.extra = "";
    
            /**
             * Creates a new TronInternalTransaction instance using the specified properties.
             * @function create
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {protocol.ITronInternalTransaction=} [properties] Properties to set
             * @returns {protocol.TronInternalTransaction} TronInternalTransaction instance
             */
            TronInternalTransaction.create = function create(properties) {
                return new TronInternalTransaction(properties);
            };
    
            /**
             * Encodes the specified TronInternalTransaction message. Does not implicitly {@link protocol.TronInternalTransaction.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {protocol.ITronInternalTransaction} message TronInternalTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronInternalTransaction.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.hash);
                if (message.callerAddress != null && Object.hasOwnProperty.call(message, "callerAddress"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.callerAddress);
                if (message.transferToAddress != null && Object.hasOwnProperty.call(message, "transferToAddress"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.transferToAddress);
                if (message.callValueInfo != null && message.callValueInfo.length)
                    for (var i = 0; i < message.callValueInfo.length; ++i)
                        $root.protocol.TronInternalTransaction.CallValueInfo.encode(message.callValueInfo[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                    writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.note);
                if (message.rejected != null && Object.hasOwnProperty.call(message, "rejected"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.rejected);
                if (message.extra != null && Object.hasOwnProperty.call(message, "extra"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.extra);
                return writer;
            };
    
            /**
             * Encodes the specified TronInternalTransaction message, length delimited. Does not implicitly {@link protocol.TronInternalTransaction.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {protocol.ITronInternalTransaction} message TronInternalTransaction message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronInternalTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronInternalTransaction message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronInternalTransaction} TronInternalTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronInternalTransaction.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronInternalTransaction();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.hash = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.callerAddress = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.transferToAddress = reader.bytes();
                            break;
                        }
                    case 4: {
                            if (!(message.callValueInfo && message.callValueInfo.length))
                                message.callValueInfo = [];
                            message.callValueInfo.push($root.protocol.TronInternalTransaction.CallValueInfo.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.note = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.rejected = reader.bool();
                            break;
                        }
                    case 7: {
                            message.extra = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronInternalTransaction message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronInternalTransaction} TronInternalTransaction
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronInternalTransaction.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronInternalTransaction message.
             * @function verify
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronInternalTransaction.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hash != null && message.hasOwnProperty("hash"))
                    if (!(message.hash && typeof message.hash.length === "number" || $util.isString(message.hash)))
                        return "hash: buffer expected";
                if (message.callerAddress != null && message.hasOwnProperty("callerAddress"))
                    if (!(message.callerAddress && typeof message.callerAddress.length === "number" || $util.isString(message.callerAddress)))
                        return "callerAddress: buffer expected";
                if (message.transferToAddress != null && message.hasOwnProperty("transferToAddress"))
                    if (!(message.transferToAddress && typeof message.transferToAddress.length === "number" || $util.isString(message.transferToAddress)))
                        return "transferToAddress: buffer expected";
                if (message.callValueInfo != null && message.hasOwnProperty("callValueInfo")) {
                    if (!Array.isArray(message.callValueInfo))
                        return "callValueInfo: array expected";
                    for (var i = 0; i < message.callValueInfo.length; ++i) {
                        var error = $root.protocol.TronInternalTransaction.CallValueInfo.verify(message.callValueInfo[i]);
                        if (error)
                            return "callValueInfo." + error;
                    }
                }
                if (message.note != null && message.hasOwnProperty("note"))
                    if (!(message.note && typeof message.note.length === "number" || $util.isString(message.note)))
                        return "note: buffer expected";
                if (message.rejected != null && message.hasOwnProperty("rejected"))
                    if (typeof message.rejected !== "boolean")
                        return "rejected: boolean expected";
                if (message.extra != null && message.hasOwnProperty("extra"))
                    if (!$util.isString(message.extra))
                        return "extra: string expected";
                return null;
            };
    
            /**
             * Creates a TronInternalTransaction message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronInternalTransaction} TronInternalTransaction
             */
            TronInternalTransaction.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronInternalTransaction)
                    return object;
                var message = new $root.protocol.TronInternalTransaction();
                if (object.hash != null)
                    if (typeof object.hash === "string")
                        $util.base64.decode(object.hash, message.hash = $util.newBuffer($util.base64.length(object.hash)), 0);
                    else if (object.hash.length >= 0)
                        message.hash = object.hash;
                if (object.callerAddress != null)
                    if (typeof object.callerAddress === "string")
                        $util.base64.decode(object.callerAddress, message.callerAddress = $util.newBuffer($util.base64.length(object.callerAddress)), 0);
                    else if (object.callerAddress.length >= 0)
                        message.callerAddress = object.callerAddress;
                if (object.transferToAddress != null)
                    if (typeof object.transferToAddress === "string")
                        $util.base64.decode(object.transferToAddress, message.transferToAddress = $util.newBuffer($util.base64.length(object.transferToAddress)), 0);
                    else if (object.transferToAddress.length >= 0)
                        message.transferToAddress = object.transferToAddress;
                if (object.callValueInfo) {
                    if (!Array.isArray(object.callValueInfo))
                        throw TypeError(".protocol.TronInternalTransaction.callValueInfo: array expected");
                    message.callValueInfo = [];
                    for (var i = 0; i < object.callValueInfo.length; ++i) {
                        if (typeof object.callValueInfo[i] !== "object")
                            throw TypeError(".protocol.TronInternalTransaction.callValueInfo: object expected");
                        message.callValueInfo[i] = $root.protocol.TronInternalTransaction.CallValueInfo.fromObject(object.callValueInfo[i]);
                    }
                }
                if (object.note != null)
                    if (typeof object.note === "string")
                        $util.base64.decode(object.note, message.note = $util.newBuffer($util.base64.length(object.note)), 0);
                    else if (object.note.length >= 0)
                        message.note = object.note;
                if (object.rejected != null)
                    message.rejected = Boolean(object.rejected);
                if (object.extra != null)
                    message.extra = String(object.extra);
                return message;
            };
    
            /**
             * Creates a plain object from a TronInternalTransaction message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {protocol.TronInternalTransaction} message TronInternalTransaction
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronInternalTransaction.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.callValueInfo = [];
                if (options.defaults) {
                    if (options.bytes === String)
                        object.hash = "";
                    else {
                        object.hash = [];
                        if (options.bytes !== Array)
                            object.hash = $util.newBuffer(object.hash);
                    }
                    if (options.bytes === String)
                        object.callerAddress = "";
                    else {
                        object.callerAddress = [];
                        if (options.bytes !== Array)
                            object.callerAddress = $util.newBuffer(object.callerAddress);
                    }
                    if (options.bytes === String)
                        object.transferToAddress = "";
                    else {
                        object.transferToAddress = [];
                        if (options.bytes !== Array)
                            object.transferToAddress = $util.newBuffer(object.transferToAddress);
                    }
                    if (options.bytes === String)
                        object.note = "";
                    else {
                        object.note = [];
                        if (options.bytes !== Array)
                            object.note = $util.newBuffer(object.note);
                    }
                    object.rejected = false;
                    object.extra = "";
                }
                if (message.hash != null && message.hasOwnProperty("hash"))
                    object.hash = options.bytes === String ? $util.base64.encode(message.hash, 0, message.hash.length) : options.bytes === Array ? Array.prototype.slice.call(message.hash) : message.hash;
                if (message.callerAddress != null && message.hasOwnProperty("callerAddress"))
                    object.callerAddress = options.bytes === String ? $util.base64.encode(message.callerAddress, 0, message.callerAddress.length) : options.bytes === Array ? Array.prototype.slice.call(message.callerAddress) : message.callerAddress;
                if (message.transferToAddress != null && message.hasOwnProperty("transferToAddress"))
                    object.transferToAddress = options.bytes === String ? $util.base64.encode(message.transferToAddress, 0, message.transferToAddress.length) : options.bytes === Array ? Array.prototype.slice.call(message.transferToAddress) : message.transferToAddress;
                if (message.callValueInfo && message.callValueInfo.length) {
                    object.callValueInfo = [];
                    for (var j = 0; j < message.callValueInfo.length; ++j)
                        object.callValueInfo[j] = $root.protocol.TronInternalTransaction.CallValueInfo.toObject(message.callValueInfo[j], options);
                }
                if (message.note != null && message.hasOwnProperty("note"))
                    object.note = options.bytes === String ? $util.base64.encode(message.note, 0, message.note.length) : options.bytes === Array ? Array.prototype.slice.call(message.note) : message.note;
                if (message.rejected != null && message.hasOwnProperty("rejected"))
                    object.rejected = message.rejected;
                if (message.extra != null && message.hasOwnProperty("extra"))
                    object.extra = message.extra;
                return object;
            };
    
            /**
             * Converts this TronInternalTransaction to JSON.
             * @function toJSON
             * @memberof protocol.TronInternalTransaction
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronInternalTransaction.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronInternalTransaction
             * @function getTypeUrl
             * @memberof protocol.TronInternalTransaction
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronInternalTransaction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronInternalTransaction";
            };
    
            TronInternalTransaction.CallValueInfo = (function() {
    
                /**
                 * Properties of a CallValueInfo.
                 * @memberof protocol.TronInternalTransaction
                 * @interface ICallValueInfo
                 * @property {Long|null} [callValue] CallValueInfo callValue
                 * @property {string|null} [tokenId] CallValueInfo tokenId
                 */
    
                /**
                 * Constructs a new CallValueInfo.
                 * @memberof protocol.TronInternalTransaction
                 * @classdesc Represents a CallValueInfo.
                 * @implements ICallValueInfo
                 * @constructor
                 * @param {protocol.TronInternalTransaction.ICallValueInfo=} [properties] Properties to set
                 */
                function CallValueInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallValueInfo callValue.
                 * @member {Long} callValue
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @instance
                 */
                CallValueInfo.prototype.callValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallValueInfo tokenId.
                 * @member {string} tokenId
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @instance
                 */
                CallValueInfo.prototype.tokenId = "";
    
                /**
                 * Creates a new CallValueInfo instance using the specified properties.
                 * @function create
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {protocol.TronInternalTransaction.ICallValueInfo=} [properties] Properties to set
                 * @returns {protocol.TronInternalTransaction.CallValueInfo} CallValueInfo instance
                 */
                CallValueInfo.create = function create(properties) {
                    return new CallValueInfo(properties);
                };
    
                /**
                 * Encodes the specified CallValueInfo message. Does not implicitly {@link protocol.TronInternalTransaction.CallValueInfo.verify|verify} messages.
                 * @function encode
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {protocol.TronInternalTransaction.ICallValueInfo} message CallValueInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallValueInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callValue != null && Object.hasOwnProperty.call(message, "callValue"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.callValue);
                    if (message.tokenId != null && Object.hasOwnProperty.call(message, "tokenId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.tokenId);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallValueInfo message, length delimited. Does not implicitly {@link protocol.TronInternalTransaction.CallValueInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {protocol.TronInternalTransaction.ICallValueInfo} message CallValueInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallValueInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallValueInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {protocol.TronInternalTransaction.CallValueInfo} CallValueInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallValueInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronInternalTransaction.CallValueInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callValue = reader.int64();
                                break;
                            }
                        case 2: {
                                message.tokenId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallValueInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {protocol.TronInternalTransaction.CallValueInfo} CallValueInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallValueInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallValueInfo message.
                 * @function verify
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallValueInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callValue != null && message.hasOwnProperty("callValue"))
                        if (!$util.isInteger(message.callValue) && !(message.callValue && $util.isInteger(message.callValue.low) && $util.isInteger(message.callValue.high)))
                            return "callValue: integer|Long expected";
                    if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                        if (!$util.isString(message.tokenId))
                            return "tokenId: string expected";
                    return null;
                };
    
                /**
                 * Creates a CallValueInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {protocol.TronInternalTransaction.CallValueInfo} CallValueInfo
                 */
                CallValueInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.protocol.TronInternalTransaction.CallValueInfo)
                        return object;
                    var message = new $root.protocol.TronInternalTransaction.CallValueInfo();
                    if (object.callValue != null)
                        if ($util.Long)
                            (message.callValue = $util.Long.fromValue(object.callValue)).unsigned = false;
                        else if (typeof object.callValue === "string")
                            message.callValue = parseInt(object.callValue, 10);
                        else if (typeof object.callValue === "number")
                            message.callValue = object.callValue;
                        else if (typeof object.callValue === "object")
                            message.callValue = new $util.LongBits(object.callValue.low >>> 0, object.callValue.high >>> 0).toNumber();
                    if (object.tokenId != null)
                        message.tokenId = String(object.tokenId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallValueInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {protocol.TronInternalTransaction.CallValueInfo} message CallValueInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallValueInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.callValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.callValue = options.longs === String ? "0" : 0;
                        object.tokenId = "";
                    }
                    if (message.callValue != null && message.hasOwnProperty("callValue"))
                        if (typeof message.callValue === "number")
                            object.callValue = options.longs === String ? String(message.callValue) : message.callValue;
                        else
                            object.callValue = options.longs === String ? $util.Long.prototype.toString.call(message.callValue) : options.longs === Number ? new $util.LongBits(message.callValue.low >>> 0, message.callValue.high >>> 0).toNumber() : message.callValue;
                    if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                        object.tokenId = message.tokenId;
                    return object;
                };
    
                /**
                 * Converts this CallValueInfo to JSON.
                 * @function toJSON
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallValueInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallValueInfo
                 * @function getTypeUrl
                 * @memberof protocol.TronInternalTransaction.CallValueInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallValueInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/protocol.TronInternalTransaction.CallValueInfo";
                };
    
                return CallValueInfo;
            })();
    
            return TronInternalTransaction;
        })();
    
        protocol.TronContract = (function() {
    
            /**
             * Properties of a TronContract.
             * @memberof protocol
             * @interface ITronContract
             * @property {protocol.TronContract.ContractType|null} [type] TronContract type
             * @property {protocol.IAny|null} [parameter] TronContract parameter
             * @property {Uint8Array|null} [provider] TronContract provider
             * @property {Uint8Array|null} [contractName] TronContract contractName
             * @property {number|null} [permissionId] TronContract permissionId
             */
    
            /**
             * Constructs a new TronContract.
             * @memberof protocol
             * @classdesc Represents a TronContract.
             * @implements ITronContract
             * @constructor
             * @param {protocol.ITronContract=} [properties] Properties to set
             */
            function TronContract(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronContract type.
             * @member {protocol.TronContract.ContractType} type
             * @memberof protocol.TronContract
             * @instance
             */
            TronContract.prototype.type = 0;
    
            /**
             * TronContract parameter.
             * @member {protocol.IAny|null|undefined} parameter
             * @memberof protocol.TronContract
             * @instance
             */
            TronContract.prototype.parameter = null;
    
            /**
             * TronContract provider.
             * @member {Uint8Array} provider
             * @memberof protocol.TronContract
             * @instance
             */
            TronContract.prototype.provider = $util.newBuffer([]);
    
            /**
             * TronContract contractName.
             * @member {Uint8Array} contractName
             * @memberof protocol.TronContract
             * @instance
             */
            TronContract.prototype.contractName = $util.newBuffer([]);
    
            /**
             * TronContract permissionId.
             * @member {number} permissionId
             * @memberof protocol.TronContract
             * @instance
             */
            TronContract.prototype.permissionId = 0;
    
            /**
             * Creates a new TronContract instance using the specified properties.
             * @function create
             * @memberof protocol.TronContract
             * @static
             * @param {protocol.ITronContract=} [properties] Properties to set
             * @returns {protocol.TronContract} TronContract instance
             */
            TronContract.create = function create(properties) {
                return new TronContract(properties);
            };
    
            /**
             * Encodes the specified TronContract message. Does not implicitly {@link protocol.TronContract.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronContract
             * @static
             * @param {protocol.ITronContract} message TronContract message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronContract.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.parameter != null && Object.hasOwnProperty.call(message, "parameter"))
                    $root.protocol.Any.encode(message.parameter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.provider);
                if (message.contractName != null && Object.hasOwnProperty.call(message, "contractName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.contractName);
                if (message.permissionId != null && Object.hasOwnProperty.call(message, "permissionId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.permissionId);
                return writer;
            };
    
            /**
             * Encodes the specified TronContract message, length delimited. Does not implicitly {@link protocol.TronContract.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronContract
             * @static
             * @param {protocol.ITronContract} message TronContract message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronContract.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronContract message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronContract
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronContract} TronContract
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronContract.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronContract();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.parameter = $root.protocol.Any.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.provider = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.contractName = reader.bytes();
                            break;
                        }
                    case 5: {
                            message.permissionId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronContract message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronContract
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronContract} TronContract
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronContract.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronContract message.
             * @function verify
             * @memberof protocol.TronContract
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronContract.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 30:
                    case 31:
                    case 32:
                    case 33:
                    case 41:
                    case 42:
                    case 43:
                    case 44:
                    case 45:
                    case 46:
                    case 48:
                    case 49:
                    case 51:
                    case 52:
                    case 53:
                    case 54:
                    case 55:
                    case 56:
                    case 57:
                    case 58:
                    case 59:
                        break;
                    }
                if (message.parameter != null && message.hasOwnProperty("parameter")) {
                    var error = $root.protocol.Any.verify(message.parameter);
                    if (error)
                        return "parameter." + error;
                }
                if (message.provider != null && message.hasOwnProperty("provider"))
                    if (!(message.provider && typeof message.provider.length === "number" || $util.isString(message.provider)))
                        return "provider: buffer expected";
                if (message.contractName != null && message.hasOwnProperty("contractName"))
                    if (!(message.contractName && typeof message.contractName.length === "number" || $util.isString(message.contractName)))
                        return "contractName: buffer expected";
                if (message.permissionId != null && message.hasOwnProperty("permissionId"))
                    if (!$util.isInteger(message.permissionId))
                        return "permissionId: integer expected";
                return null;
            };
    
            /**
             * Creates a TronContract message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronContract
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronContract} TronContract
             */
            TronContract.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronContract)
                    return object;
                var message = new $root.protocol.TronContract();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "AccountCreateContract":
                case 0:
                    message.type = 0;
                    break;
                case "TransferContract":
                case 1:
                    message.type = 1;
                    break;
                case "TransferAssetContract":
                case 2:
                    message.type = 2;
                    break;
                case "VoteAssetContract":
                case 3:
                    message.type = 3;
                    break;
                case "VoteWitnessContract":
                case 4:
                    message.type = 4;
                    break;
                case "WitnessCreateContract":
                case 5:
                    message.type = 5;
                    break;
                case "AssetIssueContract":
                case 6:
                    message.type = 6;
                    break;
                case "WitnessUpdateContract":
                case 8:
                    message.type = 8;
                    break;
                case "ParticipateAssetIssueContract":
                case 9:
                    message.type = 9;
                    break;
                case "AccountUpdateContract":
                case 10:
                    message.type = 10;
                    break;
                case "FreezeBalanceContract":
                case 11:
                    message.type = 11;
                    break;
                case "UnfreezeBalanceContract":
                case 12:
                    message.type = 12;
                    break;
                case "WithdrawBalanceContract":
                case 13:
                    message.type = 13;
                    break;
                case "UnfreezeAssetContract":
                case 14:
                    message.type = 14;
                    break;
                case "UpdateAssetContract":
                case 15:
                    message.type = 15;
                    break;
                case "ProposalCreateContract":
                case 16:
                    message.type = 16;
                    break;
                case "ProposalApproveContract":
                case 17:
                    message.type = 17;
                    break;
                case "ProposalDeleteContract":
                case 18:
                    message.type = 18;
                    break;
                case "SetAccountIdContract":
                case 19:
                    message.type = 19;
                    break;
                case "CustomContract":
                case 20:
                    message.type = 20;
                    break;
                case "CreateSmartContract":
                case 30:
                    message.type = 30;
                    break;
                case "TriggerSmartContract":
                case 31:
                    message.type = 31;
                    break;
                case "GetContract":
                case 32:
                    message.type = 32;
                    break;
                case "UpdateSettingContract":
                case 33:
                    message.type = 33;
                    break;
                case "ExchangeCreateContract":
                case 41:
                    message.type = 41;
                    break;
                case "ExchangeInjectContract":
                case 42:
                    message.type = 42;
                    break;
                case "ExchangeWithdrawContract":
                case 43:
                    message.type = 43;
                    break;
                case "ExchangeTransactionContract":
                case 44:
                    message.type = 44;
                    break;
                case "UpdateEnergyLimitContract":
                case 45:
                    message.type = 45;
                    break;
                case "AccountPermissionUpdateContract":
                case 46:
                    message.type = 46;
                    break;
                case "ClearABIContract":
                case 48:
                    message.type = 48;
                    break;
                case "UpdateBrokerageContract":
                case 49:
                    message.type = 49;
                    break;
                case "ShieldedTransferContract":
                case 51:
                    message.type = 51;
                    break;
                case "MarketSellAssetContract":
                case 52:
                    message.type = 52;
                    break;
                case "MarketCancelOrderContract":
                case 53:
                    message.type = 53;
                    break;
                case "FreezeBalanceV2Contract":
                case 54:
                    message.type = 54;
                    break;
                case "UnfreezeBalanceV2Contract":
                case 55:
                    message.type = 55;
                    break;
                case "WithdrawExpireUnfreezeContract":
                case 56:
                    message.type = 56;
                    break;
                case "DelegateResourceContract":
                case 57:
                    message.type = 57;
                    break;
                case "UnDelegateResourceContract":
                case 58:
                    message.type = 58;
                    break;
                case "CancelAllUnfreezeV2Contract":
                case 59:
                    message.type = 59;
                    break;
                }
                if (object.parameter != null) {
                    if (typeof object.parameter !== "object")
                        throw TypeError(".protocol.TronContract.parameter: object expected");
                    message.parameter = $root.protocol.Any.fromObject(object.parameter);
                }
                if (object.provider != null)
                    if (typeof object.provider === "string")
                        $util.base64.decode(object.provider, message.provider = $util.newBuffer($util.base64.length(object.provider)), 0);
                    else if (object.provider.length >= 0)
                        message.provider = object.provider;
                if (object.contractName != null)
                    if (typeof object.contractName === "string")
                        $util.base64.decode(object.contractName, message.contractName = $util.newBuffer($util.base64.length(object.contractName)), 0);
                    else if (object.contractName.length >= 0)
                        message.contractName = object.contractName;
                if (object.permissionId != null)
                    message.permissionId = object.permissionId | 0;
                return message;
            };
    
            /**
             * Creates a plain object from a TronContract message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronContract
             * @static
             * @param {protocol.TronContract} message TronContract
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronContract.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "AccountCreateContract" : 0;
                    object.parameter = null;
                    if (options.bytes === String)
                        object.provider = "";
                    else {
                        object.provider = [];
                        if (options.bytes !== Array)
                            object.provider = $util.newBuffer(object.provider);
                    }
                    if (options.bytes === String)
                        object.contractName = "";
                    else {
                        object.contractName = [];
                        if (options.bytes !== Array)
                            object.contractName = $util.newBuffer(object.contractName);
                    }
                    object.permissionId = 0;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protocol.TronContract.ContractType[message.type] === undefined ? message.type : $root.protocol.TronContract.ContractType[message.type] : message.type;
                if (message.parameter != null && message.hasOwnProperty("parameter"))
                    object.parameter = $root.protocol.Any.toObject(message.parameter, options);
                if (message.provider != null && message.hasOwnProperty("provider"))
                    object.provider = options.bytes === String ? $util.base64.encode(message.provider, 0, message.provider.length) : options.bytes === Array ? Array.prototype.slice.call(message.provider) : message.provider;
                if (message.contractName != null && message.hasOwnProperty("contractName"))
                    object.contractName = options.bytes === String ? $util.base64.encode(message.contractName, 0, message.contractName.length) : options.bytes === Array ? Array.prototype.slice.call(message.contractName) : message.contractName;
                if (message.permissionId != null && message.hasOwnProperty("permissionId"))
                    object.permissionId = message.permissionId;
                return object;
            };
    
            /**
             * Converts this TronContract to JSON.
             * @function toJSON
             * @memberof protocol.TronContract
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronContract.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronContract
             * @function getTypeUrl
             * @memberof protocol.TronContract
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronContract.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronContract";
            };
    
            /**
             * ContractType enum.
             * @name protocol.TronContract.ContractType
             * @enum {number}
             * @property {number} AccountCreateContract=0 AccountCreateContract value
             * @property {number} TransferContract=1 TransferContract value
             * @property {number} TransferAssetContract=2 TransferAssetContract value
             * @property {number} VoteAssetContract=3 VoteAssetContract value
             * @property {number} VoteWitnessContract=4 VoteWitnessContract value
             * @property {number} WitnessCreateContract=5 WitnessCreateContract value
             * @property {number} AssetIssueContract=6 AssetIssueContract value
             * @property {number} WitnessUpdateContract=8 WitnessUpdateContract value
             * @property {number} ParticipateAssetIssueContract=9 ParticipateAssetIssueContract value
             * @property {number} AccountUpdateContract=10 AccountUpdateContract value
             * @property {number} FreezeBalanceContract=11 FreezeBalanceContract value
             * @property {number} UnfreezeBalanceContract=12 UnfreezeBalanceContract value
             * @property {number} WithdrawBalanceContract=13 WithdrawBalanceContract value
             * @property {number} UnfreezeAssetContract=14 UnfreezeAssetContract value
             * @property {number} UpdateAssetContract=15 UpdateAssetContract value
             * @property {number} ProposalCreateContract=16 ProposalCreateContract value
             * @property {number} ProposalApproveContract=17 ProposalApproveContract value
             * @property {number} ProposalDeleteContract=18 ProposalDeleteContract value
             * @property {number} SetAccountIdContract=19 SetAccountIdContract value
             * @property {number} CustomContract=20 CustomContract value
             * @property {number} CreateSmartContract=30 CreateSmartContract value
             * @property {number} TriggerSmartContract=31 TriggerSmartContract value
             * @property {number} GetContract=32 GetContract value
             * @property {number} UpdateSettingContract=33 UpdateSettingContract value
             * @property {number} ExchangeCreateContract=41 ExchangeCreateContract value
             * @property {number} ExchangeInjectContract=42 ExchangeInjectContract value
             * @property {number} ExchangeWithdrawContract=43 ExchangeWithdrawContract value
             * @property {number} ExchangeTransactionContract=44 ExchangeTransactionContract value
             * @property {number} UpdateEnergyLimitContract=45 UpdateEnergyLimitContract value
             * @property {number} AccountPermissionUpdateContract=46 AccountPermissionUpdateContract value
             * @property {number} ClearABIContract=48 ClearABIContract value
             * @property {number} UpdateBrokerageContract=49 UpdateBrokerageContract value
             * @property {number} ShieldedTransferContract=51 ShieldedTransferContract value
             * @property {number} MarketSellAssetContract=52 MarketSellAssetContract value
             * @property {number} MarketCancelOrderContract=53 MarketCancelOrderContract value
             * @property {number} FreezeBalanceV2Contract=54 FreezeBalanceV2Contract value
             * @property {number} UnfreezeBalanceV2Contract=55 UnfreezeBalanceV2Contract value
             * @property {number} WithdrawExpireUnfreezeContract=56 WithdrawExpireUnfreezeContract value
             * @property {number} DelegateResourceContract=57 DelegateResourceContract value
             * @property {number} UnDelegateResourceContract=58 UnDelegateResourceContract value
             * @property {number} CancelAllUnfreezeV2Contract=59 CancelAllUnfreezeV2Contract value
             */
            TronContract.ContractType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AccountCreateContract"] = 0;
                values[valuesById[1] = "TransferContract"] = 1;
                values[valuesById[2] = "TransferAssetContract"] = 2;
                values[valuesById[3] = "VoteAssetContract"] = 3;
                values[valuesById[4] = "VoteWitnessContract"] = 4;
                values[valuesById[5] = "WitnessCreateContract"] = 5;
                values[valuesById[6] = "AssetIssueContract"] = 6;
                values[valuesById[8] = "WitnessUpdateContract"] = 8;
                values[valuesById[9] = "ParticipateAssetIssueContract"] = 9;
                values[valuesById[10] = "AccountUpdateContract"] = 10;
                values[valuesById[11] = "FreezeBalanceContract"] = 11;
                values[valuesById[12] = "UnfreezeBalanceContract"] = 12;
                values[valuesById[13] = "WithdrawBalanceContract"] = 13;
                values[valuesById[14] = "UnfreezeAssetContract"] = 14;
                values[valuesById[15] = "UpdateAssetContract"] = 15;
                values[valuesById[16] = "ProposalCreateContract"] = 16;
                values[valuesById[17] = "ProposalApproveContract"] = 17;
                values[valuesById[18] = "ProposalDeleteContract"] = 18;
                values[valuesById[19] = "SetAccountIdContract"] = 19;
                values[valuesById[20] = "CustomContract"] = 20;
                values[valuesById[30] = "CreateSmartContract"] = 30;
                values[valuesById[31] = "TriggerSmartContract"] = 31;
                values[valuesById[32] = "GetContract"] = 32;
                values[valuesById[33] = "UpdateSettingContract"] = 33;
                values[valuesById[41] = "ExchangeCreateContract"] = 41;
                values[valuesById[42] = "ExchangeInjectContract"] = 42;
                values[valuesById[43] = "ExchangeWithdrawContract"] = 43;
                values[valuesById[44] = "ExchangeTransactionContract"] = 44;
                values[valuesById[45] = "UpdateEnergyLimitContract"] = 45;
                values[valuesById[46] = "AccountPermissionUpdateContract"] = 46;
                values[valuesById[48] = "ClearABIContract"] = 48;
                values[valuesById[49] = "UpdateBrokerageContract"] = 49;
                values[valuesById[51] = "ShieldedTransferContract"] = 51;
                values[valuesById[52] = "MarketSellAssetContract"] = 52;
                values[valuesById[53] = "MarketCancelOrderContract"] = 53;
                values[valuesById[54] = "FreezeBalanceV2Contract"] = 54;
                values[valuesById[55] = "UnfreezeBalanceV2Contract"] = 55;
                values[valuesById[56] = "WithdrawExpireUnfreezeContract"] = 56;
                values[valuesById[57] = "DelegateResourceContract"] = 57;
                values[valuesById[58] = "UnDelegateResourceContract"] = 58;
                values[valuesById[59] = "CancelAllUnfreezeV2Contract"] = 59;
                return values;
            })();
    
            return TronContract;
        })();
    
        protocol.TronTransactionResult = (function() {
    
            /**
             * Properties of a TronTransactionResult.
             * @memberof protocol
             * @interface ITronTransactionResult
             * @property {Long|null} [fee] TronTransactionResult fee
             * @property {protocol.TronTransactionResult.Code|null} [ret] TronTransactionResult ret
             * @property {protocol.TronTransactionResult.ContractResult|null} [contractRet] TronTransactionResult contractRet
             * @property {string|null} [assetIssueId] TronTransactionResult assetIssueId
             * @property {Long|null} [withdrawAmount] TronTransactionResult withdrawAmount
             * @property {Long|null} [unfreezeAmount] TronTransactionResult unfreezeAmount
             * @property {Long|null} [exchangeReceivedAmount] TronTransactionResult exchangeReceivedAmount
             * @property {Long|null} [exchangeInjectAnotherAmount] TronTransactionResult exchangeInjectAnotherAmount
             * @property {Long|null} [exchangeWithdrawAnotherAmount] TronTransactionResult exchangeWithdrawAnotherAmount
             * @property {Long|null} [exchangeId] TronTransactionResult exchangeId
             * @property {Long|null} [shieldedTransactionFee] TronTransactionResult shieldedTransactionFee
             * @property {Uint8Array|null} [orderId] TronTransactionResult orderId
             * @property {Array.<protocol.ITronMarketOrderDetail>|null} [orderDetails] TronTransactionResult orderDetails
             * @property {Long|null} [withdrawExpireAmount] TronTransactionResult withdrawExpireAmount
             */
    
            /**
             * Constructs a new TronTransactionResult.
             * @memberof protocol
             * @classdesc Represents a TronTransactionResult.
             * @implements ITronTransactionResult
             * @constructor
             * @param {protocol.ITronTransactionResult=} [properties] Properties to set
             */
            function TronTransactionResult(properties) {
                this.orderDetails = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronTransactionResult fee.
             * @member {Long} fee
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult ret.
             * @member {protocol.TronTransactionResult.Code} ret
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.ret = 0;
    
            /**
             * TronTransactionResult contractRet.
             * @member {protocol.TronTransactionResult.ContractResult} contractRet
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.contractRet = 0;
    
            /**
             * TronTransactionResult assetIssueId.
             * @member {string} assetIssueId
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.assetIssueId = "";
    
            /**
             * TronTransactionResult withdrawAmount.
             * @member {Long} withdrawAmount
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.withdrawAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult unfreezeAmount.
             * @member {Long} unfreezeAmount
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.unfreezeAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult exchangeReceivedAmount.
             * @member {Long} exchangeReceivedAmount
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.exchangeReceivedAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult exchangeInjectAnotherAmount.
             * @member {Long} exchangeInjectAnotherAmount
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.exchangeInjectAnotherAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult exchangeWithdrawAnotherAmount.
             * @member {Long} exchangeWithdrawAnotherAmount
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.exchangeWithdrawAnotherAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult exchangeId.
             * @member {Long} exchangeId
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.exchangeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult shieldedTransactionFee.
             * @member {Long} shieldedTransactionFee
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.shieldedTransactionFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionResult orderId.
             * @member {Uint8Array} orderId
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.orderId = $util.newBuffer([]);
    
            /**
             * TronTransactionResult orderDetails.
             * @member {Array.<protocol.ITronMarketOrderDetail>} orderDetails
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.orderDetails = $util.emptyArray;
    
            /**
             * TronTransactionResult withdrawExpireAmount.
             * @member {Long} withdrawExpireAmount
             * @memberof protocol.TronTransactionResult
             * @instance
             */
            TronTransactionResult.prototype.withdrawExpireAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TronTransactionResult instance using the specified properties.
             * @function create
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {protocol.ITronTransactionResult=} [properties] Properties to set
             * @returns {protocol.TronTransactionResult} TronTransactionResult instance
             */
            TronTransactionResult.create = function create(properties) {
                return new TronTransactionResult(properties);
            };
    
            /**
             * Encodes the specified TronTransactionResult message. Does not implicitly {@link protocol.TronTransactionResult.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {protocol.ITronTransactionResult} message TronTransactionResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTransactionResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fee);
                if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ret);
                if (message.contractRet != null && Object.hasOwnProperty.call(message, "contractRet"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.contractRet);
                if (message.assetIssueId != null && Object.hasOwnProperty.call(message, "assetIssueId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.assetIssueId);
                if (message.withdrawAmount != null && Object.hasOwnProperty.call(message, "withdrawAmount"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.withdrawAmount);
                if (message.unfreezeAmount != null && Object.hasOwnProperty.call(message, "unfreezeAmount"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.unfreezeAmount);
                if (message.exchangeReceivedAmount != null && Object.hasOwnProperty.call(message, "exchangeReceivedAmount"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.exchangeReceivedAmount);
                if (message.exchangeInjectAnotherAmount != null && Object.hasOwnProperty.call(message, "exchangeInjectAnotherAmount"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.exchangeInjectAnotherAmount);
                if (message.exchangeWithdrawAnotherAmount != null && Object.hasOwnProperty.call(message, "exchangeWithdrawAnotherAmount"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.exchangeWithdrawAnotherAmount);
                if (message.exchangeId != null && Object.hasOwnProperty.call(message, "exchangeId"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.exchangeId);
                if (message.shieldedTransactionFee != null && Object.hasOwnProperty.call(message, "shieldedTransactionFee"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int64(message.shieldedTransactionFee);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 25, wireType 2 =*/202).bytes(message.orderId);
                if (message.orderDetails != null && message.orderDetails.length)
                    for (var i = 0; i < message.orderDetails.length; ++i)
                        $root.protocol.TronMarketOrderDetail.encode(message.orderDetails[i], writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.withdrawExpireAmount != null && Object.hasOwnProperty.call(message, "withdrawExpireAmount"))
                    writer.uint32(/* id 27, wireType 0 =*/216).int64(message.withdrawExpireAmount);
                return writer;
            };
    
            /**
             * Encodes the specified TronTransactionResult message, length delimited. Does not implicitly {@link protocol.TronTransactionResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {protocol.ITronTransactionResult} message TronTransactionResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTransactionResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronTransactionResult message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronTransactionResult} TronTransactionResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTransactionResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronTransactionResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.fee = reader.int64();
                            break;
                        }
                    case 2: {
                            message.ret = reader.int32();
                            break;
                        }
                    case 3: {
                            message.contractRet = reader.int32();
                            break;
                        }
                    case 4: {
                            message.assetIssueId = reader.string();
                            break;
                        }
                    case 5: {
                            message.withdrawAmount = reader.int64();
                            break;
                        }
                    case 6: {
                            message.unfreezeAmount = reader.int64();
                            break;
                        }
                    case 7: {
                            message.exchangeReceivedAmount = reader.int64();
                            break;
                        }
                    case 8: {
                            message.exchangeInjectAnotherAmount = reader.int64();
                            break;
                        }
                    case 9: {
                            message.exchangeWithdrawAnotherAmount = reader.int64();
                            break;
                        }
                    case 10: {
                            message.exchangeId = reader.int64();
                            break;
                        }
                    case 11: {
                            message.shieldedTransactionFee = reader.int64();
                            break;
                        }
                    case 25: {
                            message.orderId = reader.bytes();
                            break;
                        }
                    case 26: {
                            if (!(message.orderDetails && message.orderDetails.length))
                                message.orderDetails = [];
                            message.orderDetails.push($root.protocol.TronMarketOrderDetail.decode(reader, reader.uint32()));
                            break;
                        }
                    case 27: {
                            message.withdrawExpireAmount = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronTransactionResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronTransactionResult} TronTransactionResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTransactionResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronTransactionResult message.
             * @function verify
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronTransactionResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                        return "fee: integer|Long expected";
                if (message.ret != null && message.hasOwnProperty("ret"))
                    switch (message.ret) {
                    default:
                        return "ret: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.contractRet != null && message.hasOwnProperty("contractRet"))
                    switch (message.contractRet) {
                    default:
                        return "contractRet: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                        break;
                    }
                if (message.assetIssueId != null && message.hasOwnProperty("assetIssueId"))
                    if (!$util.isString(message.assetIssueId))
                        return "assetIssueId: string expected";
                if (message.withdrawAmount != null && message.hasOwnProperty("withdrawAmount"))
                    if (!$util.isInteger(message.withdrawAmount) && !(message.withdrawAmount && $util.isInteger(message.withdrawAmount.low) && $util.isInteger(message.withdrawAmount.high)))
                        return "withdrawAmount: integer|Long expected";
                if (message.unfreezeAmount != null && message.hasOwnProperty("unfreezeAmount"))
                    if (!$util.isInteger(message.unfreezeAmount) && !(message.unfreezeAmount && $util.isInteger(message.unfreezeAmount.low) && $util.isInteger(message.unfreezeAmount.high)))
                        return "unfreezeAmount: integer|Long expected";
                if (message.exchangeReceivedAmount != null && message.hasOwnProperty("exchangeReceivedAmount"))
                    if (!$util.isInteger(message.exchangeReceivedAmount) && !(message.exchangeReceivedAmount && $util.isInteger(message.exchangeReceivedAmount.low) && $util.isInteger(message.exchangeReceivedAmount.high)))
                        return "exchangeReceivedAmount: integer|Long expected";
                if (message.exchangeInjectAnotherAmount != null && message.hasOwnProperty("exchangeInjectAnotherAmount"))
                    if (!$util.isInteger(message.exchangeInjectAnotherAmount) && !(message.exchangeInjectAnotherAmount && $util.isInteger(message.exchangeInjectAnotherAmount.low) && $util.isInteger(message.exchangeInjectAnotherAmount.high)))
                        return "exchangeInjectAnotherAmount: integer|Long expected";
                if (message.exchangeWithdrawAnotherAmount != null && message.hasOwnProperty("exchangeWithdrawAnotherAmount"))
                    if (!$util.isInteger(message.exchangeWithdrawAnotherAmount) && !(message.exchangeWithdrawAnotherAmount && $util.isInteger(message.exchangeWithdrawAnotherAmount.low) && $util.isInteger(message.exchangeWithdrawAnotherAmount.high)))
                        return "exchangeWithdrawAnotherAmount: integer|Long expected";
                if (message.exchangeId != null && message.hasOwnProperty("exchangeId"))
                    if (!$util.isInteger(message.exchangeId) && !(message.exchangeId && $util.isInteger(message.exchangeId.low) && $util.isInteger(message.exchangeId.high)))
                        return "exchangeId: integer|Long expected";
                if (message.shieldedTransactionFee != null && message.hasOwnProperty("shieldedTransactionFee"))
                    if (!$util.isInteger(message.shieldedTransactionFee) && !(message.shieldedTransactionFee && $util.isInteger(message.shieldedTransactionFee.low) && $util.isInteger(message.shieldedTransactionFee.high)))
                        return "shieldedTransactionFee: integer|Long expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!(message.orderId && typeof message.orderId.length === "number" || $util.isString(message.orderId)))
                        return "orderId: buffer expected";
                if (message.orderDetails != null && message.hasOwnProperty("orderDetails")) {
                    if (!Array.isArray(message.orderDetails))
                        return "orderDetails: array expected";
                    for (var i = 0; i < message.orderDetails.length; ++i) {
                        var error = $root.protocol.TronMarketOrderDetail.verify(message.orderDetails[i]);
                        if (error)
                            return "orderDetails." + error;
                    }
                }
                if (message.withdrawExpireAmount != null && message.hasOwnProperty("withdrawExpireAmount"))
                    if (!$util.isInteger(message.withdrawExpireAmount) && !(message.withdrawExpireAmount && $util.isInteger(message.withdrawExpireAmount.low) && $util.isInteger(message.withdrawExpireAmount.high)))
                        return "withdrawExpireAmount: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TronTransactionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronTransactionResult} TronTransactionResult
             */
            TronTransactionResult.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronTransactionResult)
                    return object;
                var message = new $root.protocol.TronTransactionResult();
                if (object.fee != null)
                    if ($util.Long)
                        (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
                    else if (typeof object.fee === "string")
                        message.fee = parseInt(object.fee, 10);
                    else if (typeof object.fee === "number")
                        message.fee = object.fee;
                    else if (typeof object.fee === "object")
                        message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
                switch (object.ret) {
                default:
                    if (typeof object.ret === "number") {
                        message.ret = object.ret;
                        break;
                    }
                    break;
                case "SUCESS":
                case 0:
                    message.ret = 0;
                    break;
                case "FAILED":
                case 1:
                    message.ret = 1;
                    break;
                }
                switch (object.contractRet) {
                default:
                    if (typeof object.contractRet === "number") {
                        message.contractRet = object.contractRet;
                        break;
                    }
                    break;
                case "DEFAULT":
                case 0:
                    message.contractRet = 0;
                    break;
                case "SUCCESS":
                case 1:
                    message.contractRet = 1;
                    break;
                case "REVERT":
                case 2:
                    message.contractRet = 2;
                    break;
                case "BAD_JUMP_DESTINATION":
                case 3:
                    message.contractRet = 3;
                    break;
                case "OUT_OF_MEMORY":
                case 4:
                    message.contractRet = 4;
                    break;
                case "PRECOMPILED_CONTRACT":
                case 5:
                    message.contractRet = 5;
                    break;
                case "STACK_TOO_SMALL":
                case 6:
                    message.contractRet = 6;
                    break;
                case "STACK_TOO_LARGE":
                case 7:
                    message.contractRet = 7;
                    break;
                case "ILLEGAL_OPERATION":
                case 8:
                    message.contractRet = 8;
                    break;
                case "STACK_OVERFLOW":
                case 9:
                    message.contractRet = 9;
                    break;
                case "OUT_OF_ENERGY":
                case 10:
                    message.contractRet = 10;
                    break;
                case "OUT_OF_TIME":
                case 11:
                    message.contractRet = 11;
                    break;
                case "JVM_STACK_OVER_FLOW":
                case 12:
                    message.contractRet = 12;
                    break;
                case "UNKNOWN":
                case 13:
                    message.contractRet = 13;
                    break;
                case "TRANSFER_FAILED":
                case 14:
                    message.contractRet = 14;
                    break;
                case "INVALID_CODE":
                case 15:
                    message.contractRet = 15;
                    break;
                }
                if (object.assetIssueId != null)
                    message.assetIssueId = String(object.assetIssueId);
                if (object.withdrawAmount != null)
                    if ($util.Long)
                        (message.withdrawAmount = $util.Long.fromValue(object.withdrawAmount)).unsigned = false;
                    else if (typeof object.withdrawAmount === "string")
                        message.withdrawAmount = parseInt(object.withdrawAmount, 10);
                    else if (typeof object.withdrawAmount === "number")
                        message.withdrawAmount = object.withdrawAmount;
                    else if (typeof object.withdrawAmount === "object")
                        message.withdrawAmount = new $util.LongBits(object.withdrawAmount.low >>> 0, object.withdrawAmount.high >>> 0).toNumber();
                if (object.unfreezeAmount != null)
                    if ($util.Long)
                        (message.unfreezeAmount = $util.Long.fromValue(object.unfreezeAmount)).unsigned = false;
                    else if (typeof object.unfreezeAmount === "string")
                        message.unfreezeAmount = parseInt(object.unfreezeAmount, 10);
                    else if (typeof object.unfreezeAmount === "number")
                        message.unfreezeAmount = object.unfreezeAmount;
                    else if (typeof object.unfreezeAmount === "object")
                        message.unfreezeAmount = new $util.LongBits(object.unfreezeAmount.low >>> 0, object.unfreezeAmount.high >>> 0).toNumber();
                if (object.exchangeReceivedAmount != null)
                    if ($util.Long)
                        (message.exchangeReceivedAmount = $util.Long.fromValue(object.exchangeReceivedAmount)).unsigned = false;
                    else if (typeof object.exchangeReceivedAmount === "string")
                        message.exchangeReceivedAmount = parseInt(object.exchangeReceivedAmount, 10);
                    else if (typeof object.exchangeReceivedAmount === "number")
                        message.exchangeReceivedAmount = object.exchangeReceivedAmount;
                    else if (typeof object.exchangeReceivedAmount === "object")
                        message.exchangeReceivedAmount = new $util.LongBits(object.exchangeReceivedAmount.low >>> 0, object.exchangeReceivedAmount.high >>> 0).toNumber();
                if (object.exchangeInjectAnotherAmount != null)
                    if ($util.Long)
                        (message.exchangeInjectAnotherAmount = $util.Long.fromValue(object.exchangeInjectAnotherAmount)).unsigned = false;
                    else if (typeof object.exchangeInjectAnotherAmount === "string")
                        message.exchangeInjectAnotherAmount = parseInt(object.exchangeInjectAnotherAmount, 10);
                    else if (typeof object.exchangeInjectAnotherAmount === "number")
                        message.exchangeInjectAnotherAmount = object.exchangeInjectAnotherAmount;
                    else if (typeof object.exchangeInjectAnotherAmount === "object")
                        message.exchangeInjectAnotherAmount = new $util.LongBits(object.exchangeInjectAnotherAmount.low >>> 0, object.exchangeInjectAnotherAmount.high >>> 0).toNumber();
                if (object.exchangeWithdrawAnotherAmount != null)
                    if ($util.Long)
                        (message.exchangeWithdrawAnotherAmount = $util.Long.fromValue(object.exchangeWithdrawAnotherAmount)).unsigned = false;
                    else if (typeof object.exchangeWithdrawAnotherAmount === "string")
                        message.exchangeWithdrawAnotherAmount = parseInt(object.exchangeWithdrawAnotherAmount, 10);
                    else if (typeof object.exchangeWithdrawAnotherAmount === "number")
                        message.exchangeWithdrawAnotherAmount = object.exchangeWithdrawAnotherAmount;
                    else if (typeof object.exchangeWithdrawAnotherAmount === "object")
                        message.exchangeWithdrawAnotherAmount = new $util.LongBits(object.exchangeWithdrawAnotherAmount.low >>> 0, object.exchangeWithdrawAnotherAmount.high >>> 0).toNumber();
                if (object.exchangeId != null)
                    if ($util.Long)
                        (message.exchangeId = $util.Long.fromValue(object.exchangeId)).unsigned = false;
                    else if (typeof object.exchangeId === "string")
                        message.exchangeId = parseInt(object.exchangeId, 10);
                    else if (typeof object.exchangeId === "number")
                        message.exchangeId = object.exchangeId;
                    else if (typeof object.exchangeId === "object")
                        message.exchangeId = new $util.LongBits(object.exchangeId.low >>> 0, object.exchangeId.high >>> 0).toNumber();
                if (object.shieldedTransactionFee != null)
                    if ($util.Long)
                        (message.shieldedTransactionFee = $util.Long.fromValue(object.shieldedTransactionFee)).unsigned = false;
                    else if (typeof object.shieldedTransactionFee === "string")
                        message.shieldedTransactionFee = parseInt(object.shieldedTransactionFee, 10);
                    else if (typeof object.shieldedTransactionFee === "number")
                        message.shieldedTransactionFee = object.shieldedTransactionFee;
                    else if (typeof object.shieldedTransactionFee === "object")
                        message.shieldedTransactionFee = new $util.LongBits(object.shieldedTransactionFee.low >>> 0, object.shieldedTransactionFee.high >>> 0).toNumber();
                if (object.orderId != null)
                    if (typeof object.orderId === "string")
                        $util.base64.decode(object.orderId, message.orderId = $util.newBuffer($util.base64.length(object.orderId)), 0);
                    else if (object.orderId.length >= 0)
                        message.orderId = object.orderId;
                if (object.orderDetails) {
                    if (!Array.isArray(object.orderDetails))
                        throw TypeError(".protocol.TronTransactionResult.orderDetails: array expected");
                    message.orderDetails = [];
                    for (var i = 0; i < object.orderDetails.length; ++i) {
                        if (typeof object.orderDetails[i] !== "object")
                            throw TypeError(".protocol.TronTransactionResult.orderDetails: object expected");
                        message.orderDetails[i] = $root.protocol.TronMarketOrderDetail.fromObject(object.orderDetails[i]);
                    }
                }
                if (object.withdrawExpireAmount != null)
                    if ($util.Long)
                        (message.withdrawExpireAmount = $util.Long.fromValue(object.withdrawExpireAmount)).unsigned = false;
                    else if (typeof object.withdrawExpireAmount === "string")
                        message.withdrawExpireAmount = parseInt(object.withdrawExpireAmount, 10);
                    else if (typeof object.withdrawExpireAmount === "number")
                        message.withdrawExpireAmount = object.withdrawExpireAmount;
                    else if (typeof object.withdrawExpireAmount === "object")
                        message.withdrawExpireAmount = new $util.LongBits(object.withdrawExpireAmount.low >>> 0, object.withdrawExpireAmount.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TronTransactionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {protocol.TronTransactionResult} message TronTransactionResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronTransactionResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderDetails = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fee = options.longs === String ? "0" : 0;
                    object.ret = options.enums === String ? "SUCESS" : 0;
                    object.contractRet = options.enums === String ? "DEFAULT" : 0;
                    object.assetIssueId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.withdrawAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.withdrawAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.unfreezeAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.unfreezeAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeReceivedAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeReceivedAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeInjectAnotherAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeInjectAnotherAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.shieldedTransactionFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.shieldedTransactionFee = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.orderId = "";
                    else {
                        object.orderId = [];
                        if (options.bytes !== Array)
                            object.orderId = $util.newBuffer(object.orderId);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.withdrawExpireAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.withdrawExpireAmount = options.longs === String ? "0" : 0;
                }
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (typeof message.fee === "number")
                        object.fee = options.longs === String ? String(message.fee) : message.fee;
                    else
                        object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber() : message.fee;
                if (message.ret != null && message.hasOwnProperty("ret"))
                    object.ret = options.enums === String ? $root.protocol.TronTransactionResult.Code[message.ret] === undefined ? message.ret : $root.protocol.TronTransactionResult.Code[message.ret] : message.ret;
                if (message.contractRet != null && message.hasOwnProperty("contractRet"))
                    object.contractRet = options.enums === String ? $root.protocol.TronTransactionResult.ContractResult[message.contractRet] === undefined ? message.contractRet : $root.protocol.TronTransactionResult.ContractResult[message.contractRet] : message.contractRet;
                if (message.assetIssueId != null && message.hasOwnProperty("assetIssueId"))
                    object.assetIssueId = message.assetIssueId;
                if (message.withdrawAmount != null && message.hasOwnProperty("withdrawAmount"))
                    if (typeof message.withdrawAmount === "number")
                        object.withdrawAmount = options.longs === String ? String(message.withdrawAmount) : message.withdrawAmount;
                    else
                        object.withdrawAmount = options.longs === String ? $util.Long.prototype.toString.call(message.withdrawAmount) : options.longs === Number ? new $util.LongBits(message.withdrawAmount.low >>> 0, message.withdrawAmount.high >>> 0).toNumber() : message.withdrawAmount;
                if (message.unfreezeAmount != null && message.hasOwnProperty("unfreezeAmount"))
                    if (typeof message.unfreezeAmount === "number")
                        object.unfreezeAmount = options.longs === String ? String(message.unfreezeAmount) : message.unfreezeAmount;
                    else
                        object.unfreezeAmount = options.longs === String ? $util.Long.prototype.toString.call(message.unfreezeAmount) : options.longs === Number ? new $util.LongBits(message.unfreezeAmount.low >>> 0, message.unfreezeAmount.high >>> 0).toNumber() : message.unfreezeAmount;
                if (message.exchangeReceivedAmount != null && message.hasOwnProperty("exchangeReceivedAmount"))
                    if (typeof message.exchangeReceivedAmount === "number")
                        object.exchangeReceivedAmount = options.longs === String ? String(message.exchangeReceivedAmount) : message.exchangeReceivedAmount;
                    else
                        object.exchangeReceivedAmount = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeReceivedAmount) : options.longs === Number ? new $util.LongBits(message.exchangeReceivedAmount.low >>> 0, message.exchangeReceivedAmount.high >>> 0).toNumber() : message.exchangeReceivedAmount;
                if (message.exchangeInjectAnotherAmount != null && message.hasOwnProperty("exchangeInjectAnotherAmount"))
                    if (typeof message.exchangeInjectAnotherAmount === "number")
                        object.exchangeInjectAnotherAmount = options.longs === String ? String(message.exchangeInjectAnotherAmount) : message.exchangeInjectAnotherAmount;
                    else
                        object.exchangeInjectAnotherAmount = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeInjectAnotherAmount) : options.longs === Number ? new $util.LongBits(message.exchangeInjectAnotherAmount.low >>> 0, message.exchangeInjectAnotherAmount.high >>> 0).toNumber() : message.exchangeInjectAnotherAmount;
                if (message.exchangeWithdrawAnotherAmount != null && message.hasOwnProperty("exchangeWithdrawAnotherAmount"))
                    if (typeof message.exchangeWithdrawAnotherAmount === "number")
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? String(message.exchangeWithdrawAnotherAmount) : message.exchangeWithdrawAnotherAmount;
                    else
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeWithdrawAnotherAmount) : options.longs === Number ? new $util.LongBits(message.exchangeWithdrawAnotherAmount.low >>> 0, message.exchangeWithdrawAnotherAmount.high >>> 0).toNumber() : message.exchangeWithdrawAnotherAmount;
                if (message.exchangeId != null && message.hasOwnProperty("exchangeId"))
                    if (typeof message.exchangeId === "number")
                        object.exchangeId = options.longs === String ? String(message.exchangeId) : message.exchangeId;
                    else
                        object.exchangeId = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeId) : options.longs === Number ? new $util.LongBits(message.exchangeId.low >>> 0, message.exchangeId.high >>> 0).toNumber() : message.exchangeId;
                if (message.shieldedTransactionFee != null && message.hasOwnProperty("shieldedTransactionFee"))
                    if (typeof message.shieldedTransactionFee === "number")
                        object.shieldedTransactionFee = options.longs === String ? String(message.shieldedTransactionFee) : message.shieldedTransactionFee;
                    else
                        object.shieldedTransactionFee = options.longs === String ? $util.Long.prototype.toString.call(message.shieldedTransactionFee) : options.longs === Number ? new $util.LongBits(message.shieldedTransactionFee.low >>> 0, message.shieldedTransactionFee.high >>> 0).toNumber() : message.shieldedTransactionFee;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    object.orderId = options.bytes === String ? $util.base64.encode(message.orderId, 0, message.orderId.length) : options.bytes === Array ? Array.prototype.slice.call(message.orderId) : message.orderId;
                if (message.orderDetails && message.orderDetails.length) {
                    object.orderDetails = [];
                    for (var j = 0; j < message.orderDetails.length; ++j)
                        object.orderDetails[j] = $root.protocol.TronMarketOrderDetail.toObject(message.orderDetails[j], options);
                }
                if (message.withdrawExpireAmount != null && message.hasOwnProperty("withdrawExpireAmount"))
                    if (typeof message.withdrawExpireAmount === "number")
                        object.withdrawExpireAmount = options.longs === String ? String(message.withdrawExpireAmount) : message.withdrawExpireAmount;
                    else
                        object.withdrawExpireAmount = options.longs === String ? $util.Long.prototype.toString.call(message.withdrawExpireAmount) : options.longs === Number ? new $util.LongBits(message.withdrawExpireAmount.low >>> 0, message.withdrawExpireAmount.high >>> 0).toNumber() : message.withdrawExpireAmount;
                return object;
            };
    
            /**
             * Converts this TronTransactionResult to JSON.
             * @function toJSON
             * @memberof protocol.TronTransactionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronTransactionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronTransactionResult
             * @function getTypeUrl
             * @memberof protocol.TronTransactionResult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronTransactionResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronTransactionResult";
            };
    
            /**
             * Code enum.
             * @name protocol.TronTransactionResult.Code
             * @enum {number}
             * @property {number} SUCESS=0 SUCESS value
             * @property {number} FAILED=1 FAILED value
             */
            TronTransactionResult.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCESS"] = 0;
                values[valuesById[1] = "FAILED"] = 1;
                return values;
            })();
    
            /**
             * ContractResult enum.
             * @name protocol.TronTransactionResult.ContractResult
             * @enum {number}
             * @property {number} DEFAULT=0 DEFAULT value
             * @property {number} SUCCESS=1 SUCCESS value
             * @property {number} REVERT=2 REVERT value
             * @property {number} BAD_JUMP_DESTINATION=3 BAD_JUMP_DESTINATION value
             * @property {number} OUT_OF_MEMORY=4 OUT_OF_MEMORY value
             * @property {number} PRECOMPILED_CONTRACT=5 PRECOMPILED_CONTRACT value
             * @property {number} STACK_TOO_SMALL=6 STACK_TOO_SMALL value
             * @property {number} STACK_TOO_LARGE=7 STACK_TOO_LARGE value
             * @property {number} ILLEGAL_OPERATION=8 ILLEGAL_OPERATION value
             * @property {number} STACK_OVERFLOW=9 STACK_OVERFLOW value
             * @property {number} OUT_OF_ENERGY=10 OUT_OF_ENERGY value
             * @property {number} OUT_OF_TIME=11 OUT_OF_TIME value
             * @property {number} JVM_STACK_OVER_FLOW=12 JVM_STACK_OVER_FLOW value
             * @property {number} UNKNOWN=13 UNKNOWN value
             * @property {number} TRANSFER_FAILED=14 TRANSFER_FAILED value
             * @property {number} INVALID_CODE=15 INVALID_CODE value
             */
            TronTransactionResult.ContractResult = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DEFAULT"] = 0;
                values[valuesById[1] = "SUCCESS"] = 1;
                values[valuesById[2] = "REVERT"] = 2;
                values[valuesById[3] = "BAD_JUMP_DESTINATION"] = 3;
                values[valuesById[4] = "OUT_OF_MEMORY"] = 4;
                values[valuesById[5] = "PRECOMPILED_CONTRACT"] = 5;
                values[valuesById[6] = "STACK_TOO_SMALL"] = 6;
                values[valuesById[7] = "STACK_TOO_LARGE"] = 7;
                values[valuesById[8] = "ILLEGAL_OPERATION"] = 8;
                values[valuesById[9] = "STACK_OVERFLOW"] = 9;
                values[valuesById[10] = "OUT_OF_ENERGY"] = 10;
                values[valuesById[11] = "OUT_OF_TIME"] = 11;
                values[valuesById[12] = "JVM_STACK_OVER_FLOW"] = 12;
                values[valuesById[13] = "UNKNOWN"] = 13;
                values[valuesById[14] = "TRANSFER_FAILED"] = 14;
                values[valuesById[15] = "INVALID_CODE"] = 15;
                return values;
            })();
    
            return TronTransactionResult;
        })();
    
        protocol.TronMarketOrderDetail = (function() {
    
            /**
             * Properties of a TronMarketOrderDetail.
             * @memberof protocol
             * @interface ITronMarketOrderDetail
             * @property {Uint8Array|null} [makerOrderId] TronMarketOrderDetail makerOrderId
             * @property {Uint8Array|null} [takerOrderId] TronMarketOrderDetail takerOrderId
             * @property {Long|null} [fillSellQuantity] TronMarketOrderDetail fillSellQuantity
             * @property {Long|null} [fillBuyQuantity] TronMarketOrderDetail fillBuyQuantity
             */
    
            /**
             * Constructs a new TronMarketOrderDetail.
             * @memberof protocol
             * @classdesc Represents a TronMarketOrderDetail.
             * @implements ITronMarketOrderDetail
             * @constructor
             * @param {protocol.ITronMarketOrderDetail=} [properties] Properties to set
             */
            function TronMarketOrderDetail(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronMarketOrderDetail makerOrderId.
             * @member {Uint8Array} makerOrderId
             * @memberof protocol.TronMarketOrderDetail
             * @instance
             */
            TronMarketOrderDetail.prototype.makerOrderId = $util.newBuffer([]);
    
            /**
             * TronMarketOrderDetail takerOrderId.
             * @member {Uint8Array} takerOrderId
             * @memberof protocol.TronMarketOrderDetail
             * @instance
             */
            TronMarketOrderDetail.prototype.takerOrderId = $util.newBuffer([]);
    
            /**
             * TronMarketOrderDetail fillSellQuantity.
             * @member {Long} fillSellQuantity
             * @memberof protocol.TronMarketOrderDetail
             * @instance
             */
            TronMarketOrderDetail.prototype.fillSellQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronMarketOrderDetail fillBuyQuantity.
             * @member {Long} fillBuyQuantity
             * @memberof protocol.TronMarketOrderDetail
             * @instance
             */
            TronMarketOrderDetail.prototype.fillBuyQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TronMarketOrderDetail instance using the specified properties.
             * @function create
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {protocol.ITronMarketOrderDetail=} [properties] Properties to set
             * @returns {protocol.TronMarketOrderDetail} TronMarketOrderDetail instance
             */
            TronMarketOrderDetail.create = function create(properties) {
                return new TronMarketOrderDetail(properties);
            };
    
            /**
             * Encodes the specified TronMarketOrderDetail message. Does not implicitly {@link protocol.TronMarketOrderDetail.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {protocol.ITronMarketOrderDetail} message TronMarketOrderDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronMarketOrderDetail.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.makerOrderId != null && Object.hasOwnProperty.call(message, "makerOrderId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.makerOrderId);
                if (message.takerOrderId != null && Object.hasOwnProperty.call(message, "takerOrderId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.takerOrderId);
                if (message.fillSellQuantity != null && Object.hasOwnProperty.call(message, "fillSellQuantity"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.fillSellQuantity);
                if (message.fillBuyQuantity != null && Object.hasOwnProperty.call(message, "fillBuyQuantity"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fillBuyQuantity);
                return writer;
            };
    
            /**
             * Encodes the specified TronMarketOrderDetail message, length delimited. Does not implicitly {@link protocol.TronMarketOrderDetail.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {protocol.ITronMarketOrderDetail} message TronMarketOrderDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronMarketOrderDetail.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronMarketOrderDetail message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronMarketOrderDetail} TronMarketOrderDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronMarketOrderDetail.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronMarketOrderDetail();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.makerOrderId = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.takerOrderId = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.fillSellQuantity = reader.int64();
                            break;
                        }
                    case 4: {
                            message.fillBuyQuantity = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronMarketOrderDetail message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronMarketOrderDetail} TronMarketOrderDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronMarketOrderDetail.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronMarketOrderDetail message.
             * @function verify
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronMarketOrderDetail.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.makerOrderId != null && message.hasOwnProperty("makerOrderId"))
                    if (!(message.makerOrderId && typeof message.makerOrderId.length === "number" || $util.isString(message.makerOrderId)))
                        return "makerOrderId: buffer expected";
                if (message.takerOrderId != null && message.hasOwnProperty("takerOrderId"))
                    if (!(message.takerOrderId && typeof message.takerOrderId.length === "number" || $util.isString(message.takerOrderId)))
                        return "takerOrderId: buffer expected";
                if (message.fillSellQuantity != null && message.hasOwnProperty("fillSellQuantity"))
                    if (!$util.isInteger(message.fillSellQuantity) && !(message.fillSellQuantity && $util.isInteger(message.fillSellQuantity.low) && $util.isInteger(message.fillSellQuantity.high)))
                        return "fillSellQuantity: integer|Long expected";
                if (message.fillBuyQuantity != null && message.hasOwnProperty("fillBuyQuantity"))
                    if (!$util.isInteger(message.fillBuyQuantity) && !(message.fillBuyQuantity && $util.isInteger(message.fillBuyQuantity.low) && $util.isInteger(message.fillBuyQuantity.high)))
                        return "fillBuyQuantity: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TronMarketOrderDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronMarketOrderDetail} TronMarketOrderDetail
             */
            TronMarketOrderDetail.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronMarketOrderDetail)
                    return object;
                var message = new $root.protocol.TronMarketOrderDetail();
                if (object.makerOrderId != null)
                    if (typeof object.makerOrderId === "string")
                        $util.base64.decode(object.makerOrderId, message.makerOrderId = $util.newBuffer($util.base64.length(object.makerOrderId)), 0);
                    else if (object.makerOrderId.length >= 0)
                        message.makerOrderId = object.makerOrderId;
                if (object.takerOrderId != null)
                    if (typeof object.takerOrderId === "string")
                        $util.base64.decode(object.takerOrderId, message.takerOrderId = $util.newBuffer($util.base64.length(object.takerOrderId)), 0);
                    else if (object.takerOrderId.length >= 0)
                        message.takerOrderId = object.takerOrderId;
                if (object.fillSellQuantity != null)
                    if ($util.Long)
                        (message.fillSellQuantity = $util.Long.fromValue(object.fillSellQuantity)).unsigned = false;
                    else if (typeof object.fillSellQuantity === "string")
                        message.fillSellQuantity = parseInt(object.fillSellQuantity, 10);
                    else if (typeof object.fillSellQuantity === "number")
                        message.fillSellQuantity = object.fillSellQuantity;
                    else if (typeof object.fillSellQuantity === "object")
                        message.fillSellQuantity = new $util.LongBits(object.fillSellQuantity.low >>> 0, object.fillSellQuantity.high >>> 0).toNumber();
                if (object.fillBuyQuantity != null)
                    if ($util.Long)
                        (message.fillBuyQuantity = $util.Long.fromValue(object.fillBuyQuantity)).unsigned = false;
                    else if (typeof object.fillBuyQuantity === "string")
                        message.fillBuyQuantity = parseInt(object.fillBuyQuantity, 10);
                    else if (typeof object.fillBuyQuantity === "number")
                        message.fillBuyQuantity = object.fillBuyQuantity;
                    else if (typeof object.fillBuyQuantity === "object")
                        message.fillBuyQuantity = new $util.LongBits(object.fillBuyQuantity.low >>> 0, object.fillBuyQuantity.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TronMarketOrderDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {protocol.TronMarketOrderDetail} message TronMarketOrderDetail
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronMarketOrderDetail.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.makerOrderId = "";
                    else {
                        object.makerOrderId = [];
                        if (options.bytes !== Array)
                            object.makerOrderId = $util.newBuffer(object.makerOrderId);
                    }
                    if (options.bytes === String)
                        object.takerOrderId = "";
                    else {
                        object.takerOrderId = [];
                        if (options.bytes !== Array)
                            object.takerOrderId = $util.newBuffer(object.takerOrderId);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fillSellQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fillSellQuantity = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fillBuyQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fillBuyQuantity = options.longs === String ? "0" : 0;
                }
                if (message.makerOrderId != null && message.hasOwnProperty("makerOrderId"))
                    object.makerOrderId = options.bytes === String ? $util.base64.encode(message.makerOrderId, 0, message.makerOrderId.length) : options.bytes === Array ? Array.prototype.slice.call(message.makerOrderId) : message.makerOrderId;
                if (message.takerOrderId != null && message.hasOwnProperty("takerOrderId"))
                    object.takerOrderId = options.bytes === String ? $util.base64.encode(message.takerOrderId, 0, message.takerOrderId.length) : options.bytes === Array ? Array.prototype.slice.call(message.takerOrderId) : message.takerOrderId;
                if (message.fillSellQuantity != null && message.hasOwnProperty("fillSellQuantity"))
                    if (typeof message.fillSellQuantity === "number")
                        object.fillSellQuantity = options.longs === String ? String(message.fillSellQuantity) : message.fillSellQuantity;
                    else
                        object.fillSellQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.fillSellQuantity) : options.longs === Number ? new $util.LongBits(message.fillSellQuantity.low >>> 0, message.fillSellQuantity.high >>> 0).toNumber() : message.fillSellQuantity;
                if (message.fillBuyQuantity != null && message.hasOwnProperty("fillBuyQuantity"))
                    if (typeof message.fillBuyQuantity === "number")
                        object.fillBuyQuantity = options.longs === String ? String(message.fillBuyQuantity) : message.fillBuyQuantity;
                    else
                        object.fillBuyQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.fillBuyQuantity) : options.longs === Number ? new $util.LongBits(message.fillBuyQuantity.low >>> 0, message.fillBuyQuantity.high >>> 0).toNumber() : message.fillBuyQuantity;
                return object;
            };
    
            /**
             * Converts this TronMarketOrderDetail to JSON.
             * @function toJSON
             * @memberof protocol.TronMarketOrderDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronMarketOrderDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronMarketOrderDetail
             * @function getTypeUrl
             * @memberof protocol.TronMarketOrderDetail
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronMarketOrderDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronMarketOrderDetail";
            };
    
            return TronMarketOrderDetail;
        })();
    
        protocol.TronAuthority = (function() {
    
            /**
             * Properties of a TronAuthority.
             * @memberof protocol
             * @interface ITronAuthority
             * @property {protocol.ITronAccountId|null} [account] TronAuthority account
             * @property {Uint8Array|null} [permissionName] TronAuthority permissionName
             */
    
            /**
             * Constructs a new TronAuthority.
             * @memberof protocol
             * @classdesc Represents a TronAuthority.
             * @implements ITronAuthority
             * @constructor
             * @param {protocol.ITronAuthority=} [properties] Properties to set
             */
            function TronAuthority(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronAuthority account.
             * @member {protocol.ITronAccountId|null|undefined} account
             * @memberof protocol.TronAuthority
             * @instance
             */
            TronAuthority.prototype.account = null;
    
            /**
             * TronAuthority permissionName.
             * @member {Uint8Array} permissionName
             * @memberof protocol.TronAuthority
             * @instance
             */
            TronAuthority.prototype.permissionName = $util.newBuffer([]);
    
            /**
             * Creates a new TronAuthority instance using the specified properties.
             * @function create
             * @memberof protocol.TronAuthority
             * @static
             * @param {protocol.ITronAuthority=} [properties] Properties to set
             * @returns {protocol.TronAuthority} TronAuthority instance
             */
            TronAuthority.create = function create(properties) {
                return new TronAuthority(properties);
            };
    
            /**
             * Encodes the specified TronAuthority message. Does not implicitly {@link protocol.TronAuthority.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronAuthority
             * @static
             * @param {protocol.ITronAuthority} message TronAuthority message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronAuthority.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    $root.protocol.TronAccountId.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.permissionName != null && Object.hasOwnProperty.call(message, "permissionName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.permissionName);
                return writer;
            };
    
            /**
             * Encodes the specified TronAuthority message, length delimited. Does not implicitly {@link protocol.TronAuthority.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronAuthority
             * @static
             * @param {protocol.ITronAuthority} message TronAuthority message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronAuthority.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronAuthority message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronAuthority
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronAuthority} TronAuthority
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronAuthority.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronAuthority();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = $root.protocol.TronAccountId.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.permissionName = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronAuthority message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronAuthority
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronAuthority} TronAuthority
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronAuthority.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronAuthority message.
             * @function verify
             * @memberof protocol.TronAuthority
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronAuthority.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account")) {
                    var error = $root.protocol.TronAccountId.verify(message.account);
                    if (error)
                        return "account." + error;
                }
                if (message.permissionName != null && message.hasOwnProperty("permissionName"))
                    if (!(message.permissionName && typeof message.permissionName.length === "number" || $util.isString(message.permissionName)))
                        return "permissionName: buffer expected";
                return null;
            };
    
            /**
             * Creates a TronAuthority message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronAuthority
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronAuthority} TronAuthority
             */
            TronAuthority.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronAuthority)
                    return object;
                var message = new $root.protocol.TronAuthority();
                if (object.account != null) {
                    if (typeof object.account !== "object")
                        throw TypeError(".protocol.TronAuthority.account: object expected");
                    message.account = $root.protocol.TronAccountId.fromObject(object.account);
                }
                if (object.permissionName != null)
                    if (typeof object.permissionName === "string")
                        $util.base64.decode(object.permissionName, message.permissionName = $util.newBuffer($util.base64.length(object.permissionName)), 0);
                    else if (object.permissionName.length >= 0)
                        message.permissionName = object.permissionName;
                return message;
            };
    
            /**
             * Creates a plain object from a TronAuthority message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronAuthority
             * @static
             * @param {protocol.TronAuthority} message TronAuthority
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronAuthority.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.account = null;
                    if (options.bytes === String)
                        object.permissionName = "";
                    else {
                        object.permissionName = [];
                        if (options.bytes !== Array)
                            object.permissionName = $util.newBuffer(object.permissionName);
                    }
                }
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = $root.protocol.TronAccountId.toObject(message.account, options);
                if (message.permissionName != null && message.hasOwnProperty("permissionName"))
                    object.permissionName = options.bytes === String ? $util.base64.encode(message.permissionName, 0, message.permissionName.length) : options.bytes === Array ? Array.prototype.slice.call(message.permissionName) : message.permissionName;
                return object;
            };
    
            /**
             * Converts this TronAuthority to JSON.
             * @function toJSON
             * @memberof protocol.TronAuthority
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronAuthority.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronAuthority
             * @function getTypeUrl
             * @memberof protocol.TronAuthority
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronAuthority.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronAuthority";
            };
    
            return TronAuthority;
        })();
    
        protocol.TronAccountId = (function() {
    
            /**
             * Properties of a TronAccountId.
             * @memberof protocol
             * @interface ITronAccountId
             * @property {Uint8Array|null} [name] TronAccountId name
             * @property {Uint8Array|null} [address] TronAccountId address
             */
    
            /**
             * Constructs a new TronAccountId.
             * @memberof protocol
             * @classdesc Represents a TronAccountId.
             * @implements ITronAccountId
             * @constructor
             * @param {protocol.ITronAccountId=} [properties] Properties to set
             */
            function TronAccountId(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronAccountId name.
             * @member {Uint8Array} name
             * @memberof protocol.TronAccountId
             * @instance
             */
            TronAccountId.prototype.name = $util.newBuffer([]);
    
            /**
             * TronAccountId address.
             * @member {Uint8Array} address
             * @memberof protocol.TronAccountId
             * @instance
             */
            TronAccountId.prototype.address = $util.newBuffer([]);
    
            /**
             * Creates a new TronAccountId instance using the specified properties.
             * @function create
             * @memberof protocol.TronAccountId
             * @static
             * @param {protocol.ITronAccountId=} [properties] Properties to set
             * @returns {protocol.TronAccountId} TronAccountId instance
             */
            TronAccountId.create = function create(properties) {
                return new TronAccountId(properties);
            };
    
            /**
             * Encodes the specified TronAccountId message. Does not implicitly {@link protocol.TronAccountId.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronAccountId
             * @static
             * @param {protocol.ITronAccountId} message TronAccountId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronAccountId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.name);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.address);
                return writer;
            };
    
            /**
             * Encodes the specified TronAccountId message, length delimited. Does not implicitly {@link protocol.TronAccountId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronAccountId
             * @static
             * @param {protocol.ITronAccountId} message TronAccountId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronAccountId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronAccountId message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronAccountId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronAccountId} TronAccountId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronAccountId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronAccountId();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.address = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronAccountId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronAccountId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronAccountId} TronAccountId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronAccountId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronAccountId message.
             * @function verify
             * @memberof protocol.TronAccountId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronAccountId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!(message.name && typeof message.name.length === "number" || $util.isString(message.name)))
                        return "name: buffer expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                return null;
            };
    
            /**
             * Creates a TronAccountId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronAccountId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronAccountId} TronAccountId
             */
            TronAccountId.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronAccountId)
                    return object;
                var message = new $root.protocol.TronAccountId();
                if (object.name != null)
                    if (typeof object.name === "string")
                        $util.base64.decode(object.name, message.name = $util.newBuffer($util.base64.length(object.name)), 0);
                    else if (object.name.length >= 0)
                        message.name = object.name;
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length >= 0)
                        message.address = object.address;
                return message;
            };
    
            /**
             * Creates a plain object from a TronAccountId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronAccountId
             * @static
             * @param {protocol.TronAccountId} message TronAccountId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronAccountId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.name = "";
                    else {
                        object.name = [];
                        if (options.bytes !== Array)
                            object.name = $util.newBuffer(object.name);
                    }
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = options.bytes === String ? $util.base64.encode(message.name, 0, message.name.length) : options.bytes === Array ? Array.prototype.slice.call(message.name) : message.name;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                return object;
            };
    
            /**
             * Converts this TronAccountId to JSON.
             * @function toJSON
             * @memberof protocol.TronAccountId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronAccountId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronAccountId
             * @function getTypeUrl
             * @memberof protocol.TronAccountId
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronAccountId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronAccountId";
            };
    
            return TronAccountId;
        })();
    
        protocol.TronTransactionInfo = (function() {
    
            /**
             * Properties of a TronTransactionInfo.
             * @memberof protocol
             * @interface ITronTransactionInfo
             * @property {Uint8Array|null} [id] TronTransactionInfo id
             * @property {Long|null} [fee] TronTransactionInfo fee
             * @property {Long|null} [blockNumber] TronTransactionInfo blockNumber
             * @property {Long|null} [blockTimestamp] TronTransactionInfo blockTimestamp
             * @property {Array.<Uint8Array>|null} [contractResult] TronTransactionInfo contractResult
             * @property {Uint8Array|null} [contractAddress] TronTransactionInfo contractAddress
             * @property {protocol.ITronResourceReceipt|null} [receipt] TronTransactionInfo receipt
             * @property {Array.<protocol.ITronLog>|null} [log] TronTransactionInfo log
             * @property {protocol.TronTransactionInfo.Code|null} [result] TronTransactionInfo result
             * @property {Uint8Array|null} [resMessage] TronTransactionInfo resMessage
             * @property {string|null} [assetIssueId] TronTransactionInfo assetIssueId
             * @property {Long|null} [withdrawAmount] TronTransactionInfo withdrawAmount
             * @property {Long|null} [unfreezeAmount] TronTransactionInfo unfreezeAmount
             * @property {Array.<protocol.ITronInternalTransaction>|null} [internalTransactions] TronTransactionInfo internalTransactions
             * @property {Long|null} [exchangeReceivedAmount] TronTransactionInfo exchangeReceivedAmount
             * @property {Long|null} [exchangeInjectAnotherAmount] TronTransactionInfo exchangeInjectAnotherAmount
             * @property {Long|null} [exchangeWithdrawAnotherAmount] TronTransactionInfo exchangeWithdrawAnotherAmount
             * @property {Long|null} [exchangeId] TronTransactionInfo exchangeId
             * @property {Long|null} [shieldedTransactionFee] TronTransactionInfo shieldedTransactionFee
             * @property {Uint8Array|null} [orderId] TronTransactionInfo orderId
             * @property {Array.<protocol.ITronMarketOrderDetail>|null} [orderDetails] TronTransactionInfo orderDetails
             * @property {Long|null} [packingFee] TronTransactionInfo packingFee
             * @property {Long|null} [withdrawExpireAmount] TronTransactionInfo withdrawExpireAmount
             */
    
            /**
             * Constructs a new TronTransactionInfo.
             * @memberof protocol
             * @classdesc Represents a TronTransactionInfo.
             * @implements ITronTransactionInfo
             * @constructor
             * @param {protocol.ITronTransactionInfo=} [properties] Properties to set
             */
            function TronTransactionInfo(properties) {
                this.contractResult = [];
                this.log = [];
                this.internalTransactions = [];
                this.orderDetails = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronTransactionInfo id.
             * @member {Uint8Array} id
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.id = $util.newBuffer([]);
    
            /**
             * TronTransactionInfo fee.
             * @member {Long} fee
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo blockNumber.
             * @member {Long} blockNumber
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.blockNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo blockTimestamp.
             * @member {Long} blockTimestamp
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.blockTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo contractResult.
             * @member {Array.<Uint8Array>} contractResult
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.contractResult = $util.emptyArray;
    
            /**
             * TronTransactionInfo contractAddress.
             * @member {Uint8Array} contractAddress
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.contractAddress = $util.newBuffer([]);
    
            /**
             * TronTransactionInfo receipt.
             * @member {protocol.ITronResourceReceipt|null|undefined} receipt
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.receipt = null;
    
            /**
             * TronTransactionInfo log.
             * @member {Array.<protocol.ITronLog>} log
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.log = $util.emptyArray;
    
            /**
             * TronTransactionInfo result.
             * @member {protocol.TronTransactionInfo.Code} result
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.result = 0;
    
            /**
             * TronTransactionInfo resMessage.
             * @member {Uint8Array} resMessage
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.resMessage = $util.newBuffer([]);
    
            /**
             * TronTransactionInfo assetIssueId.
             * @member {string} assetIssueId
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.assetIssueId = "";
    
            /**
             * TronTransactionInfo withdrawAmount.
             * @member {Long} withdrawAmount
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.withdrawAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo unfreezeAmount.
             * @member {Long} unfreezeAmount
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.unfreezeAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo internalTransactions.
             * @member {Array.<protocol.ITronInternalTransaction>} internalTransactions
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.internalTransactions = $util.emptyArray;
    
            /**
             * TronTransactionInfo exchangeReceivedAmount.
             * @member {Long} exchangeReceivedAmount
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.exchangeReceivedAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo exchangeInjectAnotherAmount.
             * @member {Long} exchangeInjectAnotherAmount
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.exchangeInjectAnotherAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo exchangeWithdrawAnotherAmount.
             * @member {Long} exchangeWithdrawAnotherAmount
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.exchangeWithdrawAnotherAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo exchangeId.
             * @member {Long} exchangeId
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.exchangeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo shieldedTransactionFee.
             * @member {Long} shieldedTransactionFee
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.shieldedTransactionFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo orderId.
             * @member {Uint8Array} orderId
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.orderId = $util.newBuffer([]);
    
            /**
             * TronTransactionInfo orderDetails.
             * @member {Array.<protocol.ITronMarketOrderDetail>} orderDetails
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.orderDetails = $util.emptyArray;
    
            /**
             * TronTransactionInfo packingFee.
             * @member {Long} packingFee
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.packingFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronTransactionInfo withdrawExpireAmount.
             * @member {Long} withdrawExpireAmount
             * @memberof protocol.TronTransactionInfo
             * @instance
             */
            TronTransactionInfo.prototype.withdrawExpireAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TronTransactionInfo instance using the specified properties.
             * @function create
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {protocol.ITronTransactionInfo=} [properties] Properties to set
             * @returns {protocol.TronTransactionInfo} TronTransactionInfo instance
             */
            TronTransactionInfo.create = function create(properties) {
                return new TronTransactionInfo(properties);
            };
    
            /**
             * Encodes the specified TronTransactionInfo message. Does not implicitly {@link protocol.TronTransactionInfo.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {protocol.ITronTransactionInfo} message TronTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTransactionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fee);
                if (message.blockNumber != null && Object.hasOwnProperty.call(message, "blockNumber"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.blockNumber);
                if (message.blockTimestamp != null && Object.hasOwnProperty.call(message, "blockTimestamp"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.blockTimestamp);
                if (message.contractResult != null && message.contractResult.length)
                    for (var i = 0; i < message.contractResult.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.contractResult[i]);
                if (message.contractAddress != null && Object.hasOwnProperty.call(message, "contractAddress"))
                    writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.contractAddress);
                if (message.receipt != null && Object.hasOwnProperty.call(message, "receipt"))
                    $root.protocol.TronResourceReceipt.encode(message.receipt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.log != null && message.log.length)
                    for (var i = 0; i < message.log.length; ++i)
                        $root.protocol.TronLog.encode(message.log[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.result);
                if (message.resMessage != null && Object.hasOwnProperty.call(message, "resMessage"))
                    writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.resMessage);
                if (message.assetIssueId != null && Object.hasOwnProperty.call(message, "assetIssueId"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.assetIssueId);
                if (message.withdrawAmount != null && Object.hasOwnProperty.call(message, "withdrawAmount"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int64(message.withdrawAmount);
                if (message.unfreezeAmount != null && Object.hasOwnProperty.call(message, "unfreezeAmount"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int64(message.unfreezeAmount);
                if (message.internalTransactions != null && message.internalTransactions.length)
                    for (var i = 0; i < message.internalTransactions.length; ++i)
                        $root.protocol.TronInternalTransaction.encode(message.internalTransactions[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.exchangeReceivedAmount != null && Object.hasOwnProperty.call(message, "exchangeReceivedAmount"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int64(message.exchangeReceivedAmount);
                if (message.exchangeInjectAnotherAmount != null && Object.hasOwnProperty.call(message, "exchangeInjectAnotherAmount"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int64(message.exchangeInjectAnotherAmount);
                if (message.exchangeWithdrawAnotherAmount != null && Object.hasOwnProperty.call(message, "exchangeWithdrawAnotherAmount"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int64(message.exchangeWithdrawAnotherAmount);
                if (message.exchangeId != null && Object.hasOwnProperty.call(message, "exchangeId"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.exchangeId);
                if (message.shieldedTransactionFee != null && Object.hasOwnProperty.call(message, "shieldedTransactionFee"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.shieldedTransactionFee);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 20, wireType 2 =*/162).bytes(message.orderId);
                if (message.orderDetails != null && message.orderDetails.length)
                    for (var i = 0; i < message.orderDetails.length; ++i)
                        $root.protocol.TronMarketOrderDetail.encode(message.orderDetails[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.packingFee != null && Object.hasOwnProperty.call(message, "packingFee"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int64(message.packingFee);
                if (message.withdrawExpireAmount != null && Object.hasOwnProperty.call(message, "withdrawExpireAmount"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int64(message.withdrawExpireAmount);
                return writer;
            };
    
            /**
             * Encodes the specified TronTransactionInfo message, length delimited. Does not implicitly {@link protocol.TronTransactionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {protocol.ITronTransactionInfo} message TronTransactionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronTransactionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronTransactionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronTransactionInfo} TronTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTransactionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronTransactionInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.fee = reader.int64();
                            break;
                        }
                    case 3: {
                            message.blockNumber = reader.int64();
                            break;
                        }
                    case 4: {
                            message.blockTimestamp = reader.int64();
                            break;
                        }
                    case 5: {
                            if (!(message.contractResult && message.contractResult.length))
                                message.contractResult = [];
                            message.contractResult.push(reader.bytes());
                            break;
                        }
                    case 6: {
                            message.contractAddress = reader.bytes();
                            break;
                        }
                    case 7: {
                            message.receipt = $root.protocol.TronResourceReceipt.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            if (!(message.log && message.log.length))
                                message.log = [];
                            message.log.push($root.protocol.TronLog.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            message.result = reader.int32();
                            break;
                        }
                    case 10: {
                            message.resMessage = reader.bytes();
                            break;
                        }
                    case 11: {
                            message.assetIssueId = reader.string();
                            break;
                        }
                    case 12: {
                            message.withdrawAmount = reader.int64();
                            break;
                        }
                    case 13: {
                            message.unfreezeAmount = reader.int64();
                            break;
                        }
                    case 14: {
                            if (!(message.internalTransactions && message.internalTransactions.length))
                                message.internalTransactions = [];
                            message.internalTransactions.push($root.protocol.TronInternalTransaction.decode(reader, reader.uint32()));
                            break;
                        }
                    case 15: {
                            message.exchangeReceivedAmount = reader.int64();
                            break;
                        }
                    case 16: {
                            message.exchangeInjectAnotherAmount = reader.int64();
                            break;
                        }
                    case 17: {
                            message.exchangeWithdrawAnotherAmount = reader.int64();
                            break;
                        }
                    case 18: {
                            message.exchangeId = reader.int64();
                            break;
                        }
                    case 19: {
                            message.shieldedTransactionFee = reader.int64();
                            break;
                        }
                    case 20: {
                            message.orderId = reader.bytes();
                            break;
                        }
                    case 21: {
                            if (!(message.orderDetails && message.orderDetails.length))
                                message.orderDetails = [];
                            message.orderDetails.push($root.protocol.TronMarketOrderDetail.decode(reader, reader.uint32()));
                            break;
                        }
                    case 22: {
                            message.packingFee = reader.int64();
                            break;
                        }
                    case 23: {
                            message.withdrawExpireAmount = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronTransactionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronTransactionInfo} TronTransactionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronTransactionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronTransactionInfo message.
             * @function verify
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronTransactionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                        return "id: buffer expected";
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                        return "fee: integer|Long expected";
                if (message.blockNumber != null && message.hasOwnProperty("blockNumber"))
                    if (!$util.isInteger(message.blockNumber) && !(message.blockNumber && $util.isInteger(message.blockNumber.low) && $util.isInteger(message.blockNumber.high)))
                        return "blockNumber: integer|Long expected";
                if (message.blockTimestamp != null && message.hasOwnProperty("blockTimestamp"))
                    if (!$util.isInteger(message.blockTimestamp) && !(message.blockTimestamp && $util.isInteger(message.blockTimestamp.low) && $util.isInteger(message.blockTimestamp.high)))
                        return "blockTimestamp: integer|Long expected";
                if (message.contractResult != null && message.hasOwnProperty("contractResult")) {
                    if (!Array.isArray(message.contractResult))
                        return "contractResult: array expected";
                    for (var i = 0; i < message.contractResult.length; ++i)
                        if (!(message.contractResult[i] && typeof message.contractResult[i].length === "number" || $util.isString(message.contractResult[i])))
                            return "contractResult: buffer[] expected";
                }
                if (message.contractAddress != null && message.hasOwnProperty("contractAddress"))
                    if (!(message.contractAddress && typeof message.contractAddress.length === "number" || $util.isString(message.contractAddress)))
                        return "contractAddress: buffer expected";
                if (message.receipt != null && message.hasOwnProperty("receipt")) {
                    var error = $root.protocol.TronResourceReceipt.verify(message.receipt);
                    if (error)
                        return "receipt." + error;
                }
                if (message.log != null && message.hasOwnProperty("log")) {
                    if (!Array.isArray(message.log))
                        return "log: array expected";
                    for (var i = 0; i < message.log.length; ++i) {
                        var error = $root.protocol.TronLog.verify(message.log[i]);
                        if (error)
                            return "log." + error;
                    }
                }
                if (message.result != null && message.hasOwnProperty("result"))
                    switch (message.result) {
                    default:
                        return "result: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.resMessage != null && message.hasOwnProperty("resMessage"))
                    if (!(message.resMessage && typeof message.resMessage.length === "number" || $util.isString(message.resMessage)))
                        return "resMessage: buffer expected";
                if (message.assetIssueId != null && message.hasOwnProperty("assetIssueId"))
                    if (!$util.isString(message.assetIssueId))
                        return "assetIssueId: string expected";
                if (message.withdrawAmount != null && message.hasOwnProperty("withdrawAmount"))
                    if (!$util.isInteger(message.withdrawAmount) && !(message.withdrawAmount && $util.isInteger(message.withdrawAmount.low) && $util.isInteger(message.withdrawAmount.high)))
                        return "withdrawAmount: integer|Long expected";
                if (message.unfreezeAmount != null && message.hasOwnProperty("unfreezeAmount"))
                    if (!$util.isInteger(message.unfreezeAmount) && !(message.unfreezeAmount && $util.isInteger(message.unfreezeAmount.low) && $util.isInteger(message.unfreezeAmount.high)))
                        return "unfreezeAmount: integer|Long expected";
                if (message.internalTransactions != null && message.hasOwnProperty("internalTransactions")) {
                    if (!Array.isArray(message.internalTransactions))
                        return "internalTransactions: array expected";
                    for (var i = 0; i < message.internalTransactions.length; ++i) {
                        var error = $root.protocol.TronInternalTransaction.verify(message.internalTransactions[i]);
                        if (error)
                            return "internalTransactions." + error;
                    }
                }
                if (message.exchangeReceivedAmount != null && message.hasOwnProperty("exchangeReceivedAmount"))
                    if (!$util.isInteger(message.exchangeReceivedAmount) && !(message.exchangeReceivedAmount && $util.isInteger(message.exchangeReceivedAmount.low) && $util.isInteger(message.exchangeReceivedAmount.high)))
                        return "exchangeReceivedAmount: integer|Long expected";
                if (message.exchangeInjectAnotherAmount != null && message.hasOwnProperty("exchangeInjectAnotherAmount"))
                    if (!$util.isInteger(message.exchangeInjectAnotherAmount) && !(message.exchangeInjectAnotherAmount && $util.isInteger(message.exchangeInjectAnotherAmount.low) && $util.isInteger(message.exchangeInjectAnotherAmount.high)))
                        return "exchangeInjectAnotherAmount: integer|Long expected";
                if (message.exchangeWithdrawAnotherAmount != null && message.hasOwnProperty("exchangeWithdrawAnotherAmount"))
                    if (!$util.isInteger(message.exchangeWithdrawAnotherAmount) && !(message.exchangeWithdrawAnotherAmount && $util.isInteger(message.exchangeWithdrawAnotherAmount.low) && $util.isInteger(message.exchangeWithdrawAnotherAmount.high)))
                        return "exchangeWithdrawAnotherAmount: integer|Long expected";
                if (message.exchangeId != null && message.hasOwnProperty("exchangeId"))
                    if (!$util.isInteger(message.exchangeId) && !(message.exchangeId && $util.isInteger(message.exchangeId.low) && $util.isInteger(message.exchangeId.high)))
                        return "exchangeId: integer|Long expected";
                if (message.shieldedTransactionFee != null && message.hasOwnProperty("shieldedTransactionFee"))
                    if (!$util.isInteger(message.shieldedTransactionFee) && !(message.shieldedTransactionFee && $util.isInteger(message.shieldedTransactionFee.low) && $util.isInteger(message.shieldedTransactionFee.high)))
                        return "shieldedTransactionFee: integer|Long expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!(message.orderId && typeof message.orderId.length === "number" || $util.isString(message.orderId)))
                        return "orderId: buffer expected";
                if (message.orderDetails != null && message.hasOwnProperty("orderDetails")) {
                    if (!Array.isArray(message.orderDetails))
                        return "orderDetails: array expected";
                    for (var i = 0; i < message.orderDetails.length; ++i) {
                        var error = $root.protocol.TronMarketOrderDetail.verify(message.orderDetails[i]);
                        if (error)
                            return "orderDetails." + error;
                    }
                }
                if (message.packingFee != null && message.hasOwnProperty("packingFee"))
                    if (!$util.isInteger(message.packingFee) && !(message.packingFee && $util.isInteger(message.packingFee.low) && $util.isInteger(message.packingFee.high)))
                        return "packingFee: integer|Long expected";
                if (message.withdrawExpireAmount != null && message.hasOwnProperty("withdrawExpireAmount"))
                    if (!$util.isInteger(message.withdrawExpireAmount) && !(message.withdrawExpireAmount && $util.isInteger(message.withdrawExpireAmount.low) && $util.isInteger(message.withdrawExpireAmount.high)))
                        return "withdrawExpireAmount: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TronTransactionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronTransactionInfo} TronTransactionInfo
             */
            TronTransactionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronTransactionInfo)
                    return object;
                var message = new $root.protocol.TronTransactionInfo();
                if (object.id != null)
                    if (typeof object.id === "string")
                        $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                    else if (object.id.length >= 0)
                        message.id = object.id;
                if (object.fee != null)
                    if ($util.Long)
                        (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
                    else if (typeof object.fee === "string")
                        message.fee = parseInt(object.fee, 10);
                    else if (typeof object.fee === "number")
                        message.fee = object.fee;
                    else if (typeof object.fee === "object")
                        message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
                if (object.blockNumber != null)
                    if ($util.Long)
                        (message.blockNumber = $util.Long.fromValue(object.blockNumber)).unsigned = false;
                    else if (typeof object.blockNumber === "string")
                        message.blockNumber = parseInt(object.blockNumber, 10);
                    else if (typeof object.blockNumber === "number")
                        message.blockNumber = object.blockNumber;
                    else if (typeof object.blockNumber === "object")
                        message.blockNumber = new $util.LongBits(object.blockNumber.low >>> 0, object.blockNumber.high >>> 0).toNumber();
                if (object.blockTimestamp != null)
                    if ($util.Long)
                        (message.blockTimestamp = $util.Long.fromValue(object.blockTimestamp)).unsigned = false;
                    else if (typeof object.blockTimestamp === "string")
                        message.blockTimestamp = parseInt(object.blockTimestamp, 10);
                    else if (typeof object.blockTimestamp === "number")
                        message.blockTimestamp = object.blockTimestamp;
                    else if (typeof object.blockTimestamp === "object")
                        message.blockTimestamp = new $util.LongBits(object.blockTimestamp.low >>> 0, object.blockTimestamp.high >>> 0).toNumber();
                if (object.contractResult) {
                    if (!Array.isArray(object.contractResult))
                        throw TypeError(".protocol.TronTransactionInfo.contractResult: array expected");
                    message.contractResult = [];
                    for (var i = 0; i < object.contractResult.length; ++i)
                        if (typeof object.contractResult[i] === "string")
                            $util.base64.decode(object.contractResult[i], message.contractResult[i] = $util.newBuffer($util.base64.length(object.contractResult[i])), 0);
                        else if (object.contractResult[i].length >= 0)
                            message.contractResult[i] = object.contractResult[i];
                }
                if (object.contractAddress != null)
                    if (typeof object.contractAddress === "string")
                        $util.base64.decode(object.contractAddress, message.contractAddress = $util.newBuffer($util.base64.length(object.contractAddress)), 0);
                    else if (object.contractAddress.length >= 0)
                        message.contractAddress = object.contractAddress;
                if (object.receipt != null) {
                    if (typeof object.receipt !== "object")
                        throw TypeError(".protocol.TronTransactionInfo.receipt: object expected");
                    message.receipt = $root.protocol.TronResourceReceipt.fromObject(object.receipt);
                }
                if (object.log) {
                    if (!Array.isArray(object.log))
                        throw TypeError(".protocol.TronTransactionInfo.log: array expected");
                    message.log = [];
                    for (var i = 0; i < object.log.length; ++i) {
                        if (typeof object.log[i] !== "object")
                            throw TypeError(".protocol.TronTransactionInfo.log: object expected");
                        message.log[i] = $root.protocol.TronLog.fromObject(object.log[i]);
                    }
                }
                switch (object.result) {
                default:
                    if (typeof object.result === "number") {
                        message.result = object.result;
                        break;
                    }
                    break;
                case "SUCESS":
                case 0:
                    message.result = 0;
                    break;
                case "FAILED":
                case 1:
                    message.result = 1;
                    break;
                }
                if (object.resMessage != null)
                    if (typeof object.resMessage === "string")
                        $util.base64.decode(object.resMessage, message.resMessage = $util.newBuffer($util.base64.length(object.resMessage)), 0);
                    else if (object.resMessage.length >= 0)
                        message.resMessage = object.resMessage;
                if (object.assetIssueId != null)
                    message.assetIssueId = String(object.assetIssueId);
                if (object.withdrawAmount != null)
                    if ($util.Long)
                        (message.withdrawAmount = $util.Long.fromValue(object.withdrawAmount)).unsigned = false;
                    else if (typeof object.withdrawAmount === "string")
                        message.withdrawAmount = parseInt(object.withdrawAmount, 10);
                    else if (typeof object.withdrawAmount === "number")
                        message.withdrawAmount = object.withdrawAmount;
                    else if (typeof object.withdrawAmount === "object")
                        message.withdrawAmount = new $util.LongBits(object.withdrawAmount.low >>> 0, object.withdrawAmount.high >>> 0).toNumber();
                if (object.unfreezeAmount != null)
                    if ($util.Long)
                        (message.unfreezeAmount = $util.Long.fromValue(object.unfreezeAmount)).unsigned = false;
                    else if (typeof object.unfreezeAmount === "string")
                        message.unfreezeAmount = parseInt(object.unfreezeAmount, 10);
                    else if (typeof object.unfreezeAmount === "number")
                        message.unfreezeAmount = object.unfreezeAmount;
                    else if (typeof object.unfreezeAmount === "object")
                        message.unfreezeAmount = new $util.LongBits(object.unfreezeAmount.low >>> 0, object.unfreezeAmount.high >>> 0).toNumber();
                if (object.internalTransactions) {
                    if (!Array.isArray(object.internalTransactions))
                        throw TypeError(".protocol.TronTransactionInfo.internalTransactions: array expected");
                    message.internalTransactions = [];
                    for (var i = 0; i < object.internalTransactions.length; ++i) {
                        if (typeof object.internalTransactions[i] !== "object")
                            throw TypeError(".protocol.TronTransactionInfo.internalTransactions: object expected");
                        message.internalTransactions[i] = $root.protocol.TronInternalTransaction.fromObject(object.internalTransactions[i]);
                    }
                }
                if (object.exchangeReceivedAmount != null)
                    if ($util.Long)
                        (message.exchangeReceivedAmount = $util.Long.fromValue(object.exchangeReceivedAmount)).unsigned = false;
                    else if (typeof object.exchangeReceivedAmount === "string")
                        message.exchangeReceivedAmount = parseInt(object.exchangeReceivedAmount, 10);
                    else if (typeof object.exchangeReceivedAmount === "number")
                        message.exchangeReceivedAmount = object.exchangeReceivedAmount;
                    else if (typeof object.exchangeReceivedAmount === "object")
                        message.exchangeReceivedAmount = new $util.LongBits(object.exchangeReceivedAmount.low >>> 0, object.exchangeReceivedAmount.high >>> 0).toNumber();
                if (object.exchangeInjectAnotherAmount != null)
                    if ($util.Long)
                        (message.exchangeInjectAnotherAmount = $util.Long.fromValue(object.exchangeInjectAnotherAmount)).unsigned = false;
                    else if (typeof object.exchangeInjectAnotherAmount === "string")
                        message.exchangeInjectAnotherAmount = parseInt(object.exchangeInjectAnotherAmount, 10);
                    else if (typeof object.exchangeInjectAnotherAmount === "number")
                        message.exchangeInjectAnotherAmount = object.exchangeInjectAnotherAmount;
                    else if (typeof object.exchangeInjectAnotherAmount === "object")
                        message.exchangeInjectAnotherAmount = new $util.LongBits(object.exchangeInjectAnotherAmount.low >>> 0, object.exchangeInjectAnotherAmount.high >>> 0).toNumber();
                if (object.exchangeWithdrawAnotherAmount != null)
                    if ($util.Long)
                        (message.exchangeWithdrawAnotherAmount = $util.Long.fromValue(object.exchangeWithdrawAnotherAmount)).unsigned = false;
                    else if (typeof object.exchangeWithdrawAnotherAmount === "string")
                        message.exchangeWithdrawAnotherAmount = parseInt(object.exchangeWithdrawAnotherAmount, 10);
                    else if (typeof object.exchangeWithdrawAnotherAmount === "number")
                        message.exchangeWithdrawAnotherAmount = object.exchangeWithdrawAnotherAmount;
                    else if (typeof object.exchangeWithdrawAnotherAmount === "object")
                        message.exchangeWithdrawAnotherAmount = new $util.LongBits(object.exchangeWithdrawAnotherAmount.low >>> 0, object.exchangeWithdrawAnotherAmount.high >>> 0).toNumber();
                if (object.exchangeId != null)
                    if ($util.Long)
                        (message.exchangeId = $util.Long.fromValue(object.exchangeId)).unsigned = false;
                    else if (typeof object.exchangeId === "string")
                        message.exchangeId = parseInt(object.exchangeId, 10);
                    else if (typeof object.exchangeId === "number")
                        message.exchangeId = object.exchangeId;
                    else if (typeof object.exchangeId === "object")
                        message.exchangeId = new $util.LongBits(object.exchangeId.low >>> 0, object.exchangeId.high >>> 0).toNumber();
                if (object.shieldedTransactionFee != null)
                    if ($util.Long)
                        (message.shieldedTransactionFee = $util.Long.fromValue(object.shieldedTransactionFee)).unsigned = false;
                    else if (typeof object.shieldedTransactionFee === "string")
                        message.shieldedTransactionFee = parseInt(object.shieldedTransactionFee, 10);
                    else if (typeof object.shieldedTransactionFee === "number")
                        message.shieldedTransactionFee = object.shieldedTransactionFee;
                    else if (typeof object.shieldedTransactionFee === "object")
                        message.shieldedTransactionFee = new $util.LongBits(object.shieldedTransactionFee.low >>> 0, object.shieldedTransactionFee.high >>> 0).toNumber();
                if (object.orderId != null)
                    if (typeof object.orderId === "string")
                        $util.base64.decode(object.orderId, message.orderId = $util.newBuffer($util.base64.length(object.orderId)), 0);
                    else if (object.orderId.length >= 0)
                        message.orderId = object.orderId;
                if (object.orderDetails) {
                    if (!Array.isArray(object.orderDetails))
                        throw TypeError(".protocol.TronTransactionInfo.orderDetails: array expected");
                    message.orderDetails = [];
                    for (var i = 0; i < object.orderDetails.length; ++i) {
                        if (typeof object.orderDetails[i] !== "object")
                            throw TypeError(".protocol.TronTransactionInfo.orderDetails: object expected");
                        message.orderDetails[i] = $root.protocol.TronMarketOrderDetail.fromObject(object.orderDetails[i]);
                    }
                }
                if (object.packingFee != null)
                    if ($util.Long)
                        (message.packingFee = $util.Long.fromValue(object.packingFee)).unsigned = false;
                    else if (typeof object.packingFee === "string")
                        message.packingFee = parseInt(object.packingFee, 10);
                    else if (typeof object.packingFee === "number")
                        message.packingFee = object.packingFee;
                    else if (typeof object.packingFee === "object")
                        message.packingFee = new $util.LongBits(object.packingFee.low >>> 0, object.packingFee.high >>> 0).toNumber();
                if (object.withdrawExpireAmount != null)
                    if ($util.Long)
                        (message.withdrawExpireAmount = $util.Long.fromValue(object.withdrawExpireAmount)).unsigned = false;
                    else if (typeof object.withdrawExpireAmount === "string")
                        message.withdrawExpireAmount = parseInt(object.withdrawExpireAmount, 10);
                    else if (typeof object.withdrawExpireAmount === "number")
                        message.withdrawExpireAmount = object.withdrawExpireAmount;
                    else if (typeof object.withdrawExpireAmount === "object")
                        message.withdrawExpireAmount = new $util.LongBits(object.withdrawExpireAmount.low >>> 0, object.withdrawExpireAmount.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TronTransactionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {protocol.TronTransactionInfo} message TronTransactionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronTransactionInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.contractResult = [];
                    object.log = [];
                    object.internalTransactions = [];
                    object.orderDetails = [];
                }
                if (options.defaults) {
                    if (options.bytes === String)
                        object.id = "";
                    else {
                        object.id = [];
                        if (options.bytes !== Array)
                            object.id = $util.newBuffer(object.id);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.blockNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockNumber = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.blockTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.blockTimestamp = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.contractAddress = "";
                    else {
                        object.contractAddress = [];
                        if (options.bytes !== Array)
                            object.contractAddress = $util.newBuffer(object.contractAddress);
                    }
                    object.receipt = null;
                    object.result = options.enums === String ? "SUCESS" : 0;
                    if (options.bytes === String)
                        object.resMessage = "";
                    else {
                        object.resMessage = [];
                        if (options.bytes !== Array)
                            object.resMessage = $util.newBuffer(object.resMessage);
                    }
                    object.assetIssueId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.withdrawAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.withdrawAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.unfreezeAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.unfreezeAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeReceivedAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeReceivedAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeInjectAnotherAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeInjectAnotherAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.exchangeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.exchangeId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.shieldedTransactionFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.shieldedTransactionFee = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.orderId = "";
                    else {
                        object.orderId = [];
                        if (options.bytes !== Array)
                            object.orderId = $util.newBuffer(object.orderId);
                    }
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.packingFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.packingFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.withdrawExpireAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.withdrawExpireAmount = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
                if (message.fee != null && message.hasOwnProperty("fee"))
                    if (typeof message.fee === "number")
                        object.fee = options.longs === String ? String(message.fee) : message.fee;
                    else
                        object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber() : message.fee;
                if (message.blockNumber != null && message.hasOwnProperty("blockNumber"))
                    if (typeof message.blockNumber === "number")
                        object.blockNumber = options.longs === String ? String(message.blockNumber) : message.blockNumber;
                    else
                        object.blockNumber = options.longs === String ? $util.Long.prototype.toString.call(message.blockNumber) : options.longs === Number ? new $util.LongBits(message.blockNumber.low >>> 0, message.blockNumber.high >>> 0).toNumber() : message.blockNumber;
                if (message.blockTimestamp != null && message.hasOwnProperty("blockTimestamp"))
                    if (typeof message.blockTimestamp === "number")
                        object.blockTimestamp = options.longs === String ? String(message.blockTimestamp) : message.blockTimestamp;
                    else
                        object.blockTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.blockTimestamp) : options.longs === Number ? new $util.LongBits(message.blockTimestamp.low >>> 0, message.blockTimestamp.high >>> 0).toNumber() : message.blockTimestamp;
                if (message.contractResult && message.contractResult.length) {
                    object.contractResult = [];
                    for (var j = 0; j < message.contractResult.length; ++j)
                        object.contractResult[j] = options.bytes === String ? $util.base64.encode(message.contractResult[j], 0, message.contractResult[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.contractResult[j]) : message.contractResult[j];
                }
                if (message.contractAddress != null && message.hasOwnProperty("contractAddress"))
                    object.contractAddress = options.bytes === String ? $util.base64.encode(message.contractAddress, 0, message.contractAddress.length) : options.bytes === Array ? Array.prototype.slice.call(message.contractAddress) : message.contractAddress;
                if (message.receipt != null && message.hasOwnProperty("receipt"))
                    object.receipt = $root.protocol.TronResourceReceipt.toObject(message.receipt, options);
                if (message.log && message.log.length) {
                    object.log = [];
                    for (var j = 0; j < message.log.length; ++j)
                        object.log[j] = $root.protocol.TronLog.toObject(message.log[j], options);
                }
                if (message.result != null && message.hasOwnProperty("result"))
                    object.result = options.enums === String ? $root.protocol.TronTransactionInfo.Code[message.result] === undefined ? message.result : $root.protocol.TronTransactionInfo.Code[message.result] : message.result;
                if (message.resMessage != null && message.hasOwnProperty("resMessage"))
                    object.resMessage = options.bytes === String ? $util.base64.encode(message.resMessage, 0, message.resMessage.length) : options.bytes === Array ? Array.prototype.slice.call(message.resMessage) : message.resMessage;
                if (message.assetIssueId != null && message.hasOwnProperty("assetIssueId"))
                    object.assetIssueId = message.assetIssueId;
                if (message.withdrawAmount != null && message.hasOwnProperty("withdrawAmount"))
                    if (typeof message.withdrawAmount === "number")
                        object.withdrawAmount = options.longs === String ? String(message.withdrawAmount) : message.withdrawAmount;
                    else
                        object.withdrawAmount = options.longs === String ? $util.Long.prototype.toString.call(message.withdrawAmount) : options.longs === Number ? new $util.LongBits(message.withdrawAmount.low >>> 0, message.withdrawAmount.high >>> 0).toNumber() : message.withdrawAmount;
                if (message.unfreezeAmount != null && message.hasOwnProperty("unfreezeAmount"))
                    if (typeof message.unfreezeAmount === "number")
                        object.unfreezeAmount = options.longs === String ? String(message.unfreezeAmount) : message.unfreezeAmount;
                    else
                        object.unfreezeAmount = options.longs === String ? $util.Long.prototype.toString.call(message.unfreezeAmount) : options.longs === Number ? new $util.LongBits(message.unfreezeAmount.low >>> 0, message.unfreezeAmount.high >>> 0).toNumber() : message.unfreezeAmount;
                if (message.internalTransactions && message.internalTransactions.length) {
                    object.internalTransactions = [];
                    for (var j = 0; j < message.internalTransactions.length; ++j)
                        object.internalTransactions[j] = $root.protocol.TronInternalTransaction.toObject(message.internalTransactions[j], options);
                }
                if (message.exchangeReceivedAmount != null && message.hasOwnProperty("exchangeReceivedAmount"))
                    if (typeof message.exchangeReceivedAmount === "number")
                        object.exchangeReceivedAmount = options.longs === String ? String(message.exchangeReceivedAmount) : message.exchangeReceivedAmount;
                    else
                        object.exchangeReceivedAmount = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeReceivedAmount) : options.longs === Number ? new $util.LongBits(message.exchangeReceivedAmount.low >>> 0, message.exchangeReceivedAmount.high >>> 0).toNumber() : message.exchangeReceivedAmount;
                if (message.exchangeInjectAnotherAmount != null && message.hasOwnProperty("exchangeInjectAnotherAmount"))
                    if (typeof message.exchangeInjectAnotherAmount === "number")
                        object.exchangeInjectAnotherAmount = options.longs === String ? String(message.exchangeInjectAnotherAmount) : message.exchangeInjectAnotherAmount;
                    else
                        object.exchangeInjectAnotherAmount = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeInjectAnotherAmount) : options.longs === Number ? new $util.LongBits(message.exchangeInjectAnotherAmount.low >>> 0, message.exchangeInjectAnotherAmount.high >>> 0).toNumber() : message.exchangeInjectAnotherAmount;
                if (message.exchangeWithdrawAnotherAmount != null && message.hasOwnProperty("exchangeWithdrawAnotherAmount"))
                    if (typeof message.exchangeWithdrawAnotherAmount === "number")
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? String(message.exchangeWithdrawAnotherAmount) : message.exchangeWithdrawAnotherAmount;
                    else
                        object.exchangeWithdrawAnotherAmount = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeWithdrawAnotherAmount) : options.longs === Number ? new $util.LongBits(message.exchangeWithdrawAnotherAmount.low >>> 0, message.exchangeWithdrawAnotherAmount.high >>> 0).toNumber() : message.exchangeWithdrawAnotherAmount;
                if (message.exchangeId != null && message.hasOwnProperty("exchangeId"))
                    if (typeof message.exchangeId === "number")
                        object.exchangeId = options.longs === String ? String(message.exchangeId) : message.exchangeId;
                    else
                        object.exchangeId = options.longs === String ? $util.Long.prototype.toString.call(message.exchangeId) : options.longs === Number ? new $util.LongBits(message.exchangeId.low >>> 0, message.exchangeId.high >>> 0).toNumber() : message.exchangeId;
                if (message.shieldedTransactionFee != null && message.hasOwnProperty("shieldedTransactionFee"))
                    if (typeof message.shieldedTransactionFee === "number")
                        object.shieldedTransactionFee = options.longs === String ? String(message.shieldedTransactionFee) : message.shieldedTransactionFee;
                    else
                        object.shieldedTransactionFee = options.longs === String ? $util.Long.prototype.toString.call(message.shieldedTransactionFee) : options.longs === Number ? new $util.LongBits(message.shieldedTransactionFee.low >>> 0, message.shieldedTransactionFee.high >>> 0).toNumber() : message.shieldedTransactionFee;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    object.orderId = options.bytes === String ? $util.base64.encode(message.orderId, 0, message.orderId.length) : options.bytes === Array ? Array.prototype.slice.call(message.orderId) : message.orderId;
                if (message.orderDetails && message.orderDetails.length) {
                    object.orderDetails = [];
                    for (var j = 0; j < message.orderDetails.length; ++j)
                        object.orderDetails[j] = $root.protocol.TronMarketOrderDetail.toObject(message.orderDetails[j], options);
                }
                if (message.packingFee != null && message.hasOwnProperty("packingFee"))
                    if (typeof message.packingFee === "number")
                        object.packingFee = options.longs === String ? String(message.packingFee) : message.packingFee;
                    else
                        object.packingFee = options.longs === String ? $util.Long.prototype.toString.call(message.packingFee) : options.longs === Number ? new $util.LongBits(message.packingFee.low >>> 0, message.packingFee.high >>> 0).toNumber() : message.packingFee;
                if (message.withdrawExpireAmount != null && message.hasOwnProperty("withdrawExpireAmount"))
                    if (typeof message.withdrawExpireAmount === "number")
                        object.withdrawExpireAmount = options.longs === String ? String(message.withdrawExpireAmount) : message.withdrawExpireAmount;
                    else
                        object.withdrawExpireAmount = options.longs === String ? $util.Long.prototype.toString.call(message.withdrawExpireAmount) : options.longs === Number ? new $util.LongBits(message.withdrawExpireAmount.low >>> 0, message.withdrawExpireAmount.high >>> 0).toNumber() : message.withdrawExpireAmount;
                return object;
            };
    
            /**
             * Converts this TronTransactionInfo to JSON.
             * @function toJSON
             * @memberof protocol.TronTransactionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronTransactionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronTransactionInfo
             * @function getTypeUrl
             * @memberof protocol.TronTransactionInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronTransactionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronTransactionInfo";
            };
    
            /**
             * Code enum.
             * @name protocol.TronTransactionInfo.Code
             * @enum {number}
             * @property {number} SUCESS=0 SUCESS value
             * @property {number} FAILED=1 FAILED value
             */
            TronTransactionInfo.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCESS"] = 0;
                values[valuesById[1] = "FAILED"] = 1;
                return values;
            })();
    
            return TronTransactionInfo;
        })();
    
        protocol.TronResourceReceipt = (function() {
    
            /**
             * Properties of a TronResourceReceipt.
             * @memberof protocol
             * @interface ITronResourceReceipt
             * @property {Long|null} [energyUsage] TronResourceReceipt energyUsage
             * @property {Long|null} [energyFee] TronResourceReceipt energyFee
             * @property {Long|null} [originEnergyUsage] TronResourceReceipt originEnergyUsage
             * @property {Long|null} [energyUsageTotal] TronResourceReceipt energyUsageTotal
             * @property {Long|null} [netUsage] TronResourceReceipt netUsage
             * @property {Long|null} [netFee] TronResourceReceipt netFee
             * @property {protocol.TronTransactionResult.ContractResult|null} [result] TronResourceReceipt result
             * @property {Long|null} [energyPenaltyTotal] TronResourceReceipt energyPenaltyTotal
             */
    
            /**
             * Constructs a new TronResourceReceipt.
             * @memberof protocol
             * @classdesc Represents a TronResourceReceipt.
             * @implements ITronResourceReceipt
             * @constructor
             * @param {protocol.ITronResourceReceipt=} [properties] Properties to set
             */
            function TronResourceReceipt(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TronResourceReceipt energyUsage.
             * @member {Long} energyUsage
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.energyUsage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronResourceReceipt energyFee.
             * @member {Long} energyFee
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.energyFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronResourceReceipt originEnergyUsage.
             * @member {Long} originEnergyUsage
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.originEnergyUsage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronResourceReceipt energyUsageTotal.
             * @member {Long} energyUsageTotal
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.energyUsageTotal = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronResourceReceipt netUsage.
             * @member {Long} netUsage
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.netUsage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronResourceReceipt netFee.
             * @member {Long} netFee
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.netFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * TronResourceReceipt result.
             * @member {protocol.TronTransactionResult.ContractResult} result
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.result = 0;
    
            /**
             * TronResourceReceipt energyPenaltyTotal.
             * @member {Long} energyPenaltyTotal
             * @memberof protocol.TronResourceReceipt
             * @instance
             */
            TronResourceReceipt.prototype.energyPenaltyTotal = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TronResourceReceipt instance using the specified properties.
             * @function create
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {protocol.ITronResourceReceipt=} [properties] Properties to set
             * @returns {protocol.TronResourceReceipt} TronResourceReceipt instance
             */
            TronResourceReceipt.create = function create(properties) {
                return new TronResourceReceipt(properties);
            };
    
            /**
             * Encodes the specified TronResourceReceipt message. Does not implicitly {@link protocol.TronResourceReceipt.verify|verify} messages.
             * @function encode
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {protocol.ITronResourceReceipt} message TronResourceReceipt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronResourceReceipt.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.energyUsage != null && Object.hasOwnProperty.call(message, "energyUsage"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.energyUsage);
                if (message.energyFee != null && Object.hasOwnProperty.call(message, "energyFee"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.energyFee);
                if (message.originEnergyUsage != null && Object.hasOwnProperty.call(message, "originEnergyUsage"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.originEnergyUsage);
                if (message.energyUsageTotal != null && Object.hasOwnProperty.call(message, "energyUsageTotal"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.energyUsageTotal);
                if (message.netUsage != null && Object.hasOwnProperty.call(message, "netUsage"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.netUsage);
                if (message.netFee != null && Object.hasOwnProperty.call(message, "netFee"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.netFee);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.result);
                if (message.energyPenaltyTotal != null && Object.hasOwnProperty.call(message, "energyPenaltyTotal"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.energyPenaltyTotal);
                return writer;
            };
    
            /**
             * Encodes the specified TronResourceReceipt message, length delimited. Does not implicitly {@link protocol.TronResourceReceipt.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {protocol.ITronResourceReceipt} message TronResourceReceipt message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TronResourceReceipt.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TronResourceReceipt message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TronResourceReceipt} TronResourceReceipt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronResourceReceipt.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TronResourceReceipt();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.energyUsage = reader.int64();
                            break;
                        }
                    case 2: {
                            message.energyFee = reader.int64();
                            break;
                        }
                    case 3: {
                            message.originEnergyUsage = reader.int64();
                            break;
                        }
                    case 4: {
                            message.energyUsageTotal = reader.int64();
                            break;
                        }
                    case 5: {
                            message.netUsage = reader.int64();
                            break;
                        }
                    case 6: {
                            message.netFee = reader.int64();
                            break;
                        }
                    case 7: {
                            message.result = reader.int32();
                            break;
                        }
                    case 8: {
                            message.energyPenaltyTotal = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TronResourceReceipt message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TronResourceReceipt} TronResourceReceipt
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TronResourceReceipt.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TronResourceReceipt message.
             * @function verify
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TronResourceReceipt.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.energyUsage != null && message.hasOwnProperty("energyUsage"))
                    if (!$util.isInteger(message.energyUsage) && !(message.energyUsage && $util.isInteger(message.energyUsage.low) && $util.isInteger(message.energyUsage.high)))
                        return "energyUsage: integer|Long expected";
                if (message.energyFee != null && message.hasOwnProperty("energyFee"))
                    if (!$util.isInteger(message.energyFee) && !(message.energyFee && $util.isInteger(message.energyFee.low) && $util.isInteger(message.energyFee.high)))
                        return "energyFee: integer|Long expected";
                if (message.originEnergyUsage != null && message.hasOwnProperty("originEnergyUsage"))
                    if (!$util.isInteger(message.originEnergyUsage) && !(message.originEnergyUsage && $util.isInteger(message.originEnergyUsage.low) && $util.isInteger(message.originEnergyUsage.high)))
                        return "originEnergyUsage: integer|Long expected";
                if (message.energyUsageTotal != null && message.hasOwnProperty("energyUsageTotal"))
                    if (!$util.isInteger(message.energyUsageTotal) && !(message.energyUsageTotal && $util.isInteger(message.energyUsageTotal.low) && $util.isInteger(message.energyUsageTotal.high)))
                        return "energyUsageTotal: integer|Long expected";
                if (message.netUsage != null && message.hasOwnProperty("netUsage"))
                    if (!$util.isInteger(message.netUsage) && !(message.netUsage && $util.isInteger(message.netUsage.low) && $util.isInteger(message.netUsage.high)))
                        return "netUsage: integer|Long expected";
                if (message.netFee != null && message.hasOwnProperty("netFee"))
                    if (!$util.isInteger(message.netFee) && !(message.netFee && $util.isInteger(message.netFee.low) && $util.isInteger(message.netFee.high)))
                        return "netFee: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result"))
                    switch (message.result) {
                    default:
                        return "result: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                        break;
                    }
                if (message.energyPenaltyTotal != null && message.hasOwnProperty("energyPenaltyTotal"))
                    if (!$util.isInteger(message.energyPenaltyTotal) && !(message.energyPenaltyTotal && $util.isInteger(message.energyPenaltyTotal.low) && $util.isInteger(message.energyPenaltyTotal.high)))
                        return "energyPenaltyTotal: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TronResourceReceipt message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TronResourceReceipt} TronResourceReceipt
             */
            TronResourceReceipt.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TronResourceReceipt)
                    return object;
                var message = new $root.protocol.TronResourceReceipt();
                if (object.energyUsage != null)
                    if ($util.Long)
                        (message.energyUsage = $util.Long.fromValue(object.energyUsage)).unsigned = false;
                    else if (typeof object.energyUsage === "string")
                        message.energyUsage = parseInt(object.energyUsage, 10);
                    else if (typeof object.energyUsage === "number")
                        message.energyUsage = object.energyUsage;
                    else if (typeof object.energyUsage === "object")
                        message.energyUsage = new $util.LongBits(object.energyUsage.low >>> 0, object.energyUsage.high >>> 0).toNumber();
                if (object.energyFee != null)
                    if ($util.Long)
                        (message.energyFee = $util.Long.fromValue(object.energyFee)).unsigned = false;
                    else if (typeof object.energyFee === "string")
                        message.energyFee = parseInt(object.energyFee, 10);
                    else if (typeof object.energyFee === "number")
                        message.energyFee = object.energyFee;
                    else if (typeof object.energyFee === "object")
                        message.energyFee = new $util.LongBits(object.energyFee.low >>> 0, object.energyFee.high >>> 0).toNumber();
                if (object.originEnergyUsage != null)
                    if ($util.Long)
                        (message.originEnergyUsage = $util.Long.fromValue(object.originEnergyUsage)).unsigned = false;
                    else if (typeof object.originEnergyUsage === "string")
                        message.originEnergyUsage = parseInt(object.originEnergyUsage, 10);
                    else if (typeof object.originEnergyUsage === "number")
                        message.originEnergyUsage = object.originEnergyUsage;
                    else if (typeof object.originEnergyUsage === "object")
                        message.originEnergyUsage = new $util.LongBits(object.originEnergyUsage.low >>> 0, object.originEnergyUsage.high >>> 0).toNumber();
                if (object.energyUsageTotal != null)
                    if ($util.Long)
                        (message.energyUsageTotal = $util.Long.fromValue(object.energyUsageTotal)).unsigned = false;
                    else if (typeof object.energyUsageTotal === "string")
                        message.energyUsageTotal = parseInt(object.energyUsageTotal, 10);
                    else if (typeof object.energyUsageTotal === "number")
                        message.energyUsageTotal = object.energyUsageTotal;
                    else if (typeof object.energyUsageTotal === "object")
                        message.energyUsageTotal = new $util.LongBits(object.energyUsageTotal.low >>> 0, object.energyUsageTotal.high >>> 0).toNumber();
                if (object.netUsage != null)
                    if ($util.Long)
                        (message.netUsage = $util.Long.fromValue(object.netUsage)).unsigned = false;
                    else if (typeof object.netUsage === "string")
                        message.netUsage = parseInt(object.netUsage, 10);
                    else if (typeof object.netUsage === "number")
                        message.netUsage = object.netUsage;
                    else if (typeof object.netUsage === "object")
                        message.netUsage = new $util.LongBits(object.netUsage.low >>> 0, object.netUsage.high >>> 0).toNumber();
                if (object.netFee != null)
                    if ($util.Long)
                        (message.netFee = $util.Long.fromValue(object.netFee)).unsigned = false;
                    else if (typeof object.netFee === "string")
                        message.netFee = parseInt(object.netFee, 10);
                    else if (typeof object.netFee === "number")
                        message.netFee = object.netFee;
                    else if (typeof object.netFee === "object")
                        message.netFee = new $util.LongBits(object.netFee.low >>> 0, object.netFee.high >>> 0).toNumber();
                switch (object.result) {
                default:
                    if (typeof object.result === "number") {
                        message.result = object.result;
                        break;
                    }
                    break;
                case "DEFAULT":
                case 0:
                    message.result = 0;
                    break;
                case "SUCCESS":
                case 1:
                    message.result = 1;
                    break;
                case "REVERT":
                case 2:
                    message.result = 2;
                    break;
                case "BAD_JUMP_DESTINATION":
                case 3:
                    message.result = 3;
                    break;
                case "OUT_OF_MEMORY":
                case 4:
                    message.result = 4;
                    break;
                case "PRECOMPILED_CONTRACT":
                case 5:
                    message.result = 5;
                    break;
                case "STACK_TOO_SMALL":
                case 6:
                    message.result = 6;
                    break;
                case "STACK_TOO_LARGE":
                case 7:
                    message.result = 7;
                    break;
                case "ILLEGAL_OPERATION":
                case 8:
                    message.result = 8;
                    break;
                case "STACK_OVERFLOW":
                case 9:
                    message.result = 9;
                    break;
                case "OUT_OF_ENERGY":
                case 10:
                    message.result = 10;
                    break;
                case "OUT_OF_TIME":
                case 11:
                    message.result = 11;
                    break;
                case "JVM_STACK_OVER_FLOW":
                case 12:
                    message.result = 12;
                    break;
                case "UNKNOWN":
                case 13:
                    message.result = 13;
                    break;
                case "TRANSFER_FAILED":
                case 14:
                    message.result = 14;
                    break;
                case "INVALID_CODE":
                case 15:
                    message.result = 15;
                    break;
                }
                if (object.energyPenaltyTotal != null)
                    if ($util.Long)
                        (message.energyPenaltyTotal = $util.Long.fromValue(object.energyPenaltyTotal)).unsigned = false;
                    else if (typeof object.energyPenaltyTotal === "string")
                        message.energyPenaltyTotal = parseInt(object.energyPenaltyTotal, 10);
                    else if (typeof object.energyPenaltyTotal === "number")
                        message.energyPenaltyTotal = object.energyPenaltyTotal;
                    else if (typeof object.energyPenaltyTotal === "object")
                        message.energyPenaltyTotal = new $util.LongBits(object.energyPenaltyTotal.low >>> 0, object.energyPenaltyTotal.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TronResourceReceipt message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {protocol.TronResourceReceipt} message TronResourceReceipt
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TronResourceReceipt.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.energyUsage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.energyUsage = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.energyFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.energyFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.originEnergyUsage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.originEnergyUsage = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.energyUsageTotal = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.energyUsageTotal = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.netUsage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.netUsage = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.netFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.netFee = options.longs === String ? "0" : 0;
                    object.result = options.enums === String ? "DEFAULT" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.energyPenaltyTotal = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.energyPenaltyTotal = options.longs === String ? "0" : 0;
                }
                if (message.energyUsage != null && message.hasOwnProperty("energyUsage"))
                    if (typeof message.energyUsage === "number")
                        object.energyUsage = options.longs === String ? String(message.energyUsage) : message.energyUsage;
                    else
                        object.energyUsage = options.longs === String ? $util.Long.prototype.toString.call(message.energyUsage) : options.longs === Number ? new $util.LongBits(message.energyUsage.low >>> 0, message.energyUsage.high >>> 0).toNumber() : message.energyUsage;
                if (message.energyFee != null && message.hasOwnProperty("energyFee"))
                    if (typeof message.energyFee === "number")
                        object.energyFee = options.longs === String ? String(message.energyFee) : message.energyFee;
                    else
                        object.energyFee = options.longs === String ? $util.Long.prototype.toString.call(message.energyFee) : options.longs === Number ? new $util.LongBits(message.energyFee.low >>> 0, message.energyFee.high >>> 0).toNumber() : message.energyFee;
                if (message.originEnergyUsage != null && message.hasOwnProperty("originEnergyUsage"))
                    if (typeof message.originEnergyUsage === "number")
                        object.originEnergyUsage = options.longs === String ? String(message.originEnergyUsage) : message.originEnergyUsage;
                    else
                        object.originEnergyUsage = options.longs === String ? $util.Long.prototype.toString.call(message.originEnergyUsage) : options.longs === Number ? new $util.LongBits(message.originEnergyUsage.low >>> 0, message.originEnergyUsage.high >>> 0).toNumber() : message.originEnergyUsage;
                if (message.energyUsageTotal != null && message.hasOwnProperty("energyUsageTotal"))
                    if (typeof message.energyUsageTotal === "number")
                        object.energyUsageTotal = options.longs === String ? String(message.energyUsageTotal) : message.energyUsageTotal;
                    else
                        object.energyUsageTotal = options.longs === String ? $util.Long.prototype.toString.call(message.energyUsageTotal) : options.longs === Number ? new $util.LongBits(message.energyUsageTotal.low >>> 0, message.energyUsageTotal.high >>> 0).toNumber() : message.energyUsageTotal;
                if (message.netUsage != null && message.hasOwnProperty("netUsage"))
                    if (typeof message.netUsage === "number")
                        object.netUsage = options.longs === String ? String(message.netUsage) : message.netUsage;
                    else
                        object.netUsage = options.longs === String ? $util.Long.prototype.toString.call(message.netUsage) : options.longs === Number ? new $util.LongBits(message.netUsage.low >>> 0, message.netUsage.high >>> 0).toNumber() : message.netUsage;
                if (message.netFee != null && message.hasOwnProperty("netFee"))
                    if (typeof message.netFee === "number")
                        object.netFee = options.longs === String ? String(message.netFee) : message.netFee;
                    else
                        object.netFee = options.longs === String ? $util.Long.prototype.toString.call(message.netFee) : options.longs === Number ? new $util.LongBits(message.netFee.low >>> 0, message.netFee.high >>> 0).toNumber() : message.netFee;
                if (message.result != null && message.hasOwnProperty("result"))
                    object.result = options.enums === String ? $root.protocol.TronTransactionResult.ContractResult[message.result] === undefined ? message.result : $root.protocol.TronTransactionResult.ContractResult[message.result] : message.result;
                if (message.energyPenaltyTotal != null && message.hasOwnProperty("energyPenaltyTotal"))
                    if (typeof message.energyPenaltyTotal === "number")
                        object.energyPenaltyTotal = options.longs === String ? String(message.energyPenaltyTotal) : message.energyPenaltyTotal;
                    else
                        object.energyPenaltyTotal = options.longs === String ? $util.Long.prototype.toString.call(message.energyPenaltyTotal) : options.longs === Number ? new $util.LongBits(message.energyPenaltyTotal.low >>> 0, message.energyPenaltyTotal.high >>> 0).toNumber() : message.energyPenaltyTotal;
                return object;
            };
    
            /**
             * Converts this TronResourceReceipt to JSON.
             * @function toJSON
             * @memberof protocol.TronResourceReceipt
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TronResourceReceipt.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TronResourceReceipt
             * @function getTypeUrl
             * @memberof protocol.TronResourceReceipt
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TronResourceReceipt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TronResourceReceipt";
            };
    
            return TronResourceReceipt;
        })();
    
        protocol.Any = (function() {
    
            /**
             * Properties of an Any.
             * @memberof protocol
             * @interface IAny
             * @property {string|null} [typeUrl] Any typeUrl
             * @property {Uint8Array|null} [value] Any value
             */
    
            /**
             * Constructs a new Any.
             * @memberof protocol
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {protocol.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Any typeUrl.
             * @member {string} typeUrl
             * @memberof protocol.Any
             * @instance
             */
            Any.prototype.typeUrl = "";
    
            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof protocol.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);
    
            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof protocol.Any
             * @static
             * @param {protocol.IAny=} [properties] Properties to set
             * @returns {protocol.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };
    
            /**
             * Encodes the specified Any message. Does not implicitly {@link protocol.Any.verify|verify} messages.
             * @function encode
             * @memberof protocol.Any
             * @static
             * @param {protocol.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.typeUrl != null && Object.hasOwnProperty.call(message, "typeUrl"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.typeUrl);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };
    
            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link protocol.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.Any
             * @static
             * @param {protocol.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.Any();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.typeUrl = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Any message.
             * @function verify
             * @memberof protocol.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.typeUrl != null && message.hasOwnProperty("typeUrl"))
                    if (!$util.isString(message.typeUrl))
                        return "typeUrl: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };
    
            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.Any)
                    return object;
                var message = new $root.protocol.Any();
                if (object.typeUrl != null)
                    message.typeUrl = String(object.typeUrl);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                return message;
            };
    
            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.Any
             * @static
             * @param {protocol.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.typeUrl = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.typeUrl != null && message.hasOwnProperty("typeUrl"))
                    object.typeUrl = message.typeUrl;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };
    
            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof protocol.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Any
             * @function getTypeUrl
             * @memberof protocol.Any
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Any.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.Any";
            };
    
            return Any;
        })();
    
        /**
         * EventType enum.
         * @name protocol.EventType
         * @enum {number}
         * @property {number} UnknownEvent=0 UnknownEvent value
         * @property {number} TransferEvent=1 TransferEvent value
         */
        protocol.EventType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownEvent"] = 0;
            values[valuesById[1] = "TransferEvent"] = 1;
            return values;
        })();
    
        protocol.TransferEventData = (function() {
    
            /**
             * Properties of a TransferEventData.
             * @memberof protocol
             * @interface ITransferEventData
             * @property {Uint8Array|null} [from] TransferEventData from
             * @property {Uint8Array|null} [to] TransferEventData to
             * @property {Uint8Array|null} [value] TransferEventData value
             * @property {string|null} [tokenName] TransferEventData tokenName
             * @property {string|null} [tokenSymbol] TransferEventData tokenSymbol
             * @property {Long|null} [tokenDecimals] TransferEventData tokenDecimals
             */
    
            /**
             * Constructs a new TransferEventData.
             * @memberof protocol
             * @classdesc Represents a TransferEventData.
             * @implements ITransferEventData
             * @constructor
             * @param {protocol.ITransferEventData=} [properties] Properties to set
             */
            function TransferEventData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * TransferEventData from.
             * @member {Uint8Array} from
             * @memberof protocol.TransferEventData
             * @instance
             */
            TransferEventData.prototype.from = $util.newBuffer([]);
    
            /**
             * TransferEventData to.
             * @member {Uint8Array} to
             * @memberof protocol.TransferEventData
             * @instance
             */
            TransferEventData.prototype.to = $util.newBuffer([]);
    
            /**
             * TransferEventData value.
             * @member {Uint8Array} value
             * @memberof protocol.TransferEventData
             * @instance
             */
            TransferEventData.prototype.value = $util.newBuffer([]);
    
            /**
             * TransferEventData tokenName.
             * @member {string} tokenName
             * @memberof protocol.TransferEventData
             * @instance
             */
            TransferEventData.prototype.tokenName = "";
    
            /**
             * TransferEventData tokenSymbol.
             * @member {string} tokenSymbol
             * @memberof protocol.TransferEventData
             * @instance
             */
            TransferEventData.prototype.tokenSymbol = "";
    
            /**
             * TransferEventData tokenDecimals.
             * @member {Long} tokenDecimals
             * @memberof protocol.TransferEventData
             * @instance
             */
            TransferEventData.prototype.tokenDecimals = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * Creates a new TransferEventData instance using the specified properties.
             * @function create
             * @memberof protocol.TransferEventData
             * @static
             * @param {protocol.ITransferEventData=} [properties] Properties to set
             * @returns {protocol.TransferEventData} TransferEventData instance
             */
            TransferEventData.create = function create(properties) {
                return new TransferEventData(properties);
            };
    
            /**
             * Encodes the specified TransferEventData message. Does not implicitly {@link protocol.TransferEventData.verify|verify} messages.
             * @function encode
             * @memberof protocol.TransferEventData
             * @static
             * @param {protocol.ITransferEventData} message TransferEventData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferEventData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.to);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.value);
                if (message.tokenName != null && Object.hasOwnProperty.call(message, "tokenName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.tokenName);
                if (message.tokenSymbol != null && Object.hasOwnProperty.call(message, "tokenSymbol"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.tokenSymbol);
                if (message.tokenDecimals != null && Object.hasOwnProperty.call(message, "tokenDecimals"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.tokenDecimals);
                return writer;
            };
    
            /**
             * Encodes the specified TransferEventData message, length delimited. Does not implicitly {@link protocol.TransferEventData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protocol.TransferEventData
             * @static
             * @param {protocol.ITransferEventData} message TransferEventData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransferEventData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a TransferEventData message from the specified reader or buffer.
             * @function decode
             * @memberof protocol.TransferEventData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protocol.TransferEventData} TransferEventData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferEventData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.protocol.TransferEventData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.to = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.value = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.tokenName = reader.string();
                            break;
                        }
                    case 5: {
                            message.tokenSymbol = reader.string();
                            break;
                        }
                    case 6: {
                            message.tokenDecimals = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a TransferEventData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protocol.TransferEventData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protocol.TransferEventData} TransferEventData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransferEventData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a TransferEventData message.
             * @function verify
             * @memberof protocol.TransferEventData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransferEventData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!(message.from && typeof message.from.length === "number" || $util.isString(message.from)))
                        return "from: buffer expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!(message.to && typeof message.to.length === "number" || $util.isString(message.to)))
                        return "to: buffer expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                if (message.tokenName != null && message.hasOwnProperty("tokenName"))
                    if (!$util.isString(message.tokenName))
                        return "tokenName: string expected";
                if (message.tokenSymbol != null && message.hasOwnProperty("tokenSymbol"))
                    if (!$util.isString(message.tokenSymbol))
                        return "tokenSymbol: string expected";
                if (message.tokenDecimals != null && message.hasOwnProperty("tokenDecimals"))
                    if (!$util.isInteger(message.tokenDecimals) && !(message.tokenDecimals && $util.isInteger(message.tokenDecimals.low) && $util.isInteger(message.tokenDecimals.high)))
                        return "tokenDecimals: integer|Long expected";
                return null;
            };
    
            /**
             * Creates a TransferEventData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protocol.TransferEventData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protocol.TransferEventData} TransferEventData
             */
            TransferEventData.fromObject = function fromObject(object) {
                if (object instanceof $root.protocol.TransferEventData)
                    return object;
                var message = new $root.protocol.TransferEventData();
                if (object.from != null)
                    if (typeof object.from === "string")
                        $util.base64.decode(object.from, message.from = $util.newBuffer($util.base64.length(object.from)), 0);
                    else if (object.from.length >= 0)
                        message.from = object.from;
                if (object.to != null)
                    if (typeof object.to === "string")
                        $util.base64.decode(object.to, message.to = $util.newBuffer($util.base64.length(object.to)), 0);
                    else if (object.to.length >= 0)
                        message.to = object.to;
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                if (object.tokenName != null)
                    message.tokenName = String(object.tokenName);
                if (object.tokenSymbol != null)
                    message.tokenSymbol = String(object.tokenSymbol);
                if (object.tokenDecimals != null)
                    if ($util.Long)
                        (message.tokenDecimals = $util.Long.fromValue(object.tokenDecimals)).unsigned = false;
                    else if (typeof object.tokenDecimals === "string")
                        message.tokenDecimals = parseInt(object.tokenDecimals, 10);
                    else if (typeof object.tokenDecimals === "number")
                        message.tokenDecimals = object.tokenDecimals;
                    else if (typeof object.tokenDecimals === "object")
                        message.tokenDecimals = new $util.LongBits(object.tokenDecimals.low >>> 0, object.tokenDecimals.high >>> 0).toNumber();
                return message;
            };
    
            /**
             * Creates a plain object from a TransferEventData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protocol.TransferEventData
             * @static
             * @param {protocol.TransferEventData} message TransferEventData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransferEventData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.from = "";
                    else {
                        object.from = [];
                        if (options.bytes !== Array)
                            object.from = $util.newBuffer(object.from);
                    }
                    if (options.bytes === String)
                        object.to = "";
                    else {
                        object.to = [];
                        if (options.bytes !== Array)
                            object.to = $util.newBuffer(object.to);
                    }
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                    object.tokenName = "";
                    object.tokenSymbol = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.tokenDecimals = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tokenDecimals = options.longs === String ? "0" : 0;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = options.bytes === String ? $util.base64.encode(message.from, 0, message.from.length) : options.bytes === Array ? Array.prototype.slice.call(message.from) : message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = options.bytes === String ? $util.base64.encode(message.to, 0, message.to.length) : options.bytes === Array ? Array.prototype.slice.call(message.to) : message.to;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                if (message.tokenName != null && message.hasOwnProperty("tokenName"))
                    object.tokenName = message.tokenName;
                if (message.tokenSymbol != null && message.hasOwnProperty("tokenSymbol"))
                    object.tokenSymbol = message.tokenSymbol;
                if (message.tokenDecimals != null && message.hasOwnProperty("tokenDecimals"))
                    if (typeof message.tokenDecimals === "number")
                        object.tokenDecimals = options.longs === String ? String(message.tokenDecimals) : message.tokenDecimals;
                    else
                        object.tokenDecimals = options.longs === String ? $util.Long.prototype.toString.call(message.tokenDecimals) : options.longs === Number ? new $util.LongBits(message.tokenDecimals.low >>> 0, message.tokenDecimals.high >>> 0).toNumber() : message.tokenDecimals;
                return object;
            };
    
            /**
             * Converts this TransferEventData to JSON.
             * @function toJSON
             * @memberof protocol.TransferEventData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransferEventData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for TransferEventData
             * @function getTypeUrl
             * @memberof protocol.TransferEventData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TransferEventData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protocol.TransferEventData";
            };
    
            return TransferEventData;
        })();
    
        return protocol;
    })();
    
    $root.google = (function() {
    
        /**
         * Namespace google.
         * @exports google
         * @namespace
         */
        var google = {};
    
        google.protobuf = (function() {
    
            /**
             * Namespace protobuf.
             * @memberof google
             * @namespace
             */
            var protobuf = {};
    
            protobuf.FileDescriptorSet = (function() {
    
                /**
                 * Properties of a FileDescriptorSet.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorSet
                 * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
                 */
    
                /**
                 * Constructs a new FileDescriptorSet.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorSet.
                 * @implements IFileDescriptorSet
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 */
                function FileDescriptorSet(properties) {
                    this.file = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorSet file.
                 * @member {Array.<google.protobuf.IFileDescriptorProto>} file
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 */
                FileDescriptorSet.prototype.file = $util.emptyArray;
    
                /**
                 * Creates a new FileDescriptorSet instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
                 */
                FileDescriptorSet.create = function create(properties) {
                    return new FileDescriptorSet(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.file != null && message.file.length)
                        for (var i = 0; i < message.file.length; ++i)
                            $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.file && message.file.length))
                                    message.file = [];
                                message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorSet message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorSet.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.file != null && message.hasOwnProperty("file")) {
                        if (!Array.isArray(message.file))
                            return "file: array expected";
                        for (var i = 0; i < message.file.length; ++i) {
                            var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                            if (error)
                                return "file." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 */
                FileDescriptorSet.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorSet)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorSet();
                    if (object.file) {
                        if (!Array.isArray(object.file))
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                        message.file = [];
                        for (var i = 0; i < object.file.length; ++i) {
                            if (typeof object.file[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                            message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorSet.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.file = [];
                    if (message.file && message.file.length) {
                        object.file = [];
                        for (var j = 0; j < message.file.length; ++j)
                            object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorSet to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileDescriptorSet
                 * @function getTypeUrl
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileDescriptorSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FileDescriptorSet";
                };
    
                return FileDescriptorSet;
            })();
    
            protobuf.FileDescriptorProto = (function() {
    
                /**
                 * Properties of a FileDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorProto
                 * @property {string|null} [name] FileDescriptorProto name
                 * @property {string|null} ["package"] FileDescriptorProto package
                 * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
                 * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
                 * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
                 * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
                 * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
                 * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
                 * @property {string|null} [syntax] FileDescriptorProto syntax
                 */
    
                /**
                 * Constructs a new FileDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorProto.
                 * @implements IFileDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 */
                function FileDescriptorProto(properties) {
                    this.dependency = [];
                    this.publicDependency = [];
                    this.weakDependency = [];
                    this.messageType = [];
                    this.enumType = [];
                    this.service = [];
                    this.extension = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.name = "";
    
                /**
                 * FileDescriptorProto package.
                 * @member {string} package
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype["package"] = "";
    
                /**
                 * FileDescriptorProto dependency.
                 * @member {Array.<string>} dependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.dependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto publicDependency.
                 * @member {Array.<number>} publicDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.publicDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto weakDependency.
                 * @member {Array.<number>} weakDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.weakDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto messageType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} messageType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.messageType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto service.
                 * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.service = $util.emptyArray;
    
                /**
                 * FileDescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * FileDescriptorProto options.
                 * @member {google.protobuf.IFileOptions|null|undefined} options
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.options = null;
    
                /**
                 * FileDescriptorProto sourceCodeInfo.
                 * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.sourceCodeInfo = null;
    
                /**
                 * FileDescriptorProto syntax.
                 * @member {string} syntax
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.syntax = "";
    
                /**
                 * Creates a new FileDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
                 */
                FileDescriptorProto.create = function create(properties) {
                    return new FileDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                    if (message.dependency != null && message.dependency.length)
                        for (var i = 0; i < message.dependency.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                    if (message.messageType != null && message.messageType.length)
                        for (var i = 0; i < message.messageType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.service != null && message.service.length)
                        for (var i = 0; i < message.service.length; ++i)
                            $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                        $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.publicDependency != null && message.publicDependency.length)
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                    if (message.weakDependency != null && message.weakDependency.length)
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                    if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message["package"] = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.dependency && message.dependency.length))
                                    message.dependency = [];
                                message.dependency.push(reader.string());
                                break;
                            }
                        case 10: {
                                if (!(message.publicDependency && message.publicDependency.length))
                                    message.publicDependency = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.publicDependency.push(reader.int32());
                                } else
                                    message.publicDependency.push(reader.int32());
                                break;
                            }
                        case 11: {
                                if (!(message.weakDependency && message.weakDependency.length))
                                    message.weakDependency = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.weakDependency.push(reader.int32());
                                } else
                                    message.weakDependency.push(reader.int32());
                                break;
                            }
                        case 4: {
                                if (!(message.messageType && message.messageType.length))
                                    message.messageType = [];
                                message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.enumType && message.enumType.length))
                                    message.enumType = [];
                                message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                if (!(message.service && message.service.length))
                                    message.service = [];
                                message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 7: {
                                if (!(message.extension && message.extension.length))
                                    message.extension = [];
                                message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.syntax = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        if (!$util.isString(message["package"]))
                            return "package: string expected";
                    if (message.dependency != null && message.hasOwnProperty("dependency")) {
                        if (!Array.isArray(message.dependency))
                            return "dependency: array expected";
                        for (var i = 0; i < message.dependency.length; ++i)
                            if (!$util.isString(message.dependency[i]))
                                return "dependency: string[] expected";
                    }
                    if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                        if (!Array.isArray(message.publicDependency))
                            return "publicDependency: array expected";
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            if (!$util.isInteger(message.publicDependency[i]))
                                return "publicDependency: integer[] expected";
                    }
                    if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                        if (!Array.isArray(message.weakDependency))
                            return "weakDependency: array expected";
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            if (!$util.isInteger(message.weakDependency[i]))
                                return "weakDependency: integer[] expected";
                    }
                    if (message.messageType != null && message.hasOwnProperty("messageType")) {
                        if (!Array.isArray(message.messageType))
                            return "messageType: array expected";
                        for (var i = 0; i < message.messageType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                            if (error)
                                return "messageType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.service != null && message.hasOwnProperty("service")) {
                        if (!Array.isArray(message.service))
                            return "service: array expected";
                        for (var i = 0; i < message.service.length; ++i) {
                            var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                            if (error)
                                return "service." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FileOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                        var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                        if (error)
                            return "sourceCodeInfo." + error;
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        if (!$util.isString(message.syntax))
                            return "syntax: string expected";
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 */
                FileDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object["package"] != null)
                        message["package"] = String(object["package"]);
                    if (object.dependency) {
                        if (!Array.isArray(object.dependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                        message.dependency = [];
                        for (var i = 0; i < object.dependency.length; ++i)
                            message.dependency[i] = String(object.dependency[i]);
                    }
                    if (object.publicDependency) {
                        if (!Array.isArray(object.publicDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                        message.publicDependency = [];
                        for (var i = 0; i < object.publicDependency.length; ++i)
                            message.publicDependency[i] = object.publicDependency[i] | 0;
                    }
                    if (object.weakDependency) {
                        if (!Array.isArray(object.weakDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                        message.weakDependency = [];
                        for (var i = 0; i < object.weakDependency.length; ++i)
                            message.weakDependency[i] = object.weakDependency[i] | 0;
                    }
                    if (object.messageType) {
                        if (!Array.isArray(object.messageType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                        message.messageType = [];
                        for (var i = 0; i < object.messageType.length; ++i) {
                            if (typeof object.messageType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                            message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.service) {
                        if (!Array.isArray(object.service))
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                        message.service = [];
                        for (var i = 0; i < object.service.length; ++i) {
                            if (typeof object.service[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                            message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                    }
                    if (object.sourceCodeInfo != null) {
                        if (typeof object.sourceCodeInfo !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                    }
                    if (object.syntax != null)
                        message.syntax = String(object.syntax);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.dependency = [];
                        object.messageType = [];
                        object.enumType = [];
                        object.service = [];
                        object.extension = [];
                        object.publicDependency = [];
                        object.weakDependency = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object["package"] = "";
                        object.options = null;
                        object.sourceCodeInfo = null;
                        object.syntax = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        object["package"] = message["package"];
                    if (message.dependency && message.dependency.length) {
                        object.dependency = [];
                        for (var j = 0; j < message.dependency.length; ++j)
                            object.dependency[j] = message.dependency[j];
                    }
                    if (message.messageType && message.messageType.length) {
                        object.messageType = [];
                        for (var j = 0; j < message.messageType.length; ++j)
                            object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.service && message.service.length) {
                        object.service = [];
                        for (var j = 0; j < message.service.length; ++j)
                            object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                        object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                    if (message.publicDependency && message.publicDependency.length) {
                        object.publicDependency = [];
                        for (var j = 0; j < message.publicDependency.length; ++j)
                            object.publicDependency[j] = message.publicDependency[j];
                    }
                    if (message.weakDependency && message.weakDependency.length) {
                        object.weakDependency = [];
                        for (var j = 0; j < message.weakDependency.length; ++j)
                            object.weakDependency[j] = message.weakDependency[j];
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        object.syntax = message.syntax;
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FileDescriptorProto";
                };
    
                return FileDescriptorProto;
            })();
    
            protobuf.DescriptorProto = (function() {
    
                /**
                 * Properties of a DescriptorProto.
                 * @memberof google.protobuf
                 * @interface IDescriptorProto
                 * @property {string|null} [name] DescriptorProto name
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
                 * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
                 * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
                 * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
                 * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
                 * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
                 */
    
                /**
                 * Constructs a new DescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a DescriptorProto.
                 * @implements IDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 */
                function DescriptorProto(properties) {
                    this.field = [];
                    this.extension = [];
                    this.nestedType = [];
                    this.enumType = [];
                    this.extensionRange = [];
                    this.oneofDecl = [];
                    this.reservedRange = [];
                    this.reservedName = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.name = "";
    
                /**
                 * DescriptorProto field.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.field = $util.emptyArray;
    
                /**
                 * DescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * DescriptorProto nestedType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.nestedType = $util.emptyArray;
    
                /**
                 * DescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * DescriptorProto extensionRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extensionRange = $util.emptyArray;
    
                /**
                 * DescriptorProto oneofDecl.
                 * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.oneofDecl = $util.emptyArray;
    
                /**
                 * DescriptorProto options.
                 * @member {google.protobuf.IMessageOptions|null|undefined} options
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.options = null;
    
                /**
                 * DescriptorProto reservedRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedRange = $util.emptyArray;
    
                /**
                 * DescriptorProto reservedName.
                 * @member {Array.<string>} reservedName
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedName = $util.emptyArray;
    
                /**
                 * Creates a new DescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
                 */
                DescriptorProto.create = function create(properties) {
                    return new DescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.field != null && message.field.length)
                        for (var i = 0; i < message.field.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.nestedType != null && message.nestedType.length)
                        for (var i = 0; i < message.nestedType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.extensionRange != null && message.extensionRange.length)
                        for (var i = 0; i < message.extensionRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.oneofDecl != null && message.oneofDecl.length)
                        for (var i = 0; i < message.oneofDecl.length; ++i)
                            $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.reservedRange != null && message.reservedRange.length)
                        for (var i = 0; i < message.reservedRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.reservedName != null && message.reservedName.length)
                        for (var i = 0; i < message.reservedName.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.field && message.field.length))
                                    message.field = [];
                                message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                if (!(message.extension && message.extension.length))
                                    message.extension = [];
                                message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.nestedType && message.nestedType.length))
                                    message.nestedType = [];
                                message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.enumType && message.enumType.length))
                                    message.enumType = [];
                                message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.extensionRange && message.extensionRange.length))
                                    message.extensionRange = [];
                                message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                if (!(message.oneofDecl && message.oneofDecl.length))
                                    message.oneofDecl = [];
                                message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 7: {
                                message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                if (!(message.reservedRange && message.reservedRange.length))
                                    message.reservedRange = [];
                                message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                if (!(message.reservedName && message.reservedName.length))
                                    message.reservedName = [];
                                message.reservedName.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.field != null && message.hasOwnProperty("field")) {
                        if (!Array.isArray(message.field))
                            return "field: array expected";
                        for (var i = 0; i < message.field.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                            if (error)
                                return "field." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                        if (!Array.isArray(message.nestedType))
                            return "nestedType: array expected";
                        for (var i = 0; i < message.nestedType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                            if (error)
                                return "nestedType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                        if (!Array.isArray(message.extensionRange))
                            return "extensionRange: array expected";
                        for (var i = 0; i < message.extensionRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                            if (error)
                                return "extensionRange." + error;
                        }
                    }
                    if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                        if (!Array.isArray(message.oneofDecl))
                            return "oneofDecl: array expected";
                        for (var i = 0; i < message.oneofDecl.length; ++i) {
                            var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                            if (error)
                                return "oneofDecl." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MessageOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                        if (!Array.isArray(message.reservedRange))
                            return "reservedRange: array expected";
                        for (var i = 0; i < message.reservedRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                            if (error)
                                return "reservedRange." + error;
                        }
                    }
                    if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                        if (!Array.isArray(message.reservedName))
                            return "reservedName: array expected";
                        for (var i = 0; i < message.reservedName.length; ++i)
                            if (!$util.isString(message.reservedName[i]))
                                return "reservedName: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 */
                DescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.field) {
                        if (!Array.isArray(object.field))
                            throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                        message.field = [];
                        for (var i = 0; i < object.field.length; ++i) {
                            if (typeof object.field[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                            message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.nestedType) {
                        if (!Array.isArray(object.nestedType))
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                        message.nestedType = [];
                        for (var i = 0; i < object.nestedType.length; ++i) {
                            if (typeof object.nestedType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                            message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.extensionRange) {
                        if (!Array.isArray(object.extensionRange))
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                        message.extensionRange = [];
                        for (var i = 0; i < object.extensionRange.length; ++i) {
                            if (typeof object.extensionRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                            message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                        }
                    }
                    if (object.oneofDecl) {
                        if (!Array.isArray(object.oneofDecl))
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                        message.oneofDecl = [];
                        for (var i = 0; i < object.oneofDecl.length; ++i) {
                            if (typeof object.oneofDecl[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                            message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                    }
                    if (object.reservedRange) {
                        if (!Array.isArray(object.reservedRange))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                        message.reservedRange = [];
                        for (var i = 0; i < object.reservedRange.length; ++i) {
                            if (typeof object.reservedRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                            message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                        }
                    }
                    if (object.reservedName) {
                        if (!Array.isArray(object.reservedName))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                        message.reservedName = [];
                        for (var i = 0; i < object.reservedName.length; ++i)
                            message.reservedName[i] = String(object.reservedName[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.DescriptorProto} message DescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.field = [];
                        object.nestedType = [];
                        object.enumType = [];
                        object.extensionRange = [];
                        object.extension = [];
                        object.oneofDecl = [];
                        object.reservedRange = [];
                        object.reservedName = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.field && message.field.length) {
                        object.field = [];
                        for (var j = 0; j < message.field.length; ++j)
                            object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                    }
                    if (message.nestedType && message.nestedType.length) {
                        object.nestedType = [];
                        for (var j = 0; j < message.nestedType.length; ++j)
                            object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.extensionRange && message.extensionRange.length) {
                        object.extensionRange = [];
                        for (var j = 0; j < message.extensionRange.length; ++j)
                            object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                    if (message.oneofDecl && message.oneofDecl.length) {
                        object.oneofDecl = [];
                        for (var j = 0; j < message.oneofDecl.length; ++j)
                            object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                    }
                    if (message.reservedRange && message.reservedRange.length) {
                        object.reservedRange = [];
                        for (var j = 0; j < message.reservedRange.length; ++j)
                            object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                    }
                    if (message.reservedName && message.reservedName.length) {
                        object.reservedName = [];
                        for (var j = 0; j < message.reservedName.length; ++j)
                            object.reservedName[j] = message.reservedName[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this DescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto";
                };
    
                DescriptorProto.ExtensionRange = (function() {
    
                    /**
                     * Properties of an ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IExtensionRange
                     * @property {number|null} [start] ExtensionRange start
                     * @property {number|null} [end] ExtensionRange end
                     */
    
                    /**
                     * Constructs a new ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents an ExtensionRange.
                     * @implements IExtensionRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     */
                    function ExtensionRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExtensionRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.start = 0;
    
                    /**
                     * ExtensionRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.end = 0;
    
                    /**
                     * Creates a new ExtensionRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                     */
                    ExtensionRange.create = function create(properties) {
                        return new ExtensionRange(properties);
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.start = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.end = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExtensionRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExtensionRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     */
                    ExtensionRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExtensionRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ExtensionRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExtensionRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ExtensionRange
                     * @function getTypeUrl
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ExtensionRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.DescriptorProto.ExtensionRange";
                    };
    
                    return ExtensionRange;
                })();
    
                DescriptorProto.ReservedRange = (function() {
    
                    /**
                     * Properties of a ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IReservedRange
                     * @property {number|null} [start] ReservedRange start
                     * @property {number|null} [end] ReservedRange end
                     */
    
                    /**
                     * Constructs a new ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents a ReservedRange.
                     * @implements IReservedRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     */
                    function ReservedRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReservedRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.start = 0;
    
                    /**
                     * ReservedRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.end = 0;
    
                    /**
                     * Creates a new ReservedRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                     */
                    ReservedRange.create = function create(properties) {
                        return new ReservedRange(properties);
                    };
    
                    /**
                     * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.start = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.end = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReservedRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReservedRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     */
                    ReservedRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReservedRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ReservedRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReservedRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ReservedRange
                     * @function getTypeUrl
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.DescriptorProto.ReservedRange";
                    };
    
                    return ReservedRange;
                })();
    
                return DescriptorProto;
            })();
    
            protobuf.FieldDescriptorProto = (function() {
    
                /**
                 * Properties of a FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFieldDescriptorProto
                 * @property {string|null} [name] FieldDescriptorProto name
                 * @property {number|null} [number] FieldDescriptorProto number
                 * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
                 * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
                 * @property {string|null} [typeName] FieldDescriptorProto typeName
                 * @property {string|null} [extendee] FieldDescriptorProto extendee
                 * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
                 * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
                 * @property {string|null} [jsonName] FieldDescriptorProto jsonName
                 * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
                 */
    
                /**
                 * Constructs a new FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldDescriptorProto.
                 * @implements IFieldDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 */
                function FieldDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.name = "";
    
                /**
                 * FieldDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.number = 0;
    
                /**
                 * FieldDescriptorProto label.
                 * @member {google.protobuf.FieldDescriptorProto.Label} label
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.label = 1;
    
                /**
                 * FieldDescriptorProto type.
                 * @member {google.protobuf.FieldDescriptorProto.Type} type
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.type = 1;
    
                /**
                 * FieldDescriptorProto typeName.
                 * @member {string} typeName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.typeName = "";
    
                /**
                 * FieldDescriptorProto extendee.
                 * @member {string} extendee
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.extendee = "";
    
                /**
                 * FieldDescriptorProto defaultValue.
                 * @member {string} defaultValue
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.defaultValue = "";
    
                /**
                 * FieldDescriptorProto oneofIndex.
                 * @member {number} oneofIndex
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.oneofIndex = 0;
    
                /**
                 * FieldDescriptorProto jsonName.
                 * @member {string} jsonName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.jsonName = "";
    
                /**
                 * FieldDescriptorProto options.
                 * @member {google.protobuf.IFieldOptions|null|undefined} options
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new FieldDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
                 */
                FieldDescriptorProto.create = function create(properties) {
                    return new FieldDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                    if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                    if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.number = reader.int32();
                                break;
                            }
                        case 4: {
                                message.label = reader.int32();
                                break;
                            }
                        case 5: {
                                message.type = reader.int32();
                                break;
                            }
                        case 6: {
                                message.typeName = reader.string();
                                break;
                            }
                        case 2: {
                                message.extendee = reader.string();
                                break;
                            }
                        case 7: {
                                message.defaultValue = reader.string();
                                break;
                            }
                        case 9: {
                                message.oneofIndex = reader.int32();
                                break;
                            }
                        case 10: {
                                message.jsonName = reader.string();
                                break;
                            }
                        case 8: {
                                message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.label != null && message.hasOwnProperty("label"))
                        switch (message.label) {
                        default:
                            return "label: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        if (!$util.isString(message.extendee))
                            return "extendee: string expected";
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        if (!$util.isString(message.defaultValue))
                            return "defaultValue: string expected";
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        if (!$util.isInteger(message.oneofIndex))
                            return "oneofIndex: integer expected";
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        if (!$util.isString(message.jsonName))
                            return "jsonName: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FieldOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 */
                FieldDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FieldDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    switch (object.label) {
                    default:
                        if (typeof object.label === "number") {
                            message.label = object.label;
                            break;
                        }
                        break;
                    case "LABEL_OPTIONAL":
                    case 1:
                        message.label = 1;
                        break;
                    case "LABEL_REQUIRED":
                    case 2:
                        message.label = 2;
                        break;
                    case "LABEL_REPEATED":
                    case 3:
                        message.label = 3;
                        break;
                    }
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "TYPE_DOUBLE":
                    case 1:
                        message.type = 1;
                        break;
                    case "TYPE_FLOAT":
                    case 2:
                        message.type = 2;
                        break;
                    case "TYPE_INT64":
                    case 3:
                        message.type = 3;
                        break;
                    case "TYPE_UINT64":
                    case 4:
                        message.type = 4;
                        break;
                    case "TYPE_INT32":
                    case 5:
                        message.type = 5;
                        break;
                    case "TYPE_FIXED64":
                    case 6:
                        message.type = 6;
                        break;
                    case "TYPE_FIXED32":
                    case 7:
                        message.type = 7;
                        break;
                    case "TYPE_BOOL":
                    case 8:
                        message.type = 8;
                        break;
                    case "TYPE_STRING":
                    case 9:
                        message.type = 9;
                        break;
                    case "TYPE_GROUP":
                    case 10:
                        message.type = 10;
                        break;
                    case "TYPE_MESSAGE":
                    case 11:
                        message.type = 11;
                        break;
                    case "TYPE_BYTES":
                    case 12:
                        message.type = 12;
                        break;
                    case "TYPE_UINT32":
                    case 13:
                        message.type = 13;
                        break;
                    case "TYPE_ENUM":
                    case 14:
                        message.type = 14;
                        break;
                    case "TYPE_SFIXED32":
                    case 15:
                        message.type = 15;
                        break;
                    case "TYPE_SFIXED64":
                    case 16:
                        message.type = 16;
                        break;
                    case "TYPE_SINT32":
                    case 17:
                        message.type = 17;
                        break;
                    case "TYPE_SINT64":
                    case 18:
                        message.type = 18;
                        break;
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.extendee != null)
                        message.extendee = String(object.extendee);
                    if (object.defaultValue != null)
                        message.defaultValue = String(object.defaultValue);
                    if (object.oneofIndex != null)
                        message.oneofIndex = object.oneofIndex | 0;
                    if (object.jsonName != null)
                        message.jsonName = String(object.jsonName);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.extendee = "";
                        object.number = 0;
                        object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                        object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                        object.typeName = "";
                        object.defaultValue = "";
                        object.options = null;
                        object.oneofIndex = 0;
                        object.jsonName = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        object.extendee = message.extendee;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] === undefined ? message.label : $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] === undefined ? message.type : $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        object.defaultValue = message.defaultValue;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        object.oneofIndex = message.oneofIndex;
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        object.jsonName = message.jsonName;
                    return object;
                };
    
                /**
                 * Converts this FieldDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FieldDescriptorProto";
                };
    
                /**
                 * Type enum.
                 * @name google.protobuf.FieldDescriptorProto.Type
                 * @enum {number}
                 * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
                 * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
                 * @property {number} TYPE_INT64=3 TYPE_INT64 value
                 * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
                 * @property {number} TYPE_INT32=5 TYPE_INT32 value
                 * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
                 * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
                 * @property {number} TYPE_BOOL=8 TYPE_BOOL value
                 * @property {number} TYPE_STRING=9 TYPE_STRING value
                 * @property {number} TYPE_GROUP=10 TYPE_GROUP value
                 * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
                 * @property {number} TYPE_BYTES=12 TYPE_BYTES value
                 * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
                 * @property {number} TYPE_ENUM=14 TYPE_ENUM value
                 * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
                 * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
                 * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
                 * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
                 */
                FieldDescriptorProto.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                    values[valuesById[2] = "TYPE_FLOAT"] = 2;
                    values[valuesById[3] = "TYPE_INT64"] = 3;
                    values[valuesById[4] = "TYPE_UINT64"] = 4;
                    values[valuesById[5] = "TYPE_INT32"] = 5;
                    values[valuesById[6] = "TYPE_FIXED64"] = 6;
                    values[valuesById[7] = "TYPE_FIXED32"] = 7;
                    values[valuesById[8] = "TYPE_BOOL"] = 8;
                    values[valuesById[9] = "TYPE_STRING"] = 9;
                    values[valuesById[10] = "TYPE_GROUP"] = 10;
                    values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                    values[valuesById[12] = "TYPE_BYTES"] = 12;
                    values[valuesById[13] = "TYPE_UINT32"] = 13;
                    values[valuesById[14] = "TYPE_ENUM"] = 14;
                    values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                    values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                    values[valuesById[17] = "TYPE_SINT32"] = 17;
                    values[valuesById[18] = "TYPE_SINT64"] = 18;
                    return values;
                })();
    
                /**
                 * Label enum.
                 * @name google.protobuf.FieldDescriptorProto.Label
                 * @enum {number}
                 * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
                 * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
                 * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
                 */
                FieldDescriptorProto.Label = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                    values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                    values[valuesById[3] = "LABEL_REPEATED"] = 3;
                    return values;
                })();
    
                return FieldDescriptorProto;
            })();
    
            protobuf.OneofDescriptorProto = (function() {
    
                /**
                 * Properties of an OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IOneofDescriptorProto
                 * @property {string|null} [name] OneofDescriptorProto name
                 * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
                 */
    
                /**
                 * Constructs a new OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofDescriptorProto.
                 * @implements IOneofDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 */
                function OneofDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.name = "";
    
                /**
                 * OneofDescriptorProto options.
                 * @member {google.protobuf.IOneofOptions|null|undefined} options
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new OneofDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
                 */
                OneofDescriptorProto.create = function create(properties) {
                    return new OneofDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.OneofOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 */
                OneofDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.OneofDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this OneofDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OneofDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OneofDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.OneofDescriptorProto";
                };
    
                return OneofDescriptorProto;
            })();
    
            protobuf.EnumDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumDescriptorProto
                 * @property {string|null} [name] EnumDescriptorProto name
                 * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
                 * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumDescriptorProto.
                 * @implements IEnumDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 */
                function EnumDescriptorProto(properties) {
                    this.value = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.name = "";
    
                /**
                 * EnumDescriptorProto value.
                 * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.value = $util.emptyArray;
    
                /**
                 * EnumDescriptorProto options.
                 * @member {google.protobuf.IEnumOptions|null|undefined} options
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
                 */
                EnumDescriptorProto.create = function create(properties) {
                    return new EnumDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.value != null && message.value.length)
                        for (var i = 0; i < message.value.length; ++i)
                            $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.value && message.value.length))
                                    message.value = [];
                                message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        if (!Array.isArray(message.value))
                            return "value: array expected";
                        for (var i = 0; i < message.value.length; ++i) {
                            var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                            if (error)
                                return "value." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 */
                EnumDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value) {
                        if (!Array.isArray(object.value))
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                        message.value = [];
                        for (var i = 0; i < object.value.length; ++i) {
                            if (typeof object.value[i] !== "object")
                                throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                            message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.value = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value && message.value.length) {
                        object.value = [];
                        for (var j = 0; j < message.value.length; ++j)
                            object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto";
                };
    
                return EnumDescriptorProto;
            })();
    
            protobuf.EnumValueDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumValueDescriptorProto
                 * @property {string|null} [name] EnumValueDescriptorProto name
                 * @property {number|null} [number] EnumValueDescriptorProto number
                 * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueDescriptorProto.
                 * @implements IEnumValueDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 */
                function EnumValueDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.name = "";
    
                /**
                 * EnumValueDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.number = 0;
    
                /**
                 * EnumValueDescriptorProto options.
                 * @member {google.protobuf.IEnumValueOptions|null|undefined} options
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumValueDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
                 */
                EnumValueDescriptorProto.create = function create(properties) {
                    return new EnumValueDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.number = reader.int32();
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 */
                EnumValueDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumValueDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.number = 0;
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumValueDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumValueDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumValueDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumValueDescriptorProto";
                };
    
                return EnumValueDescriptorProto;
            })();
    
            protobuf.ServiceDescriptorProto = (function() {
    
                /**
                 * Properties of a ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IServiceDescriptorProto
                 * @property {string|null} [name] ServiceDescriptorProto name
                 * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
                 * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
                 */
    
                /**
                 * Constructs a new ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceDescriptorProto.
                 * @implements IServiceDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 */
                function ServiceDescriptorProto(properties) {
                    this.method = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.name = "";
    
                /**
                 * ServiceDescriptorProto method.
                 * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.method = $util.emptyArray;
    
                /**
                 * ServiceDescriptorProto options.
                 * @member {google.protobuf.IServiceOptions|null|undefined} options
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new ServiceDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
                 */
                ServiceDescriptorProto.create = function create(properties) {
                    return new ServiceDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.method != null && message.method.length)
                        for (var i = 0; i < message.method.length; ++i)
                            $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.method && message.method.length))
                                    message.method = [];
                                message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.method != null && message.hasOwnProperty("method")) {
                        if (!Array.isArray(message.method))
                            return "method: array expected";
                        for (var i = 0; i < message.method.length; ++i) {
                            var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                            if (error)
                                return "method." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 */
                ServiceDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.ServiceDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.method) {
                        if (!Array.isArray(object.method))
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                        message.method = [];
                        for (var i = 0; i < object.method.length; ++i) {
                            if (typeof object.method[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                            message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.method = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.method && message.method.length) {
                        object.method = [];
                        for (var j = 0; j < message.method.length; ++j)
                            object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this ServiceDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ServiceDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.ServiceDescriptorProto";
                };
    
                return ServiceDescriptorProto;
            })();
    
            protobuf.MethodDescriptorProto = (function() {
    
                /**
                 * Properties of a MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IMethodDescriptorProto
                 * @property {string|null} [name] MethodDescriptorProto name
                 * @property {string|null} [inputType] MethodDescriptorProto inputType
                 * @property {string|null} [outputType] MethodDescriptorProto outputType
                 * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
                 * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
                 * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
                 */
    
                /**
                 * Constructs a new MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodDescriptorProto.
                 * @implements IMethodDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 */
                function MethodDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.name = "";
    
                /**
                 * MethodDescriptorProto inputType.
                 * @member {string} inputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.inputType = "";
    
                /**
                 * MethodDescriptorProto outputType.
                 * @member {string} outputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.outputType = "";
    
                /**
                 * MethodDescriptorProto options.
                 * @member {google.protobuf.IMethodOptions|null|undefined} options
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.options = null;
    
                /**
                 * MethodDescriptorProto clientStreaming.
                 * @member {boolean} clientStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.clientStreaming = false;
    
                /**
                 * MethodDescriptorProto serverStreaming.
                 * @member {boolean} serverStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.serverStreaming = false;
    
                /**
                 * Creates a new MethodDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
                 */
                MethodDescriptorProto.create = function create(properties) {
                    return new MethodDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                    if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                    if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.inputType = reader.string();
                                break;
                            }
                        case 3: {
                                message.outputType = reader.string();
                                break;
                            }
                        case 4: {
                                message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.clientStreaming = reader.bool();
                                break;
                            }
                        case 6: {
                                message.serverStreaming = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        if (!$util.isString(message.outputType))
                            return "outputType: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MethodOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        if (typeof message.clientStreaming !== "boolean")
                            return "clientStreaming: boolean expected";
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        if (typeof message.serverStreaming !== "boolean")
                            return "serverStreaming: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 */
                MethodDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.MethodDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.outputType != null)
                        message.outputType = String(object.outputType);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                    }
                    if (object.clientStreaming != null)
                        message.clientStreaming = Boolean(object.clientStreaming);
                    if (object.serverStreaming != null)
                        message.serverStreaming = Boolean(object.serverStreaming);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.inputType = "";
                        object.outputType = "";
                        object.options = null;
                        object.clientStreaming = false;
                        object.serverStreaming = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        object.outputType = message.outputType;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        object.clientStreaming = message.clientStreaming;
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        object.serverStreaming = message.serverStreaming;
                    return object;
                };
    
                /**
                 * Converts this MethodDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MethodDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MethodDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.MethodDescriptorProto";
                };
    
                return MethodDescriptorProto;
            })();
    
            protobuf.FileOptions = (function() {
    
                /**
                 * Properties of a FileOptions.
                 * @memberof google.protobuf
                 * @interface IFileOptions
                 * @property {string|null} [javaPackage] FileOptions javaPackage
                 * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
                 * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
                 * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
                 * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
                 * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
                 * @property {string|null} [goPackage] FileOptions goPackage
                 * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
                 * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
                 * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
                 * @property {boolean|null} [deprecated] FileOptions deprecated
                 * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
                 * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
                 * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FileOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileOptions.
                 * @implements IFileOptions
                 * @constructor
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 */
                function FileOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileOptions javaPackage.
                 * @member {string} javaPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaPackage = "";
    
                /**
                 * FileOptions javaOuterClassname.
                 * @member {string} javaOuterClassname
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaOuterClassname = "";
    
                /**
                 * FileOptions javaMultipleFiles.
                 * @member {boolean} javaMultipleFiles
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaMultipleFiles = false;
    
                /**
                 * FileOptions javaGenerateEqualsAndHash.
                 * @member {boolean} javaGenerateEqualsAndHash
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenerateEqualsAndHash = false;
    
                /**
                 * FileOptions javaStringCheckUtf8.
                 * @member {boolean} javaStringCheckUtf8
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaStringCheckUtf8 = false;
    
                /**
                 * FileOptions optimizeFor.
                 * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.optimizeFor = 1;
    
                /**
                 * FileOptions goPackage.
                 * @member {string} goPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.goPackage = "";
    
                /**
                 * FileOptions ccGenericServices.
                 * @member {boolean} ccGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccGenericServices = false;
    
                /**
                 * FileOptions javaGenericServices.
                 * @member {boolean} javaGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenericServices = false;
    
                /**
                 * FileOptions pyGenericServices.
                 * @member {boolean} pyGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.pyGenericServices = false;
    
                /**
                 * FileOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.deprecated = false;
    
                /**
                 * FileOptions ccEnableArenas.
                 * @member {boolean} ccEnableArenas
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccEnableArenas = false;
    
                /**
                 * FileOptions objcClassPrefix.
                 * @member {string} objcClassPrefix
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.objcClassPrefix = "";
    
                /**
                 * FileOptions csharpNamespace.
                 * @member {string} csharpNamespace
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.csharpNamespace = "";
    
                /**
                 * FileOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FileOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FileOptions} FileOptions instance
                 */
                FileOptions.create = function create(properties) {
                    return new FileOptions(properties);
                };
    
                /**
                 * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                    if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                    if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                    if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                    if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                    if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                    if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                        writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                    if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                    if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                    if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                        writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                    if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                        writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                    if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                    if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                        writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.javaPackage = reader.string();
                                break;
                            }
                        case 8: {
                                message.javaOuterClassname = reader.string();
                                break;
                            }
                        case 10: {
                                message.javaMultipleFiles = reader.bool();
                                break;
                            }
                        case 20: {
                                message.javaGenerateEqualsAndHash = reader.bool();
                                break;
                            }
                        case 27: {
                                message.javaStringCheckUtf8 = reader.bool();
                                break;
                            }
                        case 9: {
                                message.optimizeFor = reader.int32();
                                break;
                            }
                        case 11: {
                                message.goPackage = reader.string();
                                break;
                            }
                        case 16: {
                                message.ccGenericServices = reader.bool();
                                break;
                            }
                        case 17: {
                                message.javaGenericServices = reader.bool();
                                break;
                            }
                        case 18: {
                                message.pyGenericServices = reader.bool();
                                break;
                            }
                        case 23: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 31: {
                                message.ccEnableArenas = reader.bool();
                                break;
                            }
                        case 36: {
                                message.objcClassPrefix = reader.string();
                                break;
                            }
                        case 37: {
                                message.csharpNamespace = reader.string();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileOptions message.
                 * @function verify
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        if (!$util.isString(message.javaPackage))
                            return "javaPackage: string expected";
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        if (!$util.isString(message.javaOuterClassname))
                            return "javaOuterClassname: string expected";
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        if (typeof message.javaMultipleFiles !== "boolean")
                            return "javaMultipleFiles: boolean expected";
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                            return "javaGenerateEqualsAndHash: boolean expected";
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        if (typeof message.javaStringCheckUtf8 !== "boolean")
                            return "javaStringCheckUtf8: boolean expected";
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        switch (message.optimizeFor) {
                        default:
                            return "optimizeFor: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        if (!$util.isString(message.goPackage))
                            return "goPackage: string expected";
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        if (typeof message.ccGenericServices !== "boolean")
                            return "ccGenericServices: boolean expected";
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        if (typeof message.javaGenericServices !== "boolean")
                            return "javaGenericServices: boolean expected";
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        if (typeof message.pyGenericServices !== "boolean")
                            return "pyGenericServices: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        if (typeof message.ccEnableArenas !== "boolean")
                            return "ccEnableArenas: boolean expected";
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        if (!$util.isString(message.objcClassPrefix))
                            return "objcClassPrefix: string expected";
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        if (!$util.isString(message.csharpNamespace))
                            return "csharpNamespace: string expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileOptions} FileOptions
                 */
                FileOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileOptions)
                        return object;
                    var message = new $root.google.protobuf.FileOptions();
                    if (object.javaPackage != null)
                        message.javaPackage = String(object.javaPackage);
                    if (object.javaOuterClassname != null)
                        message.javaOuterClassname = String(object.javaOuterClassname);
                    if (object.javaMultipleFiles != null)
                        message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                    if (object.javaGenerateEqualsAndHash != null)
                        message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                    if (object.javaStringCheckUtf8 != null)
                        message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                    switch (object.optimizeFor) {
                    default:
                        if (typeof object.optimizeFor === "number") {
                            message.optimizeFor = object.optimizeFor;
                            break;
                        }
                        break;
                    case "SPEED":
                    case 1:
                        message.optimizeFor = 1;
                        break;
                    case "CODE_SIZE":
                    case 2:
                        message.optimizeFor = 2;
                        break;
                    case "LITE_RUNTIME":
                    case 3:
                        message.optimizeFor = 3;
                        break;
                    }
                    if (object.goPackage != null)
                        message.goPackage = String(object.goPackage);
                    if (object.ccGenericServices != null)
                        message.ccGenericServices = Boolean(object.ccGenericServices);
                    if (object.javaGenericServices != null)
                        message.javaGenericServices = Boolean(object.javaGenericServices);
                    if (object.pyGenericServices != null)
                        message.pyGenericServices = Boolean(object.pyGenericServices);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.ccEnableArenas != null)
                        message.ccEnableArenas = Boolean(object.ccEnableArenas);
                    if (object.objcClassPrefix != null)
                        message.objcClassPrefix = String(object.objcClassPrefix);
                    if (object.csharpNamespace != null)
                        message.csharpNamespace = String(object.csharpNamespace);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.FileOptions} message FileOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.javaPackage = "";
                        object.javaOuterClassname = "";
                        object.optimizeFor = options.enums === String ? "SPEED" : 1;
                        object.javaMultipleFiles = false;
                        object.goPackage = "";
                        object.ccGenericServices = false;
                        object.javaGenericServices = false;
                        object.pyGenericServices = false;
                        object.javaGenerateEqualsAndHash = false;
                        object.deprecated = false;
                        object.javaStringCheckUtf8 = false;
                        object.ccEnableArenas = false;
                        object.objcClassPrefix = "";
                        object.csharpNamespace = "";
                    }
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        object.javaPackage = message.javaPackage;
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        object.javaOuterClassname = message.javaOuterClassname;
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] === undefined ? message.optimizeFor : $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        object.javaMultipleFiles = message.javaMultipleFiles;
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        object.goPackage = message.goPackage;
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        object.ccGenericServices = message.ccGenericServices;
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        object.javaGenericServices = message.javaGenericServices;
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        object.pyGenericServices = message.pyGenericServices;
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        object.ccEnableArenas = message.ccEnableArenas;
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        object.objcClassPrefix = message.objcClassPrefix;
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        object.csharpNamespace = message.csharpNamespace;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FileOptions";
                };
    
                /**
                 * OptimizeMode enum.
                 * @name google.protobuf.FileOptions.OptimizeMode
                 * @enum {number}
                 * @property {number} SPEED=1 SPEED value
                 * @property {number} CODE_SIZE=2 CODE_SIZE value
                 * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
                 */
                FileOptions.OptimizeMode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "SPEED"] = 1;
                    values[valuesById[2] = "CODE_SIZE"] = 2;
                    values[valuesById[3] = "LITE_RUNTIME"] = 3;
                    return values;
                })();
    
                return FileOptions;
            })();
    
            protobuf.MessageOptions = (function() {
    
                /**
                 * Properties of a MessageOptions.
                 * @memberof google.protobuf
                 * @interface IMessageOptions
                 * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
                 * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
                 * @property {boolean|null} [deprecated] MessageOptions deprecated
                 * @property {boolean|null} [mapEntry] MessageOptions mapEntry
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MessageOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MessageOptions.
                 * @implements IMessageOptions
                 * @constructor
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 */
                function MessageOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MessageOptions messageSetWireFormat.
                 * @member {boolean} messageSetWireFormat
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.messageSetWireFormat = false;
    
                /**
                 * MessageOptions noStandardDescriptorAccessor.
                 * @member {boolean} noStandardDescriptorAccessor
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.noStandardDescriptorAccessor = false;
    
                /**
                 * MessageOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.deprecated = false;
    
                /**
                 * MessageOptions mapEntry.
                 * @member {boolean} mapEntry
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.mapEntry = false;
    
                /**
                 * MessageOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MessageOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MessageOptions} MessageOptions instance
                 */
                MessageOptions.create = function create(properties) {
                    return new MessageOptions(properties);
                };
    
                /**
                 * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                    if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.messageSetWireFormat = reader.bool();
                                break;
                            }
                        case 2: {
                                message.noStandardDescriptorAccessor = reader.bool();
                                break;
                            }
                        case 3: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 7: {
                                message.mapEntry = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MessageOptions message.
                 * @function verify
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessageOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        if (typeof message.messageSetWireFormat !== "boolean")
                            return "messageSetWireFormat: boolean expected";
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        if (typeof message.noStandardDescriptorAccessor !== "boolean")
                            return "noStandardDescriptorAccessor: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        if (typeof message.mapEntry !== "boolean")
                            return "mapEntry: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 */
                MessageOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MessageOptions)
                        return object;
                    var message = new $root.google.protobuf.MessageOptions();
                    if (object.messageSetWireFormat != null)
                        message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                    if (object.noStandardDescriptorAccessor != null)
                        message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.mapEntry != null)
                        message.mapEntry = Boolean(object.mapEntry);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.MessageOptions} message MessageOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessageOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.messageSetWireFormat = false;
                        object.noStandardDescriptorAccessor = false;
                        object.deprecated = false;
                        object.mapEntry = false;
                    }
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        object.messageSetWireFormat = message.messageSetWireFormat;
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        object.mapEntry = message.mapEntry;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MessageOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessageOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MessageOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.MessageOptions";
                };
    
                return MessageOptions;
            })();
    
            protobuf.FieldOptions = (function() {
    
                /**
                 * Properties of a FieldOptions.
                 * @memberof google.protobuf
                 * @interface IFieldOptions
                 * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
                 * @property {boolean|null} [packed] FieldOptions packed
                 * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
                 * @property {boolean|null} [lazy] FieldOptions lazy
                 * @property {boolean|null} [deprecated] FieldOptions deprecated
                 * @property {boolean|null} [weak] FieldOptions weak
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FieldOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldOptions.
                 * @implements IFieldOptions
                 * @constructor
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 */
                function FieldOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldOptions ctype.
                 * @member {google.protobuf.FieldOptions.CType} ctype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.ctype = 0;
    
                /**
                 * FieldOptions packed.
                 * @member {boolean} packed
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.packed = false;
    
                /**
                 * FieldOptions jstype.
                 * @member {google.protobuf.FieldOptions.JSType} jstype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.jstype = 0;
    
                /**
                 * FieldOptions lazy.
                 * @member {boolean} lazy
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.lazy = false;
    
                /**
                 * FieldOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.deprecated = false;
    
                /**
                 * FieldOptions weak.
                 * @member {boolean} weak
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.weak = false;
    
                /**
                 * FieldOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FieldOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FieldOptions} FieldOptions instance
                 */
                FieldOptions.create = function create(properties) {
                    return new FieldOptions(properties);
                };
    
                /**
                 * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                    if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                    if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                    if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ctype = reader.int32();
                                break;
                            }
                        case 2: {
                                message.packed = reader.bool();
                                break;
                            }
                        case 6: {
                                message.jstype = reader.int32();
                                break;
                            }
                        case 5: {
                                message.lazy = reader.bool();
                                break;
                            }
                        case 3: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 10: {
                                message.weak = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldOptions message.
                 * @function verify
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        switch (message.ctype) {
                        default:
                            return "ctype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        if (typeof message.packed !== "boolean")
                            return "packed: boolean expected";
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        switch (message.jstype) {
                        default:
                            return "jstype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        if (typeof message.lazy !== "boolean")
                            return "lazy: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        if (typeof message.weak !== "boolean")
                            return "weak: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 */
                FieldOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldOptions)
                        return object;
                    var message = new $root.google.protobuf.FieldOptions();
                    switch (object.ctype) {
                    default:
                        if (typeof object.ctype === "number") {
                            message.ctype = object.ctype;
                            break;
                        }
                        break;
                    case "STRING":
                    case 0:
                        message.ctype = 0;
                        break;
                    case "CORD":
                    case 1:
                        message.ctype = 1;
                        break;
                    case "STRING_PIECE":
                    case 2:
                        message.ctype = 2;
                        break;
                    }
                    if (object.packed != null)
                        message.packed = Boolean(object.packed);
                    switch (object.jstype) {
                    default:
                        if (typeof object.jstype === "number") {
                            message.jstype = object.jstype;
                            break;
                        }
                        break;
                    case "JS_NORMAL":
                    case 0:
                        message.jstype = 0;
                        break;
                    case "JS_STRING":
                    case 1:
                        message.jstype = 1;
                        break;
                    case "JS_NUMBER":
                    case 2:
                        message.jstype = 2;
                        break;
                    }
                    if (object.lazy != null)
                        message.lazy = Boolean(object.lazy);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.weak != null)
                        message.weak = Boolean(object.weak);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.FieldOptions} message FieldOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.ctype = options.enums === String ? "STRING" : 0;
                        object.packed = false;
                        object.deprecated = false;
                        object.lazy = false;
                        object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                        object.weak = false;
                    }
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] === undefined ? message.ctype : $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        object.packed = message.packed;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        object.lazy = message.lazy;
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] === undefined ? message.jstype : $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        object.weak = message.weak;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FieldOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FieldOptions";
                };
    
                /**
                 * CType enum.
                 * @name google.protobuf.FieldOptions.CType
                 * @enum {number}
                 * @property {number} STRING=0 STRING value
                 * @property {number} CORD=1 CORD value
                 * @property {number} STRING_PIECE=2 STRING_PIECE value
                 */
                FieldOptions.CType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STRING"] = 0;
                    values[valuesById[1] = "CORD"] = 1;
                    values[valuesById[2] = "STRING_PIECE"] = 2;
                    return values;
                })();
    
                /**
                 * JSType enum.
                 * @name google.protobuf.FieldOptions.JSType
                 * @enum {number}
                 * @property {number} JS_NORMAL=0 JS_NORMAL value
                 * @property {number} JS_STRING=1 JS_STRING value
                 * @property {number} JS_NUMBER=2 JS_NUMBER value
                 */
                FieldOptions.JSType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "JS_NORMAL"] = 0;
                    values[valuesById[1] = "JS_STRING"] = 1;
                    values[valuesById[2] = "JS_NUMBER"] = 2;
                    return values;
                })();
    
                return FieldOptions;
            })();
    
            protobuf.OneofOptions = (function() {
    
                /**
                 * Properties of an OneofOptions.
                 * @memberof google.protobuf
                 * @interface IOneofOptions
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new OneofOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofOptions.
                 * @implements IOneofOptions
                 * @constructor
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 */
                function OneofOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 */
                OneofOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new OneofOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 * @returns {google.protobuf.OneofOptions} OneofOptions instance
                 */
                OneofOptions.create = function create(properties) {
                    return new OneofOptions(properties);
                };
    
                /**
                 * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofOptions message.
                 * @function verify
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 */
                OneofOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofOptions)
                        return object;
                    var message = new $root.google.protobuf.OneofOptions();
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.OneofOptions} message OneofOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this OneofOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OneofOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OneofOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.OneofOptions";
                };
    
                return OneofOptions;
            })();
    
            protobuf.EnumOptions = (function() {
    
                /**
                 * Properties of an EnumOptions.
                 * @memberof google.protobuf
                 * @interface IEnumOptions
                 * @property {boolean|null} [allowAlias] EnumOptions allowAlias
                 * @property {boolean|null} [deprecated] EnumOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new EnumOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumOptions.
                 * @implements IEnumOptions
                 * @constructor
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 */
                function EnumOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumOptions allowAlias.
                 * @member {boolean} allowAlias
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.allowAlias = false;
    
                /**
                 * EnumOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.deprecated = false;
    
                /**
                 * EnumOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new EnumOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumOptions} EnumOptions instance
                 */
                EnumOptions.create = function create(properties) {
                    return new EnumOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.allowAlias = reader.bool();
                                break;
                            }
                        case 3: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        if (typeof message.allowAlias !== "boolean")
                            return "allowAlias: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 */
                EnumOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumOptions();
                    if (object.allowAlias != null)
                        message.allowAlias = Boolean(object.allowAlias);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.EnumOptions} message EnumOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.allowAlias = false;
                        object.deprecated = false;
                    }
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        object.allowAlias = message.allowAlias;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this EnumOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumOptions";
                };
    
                return EnumOptions;
            })();
    
            protobuf.EnumValueOptions = (function() {
    
                /**
                 * Properties of an EnumValueOptions.
                 * @memberof google.protobuf
                 * @interface IEnumValueOptions
                 * @property {boolean|null} [deprecated] EnumValueOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
                 * @property {Array.<string>|null} [".protocol.country"] EnumValueOptions .protocol.country
                 * @property {Array.<string>|null} [".protocol.symbol"] EnumValueOptions .protocol.symbol
                 * @property {Array.<string>|null} [".protocol.code"] EnumValueOptions .protocol.code
                 * @property {string|null} [".protocol.name"] EnumValueOptions .protocol.name
                 * @property {number|null} [".protocol.num"] EnumValueOptions .protocol.num
                 * @property {number|null} [".protocol.unit"] EnumValueOptions .protocol.unit
                 * @property {boolean|null} [".protocol.crypto"] EnumValueOptions .protocol.crypto
                 * @property {boolean|null} [".protocol.unofficial"] EnumValueOptions .protocol.unofficial
                 * @property {number|null} [".protocol.denom"] EnumValueOptions .protocol.denom
                 * @property {boolean|null} [".protocol.stable"] EnumValueOptions .protocol.stable
                 * @property {boolean|null} [".protocol.testnet"] EnumValueOptions .protocol.testnet
                 */
    
                /**
                 * Constructs a new EnumValueOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueOptions.
                 * @implements IEnumValueOptions
                 * @constructor
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 */
                function EnumValueOptions(properties) {
                    this.uninterpretedOption = [];
                    this[".protocol.country"] = [];
                    this[".protocol.symbol"] = [];
                    this[".protocol.code"] = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.deprecated = false;
    
                /**
                 * EnumValueOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * EnumValueOptions .protocol.country.
                 * @member {Array.<string>} .protocol.country
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.country"] = $util.emptyArray;
    
                /**
                 * EnumValueOptions .protocol.symbol.
                 * @member {Array.<string>} .protocol.symbol
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.symbol"] = $util.emptyArray;
    
                /**
                 * EnumValueOptions .protocol.code.
                 * @member {Array.<string>} .protocol.code
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.code"] = $util.emptyArray;
    
                /**
                 * EnumValueOptions .protocol.name.
                 * @member {string} .protocol.name
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.name"] = "";
    
                /**
                 * EnumValueOptions .protocol.num.
                 * @member {number} .protocol.num
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.num"] = 0;
    
                /**
                 * EnumValueOptions .protocol.unit.
                 * @member {number} .protocol.unit
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.unit"] = 0;
    
                /**
                 * EnumValueOptions .protocol.crypto.
                 * @member {boolean} .protocol.crypto
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.crypto"] = false;
    
                /**
                 * EnumValueOptions .protocol.unofficial.
                 * @member {boolean} .protocol.unofficial
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.unofficial"] = false;
    
                /**
                 * EnumValueOptions .protocol.denom.
                 * @member {number} .protocol.denom
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.denom"] = 0;
    
                /**
                 * EnumValueOptions .protocol.stable.
                 * @member {boolean} .protocol.stable
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.stable"] = false;
    
                /**
                 * EnumValueOptions .protocol.testnet.
                 * @member {boolean} .protocol.testnet
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".protocol.testnet"] = false;
    
                /**
                 * Creates a new EnumValueOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
                 */
                EnumValueOptions.create = function create(properties) {
                    return new EnumValueOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    if (message[".protocol.country"] != null && message[".protocol.country"].length)
                        for (var i = 0; i < message[".protocol.country"].length; ++i)
                            writer.uint32(/* id 50000, wireType 2 =*/400002).string(message[".protocol.country"][i]);
                    if (message[".protocol.symbol"] != null && message[".protocol.symbol"].length)
                        for (var i = 0; i < message[".protocol.symbol"].length; ++i)
                            writer.uint32(/* id 50001, wireType 2 =*/400010).string(message[".protocol.symbol"][i]);
                    if (message[".protocol.code"] != null && message[".protocol.code"].length)
                        for (var i = 0; i < message[".protocol.code"].length; ++i)
                            writer.uint32(/* id 50002, wireType 2 =*/400018).string(message[".protocol.code"][i]);
                    if (message[".protocol.name"] != null && Object.hasOwnProperty.call(message, ".protocol.name"))
                        writer.uint32(/* id 50003, wireType 2 =*/400026).string(message[".protocol.name"]);
                    if (message[".protocol.num"] != null && Object.hasOwnProperty.call(message, ".protocol.num"))
                        writer.uint32(/* id 50004, wireType 0 =*/400032).uint32(message[".protocol.num"]);
                    if (message[".protocol.unit"] != null && Object.hasOwnProperty.call(message, ".protocol.unit"))
                        writer.uint32(/* id 50005, wireType 0 =*/400040).uint32(message[".protocol.unit"]);
                    if (message[".protocol.crypto"] != null && Object.hasOwnProperty.call(message, ".protocol.crypto"))
                        writer.uint32(/* id 50006, wireType 0 =*/400048).bool(message[".protocol.crypto"]);
                    if (message[".protocol.unofficial"] != null && Object.hasOwnProperty.call(message, ".protocol.unofficial"))
                        writer.uint32(/* id 50007, wireType 0 =*/400056).bool(message[".protocol.unofficial"]);
                    if (message[".protocol.denom"] != null && Object.hasOwnProperty.call(message, ".protocol.denom"))
                        writer.uint32(/* id 50008, wireType 0 =*/400064).uint32(message[".protocol.denom"]);
                    if (message[".protocol.stable"] != null && Object.hasOwnProperty.call(message, ".protocol.stable"))
                        writer.uint32(/* id 50009, wireType 0 =*/400072).bool(message[".protocol.stable"]);
                    if (message[".protocol.testnet"] != null && Object.hasOwnProperty.call(message, ".protocol.testnet"))
                        writer.uint32(/* id 50010, wireType 0 =*/400080).bool(message[".protocol.testnet"]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        case 50000: {
                                if (!(message[".protocol.country"] && message[".protocol.country"].length))
                                    message[".protocol.country"] = [];
                                message[".protocol.country"].push(reader.string());
                                break;
                            }
                        case 50001: {
                                if (!(message[".protocol.symbol"] && message[".protocol.symbol"].length))
                                    message[".protocol.symbol"] = [];
                                message[".protocol.symbol"].push(reader.string());
                                break;
                            }
                        case 50002: {
                                if (!(message[".protocol.code"] && message[".protocol.code"].length))
                                    message[".protocol.code"] = [];
                                message[".protocol.code"].push(reader.string());
                                break;
                            }
                        case 50003: {
                                message[".protocol.name"] = reader.string();
                                break;
                            }
                        case 50004: {
                                message[".protocol.num"] = reader.uint32();
                                break;
                            }
                        case 50005: {
                                message[".protocol.unit"] = reader.uint32();
                                break;
                            }
                        case 50006: {
                                message[".protocol.crypto"] = reader.bool();
                                break;
                            }
                        case 50007: {
                                message[".protocol.unofficial"] = reader.bool();
                                break;
                            }
                        case 50008: {
                                message[".protocol.denom"] = reader.uint32();
                                break;
                            }
                        case 50009: {
                                message[".protocol.stable"] = reader.bool();
                                break;
                            }
                        case 50010: {
                                message[".protocol.testnet"] = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    if (message[".protocol.country"] != null && message.hasOwnProperty(".protocol.country")) {
                        if (!Array.isArray(message[".protocol.country"]))
                            return ".protocol.country: array expected";
                        for (var i = 0; i < message[".protocol.country"].length; ++i)
                            if (!$util.isString(message[".protocol.country"][i]))
                                return ".protocol.country: string[] expected";
                    }
                    if (message[".protocol.symbol"] != null && message.hasOwnProperty(".protocol.symbol")) {
                        if (!Array.isArray(message[".protocol.symbol"]))
                            return ".protocol.symbol: array expected";
                        for (var i = 0; i < message[".protocol.symbol"].length; ++i)
                            if (!$util.isString(message[".protocol.symbol"][i]))
                                return ".protocol.symbol: string[] expected";
                    }
                    if (message[".protocol.code"] != null && message.hasOwnProperty(".protocol.code")) {
                        if (!Array.isArray(message[".protocol.code"]))
                            return ".protocol.code: array expected";
                        for (var i = 0; i < message[".protocol.code"].length; ++i)
                            if (!$util.isString(message[".protocol.code"][i]))
                                return ".protocol.code: string[] expected";
                    }
                    if (message[".protocol.name"] != null && message.hasOwnProperty(".protocol.name"))
                        if (!$util.isString(message[".protocol.name"]))
                            return ".protocol.name: string expected";
                    if (message[".protocol.num"] != null && message.hasOwnProperty(".protocol.num"))
                        if (!$util.isInteger(message[".protocol.num"]))
                            return ".protocol.num: integer expected";
                    if (message[".protocol.unit"] != null && message.hasOwnProperty(".protocol.unit"))
                        if (!$util.isInteger(message[".protocol.unit"]))
                            return ".protocol.unit: integer expected";
                    if (message[".protocol.crypto"] != null && message.hasOwnProperty(".protocol.crypto"))
                        if (typeof message[".protocol.crypto"] !== "boolean")
                            return ".protocol.crypto: boolean expected";
                    if (message[".protocol.unofficial"] != null && message.hasOwnProperty(".protocol.unofficial"))
                        if (typeof message[".protocol.unofficial"] !== "boolean")
                            return ".protocol.unofficial: boolean expected";
                    if (message[".protocol.denom"] != null && message.hasOwnProperty(".protocol.denom"))
                        if (!$util.isInteger(message[".protocol.denom"]))
                            return ".protocol.denom: integer expected";
                    if (message[".protocol.stable"] != null && message.hasOwnProperty(".protocol.stable"))
                        if (typeof message[".protocol.stable"] !== "boolean")
                            return ".protocol.stable: boolean expected";
                    if (message[".protocol.testnet"] != null && message.hasOwnProperty(".protocol.testnet"))
                        if (typeof message[".protocol.testnet"] !== "boolean")
                            return ".protocol.testnet: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 */
                EnumValueOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumValueOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    if (object[".protocol.country"]) {
                        if (!Array.isArray(object[".protocol.country"]))
                            throw TypeError(".google.protobuf.EnumValueOptions..protocol.country: array expected");
                        message[".protocol.country"] = [];
                        for (var i = 0; i < object[".protocol.country"].length; ++i)
                            message[".protocol.country"][i] = String(object[".protocol.country"][i]);
                    }
                    if (object[".protocol.symbol"]) {
                        if (!Array.isArray(object[".protocol.symbol"]))
                            throw TypeError(".google.protobuf.EnumValueOptions..protocol.symbol: array expected");
                        message[".protocol.symbol"] = [];
                        for (var i = 0; i < object[".protocol.symbol"].length; ++i)
                            message[".protocol.symbol"][i] = String(object[".protocol.symbol"][i]);
                    }
                    if (object[".protocol.code"]) {
                        if (!Array.isArray(object[".protocol.code"]))
                            throw TypeError(".google.protobuf.EnumValueOptions..protocol.code: array expected");
                        message[".protocol.code"] = [];
                        for (var i = 0; i < object[".protocol.code"].length; ++i)
                            message[".protocol.code"][i] = String(object[".protocol.code"][i]);
                    }
                    if (object[".protocol.name"] != null)
                        message[".protocol.name"] = String(object[".protocol.name"]);
                    if (object[".protocol.num"] != null)
                        message[".protocol.num"] = object[".protocol.num"] >>> 0;
                    if (object[".protocol.unit"] != null)
                        message[".protocol.unit"] = object[".protocol.unit"] >>> 0;
                    if (object[".protocol.crypto"] != null)
                        message[".protocol.crypto"] = Boolean(object[".protocol.crypto"]);
                    if (object[".protocol.unofficial"] != null)
                        message[".protocol.unofficial"] = Boolean(object[".protocol.unofficial"]);
                    if (object[".protocol.denom"] != null)
                        message[".protocol.denom"] = object[".protocol.denom"] >>> 0;
                    if (object[".protocol.stable"] != null)
                        message[".protocol.stable"] = Boolean(object[".protocol.stable"]);
                    if (object[".protocol.testnet"] != null)
                        message[".protocol.testnet"] = Boolean(object[".protocol.testnet"]);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.uninterpretedOption = [];
                        object[".protocol.country"] = [];
                        object[".protocol.symbol"] = [];
                        object[".protocol.code"] = [];
                    }
                    if (options.defaults) {
                        object.deprecated = false;
                        object[".protocol.name"] = "";
                        object[".protocol.num"] = 0;
                        object[".protocol.unit"] = 0;
                        object[".protocol.crypto"] = false;
                        object[".protocol.unofficial"] = false;
                        object[".protocol.denom"] = 0;
                        object[".protocol.stable"] = false;
                        object[".protocol.testnet"] = false;
                    }
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    if (message[".protocol.country"] && message[".protocol.country"].length) {
                        object[".protocol.country"] = [];
                        for (var j = 0; j < message[".protocol.country"].length; ++j)
                            object[".protocol.country"][j] = message[".protocol.country"][j];
                    }
                    if (message[".protocol.symbol"] && message[".protocol.symbol"].length) {
                        object[".protocol.symbol"] = [];
                        for (var j = 0; j < message[".protocol.symbol"].length; ++j)
                            object[".protocol.symbol"][j] = message[".protocol.symbol"][j];
                    }
                    if (message[".protocol.code"] && message[".protocol.code"].length) {
                        object[".protocol.code"] = [];
                        for (var j = 0; j < message[".protocol.code"].length; ++j)
                            object[".protocol.code"][j] = message[".protocol.code"][j];
                    }
                    if (message[".protocol.name"] != null && message.hasOwnProperty(".protocol.name"))
                        object[".protocol.name"] = message[".protocol.name"];
                    if (message[".protocol.num"] != null && message.hasOwnProperty(".protocol.num"))
                        object[".protocol.num"] = message[".protocol.num"];
                    if (message[".protocol.unit"] != null && message.hasOwnProperty(".protocol.unit"))
                        object[".protocol.unit"] = message[".protocol.unit"];
                    if (message[".protocol.crypto"] != null && message.hasOwnProperty(".protocol.crypto"))
                        object[".protocol.crypto"] = message[".protocol.crypto"];
                    if (message[".protocol.unofficial"] != null && message.hasOwnProperty(".protocol.unofficial"))
                        object[".protocol.unofficial"] = message[".protocol.unofficial"];
                    if (message[".protocol.denom"] != null && message.hasOwnProperty(".protocol.denom"))
                        object[".protocol.denom"] = message[".protocol.denom"];
                    if (message[".protocol.stable"] != null && message.hasOwnProperty(".protocol.stable"))
                        object[".protocol.stable"] = message[".protocol.stable"];
                    if (message[".protocol.testnet"] != null && message.hasOwnProperty(".protocol.testnet"))
                        object[".protocol.testnet"] = message[".protocol.testnet"];
                    return object;
                };
    
                /**
                 * Converts this EnumValueOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumValueOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumValueOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumValueOptions";
                };
    
                return EnumValueOptions;
            })();
    
            protobuf.ServiceOptions = (function() {
    
                /**
                 * Properties of a ServiceOptions.
                 * @memberof google.protobuf
                 * @interface IServiceOptions
                 * @property {boolean|null} [deprecated] ServiceOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new ServiceOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceOptions.
                 * @implements IServiceOptions
                 * @constructor
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 */
                function ServiceOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.deprecated = false;
    
                /**
                 * ServiceOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new ServiceOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
                 */
                ServiceOptions.create = function create(properties) {
                    return new ServiceOptions(properties);
                };
    
                /**
                 * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceOptions message.
                 * @function verify
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 */
                ServiceOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceOptions)
                        return object;
                    var message = new $root.google.protobuf.ServiceOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.ServiceOptions} message ServiceOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ServiceOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ServiceOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.ServiceOptions";
                };
    
                return ServiceOptions;
            })();
    
            protobuf.MethodOptions = (function() {
    
                /**
                 * Properties of a MethodOptions.
                 * @memberof google.protobuf
                 * @interface IMethodOptions
                 * @property {boolean|null} [deprecated] MethodOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MethodOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodOptions.
                 * @implements IMethodOptions
                 * @constructor
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 */
                function MethodOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.deprecated = false;
    
                /**
                 * MethodOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MethodOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MethodOptions} MethodOptions instance
                 */
                MethodOptions.create = function create(properties) {
                    return new MethodOptions(properties);
                };
    
                /**
                 * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodOptions message.
                 * @function verify
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 */
                MethodOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodOptions)
                        return object;
                    var message = new $root.google.protobuf.MethodOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.MethodOptions} message MethodOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MethodOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MethodOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.MethodOptions";
                };
    
                return MethodOptions;
            })();
    
            protobuf.UninterpretedOption = (function() {
    
                /**
                 * Properties of an UninterpretedOption.
                 * @memberof google.protobuf
                 * @interface IUninterpretedOption
                 * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
                 * @property {string|null} [identifierValue] UninterpretedOption identifierValue
                 * @property {Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
                 * @property {Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
                 * @property {number|null} [doubleValue] UninterpretedOption doubleValue
                 * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
                 * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
                 */
    
                /**
                 * Constructs a new UninterpretedOption.
                 * @memberof google.protobuf
                 * @classdesc Represents an UninterpretedOption.
                 * @implements IUninterpretedOption
                 * @constructor
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 */
                function UninterpretedOption(properties) {
                    this.name = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UninterpretedOption name.
                 * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.name = $util.emptyArray;
    
                /**
                 * UninterpretedOption identifierValue.
                 * @member {string} identifierValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.identifierValue = "";
    
                /**
                 * UninterpretedOption positiveIntValue.
                 * @member {Long} positiveIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
                /**
                 * UninterpretedOption negativeIntValue.
                 * @member {Long} negativeIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UninterpretedOption doubleValue.
                 * @member {number} doubleValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.doubleValue = 0;
    
                /**
                 * UninterpretedOption stringValue.
                 * @member {Uint8Array} stringValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.stringValue = $util.newBuffer([]);
    
                /**
                 * UninterpretedOption aggregateValue.
                 * @member {string} aggregateValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.aggregateValue = "";
    
                /**
                 * Creates a new UninterpretedOption instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
                 */
                UninterpretedOption.create = function create(properties) {
                    return new UninterpretedOption(properties);
                };
    
                /**
                 * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && message.name.length)
                        for (var i = 0; i < message.name.length; ++i)
                            $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                    if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                    if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                    if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                    if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                    if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                if (!(message.name && message.name.length))
                                    message.name = [];
                                message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.identifierValue = reader.string();
                                break;
                            }
                        case 4: {
                                message.positiveIntValue = reader.uint64();
                                break;
                            }
                        case 5: {
                                message.negativeIntValue = reader.int64();
                                break;
                            }
                        case 6: {
                                message.doubleValue = reader.double();
                                break;
                            }
                        case 7: {
                                message.stringValue = reader.bytes();
                                break;
                            }
                        case 8: {
                                message.aggregateValue = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UninterpretedOption message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UninterpretedOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        if (!Array.isArray(message.name))
                            return "name: array expected";
                        for (var i = 0; i < message.name.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                            if (error)
                                return "name." + error;
                        }
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        if (!$util.isString(message.identifierValue))
                            return "identifierValue: string expected";
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                            return "positiveIntValue: integer|Long expected";
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                            return "negativeIntValue: integer|Long expected";
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        if (typeof message.doubleValue !== "number")
                            return "doubleValue: number expected";
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                            return "stringValue: buffer expected";
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        if (!$util.isString(message.aggregateValue))
                            return "aggregateValue: string expected";
                    return null;
                };
    
                /**
                 * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 */
                UninterpretedOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption();
                    if (object.name) {
                        if (!Array.isArray(object.name))
                            throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                        message.name = [];
                        for (var i = 0; i < object.name.length; ++i) {
                            if (typeof object.name[i] !== "object")
                                throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                            message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                        }
                    }
                    if (object.identifierValue != null)
                        message.identifierValue = String(object.identifierValue);
                    if (object.positiveIntValue != null)
                        if ($util.Long)
                            (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                        else if (typeof object.positiveIntValue === "string")
                            message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                        else if (typeof object.positiveIntValue === "number")
                            message.positiveIntValue = object.positiveIntValue;
                        else if (typeof object.positiveIntValue === "object")
                            message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                    if (object.negativeIntValue != null)
                        if ($util.Long)
                            (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                        else if (typeof object.negativeIntValue === "string")
                            message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                        else if (typeof object.negativeIntValue === "number")
                            message.negativeIntValue = object.negativeIntValue;
                        else if (typeof object.negativeIntValue === "object")
                            message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                    if (object.doubleValue != null)
                        message.doubleValue = Number(object.doubleValue);
                    if (object.stringValue != null)
                        if (typeof object.stringValue === "string")
                            $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                        else if (object.stringValue.length >= 0)
                            message.stringValue = object.stringValue;
                    if (object.aggregateValue != null)
                        message.aggregateValue = String(object.aggregateValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UninterpretedOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.name = [];
                    if (options.defaults) {
                        object.identifierValue = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.positiveIntValue = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.negativeIntValue = options.longs === String ? "0" : 0;
                        object.doubleValue = 0;
                        if (options.bytes === String)
                            object.stringValue = "";
                        else {
                            object.stringValue = [];
                            if (options.bytes !== Array)
                                object.stringValue = $util.newBuffer(object.stringValue);
                        }
                        object.aggregateValue = "";
                    }
                    if (message.name && message.name.length) {
                        object.name = [];
                        for (var j = 0; j < message.name.length; ++j)
                            object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        object.identifierValue = message.identifierValue;
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (typeof message.positiveIntValue === "number")
                            object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                        else
                            object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (typeof message.negativeIntValue === "number")
                            object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                        else
                            object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        object.aggregateValue = message.aggregateValue;
                    return object;
                };
    
                /**
                 * Converts this UninterpretedOption to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UninterpretedOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UninterpretedOption
                 * @function getTypeUrl
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UninterpretedOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UninterpretedOption";
                };
    
                UninterpretedOption.NamePart = (function() {
    
                    /**
                     * Properties of a NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @interface INamePart
                     * @property {string} namePart NamePart namePart
                     * @property {boolean} isExtension NamePart isExtension
                     */
    
                    /**
                     * Constructs a new NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @classdesc Represents a NamePart.
                     * @implements INamePart
                     * @constructor
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     */
                    function NamePart(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NamePart namePart.
                     * @member {string} namePart
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.namePart = "";
    
                    /**
                     * NamePart isExtension.
                     * @member {boolean} isExtension
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.isExtension = false;
    
                    /**
                     * Creates a new NamePart instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                     */
                    NamePart.create = function create(properties) {
                        return new NamePart(properties);
                    };
    
                    /**
                     * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.namePart = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.isExtension = reader.bool();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        if (!message.hasOwnProperty("namePart"))
                            throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                        if (!message.hasOwnProperty("isExtension"))
                            throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                        return message;
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NamePart message.
                     * @function verify
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NamePart.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (!$util.isString(message.namePart))
                            return "namePart: string expected";
                        if (typeof message.isExtension !== "boolean")
                            return "isExtension: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     */
                    NamePart.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                            return object;
                        var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        if (object.namePart != null)
                            message.namePart = String(object.namePart);
                        if (object.isExtension != null)
                            message.isExtension = Boolean(object.isExtension);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NamePart.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.namePart = "";
                            object.isExtension = false;
                        }
                        if (message.namePart != null && message.hasOwnProperty("namePart"))
                            object.namePart = message.namePart;
                        if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                            object.isExtension = message.isExtension;
                        return object;
                    };
    
                    /**
                     * Converts this NamePart to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NamePart.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for NamePart
                     * @function getTypeUrl
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    NamePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.UninterpretedOption.NamePart";
                    };
    
                    return NamePart;
                })();
    
                return UninterpretedOption;
            })();
    
            protobuf.SourceCodeInfo = (function() {
    
                /**
                 * Properties of a SourceCodeInfo.
                 * @memberof google.protobuf
                 * @interface ISourceCodeInfo
                 * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
                 */
    
                /**
                 * Constructs a new SourceCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a SourceCodeInfo.
                 * @implements ISourceCodeInfo
                 * @constructor
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 */
                function SourceCodeInfo(properties) {
                    this.location = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SourceCodeInfo location.
                 * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 */
                SourceCodeInfo.prototype.location = $util.emptyArray;
    
                /**
                 * Creates a new SourceCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
                 */
                SourceCodeInfo.create = function create(properties) {
                    return new SourceCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && message.location.length)
                        for (var i = 0; i < message.location.length; ++i)
                            $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.location && message.location.length))
                                    message.location = [];
                                message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SourceCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SourceCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        if (!Array.isArray(message.location))
                            return "location: array expected";
                        for (var i = 0; i < message.location.length; ++i) {
                            var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                            if (error)
                                return "location." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 */
                SourceCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo();
                    if (object.location) {
                        if (!Array.isArray(object.location))
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                        message.location = [];
                        for (var i = 0; i < object.location.length; ++i) {
                            if (typeof object.location[i] !== "object")
                                throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                            message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SourceCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.location = [];
                    if (message.location && message.location.length) {
                        object.location = [];
                        for (var j = 0; j < message.location.length; ++j)
                            object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SourceCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SourceCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SourceCodeInfo
                 * @function getTypeUrl
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SourceCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.SourceCodeInfo";
                };
    
                SourceCodeInfo.Location = (function() {
    
                    /**
                     * Properties of a Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @interface ILocation
                     * @property {Array.<number>|null} [path] Location path
                     * @property {Array.<number>|null} [span] Location span
                     * @property {string|null} [leadingComments] Location leadingComments
                     * @property {string|null} [trailingComments] Location trailingComments
                     * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                     */
    
                    /**
                     * Constructs a new Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @classdesc Represents a Location.
                     * @implements ILocation
                     * @constructor
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     */
                    function Location(properties) {
                        this.path = [];
                        this.span = [];
                        this.leadingDetachedComments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Location path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.path = $util.emptyArray;
    
                    /**
                     * Location span.
                     * @member {Array.<number>} span
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.span = $util.emptyArray;
    
                    /**
                     * Location leadingComments.
                     * @member {string} leadingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingComments = "";
    
                    /**
                     * Location trailingComments.
                     * @member {string} trailingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.trailingComments = "";
    
                    /**
                     * Location leadingDetachedComments.
                     * @member {Array.<string>} leadingDetachedComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingDetachedComments = $util.emptyArray;
    
                    /**
                     * Creates a new Location instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                     */
                    Location.create = function create(properties) {
                        return new Location(properties);
                    };
    
                    /**
                     * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.span != null && message.span.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.span.length; ++i)
                                writer.int32(message.span[i]);
                            writer.ldelim();
                        }
                        if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                        if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                        if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.path && message.path.length))
                                        message.path = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.path.push(reader.int32());
                                    } else
                                        message.path.push(reader.int32());
                                    break;
                                }
                            case 2: {
                                    if (!(message.span && message.span.length))
                                        message.span = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.span.push(reader.int32());
                                    } else
                                        message.span.push(reader.int32());
                                    break;
                                }
                            case 3: {
                                    message.leadingComments = reader.string();
                                    break;
                                }
                            case 4: {
                                    message.trailingComments = reader.string();
                                    break;
                                }
                            case 6: {
                                    if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                        message.leadingDetachedComments = [];
                                    message.leadingDetachedComments.push(reader.string());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Location message.
                     * @function verify
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Location.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.span != null && message.hasOwnProperty("span")) {
                            if (!Array.isArray(message.span))
                                return "span: array expected";
                            for (var i = 0; i < message.span.length; ++i)
                                if (!$util.isInteger(message.span[i]))
                                    return "span: integer[] expected";
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            if (!$util.isString(message.leadingComments))
                                return "leadingComments: string expected";
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            if (!$util.isString(message.trailingComments))
                                return "trailingComments: string expected";
                        if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                            if (!Array.isArray(message.leadingDetachedComments))
                                return "leadingDetachedComments: array expected";
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                if (!$util.isString(message.leadingDetachedComments[i]))
                                    return "leadingDetachedComments: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Location message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     */
                    Location.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                            return object;
                        var message = new $root.google.protobuf.SourceCodeInfo.Location();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.span) {
                            if (!Array.isArray(object.span))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                            message.span = [];
                            for (var i = 0; i < object.span.length; ++i)
                                message.span[i] = object.span[i] | 0;
                        }
                        if (object.leadingComments != null)
                            message.leadingComments = String(object.leadingComments);
                        if (object.trailingComments != null)
                            message.trailingComments = String(object.trailingComments);
                        if (object.leadingDetachedComments) {
                            if (!Array.isArray(object.leadingDetachedComments))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                            message.leadingDetachedComments = [];
                            for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                                message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Location message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.Location} message Location
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Location.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.path = [];
                            object.span = [];
                            object.leadingDetachedComments = [];
                        }
                        if (options.defaults) {
                            object.leadingComments = "";
                            object.trailingComments = "";
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.span && message.span.length) {
                            object.span = [];
                            for (var j = 0; j < message.span.length; ++j)
                                object.span[j] = message.span[j];
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            object.leadingComments = message.leadingComments;
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            object.trailingComments = message.trailingComments;
                        if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                            object.leadingDetachedComments = [];
                            for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                                object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Location to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Location.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Location
                     * @function getTypeUrl
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.SourceCodeInfo.Location";
                    };
    
                    return Location;
                })();
    
                return SourceCodeInfo;
            })();
    
            protobuf.GeneratedCodeInfo = (function() {
    
                /**
                 * Properties of a GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @interface IGeneratedCodeInfo
                 * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
                 */
    
                /**
                 * Constructs a new GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a GeneratedCodeInfo.
                 * @implements IGeneratedCodeInfo
                 * @constructor
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 */
                function GeneratedCodeInfo(properties) {
                    this.annotation = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedCodeInfo annotation.
                 * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 */
                GeneratedCodeInfo.prototype.annotation = $util.emptyArray;
    
                /**
                 * Creates a new GeneratedCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
                 */
                GeneratedCodeInfo.create = function create(properties) {
                    return new GeneratedCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.annotation != null && message.annotation.length)
                        for (var i = 0; i < message.annotation.length; ++i)
                            $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.annotation && message.annotation.length))
                                    message.annotation = [];
                                message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.annotation != null && message.hasOwnProperty("annotation")) {
                        if (!Array.isArray(message.annotation))
                            return "annotation: array expected";
                        for (var i = 0; i < message.annotation.length; ++i) {
                            var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                            if (error)
                                return "annotation." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 */
                GeneratedCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo();
                    if (object.annotation) {
                        if (!Array.isArray(object.annotation))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                        message.annotation = [];
                        for (var i = 0; i < object.annotation.length; ++i) {
                            if (typeof object.annotation[i] !== "object")
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                            message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.annotation = [];
                    if (message.annotation && message.annotation.length) {
                        object.annotation = [];
                        for (var j = 0; j < message.annotation.length; ++j)
                            object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GeneratedCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GeneratedCodeInfo
                 * @function getTypeUrl
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GeneratedCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo";
                };
    
                GeneratedCodeInfo.Annotation = (function() {
    
                    /**
                     * Properties of an Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @interface IAnnotation
                     * @property {Array.<number>|null} [path] Annotation path
                     * @property {string|null} [sourceFile] Annotation sourceFile
                     * @property {number|null} [begin] Annotation begin
                     * @property {number|null} [end] Annotation end
                     */
    
                    /**
                     * Constructs a new Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @classdesc Represents an Annotation.
                     * @implements IAnnotation
                     * @constructor
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     */
                    function Annotation(properties) {
                        this.path = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Annotation path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.path = $util.emptyArray;
    
                    /**
                     * Annotation sourceFile.
                     * @member {string} sourceFile
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.sourceFile = "";
    
                    /**
                     * Annotation begin.
                     * @member {number} begin
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.begin = 0;
    
                    /**
                     * Annotation end.
                     * @member {number} end
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.end = 0;
    
                    /**
                     * Creates a new Annotation instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                     */
                    Annotation.create = function create(properties) {
                        return new Annotation(properties);
                    };
    
                    /**
                     * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                        if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.path && message.path.length))
                                        message.path = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.path.push(reader.int32());
                                    } else
                                        message.path.push(reader.int32());
                                    break;
                                }
                            case 2: {
                                    message.sourceFile = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.begin = reader.int32();
                                    break;
                                }
                            case 4: {
                                    message.end = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Annotation message.
                     * @function verify
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Annotation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            if (!$util.isString(message.sourceFile))
                                return "sourceFile: string expected";
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            if (!$util.isInteger(message.begin))
                                return "begin: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     */
                    Annotation.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                            return object;
                        var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.sourceFile != null)
                            message.sourceFile = String(object.sourceFile);
                        if (object.begin != null)
                            message.begin = object.begin | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Annotation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.path = [];
                        if (options.defaults) {
                            object.sourceFile = "";
                            object.begin = 0;
                            object.end = 0;
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            object.sourceFile = message.sourceFile;
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            object.begin = message.begin;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this Annotation to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Annotation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Annotation
                     * @function getTypeUrl
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Annotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo.Annotation";
                    };
    
                    return Annotation;
                })();
    
                return GeneratedCodeInfo;
            })();
    
            return protobuf;
        })();
    
        return google;
    })();

    return $root;
});
