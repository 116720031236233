import { protocol } from '../../api/proto';
import { round } from './rount';
import { formatUnits, parseUnits } from '@ethersproject/units';

export function getChainFullName(currency: protocol.Chain) {
  switch (currency as protocol.Chain) {
    case protocol.Chain.BTC:
      return 'Bitcoin';
    case protocol.Chain.ETH:
      return 'Etherium';
    case protocol.Chain.TRX:
      return 'Tron';
    default:
      return '';
  }
}

export function getChainShortName(currency: protocol.Chain) {
  switch (currency as protocol.Chain) {
    case protocol.Chain.BTC:
      return 'BTC';
    case protocol.Chain.ETH:
      return 'ETH';
    case protocol.Chain.TRX:
      return 'TRX';
    default:
      return '';
  }
}

export function chainShortNameToChain(chainString: string) {
  switch (chainString) {
    case 'BTC':
      return protocol.Chain.BTC;
    case 'ETH':
      return protocol.Chain.ETH;
    case 'TRX':
      return protocol.Chain.TRX;
    default:
      return '';
  }
}
export function chainToCurrency(currency: protocol.Chain) {
  switch (currency as protocol.Chain) {
    case protocol.Chain.BTC:
      return protocol.WLCurrency.WLC_BTC;
    case protocol.Chain.ETH:
      return protocol.WLCurrency.WLC_ETH;
    case protocol.Chain.TRX:
      return protocol.WLCurrency.WLC_TRX;
  }
}

export function getWlCurrencyCode(currency: protocol.WLCurrency, short?: boolean) {
  switch (currency) {
    case protocol.WLCurrency.WLC_USD:
      return short ? '$' : 'USD';
    case protocol.WLCurrency.WLC_EUR:
      return short ? '€' : 'EUR';
    case protocol.WLCurrency.WLC_USDT:
      return 'USDT';
    case protocol.WLCurrency.WLC_USDTERC20:
      return 'USDT ERC20';
    case protocol.WLCurrency.WLC_USDTTRC20:
      return 'USDT TRC20';
    default:
      return protocol.WLCurrency[currency].replace('WLC_', '');
  }
}

export const currenciesWithIcons = [
  protocol.WLCurrency.WLC_BTC,
  protocol.WLCurrency.WLC_TRX,
  protocol.WLCurrency.WLC_ETH,
  protocol.WLCurrency.WLC_USDTERC20,
  protocol.WLCurrency.WLC_USDTTRC20,
];

export function getWlCurrencyCodeForIcon(currency: protocol.WLCurrency) {
  if (currenciesWithIcons.includes(currency)) {
    return protocol.WLCurrency[currency].replace('WLC_', '')?.toLowerCase();
  }
  return null;
}

export function formatCurrency(value: string, currency: protocol.WLCurrency, removeCurrencyCode?: boolean): string {
  const currencyCode = getWlCurrencyCode(currency);
  if (value === '0') {
    return removeCurrencyCode ? '0' : `0 ${currencyCode}`;
  } else {
    let denom = getDenomitation(currency);
    const currencyValue = getCurrencyValue(value, denom, currency, currencyCode);

    return removeCurrencyCode ? currencyValue : `${currencyValue} ${currencyCode}`;
  }
}
function getCurrencyValue(value: string, denom: number, currency: protocol.WLCurrency, currencyCode: string) {
  const denomValue = formatUnits(value, denom).replace(new RegExp(/.0$/), '');

  if (denomValue !== '0') {
    if (currency === protocol.WLCurrency.WLC_USD) {
      return numberWithSpaces(denomValue);
    }
    /*if (roundSomeCurrenciesValues) {
      if (
        currency === protocol.WLCurrency.WLC_USDTERC20 ||
        currency === protocol.WLCurrency.WLC_USDTTRC20 ||
        currency === protocol.WLCurrency.WLC_TRX
      ) {
        return String(round(Number(denomValue), 3));
      }
      if (currency === protocol.WLCurrency.WLC_ETH) {
        return String(round(Number(denomValue), 8));
      }
    }*/
    return denomValue;
  }
  return '';
}

function getDenomitation(currency: protocol.WLCurrency) {
  switch (currency) {
    case protocol.WLCurrency.WLC_USD:
      return 2;
    case protocol.WLCurrency.WLC_BTC:
      return 8;
    case protocol.WLCurrency.WLC_ETH:
    case protocol.WLCurrency.WLC_WETHERC20:
      return 18;
    case protocol.WLCurrency.WLC_TRX:
      return 6;
    case protocol.WLCurrency.WLC_USDTTRC20:
    case protocol.WLCurrency.WLC_USDTERC20:
    case protocol.WLCurrency.WLC_USDCERC20:
      return 6;
    default:
      return 8;
  }
}

export function denominateValue(value: string, denom: number, currencyCode: string): string {
  if (!value || !currencyCode) {
    return '0';
  }

  let strValue = value!.toString();

  if (strValue === '0') {
    return '0';
  }

  const isNegative = strValue[0] === '-';

  if (isNegative) {
    strValue = strValue.substring(1);
  }

  if (strValue.length <= denom) {
    const zerosCount = denom - strValue.length + 1;
    for (let i = 0; i < zerosCount; i++) {
      strValue = '0' + strValue;
    }
  }
  const leadingSign = isNegative ? '-' : '';

  return leadingSign + strValue.substr(0, strValue.length - denom) + '.' + strValue.substr(strValue.length - denom);
}

function numberWithSpaces(num: string) {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export const chainOptions = [
  {
    label: 'BTC',
    value: protocol.Chain.BTC,
  },
  {
    label: 'ETH',
    value: protocol.Chain.ETH,
  },
  {
    label: 'TRX',
    value: protocol.Chain.TRX,
  },
];
