import { Icon } from '../../Icon/Icon';
import styles from './TokenListItem.module.scss';

export const TokenListItem = () => {
  return (
    <div className={styles.tokenListItem}>
      <div className={styles.tokenListItemLeft}>
        <Icon iconName="btc-icon" widthInPx={16} heightInPx={16} />
        <div className={styles.tokenListItemName}>Bitcoin</div>
        <div className={styles.tokenListItemCode}>BTC</div>
      </div>
      <div className={styles.tokenListItemRight}>
        <div className={styles.tokenListItemPrice}>$36,000.00</div>
        <div className={styles.tokenListItemPriceDiff}>+12.36%</div>
      </div>
    </div>
  );
};
