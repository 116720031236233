import ReactPaginate from 'react-paginate';
import './Pagination.scss';
import { useEffect, useState } from 'react';

type PaginationProps = {
  onPageChange: (page: number) => void;
  pageCount: number;
  page: number;
  nextButtonText?: string;
  prevButtonText?: string;
  saveScrollPosition?: boolean;
  isLoading?: boolean;
};

export const Pagination = ({
  onPageChange,
  pageCount,
  page,
  nextButtonText,
  prevButtonText,
  saveScrollPosition,
  isLoading,
}: PaginationProps) => {
  const [scrollYPos, setScrollYPos] = useState(0);
  const onChange = (page: number) => {
    if (saveScrollPosition) setScrollYPos(window.scrollY);
    onPageChange(page);
  };
  useEffect(() => {
    console.debug('isLoading', isLoading, saveScrollPosition);

    if (!isLoading && saveScrollPosition) {
      console.debug('ok', scrollYPos);
      window.scrollTo(0, scrollYPos);
    }
  }, [isLoading]);
  return (
    <div className="Pagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <span>
            <span className="next-button">{nextButtonText ?? 'next'} </span>
            {'>'}
          </span>
        }
        onPageChange={(selectedItem: { selected: number }) => onChange(selectedItem.selected)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={
          <span>
            {'<'}
            <span className="previous-button"> {prevButtonText ?? 'previous'}</span>
          </span>
        }
        renderOnZeroPageCount={undefined}
        forcePage={page}
      />
    </div>
  );
};
