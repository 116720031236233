import { Link } from 'react-router-dom';
import { TxOperationFormatted } from '../../state/transactions/Transaction';
import { getShortAddress } from '../../state/utils/getShortAddress';
import { CopyButton } from '../controls/CopyButton/CopyButton';
import { Icon } from '../Icon/Icon';
import { CurrencyIcon } from '../CurrencyIcon/CurrencyIcon';
import './TransactionInsOuts.scss';
import cn from 'classnames';

export const TransactionInsOuts = ({
  ins,
  outs,
  withoutAmounts,
  small,
}: {
  ins: TxOperationFormatted[];
  outs: TxOperationFormatted[];
  withoutAmounts?: boolean;
  small?: boolean;
}) => {
  return (
    <div className={cn('TransactionInsOuts', { smallView: !!small })}>
      <div className="TransactionInsOuts__ins">
        <div className="InfoItem">
          <div className="InfoItem__title margin-b-4">From</div>
          {ins?.map(_ => (
            <div className="margin-b-4" key={_.address}>
              <div className={cn('InfoItem__value', { big: !small })}>
                {_.isValidAddress ? (
                  <Link to={`/address/${_.address}`}>{getShortAddress(_.address)}</Link>
                ) : (
                  <span>{_.address}</span>
                )}{' '}
                <CopyButton contentForCopy={_.address} />
              </div>
              {!withoutAmounts && _.amount ? (
                <div className="InfoItem__value gray nowrap">
                  {_.amount}
                  <span>{_.currency ? <CurrencyIcon size="sm" currency={_.currency} /> : null}</span>
                  <span>{_.currencyFormatted}</span>
                  {_.amountUsdFormatted ? <div className="InfoItem__value__gray"> · {_.amountUsdFormatted}</div> : null}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className="TransactionInsOuts__divider">
        <Icon className="arrow" iconName="transfer-arrow" widthInPx={24} heightInPx={24} />
      </div>
      <div className="TransactionInsOuts__outs">
        <div className="InfoItem ">
          <div className="InfoItem__title margin-b-4">To</div>
          {outs?.map(_ => (
            <div className="margin-b-4" key={_.address}>
              <div className={cn('InfoItem__value', { big: !small })}>
                {_.isValidAddress ? (
                  <Link to={`/address/${_.address}`}>{getShortAddress(_.address)}</Link>
                ) : (
                  <span>{_.address}</span>
                )}{' '}
                <CopyButton contentForCopy={_.address} />
              </div>
              {!withoutAmounts ? (
                <div className="InfoItem__value gray nowrap">
                  {_.amount}
                  <span>{_.currency ? <CurrencyIcon size="sm" currency={_.currency} /> : null}</span>
                  <span>{_.currencyFormatted}</span>
                  {_.amountUsdFormatted ? <div className="InfoItem__value__gray"> · {_.amountUsdFormatted}</div> : null}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
