import { observer } from 'mobx-react';
import { useStore } from '../../state/AppState';
import { FullPageLoader } from '../Loader/Loader';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import { BackButton } from '../controls/BackButton/BackButton';
import './SearchBlock.scss';

export const SearchBlock = observer(() => {
  const store = useStore();
  const { searchStore } = store;

  return (
    <div className="SearchBlock">
      {searchStore.isLoading ? (
        <FullPageLoader />
      ) : (
        <ContentBlock className="margin-b-16 SearchContainer">
          <div className="SearchContent">
            <div className="SearchContent__notFoundTitle">Oops! We couldn't find what you are looking for.</div>
            <div className="SearchContent__notFoundDesc margin-b-16">Please enter an address or transaction hash</div>
            <BackButton onClick={searchStore.reset} />
          </div>
        </ContentBlock>
      )}
    </div>
  );
});
