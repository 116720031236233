import { makeObservable, observable, action, makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { v4 as uuid } from 'uuid';
import { protocol } from '../api/proto';
import Long from 'long';
import { Block } from './Block';
import { Transaction } from './transactions/Transaction';

export class TransactionsStore {
  private appState: AppState;

  searchText: string = '';
  selectedChain: protocol.Chain = protocol.Chain.BTC;
  isLoading: boolean = false;
  isSearchActive: boolean = false;

  txs?: Transaction[] = [];

  constructor(appState: AppState) {
    makeAutoObservable(this);
    this.appState = appState;
  }

  onChangeSearchText = (val: string) => {
    this.searchText = val;
    if (val === '') {
      this.isSearchActive = false;
      this.loadTxs();
    }
  };

  onChangeSelectedChain = (chain: protocol.Chain) => {
    this.selectedChain = chain;
    if (this.isSearchActive) {
      this.search();
    } else {
      this.loadTxs();
    }
  };

  resetSearchText = () => {
    this.searchText = '';
  };

  search = async () => {
    if (this.searchText?.length) {
      this.isSearchActive = true;
      this.txs = [];
      this.isLoading = true;
      const res = await this.appState.api.getTxByHashRequest({
        txHashes: [this.searchText],
      });
      if (res?.error) {
      } else if (res.getTxByHash?.txs?.length) {
        this.txs = [new Transaction(res.getTxByHash?.txs[0])];
      }
      this.isLoading = false;
    }
  };

  initPage = () => {
    if (!this.isSearchActive) {
      this.loadTxs();
    }
  };

  loadTxs = async () => {
    this.isLoading = true;
    this.txs = [];
    const res = await this.appState.api.getBlockByHeight({
      chain: this.selectedChain,
      heights: [Long.fromNumber(1)],
    });
    if (res?.error) {
    } else if (res.getBlockByHeight?.blocks?.length) {
      const firstBlock = res.getBlockByHeight?.blocks[0];
      const lastBlock = firstBlock.blockConfirmations?.notEquals(0) ? firstBlock.blockConfirmations : null;
      if (lastBlock) {
        const res = await this.appState.api.getBlockByHeight({
          chain: this.selectedChain,
          heights: [lastBlock],
          full: true,
          pagination: {
            limit: Long.fromNumber(50),
            offset: Long.fromNumber(0),
          },
        });
        if (res.getBlockByHeight?.blocks?.length) {
          this.txs = res.getBlockByHeight?.blocks[0]?.transactions?.map(_ => new Transaction(_)) ?? [];
        }
      }
    }
    this.isLoading = false;
  };

  reset = () => {
    this.isLoading = false;
    this.selectedChain = protocol.Chain.BTC;
    this.resetSearchText();
    this.txs = [];
    this.isSearchActive = false;
  };
}
