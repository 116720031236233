import { protocol } from '../../api/proto';
import { getWlCurrencyCodeForIcon } from '../../state/utils/Money';
import { Icon } from '../Icon/Icon';

type CurrencyIconProps = {
  currency: protocol.WLCurrency;
  size?: 'sm' | 'md' | 'lg';
};

export const CurrencyIcon = ({ currency, size }: CurrencyIconProps) => {
  let width = 16;
  let height = 16;
  let wlCurrencyForIcon = getWlCurrencyCodeForIcon(currency);
  if (!wlCurrencyForIcon) return null;
  if (currency === protocol.WLCurrency.WLC_USDTERC20 || currency === protocol.WLCurrency.WLC_USDTTRC20) {
    width = 27;
    height = 16;
  }
  if (size === 'lg') {
    height = 24;
    width = 24;
    if (currency === protocol.WLCurrency.WLC_USDTERC20 || currency === protocol.WLCurrency.WLC_USDTTRC20) {
      width = 36;
      height = 22;
    }
  }
  if (size === 'sm') {
    height = 12;
    width = 12;
    if (currency === protocol.WLCurrency.WLC_USDTERC20 || currency === protocol.WLCurrency.WLC_USDTTRC20) {
      width = 20;
      height = 12;
    }
  }

  return <Icon iconName={`${wlCurrencyForIcon}-icon`} widthInPx={width ?? 24} heightInPx={height ?? 24} />;
};
