import { makeObservable, observable, action, makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { v4 as uuid } from 'uuid';
import { protocol } from '../api/proto';
import Long from 'long';
import { Block } from './Block';
import { Transaction } from './transactions/Transaction';

export class MainPageStore {
  private appState: AppState;

  blocksLoading: boolean = false;
  txsLoading: boolean = false;

  blocksChain: protocol.Chain = protocol.Chain.BTC;
  txsChain: protocol.Chain = protocol.Chain.BTC;

  blocks: Block[] = [];
  txs: Transaction[] = [];

  updateInterval?: number = undefined;

  constructor(appState: AppState) {
    makeAutoObservable(this);
    this.appState = appState;
  }

  initPage = () => {
    this.loadBlocks();
    this.loadTxs();
  };

  onChangeBlocksChain = (chain: protocol.Chain) => {
    this.blocksChain = chain;
    clearInterval(this.updateInterval);
    this.loadBlocks();
  };

  onChangeTxsChain = (chain: protocol.Chain) => {
    this.txsChain = chain;
    this.loadTxs();
  };

  loadBlocks = async () => {
    this.blocksLoading = true;
    this.blocks = [];
    const res = await this.appState.api.getBlockByHeight({
      chain: this.blocksChain,
      heights: [Long.fromNumber(1)],
    });
    if (res?.error) {
    } else if (res.getBlockByHeight?.blocks?.length) {
      const firstBlock = res.getBlockByHeight?.blocks[0];
      const lastBlock = firstBlock.blockConfirmations?.notEquals(0) ? firstBlock.blockConfirmations : null;
      if (lastBlock) {
        let blocks = [];
        if (lastBlock.greaterThan(15)) {
          for (let i = 0; i < 15; i++) {
            blocks.push(lastBlock.subtract(i));
          }
        }
        const res = await this.appState.api.getBlockByHeight({
          chain: this.blocksChain,
          heights: blocks,
        });
        if (res.getBlockByHeight?.blocks?.length) {
          this.blocks = res.getBlockByHeight?.blocks?.map(_ => new Block(_, true));
        }
      }
    }
    this.blocksLoading = false;
    this.updateInterval = window.setInterval(async () => {
      const latestBlockHeight = this.blocks?.length ? this.blocks[0].blockHeight : null;
      if (latestBlockHeight) {
        const res = await this.appState.api.getBlockByHeight({
          chain: this.blocksChain,
          heights: [latestBlockHeight.add(1)],
        });
        if (res.getBlockByHeight?.blocks?.length) {
          this.blocks.unshift(new Block(res.getBlockByHeight.blocks[0]));
          this.blocks.pop();
        }
      }
    }, 5000);
  };

  loadTxs = async () => {
    this.txsLoading = true;
    this.txs = [];
    const res = await this.appState.api.getBlockByHeight({
      chain: this.txsChain,
      heights: [Long.fromNumber(1)],
    });
    if (res?.error) {
    } else if (res.getBlockByHeight?.blocks?.length) {
      const firstBlock = res.getBlockByHeight?.blocks[0];
      const lastBlock = firstBlock.blockConfirmations?.notEquals(0) ? firstBlock.blockConfirmations : null;
      if (lastBlock) {
        const res = await this.appState.api.getBlockByHeight({
          chain: this.txsChain,
          heights: [lastBlock],
          full: true,
          pagination: {
            limit: Long.fromNumber(35),
            offset: Long.fromNumber(0),
          },
        });
        if (res.getBlockByHeight?.blocks?.length) {
          this.txs = res.getBlockByHeight?.blocks[0]?.transactions?.map(_ => new Transaction(_)) ?? [];
        }
      }
    }
    this.txsLoading = false;
  };

  reset = () => {
    clearInterval(this.updateInterval);
  };
}
