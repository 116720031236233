import { observer } from 'mobx-react';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { Input } from '../../components/controls/Input/Input';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { LatestBlocks } from '../../components/home-page/LatestBlocks/LatestBlocks';
import { LatestTransactions } from '../../components/home-page/LatestTransactions/LatestTransactions';
import './HomePage.scss';
import cn from 'classnames';
import { useStore } from '../../state/AppState';
import { useEffect } from 'react';

export const HomePage = observer(() => {
  const store = useStore();
  const { mainPageStore } = store;
  useEffect(() => {
    mainPageStore.initPage();
    return () => {
      mainPageStore.reset();
    };
  }, []);
  return (
    <PageContainer>
      <MainSearchInput />
      {/* <div className={cn('topContentBlock', 'contentBlock')}>
        <TokensBlock />
        <PriceBlock />
      </div>*/}
      <div className={'bottomContentBlocks'}>
        <div className={cn('contentBlock', 'blockWrapper')}>
          <LatestTransactions />
        </div>
        <div className={cn('contentBlock', 'blockWrapper')}>
          <LatestBlocks />
        </div>
      </div>
    </PageContainer>
  );
});
