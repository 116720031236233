import ReactDatePicker from 'react-datepicker';
import './DatePicker.scss';
import { ReactNode } from 'react';

interface IDatePickerProps {
  dateRange?: [Date | null, Date | null];
  setDateRange?: (range: Date | [Date | null, Date | null] | null) => void;
  topElement?: ReactNode;
}
export const DatePicker = (props: IDatePickerProps) => {
  const [startDate, endDate] = props.dateRange || [];

  return (
    <div className="DatePicker">
      {props.topElement}
      <ReactDatePicker
        inline
        selectsRange
        startDate={startDate ?? undefined}
        endDate={endDate ?? undefined}
        onChange={update => {
          if (props.setDateRange) props.setDateRange(update);
        }}
      />
    </div>
  );
};
