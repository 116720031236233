import './LatestTransactions.scss';
import { Button } from '../../controls/Button/Button';
import { CurrencySelect } from '../../controls/CurrencySelect/CurrencySelect';
import { protocol } from '../../../api/proto';
import { observer } from 'mobx-react';
import { useStore } from '../../../state/AppState';
import { useNavigate } from 'react-router';
import { ContentBlockTitle } from '../../ContentBlock/ContentBlockTitle';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

export const LatestTransactions = observer(() => {
  const store = useStore();
  const { mainPageStore } = store;
  const navigate = useNavigate();

  return (
    <div className={'latestTransactions'}>
      <div className={'contentBlockHead'}>
        <ContentBlockTitle>Latest transactions</ContentBlockTitle>
        <Button
          onClick={() => navigate('/transactions')}
          label={window.innerWidth < 420 ? 'All' : 'All Transactions'}
        />
      </div>
      <CurrencySelect chain={mainPageStore.txsChain} onChange={mainPageStore.onChangeTxsChain} />
      <div className="latestTransactionsListContainer">
        <div className={'latestTransactionsList'}>
          {mainPageStore.txsLoading
            ? Array.from(Array(30)).map(item => (
                <div className="margin-b-16">
                  <Skeleton width={'100%'} height={18} />
                </div>
              ))
            : mainPageStore.txs.map(tx => (
                <div key={tx.hash} className={'latestTransactionsItem'}>
                  <div>
                    <div className="latestTransactionsItemLeft">
                      <Link to={`/transaction/${tx.hash}`}>
                        <div className="hash">{tx.shortHash}</div>
                      </Link>
                      <div>{tx.timeFormatted}</div>
                    </div>
                  </div>
                  <div className="latestTransactionsItemRight amount">
                    {tx.amountFormatted} {tx.amountCurrencyFormatted}
                    <div className="latestTransactionsItemRight fiatAmountSecond">{tx.amountUsdFormatted}</div>
                  </div>
                  <div className="latestTransactionsItemRight fiatAmount">{tx.amountUsdFormatted}</div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
});
