import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const TableLoader = ({ itemsInRow }: { itemsInRow: number }) => {
  return (
    <tbody>
      {Array.from(Array(10)).map(_ => (
        <tr>
          {Array.from(Array(itemsInRow)).map(item => (
            <td>
              <Skeleton width={'100%'} height={18} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
