import { makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { Transaction } from './transactions/Transaction';

export class TransactionStore {
  private appState: AppState;

  txHash?: string = undefined;
  txLoading: boolean = false;

  txInfo?: Transaction = undefined;
  error?: string;

  constructor(appState: AppState) {
    this.appState = appState;
    makeAutoObservable(this);
  }

  init = async (txHash?: string) => {
    this.reset();
    if (txHash) {
      this.txHash = txHash;
      this.txLoading = true;
      const res = await this.appState.api.getTxByHashRequest({ txHashes: [txHash] });
      if (res.error) {
        this.error = res.error;
      } else if (res.getTxByHash?.txs?.length) {
        this.txInfo = new Transaction(res.getTxByHash.txs[0]);
      }
      this.txLoading = false;
    }
  };

  reset = () => {
    this.txHash = undefined;
    this.txLoading = false;
    this.txInfo = undefined;
  };
}
