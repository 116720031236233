import { ReactNode } from 'react';
import './Loader.scss';
import cn from 'classnames';

export const Loader = ({ size }: { size?: 'small' | 'normal' | 'big' }) => {
  return (
    <div className={cn('lds-ring', size)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const FullWidthLoader = ({ content }: { content?: ReactNode }) => {
  return (
    <div className="full-width-loader">
      <div className="full-width-loader__wrapper">
        <Loader />
        {content}
      </div>
    </div>
  );
};

export const FullPageLoader = ({ content }: { content?: ReactNode }) => {
  return (
    <div className="full-page-loader">
      <FullWidthLoader content={content} />
    </div>
  );
};
