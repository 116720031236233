import { makeAutoObservable } from 'mobx';
import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import APIConnector from '../api/APIConnector';
import { TransactionStore } from './TransactionStore';
import { NotificationsState } from './NotificationsStore';
import { AddressStore } from './AddressStore';
import { SearchStore } from './SearchStore';
import { NavigateFunction } from 'react-router';
import { BlocksStore } from './BlocksStore';
import { BlockPageStore } from './BlockPageStore';
import { MainPageStore } from './MainPageStore';
import { TransactionsStore } from './TransactionsStore';

export class AppState {
  navigate?: NavigateFunction = undefined;
  currentRoute?: string = undefined;

  api: APIConnector = new APIConnector(this);
  notifications: NotificationsState = new NotificationsState(this);

  mainPageStore = new MainPageStore(this);
  transactionStore = new TransactionStore(this);
  addressStore = new AddressStore(this);
  searchStore = new SearchStore(this);
  blocksStore = new BlocksStore(this);
  blockPageStore = new BlockPageStore(this);
  transactionsStore = new TransactionsStore(this);

  constructor() {
    makeAutoObservable(this);
  }

  initNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate;
  };

  onChangeRoute = (path: string) => {
    this.currentRoute = path;
  };
}

export const store = new AppState();

export function useStore(): AppState {
  return React.useContext(MobXProviderContext).store;
}
