import { protocol } from '../../../api/proto';
import useComponentVisible from '../../../hooks/useComponentVisible';
import {
  chainOptions,
  chainToCurrency,
  getChainFullName,
  getChainShortName,
  getWlCurrencyCodeForIcon,
} from '../../../state/utils/Money';
import { Icon } from '../../Icon/Icon';
import './CurrencySelect.scss';
import cn from 'classnames';

export const CurrencySelect = ({
  chain,
  onChange,
  className,
}: {
  chain: protocol.Chain;
  onChange?: (chain: protocol.Chain) => void;
  className?: string;
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const currency = chainToCurrency(chain);
  const icon = getWlCurrencyCodeForIcon(currency);
  const fullName = getChainFullName(chain);
  const shortName = getChainShortName(chain);

  const toggleDropdown = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const onOptionClick = (chain: protocol.Chain) => {
    toggleDropdown();
    if (onChange) onChange(chain);
  };

  return (
    <div ref={ref} className={cn('CurrencySelectContainer', className)}>
      <div className="CurrencySelect" onClick={toggleDropdown}>
        <div className="CurrencySelectMain">
          <Icon iconName={`${icon}-icon`} widthInPx={32} heightInPx={32} />
          <div className="CurrencySelectName">{fullName}</div>
          <div className="CurrencySelectCode">{shortName}</div>
        </div>
        <div className="CurrencySelectDropdownIcon">
          <Icon iconName="dropdown" widthInPx={24} heightInPx={24} />
        </div>
      </div>
      {isComponentVisible ? (
        <div className="CurrencySelectDropdown">
          {chainOptions.map(_ => (
            <ChainOption onClick={onOptionClick} chain={_.value} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

const ChainOption = ({ chain, onClick }: { chain: protocol.Chain; onClick: (chain: protocol.Chain) => void }) => {
  const currency = chainToCurrency(chain);
  const icon = getWlCurrencyCodeForIcon(currency);
  const fullName = getChainFullName(chain);
  const shortName = getChainShortName(chain);

  return (
    <div className="ChainOption" onClick={() => onClick(chain)}>
      <Icon iconName={`${icon}-icon`} widthInPx={32} heightInPx={32} />
      <div className="CurrencySelectName">{fullName}</div>
      <div className="CurrencySelectCode">{shortName}</div>
    </div>
  );
};
