import { ReactElement } from 'react';
import './ContentBlock.scss';
import cn from 'classnames';

export const ContentBlock = ({
  children,
  className,
}: {
  children: ReactElement | ReactElement[];
  className?: string;
}) => {
  return <div className={cn('ContentBlock', className)}>{children}</div>;
};
