import { makeObservable, observable, action, makeAutoObservable } from 'mobx';
import { AppState } from './AppState';
import { v4 as uuid } from 'uuid';
import { protocol } from '../api/proto';
import Long from 'long';
import { Block } from './Block';
import { chainShortNameToChain } from './utils/Money';
import { Transaction } from './transactions/Transaction';

const LIMIT_PAGINATION = 10;

export class BlockPageStore {
  private appState: AppState;

  height: string = '';
  chain: protocol.Chain = protocol.Chain.BTC;
  isLoading: boolean = false;

  block?: Block = undefined;
  transactions: Transaction[] = [];

  txsPage: number = 0;
  txsTotalPages: number = 0;
  totalCountTxs: number = 0;

  constructor(appState: AppState) {
    makeAutoObservable(this);
    this.appState = appState;
  }

  changePage = (page: number) => {
    this.txsPage = page;
    this.fetchBlockInfo();
  };

  init = async (chainString: string, height: string) => {
    const chain = chainShortNameToChain(chainString);
    if (chain !== '' && height !== '') {
      this.chain = chain;
      this.height = height;
      this.fetchBlockInfo();
    }
  };

  fetchBlockInfo = async () => {
    this.isLoading = true;
    const res = await this.appState.api.getBlockByHeight({
      chain: this.chain,
      heights: [Long.fromString(this.height)],
      full: true,
      pagination: {
        limit: Long.fromNumber(LIMIT_PAGINATION),
        offset: Long.fromNumber(this.txsPage * LIMIT_PAGINATION),
      },
    });
    if (res?.error) {
    } else if (res.getBlockByHeight?.blocks?.length) {
      this.block = new Block(res.getBlockByHeight?.blocks[0]);
      if (res.getBlockByHeight?.blocks[0]?.transactions?.length) {
        this.transactions = res.getBlockByHeight?.blocks[0]?.transactions.map(_ => new Transaction(_));
      }
      this.totalCountTxs = this.block.txCount ?? 0;
      this.txsTotalPages = Math.ceil(this.totalCountTxs / LIMIT_PAGINATION);
    }
    this.isLoading = false;
  };

  reset = () => {
    this.txsPage = 0;
    this.txsTotalPages = 0;
    this.totalCountTxs = 0;
    this.isLoading = false;
    this.chain = protocol.Chain.BTC;
    this.block = undefined;
    this.transactions = [];
  };
}
