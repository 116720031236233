export enum RoutesList {
  MAIN_PAGE = '/',
  TOKENS = '/tokens',
  TRANSACTIONS = '/transactions',
  BLOCKS = '/blocks',
  BLOCK = '/blocks/:chain/:blockHeight',
  GRAPHS = '/graphs',
  NFTS = '/nfts',
  TRANSACTION = '/transaction/:txHash',
  ADDRESS = '/address/:address',
}
