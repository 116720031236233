import stylesIcon from './Icon.module.scss';
import cn from 'classnames';

export interface IconProps {
  iconName?: string;
  heightInPx?: number;
  widthInPx?: number;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Icon = (props: IconProps) => {
  if (props.iconName) {
    let styles: { [key: string]: string | number } = {
      backgroundImage: `url(/images/icons/${props.iconName}.svg)`,
    };
    if (props.heightInPx) {
      styles['minHeight'] = props.heightInPx;
      styles['height'] = props.heightInPx;
    }
    if (props.widthInPx) {
      styles['minWidth'] = props.widthInPx;
      styles['width'] = props.widthInPx;
    }
    return (
      <div
        style={styles}
        onClick={e => (props.onClick ? props.onClick(e) : undefined)}
        className={cn(stylesIcon.icon, props.className)}
      />
    );
  }
  return null;
};
