import { Icon, IconProps } from '../../Icon/Icon';
import './IconButton.scss';

interface IconButtonProps extends IconProps {
  label?: string;
}

export const IconButton = (props: IconButtonProps) => {
  return (
    <div className="IconButton">
      <Icon {...props} />
      {props.label ? <span className="label">{props.label}</span> : null}
    </div>
  );
};
