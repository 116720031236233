import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { Input } from '../../components/controls/Input/Input';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { TokenListItem } from '../../components/tokens/TokenListItem/TokenListItem';
import styles from './TokensPage.module.scss';

export const TokensPage = () => {
  return (
    <PageContainer>
      <MainSearchInput />
      <ContentBlock>
        <div className={styles.tokensTitle}>Tokens</div>
        <Input
          classNames={styles.tokensSearchInput}
          iconName="search"
          onChange={() => {}}
          placeholder="Search tokens"
        />
        <div className={styles.tokensList}>
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
          <TokenListItem />
        </div>
      </ContentBlock>
    </PageContainer>
  );
};
