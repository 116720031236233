import React from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import './PageContainer.scss';

export const PageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={'PageContainer'}>
      <Sidebar />
      <div className={'ContentContainer'}>{children}</div>
    </div>
  );
};
