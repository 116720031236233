import { useNavigate } from 'react-router';
import { Button } from '../Button/Button';
import cn from 'classnames';

type BackButtonProps = {
  className?: string;
  link?: string;
  onClick?: () => void;
};

export const BackButton = ({ className, link, onClick }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      className={cn('BackButton', className)}
      onClick={onClick ? () => onClick() : link ? () => navigate(link) : () => navigate(-1)}
      iconName="arrow-back"
      iconPosition="left"
      label="Back"
    />
  );
};
