import { Route, Routes, matchRoutes, useLocation, useNavigate } from 'react-router';
import { RoutesList } from './state/Routes';
import './assets/scss/app.scss';
import { HomePage } from './pages/HomePage/HomePage';
import { TokensPage } from './pages/TokensPage/TokensPage';
import { TransactionsPage } from './pages/TransactionsPage/TransactionsPage';
import { BlocksPage } from './pages/BlocksPage/BlocksPage';
import { GraphsPage } from './pages/GraphsPage/GraphsPage';
import { NftsPage } from './pages/NftsPage/NftsPage';
import { TransactionPage } from './pages/TransactionPage/TransactionPage';
import { Notifications } from './components/Notifications/Notifications';
import { AddressPage } from './pages/AddressPage/AddressPage';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from './state/AppState';
import { BlockPage } from './pages/BlockPage/BlockPage';

const App = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const store = useStore();
  useEffect(() => {
    store.initNavigate(navigate);
  }, []);
  useEffect(() => {
    store.onChangeRoute(location.pathname);
  }, [location.pathname]);
  return (
    <div>
      <Notifications />
      <Routes>
        <Route path={RoutesList.MAIN_PAGE} element={<HomePage />} />
        <Route path={RoutesList.TOKENS} element={<TokensPage />} />
        <Route path={RoutesList.TRANSACTIONS} element={<TransactionsPage />} />
        <Route path={RoutesList.TRANSACTION} element={<TransactionPage />} />
        <Route path={RoutesList.ADDRESS} element={<AddressPage />} />
        <Route path={RoutesList.BLOCKS} element={<BlocksPage />} />
        <Route path={RoutesList.BLOCK} element={<BlockPage />} />
        <Route path={RoutesList.GRAPHS} element={<GraphsPage />} />
        <Route path={RoutesList.NFTS} element={<NftsPage />} />
      </Routes>
    </div>
  );
});

export default App;
