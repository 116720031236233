import { observer } from 'mobx-react';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useStore } from '../../state/AppState';
import { useParams } from 'react-router';
import { MouseEvent, useEffect, useState } from 'react';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import './AddressPage.scss';
import { CopyButton } from '../../components/controls/CopyButton/CopyButton';
import { ContentBlockTitle } from '../../components/ContentBlock/ContentBlockTitle';
import { Link } from 'react-router-dom';
import { BackButton } from '../../components/controls/BackButton/BackButton';
import { TableLoader } from '../../components/TableLoader/TableLoader';
import { Pagination } from '../../components/Pagination/Pagination';
import { getShortAddress } from '../../state/utils/getShortAddress';
import { CurrencyIcon } from '../../components/CurrencyIcon/CurrencyIcon';
import useComponentVisible from '../../hooks/useComponentVisible';
import { Icon } from '../../components/Icon/Icon';
import { Button } from '../../components/controls/Button/Button';
import { TxAddressesList } from '../../components/TxAddressesList/TxAddressesList';
import { protocol } from '../../api/proto';
import cn from 'classnames';
import { getChainShortName } from '../../state/utils/Money';
import { Transaction } from '../../state/transactions/Transaction';
import { TransactionInsOuts } from '../../components/TransactionInsOuts/TransactionInsOuts';
import { PeriodFilters } from '../../components/PeriodFilters/PeriodFilters';
import { PERIODS } from '../../state/AddressStore';

export const AddressPage = observer(() => {
  const store = useStore();
  const { addressStore } = store;
  const { address, transactions } = addressStore;
  const params = useParams();
  useEffect(() => {
    addressStore.init(params.address);
  }, [params.address]);
  return (
    <PageContainer>
      <MainSearchInput />
      <ContentBlock className="margin-b-16">
        <>
          <BackButton className="margin-b-16" />
          <div className="InfoItem">
            <div className="InfoItem__title">Address</div>
            <div className="InfoItem__value middle">
              {address}
              <CopyButton contentForCopy={address} />
            </div>
          </div>
          {addressStore.chainCurrency ? (
            <div className="InfoItem">
              <div className="InfoItem__title">{addressStore.chainCurrency.currencyFormatted}</div>
              <div className="InfoItem__value big">
                {addressStore.chainCurrency.amountFormatted}
                {addressStore.chainCurrency.balance?.valueCurrency ? (
                  <CurrencyIcon size="lg" currency={addressStore.chainCurrency.balance?.valueCurrency} />
                ) : null}
                <span className="InfoItem__value__nowrap">{addressStore.chainCurrency.currencyFormatted}</span>{' '}
                {addressStore.chainCurrency?.amountUsdFormatted ? (
                  <span className="InfoItem__value__gray"> · {addressStore.chainCurrency?.amountUsdFormatted}</span>
                ) : null}
              </div>
            </div>
          ) : null}
          {addressStore.balances?.length
            ? addressStore.balances?.map(balance => (
                <div className="InfoItem">
                  <div className="InfoItem__title">{balance.currencyFormatted}</div>
                  <div className="InfoItem__value middle">
                    {balance.amountFormatted}
                    {balance.balance?.valueCurrency ? (
                      <CurrencyIcon size="md" currency={balance.balance.valueCurrency} />
                    ) : null}
                    <span>{balance.currencyFormatted}</span>
                    {balance?.amountUsdFormatted ? (
                      <span className="InfoItem__value__gray"> · {balance?.amountUsdFormatted}</span>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </>
      </ContentBlock>
      <ContentBlock>
        <>
          <ContentBlockTitle className="margin-b-16">
            {addressStore.totalCountTxs === 10000 ? 'Showing first ' : null}
            {addressStore.totalCountTxs ? `${addressStore.totalCountTxs} ` : null}Transactions
          </ContentBlockTitle>
          <div className="AddressPage__tableTop">
            <div>
              {addressStore.chainCurrency?.balance?.valueCurrency !== protocol.WLCurrency.WLC_BTC ? (
                <div className="AddressPage__txsTabs">
                  <div
                    onClick={() => addressStore.onChangeTxsTab('all')}
                    className={cn('AddressPage__txsTab', { active: addressStore.txsTab === 'all' })}
                  >
                    All
                  </div>
                  <div
                    onClick={() => addressStore.onChangeTxsTab('tokens')}
                    className={cn('AddressPage__txsTab', { active: addressStore.txsTab === 'tokens' })}
                  >
                    Token transfers
                  </div>
                </div>
              ) : null}
            </div>
            <PeriodFilters
              customDateRange={addressStore.customDateRange}
              setCustomDateRange={addressStore.setCustomDateRange}
              periods={PERIODS as unknown as string[]}
              value={addressStore.period!}
              onChange={addressStore.changePeriod}
              withoutFixed
            />
          </div>

          <AddressPageTxsTable />
          {addressStore.totalPages > 1 ? (
            <Pagination
              pageCount={addressStore.totalPages}
              page={addressStore.page}
              onPageChange={addressStore.changePage}
              saveScrollPosition
              isLoading={addressStore.addressLoading}
            />
          ) : null}
        </>
      </ContentBlock>
    </PageContainer>
  );
});

type TxInfoRow = { hash: string; isInfoRow: boolean };
type TransactionRow = Transaction | TxInfoRow;

const AddressPageTxsTable = observer(() => {
  const store = useStore();
  const { addressStore } = store;
  const { transactions } = addressStore;
  const [rows, setRows] = useState<Transaction[]>([]);
  useEffect(() => {
    setRows(transactions);
  }, [transactions]);
  const onRowClick = (tx: Transaction) => {
    tx.toogleInfoForTable();
  };
  return (
    <div className="TableContainer">
      <table className="Table">
        <thead>
          <tr>
            <th>Hash</th>
            <th>Block</th>
            <th>Age</th>
            <th>From</th>
            <th>In | Out</th>
            <th>To</th>
            <th>Amount</th>
            <th>Amount, $</th>
            <th>Date and Time</th>
            <th></th>
          </tr>
        </thead>
        {addressStore.addressLoading ? (
          <TableLoader itemsInRow={9} />
        ) : transactions?.length ? (
          <tbody>
            {transactions.map(tx => (
              <>
                <tr className="Table__clickedRow" onClick={() => onRowClick(tx)}>
                  <td>
                    <Link to={`/transaction/${tx.hash}`}>{tx.shortHash}</Link>
                  </td>
                  <td>
                    {tx?.blockHeight ? (
                      <Link to={`/blocks/${getChainShortName(tx?.chain!)}/${tx?.blockHeight?.toNumber()}`}>
                        {tx?.blockHeight?.toNumber()}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="AddressPage__ageCell">{tx.ageFormatted}</td>
                  <td>
                    {tx?.ins?.length ? (
                      <TxAddressesList
                        onToggleShowMoreClick={() => onRowClick(tx)}
                        addresses={tx?.ins?.map(_ => ({ address: _.address, isValidAddress: _.isValidAddress }))}
                      />
                    ) : null}
                  </td>
                  <td>
                    {tx.inOrOutForAddress ? (
                      <div className={cn('AddressPage__inOrOutTxCell', tx.inOrOutForAddress)}>
                        {tx.inOrOutForAddress}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {tx?.outs?.length ? (
                      <TxAddressesList
                        onToggleShowMoreClick={() => onRowClick(tx)}
                        addresses={tx?.outs?.map(_ => ({ address: _.address, isValidAddress: _.isValidAddress }))}
                      />
                    ) : null}
                  </td>
                  <td>
                    <div
                      className={cn(
                        'd-flex flex-align-items-center column-gap-4 AddressPage__amountCell',
                        tx.inOrOutForAddress,
                      )}
                    >
                      {tx.selectedAddressAmount}
                      <span>
                        {tx.selectedAddressAmountCurrency ? (
                          <CurrencyIcon size="sm" currency={tx.selectedAddressAmountCurrency} />
                        ) : null}
                      </span>
                      <span>{tx.amountCurrencyFormatted}</span>
                    </div>
                  </td>
                  <td className={cn('AddressPage__amountCell', tx.inOrOutForAddress)}>{tx.selectedAddressAmountUsd}</td>
                  <td className="dateTime">{tx.timeFormatted}</td>
                  <td>
                    <div className={cn('Table__collapseCell', { active: tx.infoForTableIsOpen })}>
                      <Icon className="Table__collapseCell__icon" iconName="dropdown" widthInPx={26} heightInPx={26} />
                    </div>
                  </td>
                </tr>
                <tr className={cn('Table__infoRow', { active: tx.infoForTableIsOpen })}>
                  <td colSpan={10}>
                    <div>
                      {tx?.txOperations?.map(op => {
                        return <TransactionInsOuts ins={op.ins ?? []} outs={op.outs ?? []} />;
                      })}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        ) : (
          <div>Transactions for address not found</div>
        )}
      </table>
    </div>
  );
});
