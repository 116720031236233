import './LatestBlocks.scss';
import { Button } from '../../controls/Button/Button';
import { CurrencySelect } from '../../controls/CurrencySelect/CurrencySelect';
import { Icon } from '../../Icon/Icon';
import { observer } from 'mobx-react';
import { useStore } from '../../../state/AppState';
import { ContentBlockTitle } from '../../ContentBlock/ContentBlockTitle';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

export const LatestBlocks = observer(() => {
  const store = useStore();
  const { mainPageStore } = store;
  const navigate = useNavigate();

  return (
    <div className={'latestBlocks'}>
      <div className={'contentBlockHead'}>
        <ContentBlockTitle>Latest blocks</ContentBlockTitle>
        <Button onClick={() => navigate('/blocks')} label="All Blocks" />
      </div>
      <CurrencySelect onChange={mainPageStore.onChangeBlocksChain} chain={mainPageStore.blocksChain} />
      {mainPageStore.blocksLoading ? (
        <div className={'latestBlocksList'}>
          {Array.from(Array(20)).map(item => (
            <div className="margin-b-16">
              <Skeleton width={'100%'} height={48} />
            </div>
          ))}
        </div>
      ) : (
        <motion.div layout layoutId={'list'} className="latestBlocksList">
          <AnimatePresence>
            {mainPageStore.blocks.map((_, index) => {
              return (
                <motion.div
                  initial={!_.initialLoad ? { y: -200, opacity: 0 } : {}}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key={_.blockHeight?.toString()}
                  className={'latestBlocksItemWrapper'}
                >
                  <Link to={`/blocks/${_.chainShortName}/${_.blockHeight?.toString()}`} className={'latestBlocksItem'}>
                    <Icon iconName="block" widthInPx={48} heightInPx={48} />
                    <div className="latestBlocksItemContent">
                      <div className={'latestBlocksItemLeft'}>
                        <div className={'latestBlocksItemContentTop'}>{_.blockHeight?.toString()}</div>
                        <div className={'latestBlocksItemContentBottom'}>{_.timeFormatted}</div>
                      </div>
                      <div className={'latestBlocksItemRight'}>
                        <div>{_.txCount} Txs</div>
                        <div>{_.sizeFormatted}</div>
                      </div>
                    </div>
                  </Link>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </motion.div>
      )}

      {/*<div className={'latestBlocksList'}>
        {mainPageStore.blocksLoading
          ? Array.from(Array(20)).map(item => (
              <div className="margin-b-16">
                <Skeleton width={'100%'} height={48} />
              </div>
            ))
          : mainPageStore.blocks.map(_ => (
              <Link
                key={_.blockHeight?.toString()}
                to={`/blocks/${_.chainShortName}/${_.blockHeight?.toString()}`}
                className={'latestBlocksItem'}
              >
                <Icon iconName="block" widthInPx={48} heightInPx={48} />
                <div className="latestBlocksItemContent">
                  <div className={'latestBlocksItemLeft'}>
                    <div className={'latestBlocksItemContentTop'}>{_.blockHeight?.toString()}</div>
                    <div className={'latestBlocksItemContentBottom'}>{_.timeFormatted}</div>
                  </div>
                  <div className={'latestBlocksItemRight'}>
                    <div>{_.txCount} Txs</div>
                    <div>{_.sizeFormatted}</div>
                  </div>
                </div>
              </Link>
            ))}
      </div>*/}
    </div>
  );
});
