import { observer } from 'mobx-react';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { MainSearchInput } from '../../components/controls/MainSearchInput/MainSearchInput';
import { Button } from '../../components/controls/Button/Button';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { useEffect } from 'react';
import { useStore } from '../../state/AppState';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { BackButton } from '../../components/controls/BackButton/BackButton';
import './BlockPage.scss';
import { BlockContent } from './BlockContent';
import { BlockTransactions } from './BlockTransactions';

export const BlockPage = observer(() => {
  const store = useStore();
  const { blockPageStore } = store;
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.blockHeight && params.chain) {
      blockPageStore.init(params.chain, params.blockHeight);
    }
    return () => {};
  }, []);
  return (
    <PageContainer>
      <MainSearchInput />
      {blockPageStore.isLoading || blockPageStore.block ? (
        <div className="BlockPageContentContainer">
          <BlockContent />
          <BlockTransactions />
        </div>
      ) : (
        <ContentBlock>
          <div className="d-flex flex-justify-center">
            <div className="d-flex flex-column flex-align-items-center">
              <div className="margin-b-16">Block not found</div>
              <Button onClick={() => navigate('/')} label="Back" />
            </div>
          </div>
        </ContentBlock>
      )}
    </PageContainer>
  );
});
